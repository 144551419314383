import { TranscriptionViewer } from '@laguna/components/TranscriptionViewer';
import { Chunk, TranscriptRow } from '@laguna/components/TranscriptionViewer/types';
import { Button, Card, Divider, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { OutputTagTarget } from '../../../hooks/types';
import { TaggingGroup } from './TaggingGroup';
import { TagGroup, TagGroupItem } from './types';
import { useTagResource } from './useTagResource';

interface TranscriptTaggingJobProps {
  onSelect: (
    chunks: Chunk[],
    selectedParagraphs: string,
    getTarget: () => Promise<OutputTagTarget | undefined>
  ) => Promise<void>;
  onTagRemove: (item: TagGroupItem, freeText: string) => void;
  transcriptMetadata: Record<string, Chunk[]>;
  tagGroups: TagGroup[];
  transcript: TranscriptRow[];
  onAddGeneral: (tag: OutputTagTarget) => void;
  onRemoveGeneral: (tag: OutputTagTarget) => void;
  generalTags: OutputTagTarget[];
}

export const TranscriptTaggingJob: React.FC<TranscriptTaggingJobProps> = ({
  onSelect,
  onTagRemove,
  transcriptMetadata,
  transcript,
  tagGroups,
  onAddGeneral,
  onRemoveGeneral,
  generalTags,
}) => {
  const { tagResource } = useTagResource();
  const internalOnSelect = async (chunks: Chunk[], selectedParagraphs: string) =>
    onSelect(chunks, selectedParagraphs, tagResource);

  const onAddGeneralClicked = async () => {
    const tag = await tagResource();

    if (tag) {
      onAddGeneral(tag);
    }
  };

  return (
    <Stack direction='row' gap={5} flex={1}>
      <Stack flex={4} paddingY={2} gap={1} minHeight='80vh'>
        <Typography variant='h6'>{i18next.t('tagging:input')}</Typography>
        <Divider />
        <TranscriptionViewer
          selectionThreshold={2}
          transcriptMetadata={transcriptMetadata}
          onSelect={internalOnSelect}
          transcript={transcript}
        />
      </Stack>

      <Stack flex={3} paddingY={2} gap={1}>
        <Card>
          <Stack padding={2} gap={1}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography>{i18next.t('tagging:generalTags')}</Typography>
              <Button onClick={onAddGeneralClicked}>{i18next.t('tagging:addGeneralTag')}</Button>
            </Stack>
            {!!generalTags.length && (
              <TaggingGroup
                group={{ id: 'General', items: generalTags, freeText: 'General' }}
                onRemoveClicked={onRemoveGeneral}
              />
            )}
          </Stack>
        </Card>
        <Card>
          <Stack padding={2} gap={1}>
            <Typography variant='h6'>{i18next.t('tagging:output')}</Typography>
            <Divider />
            {!tagGroups?.length && <Typography>{i18next.t('tagging:noTags')}</Typography>}
            {tagGroups?.map((group, index) => (
              <TaggingGroup key={group.id + index} group={group} onRemoveClicked={onTagRemove} />
            ))}
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};
