import { logger } from '@laguna/logger';
import { FetchQueryOptions, QueryClient } from '@tanstack/react-query';
import { extractQueryType, queryAdditionalMethods } from './consts';

export let queryClient: QueryClient;

export const getQueryClient = () => queryClient;

export const createQueryClient = (config: { staleTime: number }) => {
  queryClient = new QueryClient({
    logger: logger,
    defaultOptions: {
      queries: {
        staleTime: config.staleTime,
        retry: 2,
      },
    },
  });
  return queryClient;
};

export const getQueryResult = async <T extends ((args?: any) => void) & queryAdditionalMethods>(
  query: T,
  variables?: any,
  options?: FetchQueryOptions<T, unknown, T, any>,
  fetcherOptions?: RequestInit['headers']
) => {
  if (!queryClient) {
    throw new Error('running getQueryResult on empty client');
  }

  const endpointName = (fetcherOptions as Endpoint)?.name;
  const key = query.getKey(variables);
  if (endpointName) {
    //add endpoint name to key for multi endpoint use
    //example getOrgs from DEMO and DEV both has the same key and they should not
    key[0] = `${key[0]}(${endpointName})`;
  }
  const res = await queryClient.fetchQuery(key, query.fetcher(variables, fetcherOptions), options);
  return res as extractQueryType<T>;
};

export const getMutationResult = async <T, Y>(
  query: { fetcher: (params: T, fetcherOptions?: RequestInit['headers']) => () => Promise<Y> },
  params: T,
  fetcherOptions?: RequestInit['headers']
): Promise<Y> => {
  const promiseResult = fetcherOptions ? query.fetcher(params, fetcherOptions) : query.fetcher(params);
  return await promiseResult();
};

export type QueryType = ((args?: any) => void) & queryAdditionalMethods;
export type Endpoint = { token: string; url: string; name: string };

export const getQueryResults = async <T extends QueryType>(query: T, variables: any, endpoint: Endpoint) => {
  const res = await getQueryResult(query, variables, {}, endpoint);
  return { [endpoint.name]: res };
};
