import { css, SerializedStyles } from '@emotion/react';
import { FieldsType } from './formTypes';

export const buildLayoutFromFields = (fields: FieldsType[]): SerializedStyles => {
  const groupById: { [key: string]: FieldsType[] } = {};
  fields.forEach((field) => {
    if (field.rowId) {
      groupById[field.rowId] ??= [];
      groupById[field.rowId].push(field);
    } else {
      groupById[field.name] ??= [field];
    }
  });
  const maxRowLength = Math.max(...Object.values(groupById).map((group) => group.length));
  const gridTemplateAreas = Object.values(groupById)
    .map((groupedFields) => {
      if (groupedFields.length === 1) {
        return `'` + `${groupedFields[0].name} `.repeat(maxRowLength) + `'`;
      }
      return `'${groupedFields.map((g) => g.name).join(' ')}'`;
    })
    .join(' ');
  return css`
    display: grid;
    grid-gap: var(--padding);
    grid-template-areas: ${gridTemplateAreas};
    align-items: baseline;
  `;
};
