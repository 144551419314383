import { FormControl, FormHelperText } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FilePicker as FilePickerField, SharedFieldProps } from '../formTypes';
import { FilePicker } from './FilePicker';

interface FilePickerInputProps extends SharedFieldProps, Omit<FilePickerField, 'type' | 'validate'> {}

export const FilePickerInput: React.FC<FilePickerInputProps> = ({
  name,
  label,
  control,
  defaultValue,
  required,
  disabled,
  testId,
  accept,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        validate: (fileUrl: string) => (fileUrl ? undefined : 'Missing file'),
      }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl
            variant='outlined'
            disabled={disabled}
            required={!!required}
            fullWidth
            className={'generate-form-item-' + name}>
            <FilePicker label={label} value={value} onChange={onChange} testId={testId} accept={accept} />
            <FormHelperText error>{error ? error.message : null}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
