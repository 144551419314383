import { QueryKey } from "@tanstack/react-query";

const SPLIT_CHAR = '@/@';

export const getHash = (key?: QueryKey) => {
    if (!key) {
      return null;
    }
    const variableStr = key[1] ? JSON.stringify(key[1]) : '';
    return `${key[0]}${SPLIT_CHAR}${variableStr}`;
  };