export enum PATHS {
  ARTICLES = 'articles',
  ORG_ORDER = 'order',
  ATTRIBUTES = 'attributes',
  TAGGING = 'tagging',
  CONFIG_PROMPTS = 'configPrompts',
}
export const TAB_URL_PARAM = 'tab';

export enum ARTICLES_TABS {
  ArticlesList = 'ArticlesList',
  ArticleOrder = 'ArticleOrder',
}

export const ARTICLE_ORDER_TAB_ABSOLUTE = `/${PATHS.ARTICLES}?${TAB_URL_PARAM}=${ARTICLES_TABS.ArticleOrder}`;

export interface TaggingJobParams {
  userId: string;
  jobId: string;
  jobType: string;
}
