import {
  addHours,
  addMinutes,
  addSeconds,
  eachDayOfInterval,
  endOfDay,
  endOfWeek,
  format,
  getHours,
  getMinutes,
  intervalToDuration,
  isToday,
  isYesterday,
  parse,
  setHours,
  setMinutes,
  startOfDay,
  startOfWeek,
  subYears,
} from 'date-fns';
import { US_DATE_FORMAT } from '@laguna/common/consts';
import { DAY_NAME_FORMAT, INITIAL_DATE_AGE, SERVER_DATE_FORMAT } from './consts';
import en from 'date-fns/locale/en-US';
import i18next from 'i18next';
import { isNumber } from 'lodash';

export const getRoundedHour = (hour: number, minutes: number): Date =>
  setMinutes(setHours(startOfDay(new Date()), hour), minutes);

export const getMinuteHourFromTime = (time: Date) => ({
  minute: [getMinutes(time)],
  hour: [getHours(time)],
});

export const getTimeFromSeconds = (seconds: number) => {
  const { hours, minutes, seconds: secondsLeft } = intervalToDuration({ start: 0, end: seconds * 1000 });
  const display = (number: number) => (number >= 10 ? number : '0' + number);

  return `${display(hours || 0)}:${display(minutes || 0)}:${display(secondsLeft || 0)}`;
};

export const getFormattedDate = (date: Date, dateFormat = SERVER_DATE_FORMAT) =>
  date ? format(date, dateFormat) : date;
export const getFormattedDateAndTime = (date: Date, dateFormat = US_DATE_FORMAT) =>
  `${format(date, dateFormat)} ${format(date, 'p')}`;
export const getFormattedShortTime = (date: Date) => format(date, 'p');

const compareDates = (obj1: any, obj2: any, order: 'asc' | 'desc', data = 'data') => {
  if (!obj1 || !obj1[data]) return order === 'asc' ? 1 : -1;
  if (!obj2 || !obj2[data]) return order === 'asc' ? -1 : 1;
  return (new Date(obj1[data]).getTime() - new Date(obj2[data]).getTime()) * (order === 'asc' ? 1 : -1);
};

export const sortDates = (order: 'asc' | 'desc', data = 'data') => {
  return (obj1: any, obj2: any) => compareDates(obj1, obj2, order, data);
};

export const getFormattedTime = (diffInSec: number, outputFormat = 'mm:ss') => {
  const helperDate = addSeconds(new Date(0), diffInSec);
  return format(helperDate, outputFormat);
};

export const convertToZulu = (date: Date) => addMinutes(date, date.getTimezoneOffset());

export const isInOfWorkingHours = (utcDelta: number | undefined | null) => {
  const properUtc = isNumber(utcDelta) ? utcDelta : 0;
  const utcNow = convertToZulu(new Date());
  const offset = addHours(utcNow, properUtc);
  const isIn = offset.getHours() > 8 && offset.getHours() < 21;
  return { isIn, offset };
};

export const isTimezoneInOfWorkingHours = (timezone: string) => {
  if (!timezone) {
    return true;
  }
  const _timezone = new Date(new Date().toLocaleString('en-US', { timeZone: timezone }));
  const hours = _timezone.getHours();
  return hours > 8 && hours < 21;
};

export const getWeekDaysArray = (locale = en) => {
  const now = new Date();
  const weekDays: string[] = [];
  const start = startOfWeek(now, { locale });
  const end = endOfWeek(now, { locale });
  eachDayOfInterval({ start, end }).forEach((day) => {
    weekDays.push(format(day, DAY_NAME_FORMAT, { locale }));
  });
  return weekDays;
};

const setUTCDate = (date: Date, ...args: [number, number, number, number]) => {
  const newDate = addMinutes(date, -date.getTimezoneOffset());
  newDate.setUTCHours(...args);
  return newDate;
};

export const getUTCStartOfDay = (date: Date) => setUTCDate(date, 0, 0, 0, 0);
export const getUTCEndOfDay = (date: Date) => setUTCDate(date, 23, 59, 59, 999);

export const prepareSingleDateForServerIgnoreTZ = (date: Date) => addMinutes(date, -date.getTimezoneOffset());
export const getSingleDateFromStringIgnoreTZ = (dateString: string) => new Date(dateString.replace('Z', ''));

export const getDatesFromStringsIgnoreTZ = <T extends { start?: string; end?: string }>(serverObject: T) => ({
  ...serverObject,
  end: serverObject.end ? getSingleDateFromStringIgnoreTZ(serverObject.end) : null,
  start: serverObject.start ? getSingleDateFromStringIgnoreTZ(serverObject.start) : null,
});

export const prepareDatesForServerIgnoreTZ = <T extends { start?: Date; end?: Date; cronExpressions?: string[] }>({
  end,
  start,
  cronExpressions,
  ...rest
}: T) => ({
  ...rest,
  cronExpressions: !cronExpressions?.length ? undefined : cronExpressions,
  end: end ? prepareSingleDateForServerIgnoreTZ(endOfDay(end)) : end,
  start: start ? prepareSingleDateForServerIgnoreTZ(startOfDay(start)) : start,
});

export const getDateFromServerFormat = (date?: string) =>
  date ? parse(date, SERVER_DATE_FORMAT, new Date()) : undefined;

export const getMinEligibleAgeDate = () => subYears(new Date(), INITIAL_DATE_AGE);
export const getDateFormattedForServer = (date: Date) => (date ? format(date, SERVER_DATE_FORMAT) : date);

export const getMessageDateStr = (date: Date) => {
  if (isToday(date)) {
    return `${i18next.t('common:today')}, ${format(date, 'hh:mma')}`;
  }

  if (isYesterday(date)) {
    return `${i18next.t('common:yesterday')}, ${format(date, 'hh:mma')}`;
  }

  return format(date, 'MMM dd, hh:mma');
};

export const getMinutesTimeFromSeconds = (secondsAmount: number) => {
  // converts seconds to -> m:ss (0:12)
  const minutes = Math.floor((secondsAmount % 3600) / 60).toString();
  const seconds = Math.floor(secondsAmount % 60)
    .toString()
    .padStart(2, '0');
  return minutes + ':' + seconds;
};

const padDuration = (d?: number): string => `0${d}`.slice(-2);
const getFormattedDurationBreakdown = (seconds?: number, minutes?: number, hours?: number) => {
  const arr = [padDuration(minutes), padDuration(seconds)];
  if (hours) arr.unshift(padDuration(hours));
  return arr.join(':');
};

export const getFormattedDuration = ({ start, end }: { start?: Date; end?: Date }) => {
  if (!start || !end) return '-';
  const { hours, minutes, seconds } = intervalToDuration({
    start,
    end,
  });
  return getFormattedDurationBreakdown(seconds, minutes, hours);
};

export const getFormattedDurationFromSeconds = (durationInSeconds?: number) => {
  if (!durationInSeconds) return '-';
  const seconds = durationInSeconds % 60;
  const minutes = Math.floor(durationInSeconds / 60) % 60;
  const hours = Math.floor(durationInSeconds / 3600);
  return getFormattedDurationBreakdown(seconds, minutes, hours);
};
