import * as Types from '../types';

import { ArticleFragment, ConfigFragment } from './fragment.generated';
import { ArticleFragmentDoc, ConfigFragmentDoc } from './fragment.generated';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetchData } from './fetchDataProxy';
export type SetArticleMutationVariables = Types.Exact<{
  setArticleParams: Types.SetArticleParams;
}>;


export type SetArticleMutation = { setArticle: ArticleFragment };

export type SetArticleGroupsMutationVariables = Types.Exact<{
  setArticleGroupsParams: Types.SetArticleGroupsParams;
}>;


export type SetArticleGroupsMutation = { setArticleGroups: Array<ArticleFragment> };

export type RemoveArticleGroupMutationVariables = Types.Exact<{
  articleGroupParams: Types.ArticleGroupParams;
}>;


export type RemoveArticleGroupMutation = Pick<Types.Mutation, 'removeArticleGroup'>;

export type AddArticleGroupMutationVariables = Types.Exact<{
  articleGroupParams: Types.ArticleGroupParams;
}>;


export type AddArticleGroupMutation = Pick<Types.Mutation, 'addArticleGroup'>;

export type DeleteArticleMutationVariables = Types.Exact<{
  deleteArticleParams: Types.DeleteArticleParams;
}>;


export type DeleteArticleMutation = Pick<Types.Mutation, 'deleteArticle'>;

export type SetAttributeOptionMutationVariables = Types.Exact<{
  setAttributeOptionParams: Types.SetAttributeOptionParams;
}>;


export type SetAttributeOptionMutation = { setAttributeOption: Pick<Types.Attribute, 'category' | 'key' | 'values' | 'description' | 'tags'> };

export type PublishAttributeOptionMutationVariables = Types.Exact<{
  publishAttributeOptionParams: Types.PublishAttributeOptionParams;
}>;


export type PublishAttributeOptionMutation = Pick<Types.Mutation, 'publishAttributeOption'>;

export type SetConfigAttributeMutationVariables = Types.Exact<{
  setConfigAttributeParams: Types.SetConfigAttributeParams;
}>;


export type SetConfigAttributeMutation = { setConfigAttribute: Pick<Types.Config, 'name'> };


export const SetArticleDocument = `
    mutation setArticle($setArticleParams: SetArticleParams!) {
  setArticle(setArticleParams: $setArticleParams) {
    ...articleFragment
  }
}
    ${ArticleFragmentDoc}`;
export const useSetArticleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetArticleMutation, TError, SetArticleMutationVariables, TContext>) =>
    useMutation<SetArticleMutation, TError, SetArticleMutationVariables, TContext>(
      ['setArticle'],
      (variables?: SetArticleMutationVariables) => fetchData<SetArticleMutation, SetArticleMutationVariables>(SetArticleDocument, variables, undefined, ['setArticle'])(),
      options
    );
useSetArticleMutation.getKey = () => ['setArticle'];

useSetArticleMutation.fetcher = (variables: SetArticleMutationVariables, options?: RequestInit['headers']) => fetchData<SetArticleMutation, SetArticleMutationVariables>(SetArticleDocument, variables, options, ['setArticle', variables]);
export const SetArticleGroupsDocument = `
    mutation setArticleGroups($setArticleGroupsParams: SetArticleGroupsParams!) {
  setArticleGroups(setArticleGroupsParams: $setArticleGroupsParams) {
    ...articleFragment
  }
}
    ${ArticleFragmentDoc}`;
export const useSetArticleGroupsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetArticleGroupsMutation, TError, SetArticleGroupsMutationVariables, TContext>) =>
    useMutation<SetArticleGroupsMutation, TError, SetArticleGroupsMutationVariables, TContext>(
      ['setArticleGroups'],
      (variables?: SetArticleGroupsMutationVariables) => fetchData<SetArticleGroupsMutation, SetArticleGroupsMutationVariables>(SetArticleGroupsDocument, variables, undefined, ['setArticleGroups'])(),
      options
    );
useSetArticleGroupsMutation.getKey = () => ['setArticleGroups'];

useSetArticleGroupsMutation.fetcher = (variables: SetArticleGroupsMutationVariables, options?: RequestInit['headers']) => fetchData<SetArticleGroupsMutation, SetArticleGroupsMutationVariables>(SetArticleGroupsDocument, variables, options, ['setArticleGroups', variables]);
export const RemoveArticleGroupDocument = `
    mutation removeArticleGroup($articleGroupParams: ArticleGroupParams!) {
  removeArticleGroup(articleGroupParams: $articleGroupParams)
}
    `;
export const useRemoveArticleGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveArticleGroupMutation, TError, RemoveArticleGroupMutationVariables, TContext>) =>
    useMutation<RemoveArticleGroupMutation, TError, RemoveArticleGroupMutationVariables, TContext>(
      ['removeArticleGroup'],
      (variables?: RemoveArticleGroupMutationVariables) => fetchData<RemoveArticleGroupMutation, RemoveArticleGroupMutationVariables>(RemoveArticleGroupDocument, variables, undefined, ['removeArticleGroup'])(),
      options
    );
useRemoveArticleGroupMutation.getKey = () => ['removeArticleGroup'];

useRemoveArticleGroupMutation.fetcher = (variables: RemoveArticleGroupMutationVariables, options?: RequestInit['headers']) => fetchData<RemoveArticleGroupMutation, RemoveArticleGroupMutationVariables>(RemoveArticleGroupDocument, variables, options, ['removeArticleGroup', variables]);
export const AddArticleGroupDocument = `
    mutation addArticleGroup($articleGroupParams: ArticleGroupParams!) {
  addArticleGroup(articleGroupParams: $articleGroupParams)
}
    `;
export const useAddArticleGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddArticleGroupMutation, TError, AddArticleGroupMutationVariables, TContext>) =>
    useMutation<AddArticleGroupMutation, TError, AddArticleGroupMutationVariables, TContext>(
      ['addArticleGroup'],
      (variables?: AddArticleGroupMutationVariables) => fetchData<AddArticleGroupMutation, AddArticleGroupMutationVariables>(AddArticleGroupDocument, variables, undefined, ['addArticleGroup'])(),
      options
    );
useAddArticleGroupMutation.getKey = () => ['addArticleGroup'];

useAddArticleGroupMutation.fetcher = (variables: AddArticleGroupMutationVariables, options?: RequestInit['headers']) => fetchData<AddArticleGroupMutation, AddArticleGroupMutationVariables>(AddArticleGroupDocument, variables, options, ['addArticleGroup', variables]);
export const DeleteArticleDocument = `
    mutation deleteArticle($deleteArticleParams: DeleteArticleParams!) {
  deleteArticle(deleteArticleParams: $deleteArticleParams)
}
    `;
export const useDeleteArticleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteArticleMutation, TError, DeleteArticleMutationVariables, TContext>) =>
    useMutation<DeleteArticleMutation, TError, DeleteArticleMutationVariables, TContext>(
      ['deleteArticle'],
      (variables?: DeleteArticleMutationVariables) => fetchData<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, variables, undefined, ['deleteArticle'])(),
      options
    );
useDeleteArticleMutation.getKey = () => ['deleteArticle'];

useDeleteArticleMutation.fetcher = (variables: DeleteArticleMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, variables, options, ['deleteArticle', variables]);
export const SetAttributeOptionDocument = `
    mutation setAttributeOption($setAttributeOptionParams: SetAttributeOptionParams!) {
  setAttributeOption(setAttributeOptionParams: $setAttributeOptionParams) {
    category
    key
    values
    description
    tags
  }
}
    `;
export const useSetAttributeOptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetAttributeOptionMutation, TError, SetAttributeOptionMutationVariables, TContext>) =>
    useMutation<SetAttributeOptionMutation, TError, SetAttributeOptionMutationVariables, TContext>(
      ['setAttributeOption'],
      (variables?: SetAttributeOptionMutationVariables) => fetchData<SetAttributeOptionMutation, SetAttributeOptionMutationVariables>(SetAttributeOptionDocument, variables, undefined, ['setAttributeOption'])(),
      options
    );
useSetAttributeOptionMutation.getKey = () => ['setAttributeOption'];

useSetAttributeOptionMutation.fetcher = (variables: SetAttributeOptionMutationVariables, options?: RequestInit['headers']) => fetchData<SetAttributeOptionMutation, SetAttributeOptionMutationVariables>(SetAttributeOptionDocument, variables, options, ['setAttributeOption', variables]);
export const PublishAttributeOptionDocument = `
    mutation publishAttributeOption($publishAttributeOptionParams: PublishAttributeOptionParams!) {
  publishAttributeOption(
    publishAttributeOptionParams: $publishAttributeOptionParams
  )
}
    `;
export const usePublishAttributeOptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishAttributeOptionMutation, TError, PublishAttributeOptionMutationVariables, TContext>) =>
    useMutation<PublishAttributeOptionMutation, TError, PublishAttributeOptionMutationVariables, TContext>(
      ['publishAttributeOption'],
      (variables?: PublishAttributeOptionMutationVariables) => fetchData<PublishAttributeOptionMutation, PublishAttributeOptionMutationVariables>(PublishAttributeOptionDocument, variables, undefined, ['publishAttributeOption'])(),
      options
    );
usePublishAttributeOptionMutation.getKey = () => ['publishAttributeOption'];

usePublishAttributeOptionMutation.fetcher = (variables: PublishAttributeOptionMutationVariables, options?: RequestInit['headers']) => fetchData<PublishAttributeOptionMutation, PublishAttributeOptionMutationVariables>(PublishAttributeOptionDocument, variables, options, ['publishAttributeOption', variables]);
export const SetConfigAttributeDocument = `
    mutation setConfigAttribute($setConfigAttributeParams: SetConfigAttributeParams!) {
  setConfigAttribute(setConfigAttributeParams: $setConfigAttributeParams) {
    name
  }
}
    `;
export const useSetConfigAttributeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetConfigAttributeMutation, TError, SetConfigAttributeMutationVariables, TContext>) =>
    useMutation<SetConfigAttributeMutation, TError, SetConfigAttributeMutationVariables, TContext>(
      ['setConfigAttribute'],
      (variables?: SetConfigAttributeMutationVariables) => fetchData<SetConfigAttributeMutation, SetConfigAttributeMutationVariables>(SetConfigAttributeDocument, variables, undefined, ['setConfigAttribute'])(),
      options
    );
useSetConfigAttributeMutation.getKey = () => ['setConfigAttribute'];

useSetConfigAttributeMutation.fetcher = (variables: SetConfigAttributeMutationVariables, options?: RequestInit['headers']) => fetchData<SetConfigAttributeMutation, SetConfigAttributeMutationVariables>(SetConfigAttributeDocument, variables, options, ['setConfigAttribute', variables]);