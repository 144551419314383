import React from 'react';
import { Delete, LocalOfferOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { TagGroup, TagGroupItem } from './types';
import { truncate } from 'lodash';
import { ItemsList } from '../../../components/ItemsList';

interface TaggingGroupProps {
  group: TagGroup;
  onRemoveClicked: (item: TagGroupItem, freeText: string) => void;
}

export const TaggingGroup: React.FC<TaggingGroupProps> = ({ group, onRemoveClicked }) => {
  return (
    <Stack data-testid='tagGroup'>
      <Stack direction='row' alignItems='center' gap={1}>
        <LocalOfferOutlined />
        <Typography fontWeight='bold'>{truncate(group.freeText, { length: 45, omission: '...' })}</Typography>
      </Stack>
      <ItemsList
        items={group.items}
        primaryText='id'
        secondaryText={'type'}
        secondaryAction={(item) => (
          <IconButton onClick={() => onRemoveClicked(item, group.freeText.trim())}>
            <Delete />
          </IconButton>
        )}
      />
    </Stack>
  );
};
