import { RowState } from '@laguna/types';
import { TableCell, TableRow, Theme, css } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Row } from 'react-table';

interface DataTableRowProps {
  row: Row;
  setRowHeight: (size: number) => void;
  state?: RowState;
}

const rowCss = css`
  display: flex;
  width: fit-content;
`;

const disableStyle = (theme: Theme) => css`
  color: ${theme.palette.text.disabled};
`;

const baseCellCenterStyle = (theme: any) =>
  css`
    padding-left: ${theme.spacing(5)};
  `;

const baseCellStyle = (theme: any) =>
  css`
    padding: 0;
    border-bottom: 1px solid ${theme.palette.divider};
  `;

const _DataTableRow = ({ row, setRowHeight, state }: DataTableRowProps) => {
  const props = row.getRowProps();
  const rowRef = useRef<HTMLTableRowElement>(null);
  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(rowRef.current.clientHeight);
      let height = rowRef.current.clientHeight;
      const resizeObserver = new ResizeObserver(
        debounce(() => {
          if (rowRef.current && height !== rowRef.current?.clientHeight) {
            setRowHeight(rowRef.current.clientHeight);
            height = rowRef.current.clientHeight;
          }
        }, 100)
      );
      resizeObserver.observe(rowRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <TableRow
      key={props.key}
      css={[rowCss, state?.isDisabled && disableStyle]}
      ref={rowRef}
      component='div'
      data-testid={`dataTableRow-${row.id}`}>
      {row.cells.map((cell) => {
        const { key, style, ...cellProps } = cell.getCellProps();
        const cellStyle = { ...(style || {}), width: cell.column.totalWidth };
        return (
          <TableCell
            component='div'
            key={key}
            {...cellProps}
            css={[baseCellStyle, cellStyle, cell.column.center && baseCellCenterStyle]}>
            {cell.render('Cell')}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export const DataTableRow = React.memo(_DataTableRow);
