/* eslint-disable react-hooks/exhaustive-deps */
import { TextFieldProps } from '@mui/material';
import { identity } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DynamicSelectField, SharedFieldProps } from '../formTypes';
import { formsStore } from '../formsStore';
import SelectInput from './SelectInput';

interface DynamicSelectInputProps extends SharedFieldProps, Omit<DynamicSelectField, 'type' | 'validate'> {
  inputProps?: TextFieldProps['inputProps'];
}

const DynamicSelectInput: React.FC<DynamicSelectInputProps> = ({ name, fetchItems, formId, ...rest }) => {
  const [values, setValues] = useState<Record<string, string>>(
    rest.defaultValue ? { [rest.defaultValue]: rest.defaultValue } : {}
  );
  const query = formsStore.dynamicSelectChanges[formId + name];

  const getItems = async () => {
    setValues(await fetchItems(query));
  };

  useEffect(() => {
    if (query) {
      getItems();
    }
  }, [query]);

  useEffect(() => {
    return () => {
      formsStore.removeTrigger(formId, name);
    };
  }, []);

  return (
    <SelectInput values={values} nullable={true} name={name} transformValues={identity} formId={formId} {...rest} />
  );
};

export default observer(DynamicSelectInput);
