import {
  Endpoint,
  extractQueryType,
  getMutationResult,
  getQueryResult,
  getQueryResults,
  getUserEnvs,
  queryClient,
  QueryType,
} from '@laguna/api/utils';
import { logger } from '@laguna/logger';

export const getMultiQueriesResults = async <T extends QueryType>(query: T) => {
  const endpoints = await getUserEnvs();

  const queries = endpoints.map((endpoint) => getQueryResults(query, undefined, endpoint));
  try {
    const all = await Promise.all(queries);

    return Object.assign({}, ...all) as { [key: string]: extractQueryType<T> };
  } catch (e) {
    return null;
  }
};

export const getEnvSpecificQueriesResult = async <T extends QueryType>(envName: string, query: T) => {
  const endpoints: Endpoint[] = await getUserEnvs();
  const wantedEnv = endpoints.find((endPoint) => endPoint.name === envName);
  if (!wantedEnv) {
    logger.error('Env not found', { envName });
    return null;
  }

  return getQueryResult(query, undefined, {}, wantedEnv);
};

export const getEnvSpecificMutationResult = async <T, Y>(
  env: string,
  mutation: { fetcher: (params: T) => () => Promise<Y> },
  params: T
) => {
  // const endpoints = await getUserEnvs();
  // const selectedEndpoint = endpoints.find((endpoint) => endpoint.name === env);
  return await getMutationResult(mutation, params);
};

export const invalidateEnvSpecificCache = async (env: string, key: string[]) => {
  const endpoints = await getUserEnvs();
  // const selectedEndpoint = endpoints.find((endpoint) => endpoint.name === env);

  // if (selectedEndpoint) {
  //   key[0] = `${key[0]}(${selectedEndpoint.name})`;
  // }
  await queryClient.invalidateQueries(key);
};
