import {
  closestCenter,
  DndContext,
  MouseSensor,
  useDraggable as useDraggableDNDKit,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { Coordinates } from '@dnd-kit/utilities';
import { CSS } from '@dnd-kit/utilities';
import { FCC } from '@laguna/types';
import { makeAutoObservable } from 'mobx';
import { CSSProperties, useEffect } from 'react';

class DraggableStore {
  private _draggableCoordinates: { [id: string]: Coordinates };

  constructor() {
    this._draggableCoordinates = {};
    makeAutoObservable(this);
  }

  getCoordinates = (id: string) => {
    return this._draggableCoordinates[id] || { x: 0, y: 0 };
  };

  addDraggable = (id: string, defaultCoordinates: Coordinates) => {
    this._draggableCoordinates[id] = defaultCoordinates;
  };
  updateDraggable = (id: string, coordinatesDelta: Coordinates) => {
    const newX = this._draggableCoordinates[id].x + coordinatesDelta.x;
    const newY = this._draggableCoordinates[id].y + coordinatesDelta.y;
    this._draggableCoordinates[id] = { x: newX, y: newY };
  };
}

const store = new DraggableStore();

export const useDraggable = ({ id, defaultCoordinates }: { id: string; defaultCoordinates: Coordinates }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggableDNDKit({ id });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    top: store.getCoordinates(id).y,
    left: store.getCoordinates(id).x,
    position: 'absolute',
  };
  useEffect(() => {
    store.addDraggable(id, defaultCoordinates);
  }, []);
  return { containerProps: { ...attributes, ...listeners }, setNodeRef, style };
};

export const DraggableContext: FCC = ({ children }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );
  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragEnd={({ delta, active }) => store.updateDraggable(active.id as string, delta)}>
      {children}
    </DndContext>
  );
};
