import { useQuery } from '@tanstack/react-query';
import { JobOutput } from './types';
import { readJsonFile } from './utils';

export const useUserJobOutput = (userId: string, jobId: string) => {
  return useQuery<JobOutput>(
    ['s3', 'useUserJobOutput', userId, jobId],
    async () => {
      try {
        return await readJsonFile({ path: `${userId}/outputs/${jobId}.json` });
      } catch (e: any) {
        if (e.message === 'The specified key does not exist.') {
          return {
            userId,
            id: jobId,
            duration: 0,
            tags: [],
          };
        }
        throw e;
      }
    },
    { enabled: !!userId && !!jobId }
  );
};
