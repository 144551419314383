import { QueryKey, useQueryClient, QueryClient } from '@tanstack/react-query';

export const removeItemFromCache = <T,>(queryClient: QueryClient, key: QueryKey, nestedQueryName?: keyof T) => {
  const queryName = nestedQueryName ?? (key[0] as string);
  const removeCacheItem = (filter: (data: any) => boolean) => {
    return queryClient.setQueryData<T>(key, (data: any) => {
      if (queryName) {
        return {
          ...data,
          [queryName]: data?.[queryName].filter(filter),
        };
      } else {
        return data.filter(filter);
      }
    });
  };

  return { removeCacheItem };
};

export const useRemoveItemFromCache = <T,>(key: QueryKey, nestedQueryName?: keyof T) => {
  const queryClient = useQueryClient();
  return removeItemFromCache(queryClient, key, nestedQueryName);
};

//TODO: improve types
export const useRemoveItemFromCacheByProp = <T,>({
  queryKey,
  propName = 'id',
  propValue,
  nestedQueryName,
}: {
  queryKey: QueryKey;
  propName?: string;
  propValue: any;
  nestedQueryName?: keyof T;
}) => {
  const { removeCacheItem: _removeCacheItem } = useRemoveItemFromCache<T>(queryKey, nestedQueryName);
  const removeCacheItem = () => _removeCacheItem((data) => data[propName] !== propValue);
  return { removeCacheItem };
};

export const useInvalidateCache = (key: QueryKey) => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries(key);

  return { invalidate };
};
