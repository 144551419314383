import { css } from '@mui/system';
import i18next from 'i18next';
import { omit } from 'lodash';
import React, { useCallback } from 'react';
import { Medication, MedicationStatus } from '@laguna/api';
import { stringArrayToObject } from '@laguna/common/utils/arrayUtils';
import { formsStore } from '../formsStore';
import { FieldsType, FieldType } from '../formTypes';
import { useMemoForm } from '../useMemoForm';
interface MedicationEditorProps {
  value: Medication;
  onChange: (med: Medication) => void;
  limitStatus?: boolean;
}

const STRENGTHS_AND_FORMS = 'STRENGTHS_AND_FORMS';
const path = `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?ef=${STRENGTHS_AND_FORMS}&maxList=100&terms=`;
const fetchMedicationsData = async (query: string | null) => {
  if (!query) {
    return {};
  }
  try {
    const res = await fetch(path + query);
    const medications = await res.json();
    const medicationOptions: string[] = medications?.[1];
    const index = medicationOptions.findIndex((med) => med.startsWith(query));
    const strengths = medications?.[2]?.[STRENGTHS_AND_FORMS];
    const listOfDosages: string[] = strengths?.[index > 0 ? index : 0].map((dosage: string) => dosage.trim());
    return { listOfDosages, medicationOptions };
  } catch (e) {
    return {};
  }
};
const fetchMedicationsOptions = async (_query: string | null) => {
  const query = _query ?? ' ';
  formsStore.triggerChange('createMedication', 'dosage', query ?? ' ');
  const { medicationOptions } = await fetchMedicationsData(query);
  return medicationOptions ?? [];
};

const fetchDosageOptions = async (query: string | null) => {
  const { listOfDosages } = await fetchMedicationsData(query);

  return stringArrayToObject(listOfDosages ?? []);
};

const getFields = (limited: boolean): FieldsType[] => [
  {
    name: 'name',
    label: 'Name',
    type: FieldType.asyncAutocomplete,
    required: true,
    fetchItems: fetchMedicationsOptions,
  },
  {
    name: 'dosage',
    label: 'Dosage',
    type: FieldType.dynamicSelect,
    fetchItems: fetchDosageOptions,
    rowId: 'dosage-frequency',
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.select,
    values: limited ? omit(MedicationStatus, 'stop') : MedicationStatus,
    nullable: false,
  },
  { name: 'specialInstructions', label: 'Special instructions', type: FieldType.text },
  limited
    ? ({ name: 'empty' } as any)
    : {
        name: 'memberVerballyProvided',
        label: 'Source',
        type: FieldType.select,
        values: {
          false: 'common:medical.dischargeInstructions',
          true: 'common:medical.memberReportedInformation',
        },
        nullable: false,
        required: true,
        transformValues: (key) => i18next.t(key),
      },
  {
    name: 'frequency',
    label: 'Frequency',
    type: FieldType.frequency,
    defaultValue: {
      cronExpressions: undefined,
      start: undefined,
    },
  },
];

interface MedicationForm extends Medication {
  frequency?: {
    start: Date;
    end?: Date;
    cronExpressions?: string[];
  };
}

const formCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const addFrequency = (med: Medication): MedicationForm => ({
  ...med,
  frequency: med.start
    ? {
        start: med.start,
        end: med.end,
        cronExpressions: med.cronExpressions,
      }
    : undefined,
});

const regularFields = getFields(false);
const limitedFields = getFields(true);

export const MedicationEditor: React.FC<MedicationEditorProps> = ({ value, onChange, limitStatus }) => {
  const { Form } = useMemoForm<MedicationForm>({
    formId: 'createMedication',
    fields: limitStatus ? limitedFields : regularFields,
    useOnlyDirtyFields: false,
    allowNoChangesSubmit: true,
  });

  const onFormChange = useCallback((val: MedicationForm) => {
    const { frequency, ...rest } = val;
    onChange({ ...rest, start: frequency?.start, end: frequency?.end, cronExpressions: frequency?.cronExpressions });
  }, []);

  return (
    <Form css={formCSS} isLoading={false} initialData={addFrequency(value)} onChange={onFormChange} useDivAsContainer />
  );
};
