import { DependencyList, useEffect } from 'react';
import { useIsMounted } from './useIsMounted';

export const useAsyncEffect = <T>(fn: (isMounted: boolean) => Promise<T>, deps?: DependencyList): void => {
  const isMounted = useIsMounted();
  const asyncFunc = async () => {
    await fn(isMounted.current);
  };
  useEffect(() => {
    asyncFunc();
  }, deps);
};
