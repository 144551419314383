import { useQuery } from '@tanstack/react-query';
import { getUserInfo } from './utils';

export const useUserName = () => {
  const query = useQuery<string>(['userName'], async () => {
    const userInfo = await getUserInfo();
    return userInfo.email.split('@')[0];
  });
  return query.data || '';
};
