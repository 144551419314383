import { useLocalStorage } from '@laguna/common/utils/hooks';
import { noop } from 'lodash';
import { createContext, ReactNode } from 'react';
import { SettingsContextProps, SettingsValueProps, themeMode } from '../utils/type';

export const defaultSettings: SettingsValueProps = {
  isLight: true,
  themeColorPresets: 'blue',
};

const initialState: SettingsContextProps = {
  ...defaultSettings,
  setTheme: noop,
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage<SettingsValueProps>('settings', defaultSettings);

  const setTheme = (mode: themeMode) => {
    setSettings({
      ...settings,
      isLight: mode === 'light',
    } as SettingsValueProps);
  };

  return <SettingsContext.Provider value={{ ...settings, setTheme }}>{children}</SettingsContext.Provider>;
}

export { SettingsProvider, SettingsContext };
