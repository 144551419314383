import { ArticleType, ArticleTopic } from '@laguna/api';
import { formsStore } from '@laguna/common/form/formsStore';
import { FORM_ID, FormData } from './consts';
import { ArticlePreviewData, createIframeContent } from '@laguna/components/ArticleViewer';
// import { CloudFront, config } from 'aws-sdk';
// import { Auth } from 'aws-amplify';

export const clearCloudfrontCache = async (articleId: string) => {
  // const credentials = await Auth.currentCredentials();
  // config.update({
  //   accessKeyId: credentials.accessKeyId,
  //   secretAccessKey: credentials.secretAccessKey,
  //   sessionToken: credentials.sessionToken,
  // });
  // const cloudFront = new CloudFront({ maxRetries: 5 });
  // await cloudFront
  //   .createInvalidation({
  //     DistributionId: 'EBMU82E4PY97Z',
  //     InvalidationBatch: {
  //       Paths: {
  //         Quantity: 1,
  //         Items: [`/${articleId}*`],
  //       },
  //       CallerReference: new Date().toISOString(),
  //     },
  //   })
  //   .promise();
};

export const getFormData = (): FormData => formsStore.getFormsValues(FORM_ID);

export const createArticleData = (content = ''): ArticlePreviewData => {
  const { title, bigImageUrl, smallImageUrl, topic, type, videoUrl, duration } = getFormData();
  return {
    title: title || '',
    bigImageUrl: bigImageUrl || '',
    smallImageUrl: smallImageUrl || '',
    type: (type || ArticleType.Article) as ArticleType,
    topic: (topic || []) as ArticleTopic[],
    videoUrl,
    duration,
    content,
  };
};

export const createContentBlob = (content = '') => {
  const data = createArticleData(content);
  return createIframeContent(data);
};
