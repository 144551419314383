import { Stack } from '@mui/material';
import { forwardRef } from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';

export const Scrollbar = forwardRef<Scrollbars, ScrollbarProps>(({ children, ...rest }, ref) => {
  return (
    <Scrollbars ref={ref} autoHeight autoHide renderView={(props) => <Stack {...props} />} {...rest}>
      {children}
    </Scrollbars>
  );
});
