import * as Types from '../types';

import { ArticleFragment, ConfigFragment } from './fragment.generated';
import { CommonBarrierTypeFragment } from '../common/fragment.generated';
import { PromptFragment } from '../harmony/fragment.generated';
import { ArticleFragmentDoc, ConfigFragmentDoc } from './fragment.generated';
import { CommonBarrierTypeFragmentDoc } from '../common/fragment.generated';
import { PromptFragmentDoc } from '../harmony/fragment.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetchData } from './fetchDataProxy';
export type GetOrganizationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { getOrgs: Array<Pick<Types.Org, 'code' | 'id' | 'logoUrl' | 'name' | 'trialDuration' | 'type' | 'zipCode'>> };

export type GetArticlesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetArticlesQuery = { getArticles: Array<ArticleFragment> };

export type GetOrgsArticleGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrgsArticleGroupsQuery = { getOrgsArticleGroups: Array<(
    Pick<Types.ArticleGroup, 'id' | 'orgId'>
    & { articles: Array<Pick<Types.Article, 'id' | 'title' | 'smallImageUrl' | 'type'>> }
  )> };

export type GetAttributesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAttributesQuery = { getAttributes: Array<Pick<Types.Attribute, 'category' | 'key' | 'values' | 'description' | 'tags'>> };

export type GetBarrierTypesQueryVariables = Types.Exact<{
  getBarrierTypeParams?: Types.InputMaybe<Types.GetBarrierTypeParams>;
}>;


export type GetBarrierTypesQuery = { getBarrierTypes: Array<CommonBarrierTypeFragment> };

export type GetConfigsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConfigsQuery = { getConfigs: Array<ConfigFragment> };

export type GetBackofficePromptsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBackofficePromptsQuery = { getPrompts: Array<PromptFragment> };


export const GetOrganizationsDocument = `
    query getOrganizations {
  getOrgs {
    code
    id
    logoUrl
    name
    trialDuration
    type
    zipCode
  }
}
    `;
export const useGetOrganizationsQuery = <
      TData = GetOrganizationsQuery,
      TError = unknown
    >(
      variables?: GetOrganizationsQueryVariables,
      options?: UseQueryOptions<GetOrganizationsQuery, TError, TData>
    ) => {
      const queryKey = variables === undefined ? ['getOrganizations'] : ['getOrganizations', variables];
      return useQuery<GetOrganizationsQuery, TError, TData>(
        queryKey,
        fetchData<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, variables, undefined, queryKey),
        options
      );
    }

useGetOrganizationsQuery.getKey = (variables?: GetOrganizationsQueryVariables) => variables === undefined ? ['getOrganizations'] : ['getOrganizations', variables];


export const useInfiniteGetOrganizationsQuery = <
      TData = GetOrganizationsQuery,
      TError = unknown
    >(
      variables?: GetOrganizationsQueryVariables,
      options?: UseInfiniteQueryOptions<GetOrganizationsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetOrganizationsQuery, TError, TData>(
      variables === undefined ? ['getOrganizations.infinite'] : ['getOrganizations.infinite', variables],
      (metaData) => fetchData<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetOrganizationsQuery.getKey = (variables?: GetOrganizationsQueryVariables) => variables === undefined ? ['getOrganizations.infinite'] : ['getOrganizations.infinite', variables];
;

useGetOrganizationsQuery.fetcher = (variables?: GetOrganizationsQueryVariables, options?: RequestInit['headers']) => fetchData<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, variables, options, variables === undefined ? ['getOrganizations'] : ['getOrganizations', variables]);
export const GetArticlesDocument = `
    query getArticles {
  getArticles {
    ...articleFragment
  }
}
    ${ArticleFragmentDoc}`;
export const useGetArticlesQuery = <
      TData = GetArticlesQuery,
      TError = unknown
    >(
      variables?: GetArticlesQueryVariables,
      options?: UseQueryOptions<GetArticlesQuery, TError, TData>
    ) => {
      const queryKey = variables === undefined ? ['getArticles'] : ['getArticles', variables];
      return useQuery<GetArticlesQuery, TError, TData>(
        queryKey,
        fetchData<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, variables, undefined, queryKey),
        options
      );
    }

useGetArticlesQuery.getKey = (variables?: GetArticlesQueryVariables) => variables === undefined ? ['getArticles'] : ['getArticles', variables];


export const useInfiniteGetArticlesQuery = <
      TData = GetArticlesQuery,
      TError = unknown
    >(
      variables?: GetArticlesQueryVariables,
      options?: UseInfiniteQueryOptions<GetArticlesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetArticlesQuery, TError, TData>(
      variables === undefined ? ['getArticles.infinite'] : ['getArticles.infinite', variables],
      (metaData) => fetchData<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetArticlesQuery.getKey = (variables?: GetArticlesQueryVariables) => variables === undefined ? ['getArticles.infinite'] : ['getArticles.infinite', variables];
;

useGetArticlesQuery.fetcher = (variables?: GetArticlesQueryVariables, options?: RequestInit['headers']) => fetchData<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, variables, options, variables === undefined ? ['getArticles'] : ['getArticles', variables]);
export const GetOrgsArticleGroupsDocument = `
    query getOrgsArticleGroups {
  getOrgsArticleGroups {
    id
    orgId
    articles {
      id
      title
      smallImageUrl
      type
    }
  }
}
    `;
export const useGetOrgsArticleGroupsQuery = <
      TData = GetOrgsArticleGroupsQuery,
      TError = unknown
    >(
      variables?: GetOrgsArticleGroupsQueryVariables,
      options?: UseQueryOptions<GetOrgsArticleGroupsQuery, TError, TData>
    ) => {
      const queryKey = variables === undefined ? ['getOrgsArticleGroups'] : ['getOrgsArticleGroups', variables];
      return useQuery<GetOrgsArticleGroupsQuery, TError, TData>(
        queryKey,
        fetchData<GetOrgsArticleGroupsQuery, GetOrgsArticleGroupsQueryVariables>(GetOrgsArticleGroupsDocument, variables, undefined, queryKey),
        options
      );
    }

useGetOrgsArticleGroupsQuery.getKey = (variables?: GetOrgsArticleGroupsQueryVariables) => variables === undefined ? ['getOrgsArticleGroups'] : ['getOrgsArticleGroups', variables];


export const useInfiniteGetOrgsArticleGroupsQuery = <
      TData = GetOrgsArticleGroupsQuery,
      TError = unknown
    >(
      variables?: GetOrgsArticleGroupsQueryVariables,
      options?: UseInfiniteQueryOptions<GetOrgsArticleGroupsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetOrgsArticleGroupsQuery, TError, TData>(
      variables === undefined ? ['getOrgsArticleGroups.infinite'] : ['getOrgsArticleGroups.infinite', variables],
      (metaData) => fetchData<GetOrgsArticleGroupsQuery, GetOrgsArticleGroupsQueryVariables>(GetOrgsArticleGroupsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetOrgsArticleGroupsQuery.getKey = (variables?: GetOrgsArticleGroupsQueryVariables) => variables === undefined ? ['getOrgsArticleGroups.infinite'] : ['getOrgsArticleGroups.infinite', variables];
;

useGetOrgsArticleGroupsQuery.fetcher = (variables?: GetOrgsArticleGroupsQueryVariables, options?: RequestInit['headers']) => fetchData<GetOrgsArticleGroupsQuery, GetOrgsArticleGroupsQueryVariables>(GetOrgsArticleGroupsDocument, variables, options, variables === undefined ? ['getOrgsArticleGroups'] : ['getOrgsArticleGroups', variables]);
export const GetAttributesDocument = `
    query getAttributes {
  getAttributes {
    category
    key
    values
    description
    tags
  }
}
    `;
export const useGetAttributesQuery = <
      TData = GetAttributesQuery,
      TError = unknown
    >(
      variables?: GetAttributesQueryVariables,
      options?: UseQueryOptions<GetAttributesQuery, TError, TData>
    ) => {
      const queryKey = variables === undefined ? ['getAttributes'] : ['getAttributes', variables];
      return useQuery<GetAttributesQuery, TError, TData>(
        queryKey,
        fetchData<GetAttributesQuery, GetAttributesQueryVariables>(GetAttributesDocument, variables, undefined, queryKey),
        options
      );
    }

useGetAttributesQuery.getKey = (variables?: GetAttributesQueryVariables) => variables === undefined ? ['getAttributes'] : ['getAttributes', variables];


export const useInfiniteGetAttributesQuery = <
      TData = GetAttributesQuery,
      TError = unknown
    >(
      variables?: GetAttributesQueryVariables,
      options?: UseInfiniteQueryOptions<GetAttributesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetAttributesQuery, TError, TData>(
      variables === undefined ? ['getAttributes.infinite'] : ['getAttributes.infinite', variables],
      (metaData) => fetchData<GetAttributesQuery, GetAttributesQueryVariables>(GetAttributesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAttributesQuery.getKey = (variables?: GetAttributesQueryVariables) => variables === undefined ? ['getAttributes.infinite'] : ['getAttributes.infinite', variables];
;

useGetAttributesQuery.fetcher = (variables?: GetAttributesQueryVariables, options?: RequestInit['headers']) => fetchData<GetAttributesQuery, GetAttributesQueryVariables>(GetAttributesDocument, variables, options, variables === undefined ? ['getAttributes'] : ['getAttributes', variables]);
export const GetBarrierTypesDocument = `
    query getBarrierTypes($getBarrierTypeParams: GetBarrierTypeParams) {
  getBarrierTypes(getBarrierTypeParams: $getBarrierTypeParams) {
    ...commonBarrierType
  }
}
    ${CommonBarrierTypeFragmentDoc}`;
export const useGetBarrierTypesQuery = <
      TData = GetBarrierTypesQuery,
      TError = unknown
    >(
      variables?: GetBarrierTypesQueryVariables,
      options?: UseQueryOptions<GetBarrierTypesQuery, TError, TData>
    ) => {
      const queryKey = variables === undefined ? ['getBarrierTypes'] : ['getBarrierTypes', variables];
      return useQuery<GetBarrierTypesQuery, TError, TData>(
        queryKey,
        fetchData<GetBarrierTypesQuery, GetBarrierTypesQueryVariables>(GetBarrierTypesDocument, variables, undefined, queryKey),
        options
      );
    }

useGetBarrierTypesQuery.getKey = (variables?: GetBarrierTypesQueryVariables) => variables === undefined ? ['getBarrierTypes'] : ['getBarrierTypes', variables];


export const useInfiniteGetBarrierTypesQuery = <
      TData = GetBarrierTypesQuery,
      TError = unknown
    >(
      variables?: GetBarrierTypesQueryVariables,
      options?: UseInfiniteQueryOptions<GetBarrierTypesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetBarrierTypesQuery, TError, TData>(
      variables === undefined ? ['getBarrierTypes.infinite'] : ['getBarrierTypes.infinite', variables],
      (metaData) => fetchData<GetBarrierTypesQuery, GetBarrierTypesQueryVariables>(GetBarrierTypesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetBarrierTypesQuery.getKey = (variables?: GetBarrierTypesQueryVariables) => variables === undefined ? ['getBarrierTypes.infinite'] : ['getBarrierTypes.infinite', variables];
;

useGetBarrierTypesQuery.fetcher = (variables?: GetBarrierTypesQueryVariables, options?: RequestInit['headers']) => fetchData<GetBarrierTypesQuery, GetBarrierTypesQueryVariables>(GetBarrierTypesDocument, variables, options, variables === undefined ? ['getBarrierTypes'] : ['getBarrierTypes', variables]);
export const GetConfigsDocument = `
    query getConfigs {
  getConfigs {
    ...configFragment
  }
}
    ${ConfigFragmentDoc}`;
export const useGetConfigsQuery = <
      TData = GetConfigsQuery,
      TError = unknown
    >(
      variables?: GetConfigsQueryVariables,
      options?: UseQueryOptions<GetConfigsQuery, TError, TData>
    ) => {
      const queryKey = variables === undefined ? ['getConfigs'] : ['getConfigs', variables];
      return useQuery<GetConfigsQuery, TError, TData>(
        queryKey,
        fetchData<GetConfigsQuery, GetConfigsQueryVariables>(GetConfigsDocument, variables, undefined, queryKey),
        options
      );
    }

useGetConfigsQuery.getKey = (variables?: GetConfigsQueryVariables) => variables === undefined ? ['getConfigs'] : ['getConfigs', variables];


export const useInfiniteGetConfigsQuery = <
      TData = GetConfigsQuery,
      TError = unknown
    >(
      variables?: GetConfigsQueryVariables,
      options?: UseInfiniteQueryOptions<GetConfigsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetConfigsQuery, TError, TData>(
      variables === undefined ? ['getConfigs.infinite'] : ['getConfigs.infinite', variables],
      (metaData) => fetchData<GetConfigsQuery, GetConfigsQueryVariables>(GetConfigsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetConfigsQuery.getKey = (variables?: GetConfigsQueryVariables) => variables === undefined ? ['getConfigs.infinite'] : ['getConfigs.infinite', variables];
;

useGetConfigsQuery.fetcher = (variables?: GetConfigsQueryVariables, options?: RequestInit['headers']) => fetchData<GetConfigsQuery, GetConfigsQueryVariables>(GetConfigsDocument, variables, options, variables === undefined ? ['getConfigs'] : ['getConfigs', variables]);
export const GetBackofficePromptsDocument = `
    query getBackofficePrompts {
  getPrompts {
    ...promptFragment
  }
}
    ${PromptFragmentDoc}`;
export const useGetBackofficePromptsQuery = <
      TData = GetBackofficePromptsQuery,
      TError = unknown
    >(
      variables?: GetBackofficePromptsQueryVariables,
      options?: UseQueryOptions<GetBackofficePromptsQuery, TError, TData>
    ) => {
      const queryKey = variables === undefined ? ['getBackofficePrompts'] : ['getBackofficePrompts', variables];
      return useQuery<GetBackofficePromptsQuery, TError, TData>(
        queryKey,
        fetchData<GetBackofficePromptsQuery, GetBackofficePromptsQueryVariables>(GetBackofficePromptsDocument, variables, undefined, queryKey),
        options
      );
    }

useGetBackofficePromptsQuery.getKey = (variables?: GetBackofficePromptsQueryVariables) => variables === undefined ? ['getBackofficePrompts'] : ['getBackofficePrompts', variables];


export const useInfiniteGetBackofficePromptsQuery = <
      TData = GetBackofficePromptsQuery,
      TError = unknown
    >(
      variables?: GetBackofficePromptsQueryVariables,
      options?: UseInfiniteQueryOptions<GetBackofficePromptsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetBackofficePromptsQuery, TError, TData>(
      variables === undefined ? ['getBackofficePrompts.infinite'] : ['getBackofficePrompts.infinite', variables],
      (metaData) => fetchData<GetBackofficePromptsQuery, GetBackofficePromptsQueryVariables>(GetBackofficePromptsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetBackofficePromptsQuery.getKey = (variables?: GetBackofficePromptsQueryVariables) => variables === undefined ? ['getBackofficePrompts.infinite'] : ['getBackofficePrompts.infinite', variables];
;

useGetBackofficePromptsQuery.fetcher = (variables?: GetBackofficePromptsQueryVariables, options?: RequestInit['headers']) => fetchData<GetBackofficePromptsQuery, GetBackofficePromptsQueryVariables>(GetBackofficePromptsDocument, variables, options, variables === undefined ? ['getBackofficePrompts'] : ['getBackofficePrompts', variables]);