import { ArticleFragment } from '@laguna/api/backoffice';
import { useMemo } from 'react';
import { useMultipleOrgArticleGroups } from '../../../hooks/useMultipleOrgArticleGroups';

export const useOrgsContainingArticle = (article: ArticleFragment) => {
  const { data: originalData, isLoading } = useMultipleOrgArticleGroups();
  const data = useMemo(() => {
    if (!originalData) {
      return [];
    }
    return originalData.map((envOrgs) => ({
      env: envOrgs.name,
      orgNames: envOrgs.articleGroups
        .filter((group) => group.articles.some((groupArticle) => groupArticle.id === article.id))
        .map((group) => group.name),
    }));
  }, [originalData, article]);

  return {
    isLoading,
    data,
  };
};
