import { BasicTooltip } from '@laguna/common/BasicTooltip'; // eslint-disable-line @nx/enforce-module-boundaries
import { Divider, IconButton, Stack, SvgIcon, Theme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { css } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';

export const SIDE_BAR_WIDTH = '52px';

const wrapper = (theme: Theme) => css`
  width: ${SIDE_BAR_WIDTH};
  background-color: ${blueGrey[900]};
  display: flex;
  flex-direction: column;

  button {
    color: white;
    border-radius: 0;
    transition: background-color 0.5s;
    width: 100%;
    &.active {
      background-color: ${theme.palette.primary.dark} !important;
    }
  }
`;

const bottomDivider = css`
  margin-top: auto;
`;

interface AppSidebarLayoutProps {
  activeTabPath?: string;
  tabs: { path: string; link?: string; name: string; testId?: string; icon: any }[];
  bottomElements?: React.ReactNode;
}

const isActive = (pathname: string, tab: string) => pathname.includes(tab);

export const AppSidebarLayout: React.FC<AppSidebarLayoutProps> = ({ tabs, bottomElements }) => {
  const pathname = useLocation().pathname;
  return (
    <Stack css={wrapper}>
      {tabs.map(({ path, icon, testId, name, link }) => {
        return (
          <Link key={name} data-testid={testId} to={link || path}>
            <BasicTooltip title={name} placement='right'>
              <IconButton
                aria-label={'navigate-' + name}
                className={isActive(pathname, path) ? 'active' : ''}
                size='large'>
                <SvgIcon component={icon} />
              </IconButton>
            </BasicTooltip>
          </Link>
        );
      })}
      {bottomElements && (
        <>
          <Divider css={bottomDivider} />
          {bottomElements}
        </>
      )}
    </Stack>
  );
};
