import { CircularProgress } from '@mui/material';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { omit } from 'lodash';
import { css } from '@mui/material';

export interface AutocompleteInputProps<T>
  extends Omit<AutocompleteProps<T, boolean, boolean, boolean>, 'name' | 'renderInput' | 'isOptionEqualToValue'> {
  name: string;
  label: string;
}

const overrideMUI = css`
.MuiAutocomplete-endAdornment {
  transform: translate(0, 0%);
}
`

export const Autocomplete = <T,>({ name, label, ...rest }: AutocompleteInputProps<T>) => {
  const properRest = omit(rest, [
    'formId',
    'formState',
    'fieldState',
    'getDerivedState',
    'isHidden',
  ]) as AutocompleteInputProps<T>;

  return (
    <MuiAutocomplete
      id={'autocomplete-' + name}
      data-testid={'autocomplete-' + name}
      css={overrideMUI}
      {...properRest}
      isOptionEqualToValue={(option: T, value: T) =>
        value === undefined || value === '' || (option as any).id === (value as any).id
      }
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {rest.loading === true ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
