import { LinkColumn } from '@laguna/types';
import { css, Link, Theme, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type LinkCellProps = ReturnType<LinkColumn['getInfo']>;

const disabledCss = (theme: Theme) =>
  css`
    color: ${theme.palette.text.disabled};
  `;

const linkCss = css`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LinkCell = ({ label, path, disabled }: LinkCellProps) => (
  <Link
    component={RouterLink}
    data-testid={'cell-link-' + path}
    to={path}
    css={[linkCss, disabled ? disabledCss : undefined]}>
    <Typography css={linkCss} fontWeight={600}>
      {label}
    </Typography>
  </Link>
);
