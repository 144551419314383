import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Scrollbar } from '../components/src/lib/Scrollbar';

const icon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckBox fontSize='small' />;

const ListboxComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = ({ children, ...props }) => (
  <Scrollbar renderView={(internalProps) => <ul {...props} {...internalProps} />}>
    <>{children}</>
  </Scrollbar>
);

interface MultiSelectProps<T>
  extends Omit<AutocompleteProps<T, boolean, boolean, boolean>, 'renderOption' | 'onChange' | 'renderInput'> {
  inputPlaceholder?: string;
  customChipLabel?: (option: T) => string;
  customRenderLabel?: (option: T) => string;
  onChange: (newVal: null | T | T[]) => void;
  inputParams?: TextFieldProps;
}

export const MultiSelect = <T extends object>({
  inputPlaceholder,
  customChipLabel,
  customRenderLabel,
  onChange,
  inputParams,
  disableCloseOnSelect,
  ...props
}: MultiSelectProps<T>) => {
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect={disableCloseOnSelect}
      renderTags={
        customChipLabel
          ? (tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={customChipLabel(option)} {...getTagProps({ index })} key={index} />
            ))
          : undefined
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          <ListItemText primary={customRenderLabel ? customRenderLabel(option) : (option as any)} />
        </MenuItem>
      )}
      ListboxComponent={ListboxComponent}
      {...props}
      onChange={(_, newValue) => onChange(newValue as any)}
      renderInput={(params) => (
        <TextField
          {...inputParams}
          {...(params as TextFieldProps)}
          placeholder={inputPlaceholder}
          variant='standard'
          maxRows={1}
        />
      )}
    />
  );
};
