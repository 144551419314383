import { CloudDownload, RestartAlt, Search } from '@mui/icons-material';
import { IconButton, Stack, Toolbar, Tooltip, Typography, css } from '@mui/material';
import i18next from 'i18next';
import { pickBy } from 'lodash';
import { ReactNode, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Column, TableInstance } from 'react-table';
import { DebounceInput } from '../DebounceInput';
import { useDataTableFiltersStorage } from '../useDataTableFiltersStorage';
import { FilterTable } from './FilterTable';
import { FiltersChipsList } from './FiltersChipsList';
import { ShowTableColumns } from './ShowTableColumns';

interface TableToolbarProps
  extends Pick<
    TableInstance,
    'allColumns' | 'toggleHideColumn' | 'setGlobalFilter' | 'preFilteredRows' | 'setAllFilters' | 'state' | 'rows'
  > {
  tableId: string;
  columns: Column[];
  extraAction?: JSX.Element;
  title?: ReactNode;
  showDownload?: boolean;
  resetTableSettings: VoidFunction;
  defaultFilters?: any;
  prepareRow?: any;
}

const titleCss = (theme: any) => css`
  width: 100%;
  padding: ${theme.spacing(2)} ${theme.spacing(3)};
`;
const toolbarCss = css`
  padding-left: 0 !important;
`;

export const TableToolbar = ({
  tableId,
  title,
  extraAction,
  allColumns = [],
  columns,
  preFilteredRows,
  state: tableState,
  rows,
  showDownload,
  resetTableSettings,
  toggleHideColumn,
  setGlobalFilter,
  setAllFilters: setTableFilters,
  defaultFilters,
  prepareRow,
}: TableToolbarProps) => {
  const { searchTerm, setSearchTerm, filters, setFilters } = useDataTableFiltersStorage(tableId, defaultFilters);

  const [openSearch, setOpenSearch] = useState(!!searchTerm);
  const [downloadData, setDownloadData] = useState<{ data: any[]; headers: any[] }>({ data: [], headers: [] });

  const getDownloadData = (event: any, done: any) => {
    const data = rows.map((row, index) => {
      prepareRow(row);
      const rowData: { [key: string]: string } = {};
      row.cells.forEach((cell) => {
        rowData[cell.column.name as string] = cell.value;
      });
      return rowData;
    });
    const headers = columns
      .filter(({ display, isVisible }) => !!display && !!isVisible)
      .map(({ label, name }) => ({ label, key: name }));
    setDownloadData({ data, headers });
    done?.();
  };
  const setSearch = (value?: string) => {
    setGlobalFilter(value);
    setSearchTerm(value || null);
  };

  const clearSearch = () => {
    setSearch(undefined);
    setSearchTerm('');
    setOpenSearch(false);
  };

  const resetFilters = () => {
    setTableFilters([]);
    setFilters({});
  };

  const resetTable = () => {
    resetTableSettings();
    clearSearch();
  };

  const setColumnFilter = (column: string, value: string[]) => {
    const safeFilters = pickBy(filters, (value, keyName) => columns.some(({ id }) => id === keyName));
    const newFiltersObject: { [key: string]: string[] } = { ...safeFilters };
    newFiltersObject[column] = value;
    setFilters(newFiltersObject);
    const newFiltersArray = Object.keys(newFiltersObject).map((key) => ({ id: key, value: newFiltersObject[key] }));
    setTableFilters(newFiltersArray);
  };

  return (
    <>
      <Toolbar css={toolbarCss}>
        <Stack direction='row' alignItems='center' css={titleCss} spacing={2}>
          <Typography variant='h6' id='tableTitle' component='div'>
            {title}
          </Typography>
          <DebounceInput
            open={openSearch}
            searchText={searchTerm}
            onSearch={setSearch}
            onHide={clearSearch}
            data-testid='searchInput'
          />
        </Stack>
        {extraAction || null}
        <Tooltip title={i18next.t('dataTable:resetFilters')}>
          <IconButton onClick={resetTable}>
            <RestartAlt />
          </IconButton>
        </Tooltip>
        <Tooltip title={i18next.t('dataTable:search')}>
          <IconButton onClick={() => setOpenSearch((val) => !val)}>
            <Search color={openSearch ? 'primary' : 'action'} data-testid='Search-iconButton' />
          </IconButton>
        </Tooltip>
        <ShowTableColumns allColumns={allColumns} toggleHideColumn={toggleHideColumn} tableId={tableId} />
        <FilterTable
          preFilteredRows={preFilteredRows}
          columns={columns}
          resetFilters={resetFilters}
          setColumnFilter={setColumnFilter}
        />
        {showDownload && (
          <CSVLink {...downloadData} filename={title + '.csv'} onClick={getDownloadData}>
            <Tooltip title={i18next.t('dataTable:download')}>
              <IconButton>
                <CloudDownload color='action' />
              </IconButton>
            </Tooltip>
          </CSVLink>
        )}
      </Toolbar>
      <FiltersChipsList state={tableState} columns={columns} setColumnFilter={setColumnFilter} />
    </>
  );
};
