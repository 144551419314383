import { QueryKey } from '@tanstack/react-query';
import { extractGQLError, UseQueryMessageParams, useQueryMessages } from './useQueryMessages';
import { useInvalidateCache } from './useRemoveItemFromCache';

type MutationFunction<PARAMS, RESPONSE> = () => {
  mutateAsync: (params: PARAMS) => Promise<RESPONSE>;
  isLoading: boolean;
};

const DEFAULT_MESSAGES = {
  successMsg: 'success',
};

interface UseAsyncMutationOptions {
  messages?: UseQueryMessageParams;
  invalidationQueryKey?: QueryKey;
}

export const useAsyncMutation = <PARAMS, RESPONSE>(
  mutation: MutationFunction<PARAMS, RESPONSE>,
  options: UseAsyncMutationOptions = {}
) => {
  const { messages = DEFAULT_MESSAGES } = options;
  const { mutateAsync: originalMutateAsync, isLoading } = mutation();
  const { invalidate } = useInvalidateCache(options.invalidationQueryKey || []);

  return {
    isLoading,
    mutateAsync: async (params: PARAMS, messagesOverride: UseQueryMessageParams = {}) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { onSuccess, onError } = useQueryMessages({ ...messages, ...messagesOverride });
      try {
        const data = await originalMutateAsync(params);
        if (!data) {
          throw new Error('Null response');
        }
        onSuccess();
        if (options.invalidationQueryKey) {
          invalidate();
        }
        return { data, error: null };
      } catch (error: any) {
        onError(error);
        return { data: null, error: extractGQLError(error) };
      }
    },
  };
};
