import { logger } from '@laguna/logger';
import { signOut } from 'aws-amplify/auth';
// import AWS from 'aws-sdk';
import { keyBy, mapValues } from 'lodash';
import { JobBase, JobMetadata } from './types';

const TAGGING_BUCKET_NAME = 'laguna-tagging';

interface S3ActionProps {
  path: string;
  bucket?: string;
}

interface ReadFileProps extends S3ActionProps {
  defaultValue?: string;
}
interface WriteFileProps extends S3ActionProps {
  content: string;
}
// let s3 = new AWS.S3({
//   apiVersion: '2006-03-01',
//   region: 'us-east-1',
// });

const updateS3CredentialsIfNeeded = async () => {
  // const { expiration } = await Auth.currentCredentials();
  // if (!expiration) {
  //   return;
  // }
  // const minutesToExpiration = differenceInMinutes(expiration, new Date());
  // if (minutesToExpiration < 20) {
  //   logger.debug('updating expiration');
  //   await Auth.currentAuthenticatedUser({ bypassCache: true });
  //   const credentials = await Auth.currentCredentials();
  //   s3.config.credentials = Auth.essentialCredentials(credentials);
  // }
};

const authSafeAction = async <T>(callback: () => T): Promise<T> => {
  try {
    await updateS3CredentialsIfNeeded();
    return await callback();
  } catch (error) {
    logger.error('authSafeAction error', { error });
    await signOut();
    throw error;
  }
};

export const setupS3BucketCredentials = async () => {
  // const credentials = await Auth.currentCredentials();
  // s3 = new AWS.S3({
  //   apiVersion: '2006-03-01',
  //   credentials: Auth.essentialCredentials(credentials),
  //   region: 'us-east-1',
  // });
};

export const readJsonFile = async (props: ReadFileProps) => {
  const text = await getTextFile({ ...props, defaultValue: props.defaultValue || '{}' });
  return JSON.parse(text);
};

const getTextFile = async ({
  path,
  defaultValue = '',
  bucket = TAGGING_BUCKET_NAME,
}: ReadFileProps): Promise<string> => {
  return defaultValue;
  // const item = await s3
  //   .getObject({
  //     Bucket: bucket,
  //     Key: path,
  //   })
  //   .promise();

  // return item.Body?.toString('utf-8') || defaultValue;
};

export const listObjectInFolder = async ({ path, bucket = TAGGING_BUCKET_NAME }: S3ActionProps) => {
  return [];
  // const Prefix = path;
  // const items = await s3
  //   .listObjectsV2({
  //     Bucket: bucket,
  //     Prefix,
  //   })
  //   .promise();

  // return items.Contents?.filter((item) => item.Key !== Prefix) || [];
};

export const getFileMetadata = async ({ path, bucket = TAGGING_BUCKET_NAME }: ReadFileProps): Promise<JobMetadata> => {
  return {};
  // const response = await s3
  //   .getObjectTagging({
  //     Bucket: bucket,
  //     Key: path,
  //   })
  //   .promise();

  // /**
  //  * convert
  //  * [{Key:'isDone',Value:'true},{Key:'jobType',Value:'transcription'}]
  //  * =>
  //  * {isDone:'true',jobType:'transcription'}
  //  */
  // const map = mapValues(keyBy(response.TagSet, 'Key'), 'Value') as Record<string, any>;
  // if (map.isDone) {
  //   map.isDone = map.isDone === 'true';
  // }
  // return map;
};

interface SetFileTagProps extends S3ActionProps {
  tagName: string;
  value: any;
}
export const setFileTag = async ({
  path,
  tagName,
  value,
  bucket = TAGGING_BUCKET_NAME,
}: SetFileTagProps): Promise<void> =>
  authSafeAction(async () => {
    // const currentTags = await s3
    //   .getObjectTagging({
    //     Bucket: bucket,
    //     Key: path,
    //   })
    //   .promise();
    // const tagToToggle = currentTags.TagSet.find((tag) => tag.Key === tagName);
    // if (tagToToggle) {
    //   tagToToggle.Value = value;
    // } else {
    //   currentTags.TagSet.push({ Key: tagName, Value: value });
    // }
    // await s3
    //   .putObjectTagging({
    //     Bucket: bucket,
    //     Key: path,
    //     Tagging: {
    //       TagSet: currentTags.TagSet,
    //     },
    //   })
    //   .promise();
  });

export const writeFile = async ({ path, bucket = TAGGING_BUCKET_NAME, content }: WriteFileProps) =>
  authSafeAction(async () => {
    // await s3
    //   .putObject({
    //     Bucket: bucket,
    //     Key: path,
    //     Body: content,
    //     ContentType: 'text/plain',
    //   })
    //   .promise();
  });

export const getJobPath = ({ id, userId, type }: JobBase) => `${userId}/incoming/${type}/${id}.json`;

export const getUserInfo = async () => {
  // const info = await Auth.currentSession();
  // return info.getIdToken().payload;
  return { email: '' };
};
