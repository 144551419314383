import { CircularProgress, CircularProgressProps, Stack, StackProps, Theme } from '@mui/material';
import { css } from '@mui/material';

const loaderWrapper = (theme: Theme) => css`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${theme.spacing(1)};
  overflow: hidden;
`;

export const FullSizeContent: React.FC<StackProps> = ({ children, ...rest }) => (
  <Stack css={loaderWrapper} {...rest}>
    {children}
  </Stack>
);

interface LoaderProps extends Pick<CircularProgressProps, 'color' | 'sx'> {
  height?: string | number;
  width?: string | number;
  size?: number | string;
}
const Loader: React.FC<LoaderProps> = ({ height = '100vh', width = undefined, size = 40, color, sx }) => {
  return (
    <FullSizeContent data-testid='loader' style={{ height, width }}>
      <CircularProgress size={size} color={color} sx={sx} />
    </FullSizeContent>
  );
};

export default Loader;
