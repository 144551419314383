import { useMemo, ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider, Theme } from '@mui/material';

import useSettings from './hooks/useSettings';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

type Props = {
  children: ReactNode;
  lockLightTheme?: boolean;
};

export function ThemeProvider({ children, lockLightTheme }: Props) {
  const { isLight } = useSettings();

  const theme: Theme = useMemo(() => {
    const shouldUseLight = isLight || lockLightTheme;
    const current = createTheme({
      palette: shouldUseLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 6 },
      shadows: shouldUseLight ? shadows.light : shadows.dark,
      customShadows: shouldUseLight ? customShadows.light : customShadows.dark,
    });

    current.components = componentsOverride(current);
    return current;
  }, [isLight, lockLightTheme]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
