import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { ImagesMap } from './Images';

export type LagunaImageType = keyof typeof ImagesMap;

interface LagunaIconProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  image: LagunaImageType;
}

export const LagunaImage: React.FC<LagunaIconProps> = ({ image, ...props }) => (
  <img alt={image} src={ImagesMap[image]} {...props} />
);
