import React from 'react';
import { App } from './App';
import { ThemeAppWrapper } from '@laguna/common/theme';
import { useInitApp } from './hooks/useInitApp';
import { SnackbarProvider } from '@laguna/common/providers';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { GoogleSignIn } from './components/GoogleSignIn';
import { BaseModal } from '@laguna/common/modals';

export const Root: React.FC = () => {
  const { isInit, queryClient } = useInitApp();

  if (!isInit) return null;

  return (
    <BrowserRouter>
      <ThemeAppWrapper>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <GoogleSignIn>
              <App />
              <BaseModal />
            </GoogleSignIn>
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeAppWrapper>
    </BrowserRouter>
  );
};
