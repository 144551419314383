import { useLocalStorage } from '@laguna/common/utils/hooks';
import { IconPopupMenu } from '@laguna/components/PopupMenu';
import { ViewColumn } from '@mui/icons-material';
import { Checkbox, css, FormControlLabel, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';
import { Scrollbar } from '../../Scrollbar';

const columnsMenuCss = (theme: any) => css`
  padding: 0;
  padding-left: ${theme.spacing(1)};
  width: 100%;
  .MuiCheckbox-root {
    padding: 0;
    margin-right: ${theme.spacing(1)};
  }
`;

interface ShowTableColumnsProps extends Pick<TableInstance, 'allColumns' | 'toggleHideColumn'> {
  tableId: string;
}

export const ShowTableColumns: React.FC<ShowTableColumnsProps> = ({ allColumns = [], toggleHideColumn, tableId }) => {
  const [columnExclusion, setColumnExclusion] = useLocalStorage('tableColumnExclusion_' + tableId, {}, true);
  const columns = useMemo(() => allColumns.filter((item) => item.display), [allColumns]);

  const onPressCheckbox = (id: string) => (e: any) => {
    toggleHideColumn(id, !e.target.checked); // we need not(!) because toggle expects the old value and not the new one.
    const newColumnExclusion = { ...columnExclusion };
    newColumnExclusion[id] = !newColumnExclusion[id];
    setColumnExclusion(newColumnExclusion);
  };

  return (
    <IconPopupMenu
      icon={
        <Tooltip title='View Columns'>
          <ViewColumn color='action' />
        </Tooltip>
      }>
      <Stack paddingLeft={2}>
        <Typography variant='subtitle2'>{i18next.t('dataTable:showColumns')}</Typography>
        <Scrollbar autoHeightMin={'50vh'}>
          {columns?.map(({ id, label, display }) => (
            <MenuItem key={id}>
              <FormControlLabel
                css={columnsMenuCss}
                id={id}
                control={
                  <Checkbox
                    edge='start'
                    defaultChecked={display && !columnExclusion[id]}
                    disableRipple
                    onChange={onPressCheckbox(id)}
                  />
                }
                label={label}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </Stack>
    </IconPopupMenu>
  );
};
