import { arrayOfObjectsToObject } from '@laguna/common/utils/arrayUtils';
import { CircularProgress, TextField, TextFieldProps } from '@mui/material';
import { identity, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { AsyncSelectField, SharedFieldProps } from '../formTypes';
import SelectInput from './SelectInput';

interface AsyncSelectInputProps extends SharedFieldProps, Omit<AsyncSelectField, 'type' | 'validate'> {
  inputProps?: TextFieldProps['inputProps'];
}

const AsyncSelectInput: React.FC<AsyncSelectInputProps> = ({ query, queryKey, valuesKey, ...rest }) => {
  const { data, isLoading } = query();
  const [values, setValues] = useState<Record<string, string>>({});

  useEffect(() => {
    if (data) {
      const key = queryKey ? queryKey : Object.getOwnPropertyNames(data)[0];
      setValues(arrayOfObjectsToObject(data[key], 'id', valuesKey));
    }
  }, [data, queryKey, valuesKey]);

  if (isLoading)
    return (
      <TextField
        label={rest.label}
        value={rest.defaultValue}
        disabled={true}
        InputProps={{
          endAdornment: <CircularProgress size={14} />,
        }}
      />
    );
  if (isEmpty(values)) {
    return <TextField label={`${rest.label} - no data`} value={rest.defaultValue} disabled={true} />;
  }
  return <SelectInput values={values} nullable={false} transformValues={identity} {...rest} />;
};

export default AsyncSelectInput;
