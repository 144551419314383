import { cast } from '@laguna/api/utils';
import { VariableSizeListCustomProps, VariableSizeListRef } from '@laguna/types';
import React, { CSSProperties, forwardRef, memo, useCallback, useRef } from 'react';
import { VariableSizeList, VariableSizeListProps, areEqual } from 'react-window';
import { CustomScrollbarsVirtualList } from './CustomScrollbarsVirtualList';

const List = cast<React.FC<VariableSizeListCustomProps>>(VariableSizeList);
const DEFAULT_ITEM_HEIGHT = 50;

export interface ReactWindowListProps extends Omit<VariableSizeListProps, 'itemSize' | 'children' | 'width'> {
  itemCount: number;
  shownItems?: number;
  defaultItemHeight?: number;
  renderItem: (index: number, setRowHeight: (height: number) => void) => React.ReactNode;
  width?: string | number;
}

const Row = memo(({ index, style, data }: { index: number; style: CSSProperties; data: any }) => {
  return <div style={style}>{data.renderItem(index, (size: any) => data.setRowHeight(index, size))}</div>;
}, areEqual);

const _ReactWindowList = forwardRef<VariableSizeListRef, ReactWindowListProps>(
  (
    {
      itemCount,
      height,
      renderItem,
      defaultItemHeight = DEFAULT_ITEM_HEIGHT,
      width = '100%',
      onItemsRendered,
      ...rest
    },
    forawardedRef
  ) => {
    const rowHeights = useRef<Record<number, number>>({});
    const listRef = useRef<VariableSizeListRef>();
    const setListRef = useCallback((node: VariableSizeListRef) => {
      if (!listRef.current && node) {
        listRef.current = node;
      }
      if (typeof forawardedRef == 'function') {
        forawardedRef(node);
      }
    }, []);

    const setRowHeight = (index: number, size: number) => {
      listRef.current?.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    };

    const getRowHeight = (index: any) => {
      return rowHeights.current[index] || defaultItemHeight;
    };

    return (
      <List
        {...rest}
        onItemsRendered={onItemsRendered}
        className='List'
        height={height}
        width={width}
        itemData={{ renderItem, setRowHeight }}
        itemCount={itemCount}
        itemSize={getRowHeight}
        outerElementType={CustomScrollbarsVirtualList}
        ref={setListRef}>
        {Row}
      </List>
    );
  }
);

export const ReactWindowList = memo(_ReactWindowList);
