import { US_DATE_FORMAT } from '@laguna/common/consts';
import { DatePickerWrapper } from '@laguna/common/PickerWrappers';
import {
  convertCronsToFormData,
  getCronStringsByRepeat,
  getTimesAccordingToRepeatValue,
  Repeat,
} from '@laguna/common/todos';
import { capitalizeCamelCase } from '@laguna/common/utils/general';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { css } from '@mui/system';
import { addDays, endOfDay } from 'date-fns';
import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { TodoTimesSelect } from './TodoTimesSelect';

const marginTop = (theme: any) => css`
  margin-top: ${theme.spacing(2)};
  display: flex;
  width: 100%;
`;
const getWeekdayName = (day: number) => i18next.t(`common:toDos.weekdays.${day.toString()}`);

const weekdays = [0, 1, 2, 3, 4, 5, 6];
const timesADay = new Array(8).fill(0).map((_: number, index: number) => index + 1);

const icon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const repeatTypes = Object.entries(Repeat).map(([key, val]) => ({ key, val, name: capitalizeCamelCase(val) }));
const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as any);
};
export interface FrequencyChangeParams {
  cronExpressions: string[];
  start: Date;
  end?: Date;
}

interface FrequencySelectorProps {
  cronExpressions: string[];
  start: Date;
  end?: Date;
  showEndDate?: boolean;
  showDate?: boolean;
  onChange: (change?: FrequencyChangeParams) => void;
  onShowEndChange?: (showEndDate: boolean) => void;
  onShowDateChange?: (showDate: boolean) => void;
  allowPast?: boolean;
}

export const FrequencySelector: React.FC<FrequencySelectorProps> = ({
  start,
  end,
  showDate,
  cronExpressions,
  onChange,
  onShowEndChange,
  onShowDateChange,
  showEndDate,
  allowPast,
}) => {
  const [internalShowEndDate, setInternalShowEndDate] = useState(!!showEndDate);
  const [internalShowDate, setInternalShowDate] = useState(!!showDate);
  const data = useMemo(
    () =>
      convertCronsToFormData({
        start,
        end,
        cronExpressions,
      }),
    []
  );
  const [repeatType, setRepeatType] = useState(data.repeat);
  const [selectedWeekdays, setSelectedWeekdays] = useState(data.weekDays);
  const [repeatCount, setRepeatCount] = useState(data.timesADay);
  const [times, setTimes] = useState(data.times);
  const updateRepeat = (newRepeatCount: number) => {
    setRepeatCount(newRepeatCount);
    setTimes(getTimesAccordingToRepeatValue(times, repeatType, newRepeatCount));
  };

  const handleRepeatCountChange = (e: SelectChangeEvent<number>) => {
    updateRepeat(Number.parseInt(e.target.value.toString()));
  };

  const onInternalShowDateChange = (_: any, checked: boolean) =>
    setInternalShowDate(() => {
      if (checked) {
        updateChanges(true);
      } else {
        onChange(undefined);
      }
      onShowDateChange?.(checked);
      return checked;
    });

  const onInternalShowEndDateChange = (_: any, checked: boolean) =>
    setInternalShowEndDate(() => {
      if (checked) {
        onChange({ cronExpressions: cronExpressions, start, end: end || addDays(start, 1) });
      } else {
        onChange({ cronExpressions: cronExpressions, start, end: undefined });
      }
      onShowEndChange?.(checked);
      return checked;
    });

  const handleRepeatTypeChange = (e: SelectChangeEvent<Repeat>) => {
    const newRepeatType = e.target.value as Repeat;
    setRepeatType(newRepeatType);
    if (newRepeatType === Repeat.doesNotRepeat) {
      updateRepeat(1);
    }
  };
  const updateChanges = (invokeNew?: boolean) => {
    if (start || invokeNew) {
      const actualStart = invokeNew ? new Date() : start;
      const newCrons = getCronStringsByRepeat(actualStart, times, repeatType, selectedWeekdays);
      onChange({ cronExpressions: newCrons, start: actualStart, end });
    } else {
      onChange(undefined);
    }
  };

  useEffect(() => {
    updateChanges();
  }, [start, times, repeatType, selectedWeekdays, end]);

  return (
    <Stack width='100%'>
      <Typography className='todo-section-header'>
        <Checkbox
          color='primary'
          id='showDateId'
          // @ts-ignore data test id ts error
          inputProps={{ ['data-testid']: 'showDateId' }}
          checked={internalShowDate}
          onChange={onInternalShowDateChange}
        />
        <InputLabel htmlFor='showDateId'>{i18next.t('common:toDos.labels.showDate')}</InputLabel>
      </Typography>
      <Collapse mountOnEnter unmountOnExit in={internalShowDate}>
        <FormControl fullWidth>
          <InputLabel id='repeat-type-select'>{i18next.t('common:toDos.labels.repeat')}</InputLabel>
          <Select
            labelId='repeat-type-select'
            value={repeatType}
            inputProps={{ ['data-testid']: 'repeat-type-select' }}
            onChange={handleRepeatTypeChange}
            label={i18next.t('common:toDos.labels.repeat')}>
            {repeatTypes.map(({ key, val, name }) => (
              <MenuItem key={key} value={val}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {repeatType === Repeat.specificWeekdays && (
          <div data-testid='weekdaysSelect' css={marginTop}>
            <Autocomplete
              multiple
              options={weekdays}
              data-testid='weekdaysSelectInput'
              disableCloseOnSelect
              onChange={(_: any, newValue: number[]) => setSelectedWeekdays(newValue)}
              value={selectedWeekdays}
              getOptionLabel={(weekday) => getWeekdayName(weekday)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {getWeekdayName(option)}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params: any) => (
                <TextField
                  variant='outlined'
                  label={i18next.t('common:toDos.labels.weekdays')}
                  placeholder={i18next.t('common:toDos.labels.weekdaysPlaceholder')}
                  {...params}
                />
              )}
            />
          </div>
        )}
        <Collapse mountOnEnter unmountOnExit in={repeatType !== Repeat.doesNotRepeat}>
          <FormControl fullWidth css={marginTop}>
            <InputLabel id='repeatCountSelector'>{i18next.t('common:toDos.labels.timesADay')}</InputLabel>
            <Select
              labelId='repeatCountSelector'
              value={repeatCount}
              inputProps={{ ['data-testid']: 'repeatCountSelector' }}
              onChange={handleRepeatCountChange}
              label={i18next.t('common:toDos.labels.timesADay')}>
              {timesADay.map((timesADay) => (
                <MenuItem key={timesADay} value={timesADay}>
                  {`${timesADay} times a day`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Collapse>
        <div className='todo-times-section'>
          <DatePickerWrapper
            value={start}
            onChange={(newDate) => {
              if (newDate && isValidDate(newDate)) {
                onChange({ cronExpressions, start: newDate, end });
              }
            }}
            label={i18next.t('common:toDos.labels.start')}
            format={US_DATE_FORMAT}
            className='appointment-date-picker'
            disablePast={!allowPast}
          />
          <TodoTimesSelect value={times} onChange={setTimes} />
        </div>
        <Typography className='todo-section-header'>
          <Checkbox
            color='primary'
            id='showEndDateId'
            checked={internalShowEndDate}
            onChange={onInternalShowEndDateChange}
          />
          <InputLabel htmlFor='showEndDateId'>{i18next.t('common:toDos.labels.end')}</InputLabel>
        </Typography>
        {internalShowEndDate && (
          <DatePickerWrapper
            value={end || addDays(start, 1)}
            onChange={(newDate) => {
              if (newDate && !isValidDate(newDate)) {
                return;
              }
              onChange({ cronExpressions, start, end: newDate ? endOfDay(newDate) : undefined });
            }}
            label={i18next.t('common:toDos.labels.end')}
            minDate={start}
            format={US_DATE_FORMAT}
            className='appointment-date-picker'
            disablePast={!allowPast}
          />
        )}
      </Collapse>
    </Stack>
  );
};
