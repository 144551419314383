import { useQuery } from '@tanstack/react-query';
import { getFileMetadata, getJobPath, setFileTag } from './utils';
import { JobBase, JobMetadata } from './types';
import { useState } from 'react';
import { logger } from '@laguna/logger';

export const useGetJobMetadata = (job: JobBase) =>
  useQuery<JobMetadata>(
    ['s3', 'useGetJobMetadata', job.id],
    async () => {
      try {
        return await getFileMetadata({ path: getJobPath(job) });
      } catch (e) {
        return {};
      }
    },
    { enabled: !!job }
  );

export const useUpdateJobMetadata = () => {
  const [isLoading, setIsLoading] = useState(false);
  const update = async (job: JobBase, isDone: boolean) => {
    setIsLoading(true);
    try {
      await setFileTag({ path: getJobPath(job), tagName: 'isDone', value: String(isDone) });
    } catch (error) {
      logger.error('error uploading tag', { error });
    }
    setIsLoading(false);
  };
  return { update, isLoading };
};
