import { ArticleFragment } from '@laguna/api/backoffice';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { ItemsList } from '../../../components/ItemsList';
import { GroupData, useEditArticleOrgGroup } from './useEditArticleOrgGroup';

interface EnvGroupProps {
  group: GroupData;
  onOrgToggle: (env: string, orgId: string) => void;
  onBatchToggle: (env: string, currentValue: boolean) => void;
}

const EnvGroup: React.FC<EnvGroupProps> = ({ group, onOrgToggle, onBatchToggle }) => {
  const batchState = useMemo(() => {
    const isAllSelected = group.articleGroups.every((org) => org.exists);
    return {
      checked: isAllSelected,
      indeterminate: !isAllSelected && group.articleGroups.some((org) => org.exists),
    };
  }, [group]);

  const areSomeSelected = batchState.checked || batchState.indeterminate;

  return (
    <ItemsList
      itemTestId='articleOrgConnection'
      items={group.articleGroups}
      primaryText='name'
      checkedProperty='exists'
      onClick={(item) => onOrgToggle(group.name, item.orgId)}
      additionalTopElements={
        <ListItem data-testid='batchUpdate' onClick={() => onBatchToggle(group.name, areSomeSelected)}>
          <ListItemIcon>
            <Checkbox edge='start' {...batchState} disableRipple />
          </ListItemIcon>
          <ListItemText primary={i18next.t(areSomeSelected ? 'articles:selectNoOrgs' : 'articles:selectAllOrgs')} />
        </ListItem>
      }
    />
  );
};

interface EditArticleOrgsProps {
  article: ArticleFragment;
}

export const EditArticleOrgs: React.FC<EditArticleOrgsProps> = ({ article }) => {
  const { groups, toggleOrg, isDirty, updateOrgs, toggleEnv } = useEditArticleOrgGroup(article);

  return (
    <Stack key={article.id}>
      {groups.map((group) => (
        <Accordion key={group.name} TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}>
          <AccordionSummary expandIcon={<ExpandMore />} id={group.name}>
            <Typography>{group.name}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <EnvGroup group={group} onOrgToggle={toggleOrg} onBatchToggle={toggleEnv} />
          </AccordionDetails>
        </Accordion>
      ))}
      <Button onClick={updateOrgs} disabled={isDirty} data-testid='updateOrgsButton'>
        {i18next.t('articles:updateOrgs')}
      </Button>
    </Stack>
  );
};
