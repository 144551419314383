import { useLocalStorage } from '@laguna/common/utils/hooks';

export const useDataTableFiltersStorage = (tableId: string, defaultFilters: any = {}) => {
  const [searchTerm, setSearchTerm] = useLocalStorage('tableSearch_' + tableId, null, true);
  const [filters, setFilters] = useLocalStorage('tableFilters_' + tableId, defaultFilters, true);
  const [columnExclusion, setColumnExclusion] = useLocalStorage('tableColumnExclusion_' + tableId, {}, true);
  const [sort, setSort] = useLocalStorage('tableSort' + tableId, [], true);

  return { searchTerm, setSearchTerm, filters, setFilters, columnExclusion, setColumnExclusion, sort, setSort };
};
