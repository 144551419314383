import { Clear, Search } from '@mui/icons-material';
import { CircularProgress, Collapse, IconButton, StandardTextFieldProps, TextField, css } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const ESC_KEYCODE = 27;

const defaultSearchInputCss = css`
  width: 30vw;
`;
interface DebounceInputProps extends StandardTextFieldProps {
  searchText?: string;
  onSearch: (search: string) => void;
  options?: any;
  onHide: VoidFunction;
  open: boolean;
}

export const DebounceInput: React.FC<DebounceInputProps> = ({
  searchText,
  onHide,
  options,
  onSearch,
  open,
  ...props
}) => {
  const elRef = useRef<any>();
  const [pending, setPending] = useState(false);
  const debouncedSearch = useCallback(
    debounce((value) => {
      setPending(false);
      onSearch(value);
    }, 500),
    []
  );

  const handleTextChangeWrapper = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPending(true);
    debouncedSearch(event.target.value);
  };

  const _onHide = () => {
    if (elRef.current) {
      elRef.current.value = '';
    }
    onHide();
  };

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === ESC_KEYCODE) {
        _onHide();
      }
    };

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, []);

  return (
    <Collapse orientation='horizontal' in={open} unmountOnExit>
      <TextField
        autoFocus={true}
        InputProps={{
          startAdornment: <Search />,
          endAdornment: pending ? (
            <CircularProgress size={16} />
          ) : (
            <IconButton style={{ visibility: options?.searchAlwaysOpen ? 'hidden' : 'visible' }} onClick={_onHide}>
              <Clear />
            </IconButton>
          ),
        }}
        defaultValue={searchText}
        onChange={handleTextChangeWrapper}
        fullWidth={true}
        inputRef={elRef}
        css={defaultSearchInputCss}
        {...props}
      />
    </Collapse>
  );
};
