import React from 'react';
import { Controller } from 'react-hook-form';
import { FrequencySelectField, SharedFieldProps } from '../formTypes';
import { MedicationEditor } from './MedicationEditor';

interface MedicationInputProps extends SharedFieldProps, Omit<FrequencySelectField, 'type' | 'validate'> {}

export const MedicationInput: React.FC<MedicationInputProps> = ({ name, control, defaultValue, required }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => <MedicationEditor {...{ onChange, value }} />}
    />
  );
};
