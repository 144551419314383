import { FCC } from '@laguna/types';
import { Button, ButtonProps, ClickAwayListener, IconButton } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import React, { useEffect, useRef, useState } from 'react';

type buttonType = 'button' | 'icon';
export interface PopupMenuBaseProps {
  buttonType: buttonType;
  buttonContent: React.ReactNode;
  closeOnClick?: boolean;
  id?: string;
  buttonProps?: ButtonProps & { ['data-testid']?: string };
  menuProps?: Omit<MenuProps, 'open' | 'onClose'>;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  customFooter?: (onClose: (event?: any) => void) => React.ReactNode;
  className?: string;
  isLeftAnchor?: boolean;
}

const paperProps = (topMenuMode: boolean, isLeft?: boolean) => ({
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: !topMenuMode && 0,
      bottom: topMenuMode && -10,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
      ...(isLeft ? { left: 14 } : { right: 14 }),
    },
  },
});

const getButtonComp = (buttonType: buttonType): React.FC<ButtonProps> => {
  return buttonType === 'button' ? Button : IconButton;
};

export const PopupMenuBase: FCC<PopupMenuBaseProps> = ({
  closeOnClick,
  id = 'menu',
  children,
  buttonType,
  buttonContent,
  buttonProps = {},
  customFooter,
  onClose,
  className,
  onOpen,
  isLeftAnchor,
  menuProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorProps, setAnchorProps] = useState({});
  const [topMenuMode, setTopMenuMode] = useState<boolean>(false);
  const [WrapperButton] = useState<React.FC<ButtonProps>>(getButtonComp(buttonType));
  const open = Boolean(anchorEl);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) return;
    onOpen?.();
    setTimeout(() => {
      const height = containerRef?.current?.clientHeight || 0;
      const top = buttonRef?.current?.getBoundingClientRect().top || 0;
      const topMenuMode = window.innerHeight - (height + top + 50) < 0;

      if (topMenuMode) {
        setTopMenuMode(true);
        setAnchorProps({
          transformOrigin: { horizontal: 'right', vertical: 'bottom' },
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
      } else {
        setTopMenuMode(false);
        setAnchorProps({
          transformOrigin: { horizontal: 'right', vertical: 'top' },
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    }, 10);
  }, [open, onOpen]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <WrapperButton
          ref={buttonRef}
          onClick={handleClick}
          aria-controls={open ? id : undefined}
          data-testid={id}
          className={className}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          {...buttonProps}>
          {buttonContent}
        </WrapperButton>
        <Menu
          anchorEl={anchorEl}
          id={id}
          open={open}
          onClose={handleClose}
          onClick={closeOnClick ? handleClose : undefined}
          PaperProps={paperProps(topMenuMode, isLeftAnchor)}
          {...menuProps}
          {...anchorProps}>
          <div ref={containerRef}>
            {children}
            {customFooter && customFooter(handleClose)}
          </div>
        </Menu>
      </>
    </ClickAwayListener>
  );
};
