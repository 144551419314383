export type deployableBranches = Environments.main | Environments.develop | Environments.demo | Environments.highmark;

export enum Environments {
  main = 'main',
  develop = 'develop',
  demo = 'demo',
  localhost = 'localhost',
  highmark = 'highmark',
}

export const DEPLOYABLE_BRANCHES = [Environments.main, Environments.develop, Environments.demo, Environments.highmark];
const replaceToken = '@@';

export const domainsMap = {
  main: [`www.${replaceToken}`, `mayo.${replaceToken}`, replaceToken],
  develop: [`dev.${replaceToken}`, `develop.${replaceToken}`],
  demo: [`demo.${replaceToken}`],
  highmark: [`hmk.${replaceToken}`],
};

const namingMap: { [key: string]: string } = {
  harmony: 'harmony',
  register: 'register',
  survey: 'survey',
};

export const getDomains = (projectName: string) => ({
  demo: domainsMap.demo.map((str) => str.replace(replaceToken, projectName)),
  main: domainsMap.main.map((str) => str.replace(replaceToken, projectName)),
  develop: domainsMap.develop.map((str) => str.replace(replaceToken, projectName)),
  highmark: domainsMap.highmark.map((str) => str.replace(replaceToken, projectName)),
  localhost: [],
});

export const getEnvFromBranch = (branchName: string) => {
  if (branchName === 'main') return Environments.main;
  if (branchName === 'demo') return Environments.demo;
  if (branchName === 'highmark') return Environments.highmark;
  else return Environments.develop;
};

export const getDomainsFromAppName = (appName: string, branch: Environments): string[] => {
  return getDomains(namingMap[appName])[branch];
};

export const getBucketName = (appName: string, branchName: string) => {
  const env = getEnvFromBranch(branchName);
  return getDomains(namingMap[appName])[env][0];
};

export const stackEnv = {
  account: '757492387178',
  region: 'us-east-1',
};
