import { usePromptForm } from '@laguna/common/modals';
import i18next from 'i18next';
import { useMemo } from 'react';
import { useGets3Types } from '../../../hooks/useGets3Types';
import { TagResourceFormState, TagResourcesMode } from './types';
import { s3ResourcesToTagFields } from './utils';
import { OutputTagTarget } from '../../../hooks/types';

export const useTagResource = () => {
  const { data } = useGets3Types();
  const { fields } = useMemo(() => s3ResourcesToTagFields(data), [data]);
  const { prompt } = usePromptForm<TagResourceFormState>(fields, { formId: 'tagResource' });

  return {
    tagResource: async (): Promise<OutputTagTarget | undefined> => {
      const result = await prompt(i18next.t('tagging:tagResourceTitle'), { mode: TagResourcesMode.attribute });
      if (result) {
        const { mode, barrier, attributeKey, attributeValue } = result;
        return {
          type: (mode || '') as TagResourcesMode,
          id: (mode === TagResourcesMode.barrier ? barrier : `${attributeKey} - ${attributeValue}`) || '',
        };
      }
    },
  };
};
