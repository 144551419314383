import { Check, Close, LightbulbOutlined } from '@mui/icons-material';
import { Divider, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { getPasswordStrength } from './utils';

import { css } from '@mui/system';
const divider = (theme: any) => css`
  margin: ${theme.spacing(1)} 0;
`;

interface PasswordInputStrengthProps {
  password: string;
}
const checkTypes: (keyof ReturnType<typeof getPasswordStrength>)[] = [
  'minimumLength',
  'mandatoryNumber',
  'diverseCharacters',
];
export const PasswordInputStrength: React.FC<PasswordInputStrengthProps> = ({ password }) => {
  const state = useMemo(() => getPasswordStrength(password), [password]);

  return (
    <Stack>
      {checkTypes.map((checkType) => (
        <Stack key={checkType} direction='row' spacing={1} data-testid={checkType}>
          {state[checkType] ? <Check color='success' /> : <Close color='error' />}
          <Typography>{i18next.t(`common:passwordStrength.${checkType}`)}</Typography>
        </Stack>
      ))}

      <Divider css={divider} />

      <Stack color={(theme) => theme.palette.text.secondary} direction='row' spacing={1} data-testid='tip'>
        <LightbulbOutlined />
        <Typography>{i18next.t('common:passwordStrength.tip')}</Typography>
      </Stack>
    </Stack>
  );
};
