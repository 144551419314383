import { isValidNumberForRegion, parsePhoneNumber } from 'libphonenumber-js';

const DEFAULT_COUNTRY_CODE = 'US';

// We currently don't support country prefixes that starts the same (for example +2, +27)
export const countryPrefixes: { [key: string]: { value: string; removeZeroPrefix?: boolean } } = {
  US: { value: '+1' },
  ZA: { value: '+27', removeZeroPrefix: true },
  IL: { value: '+972', removeZeroPrefix: true },
};
export const COUNTRY_PREFIX = countryPrefixes['US'].value;

export const MAX_PHONE_INPUT_LENGTH = 13;
export const MAX_TEXT_INPUT_LENGTH = 20;
export const MAX_EMAIL_INPUT_LENGTH = 45;

export const preparePhoneForSubmit = (phoneNumber: string, countryCode = 'US') => {
  if (!phoneNumber) return phoneNumber;
  const shouldCleanZero = countryPrefixes[countryCode].removeZeroPrefix && phoneNumber.startsWith('0');
  const cleanNumber = shouldCleanZero ? phoneNumber.slice(1) : phoneNumber;
  return countryPrefixes[countryCode].value + cleanNumber;
};

export const phoneValidationWithoutCountry = (phone: string) => {
  const phoneNumber = parsePhoneNumber(phone);
  const isValid = phoneNumber && phoneNumber.isValid();
  return isValid ? undefined : 'Malformed phone number';
};

export const phoneValidation = (phone: string, countryCode: string) => {
  return isValidNumberForRegion(phone, countryCode as any) ? undefined : 'Malformed phone number';
};

export const phoneValidationWithEmpty = (phoneNumber: string, countryCode: string) => {
  if (phoneNumber === '') return undefined;
  return phoneValidation(phoneNumber, countryCode);
};

export const getBasePhone = (fullPhone: string): { countryCode?: string; number: string } => {
  try {
    const phone = parsePhoneNumber(fullPhone);
    const countryCode = countryPrefixes[phone.country as string] ? phone.country : DEFAULT_COUNTRY_CODE;
    return { countryCode, number: fullPhone.replace('+' + phone.countryCallingCode, '') };
  } catch (error) {
    return { number: fullPhone };
  }
};

export const displayPhoneNumber = (phone: string) => {
  const fullPhone = phone.startsWith('+') ? phone : COUNTRY_PREFIX + phone;
  try {
    return parsePhoneNumber(fullPhone).formatNational();
  } catch (error) {
    return phone;
  }
};
