import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          "&.Mui-disabled": {
            background: 'none'
          },
          borderRadius: 8,
        },
        sizeLarge: {
          height: 26,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: 'none',
          '&:hover': {
            transition: theme.transitions.create(['backgroundColor', 'color']),
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: 'none',
          background: theme.palette.gradients.primary,
          transition: theme.transitions.create(['background', 'color']),
          '&:hover': {
            background: theme.palette.gradients.secondary,
            transition: theme.transitions.create(['background', 'color']),
          },
        },
        containedSecondary: {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
        },
        containedInfo: {
          boxShadow: 'none',
        },
        containedSuccess: {
          boxShadow: 'none',
        },
        containedWarning: {
          boxShadow: 'none',
        },
        containedError: {
          boxShadow: 'none',
        },
        // outlined
        outlined: {
          border: '1px solid',
          '&:hover': {
            backgroundColor: theme.palette.primary.lighter,
          },
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
