import { Theme } from '@mui/material/styles';
//
//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: 412,

              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: theme.palette.action.hover,
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
        },
      },
    },
  };
}
