import { FCC } from '@laguna/types';
import { Tooltip, TooltipProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface TooltipWrapperProps {
  title: NonNullable<React.ReactNode>;
  onlyShowTooltipTextOverflow?: boolean;
  extraTooltipProps?: Partial<TooltipProps>;
}

const TooltipWrapper: FCC<TooltipWrapperProps> = ({
  title,
  onlyShowTooltipTextOverflow,
  children,
  extraTooltipProps,
}) => {
  const [hoverStatus, setHover] = useState(!onlyShowTooltipTextOverflow);

  const textElementRef = useRef<HTMLSpanElement>(null);

  const compareSize = () => {
    const child = textElementRef.current?.firstChild as HTMLDivElement;
    const compare = child.scrollWidth > child.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    if (onlyShowTooltipTextOverflow) {
      compareSize();
      window.addEventListener('resize', compareSize);
      return () => {
        window.removeEventListener('resize', compareSize);
      };
    }
  }, [onlyShowTooltipTextOverflow]);

  return (
    <Tooltip data-testid='tooltip' title={title} disableHoverListener={!hoverStatus} {...extraTooltipProps}>
      <span ref={textElementRef}>{children}</span>
    </Tooltip>
  );
};
interface BasicTooltipProps {
  title?: React.ReactNode;
  onlyShowTooltipTextOverflow?: boolean;
  disableArrow?: boolean;
  placement?: TooltipProps['placement'];
}
// https://stackoverflow.com/questions/39133797/react-only-return-props-children
// @ts-ignore ok to return children in react 16+
export const BasicTooltip: FCC<BasicTooltipProps> = ({
  title,
  onlyShowTooltipTextOverflow,
  children,
  disableArrow,
  placement = 'bottom',
}) => {
  if (title) {
    return (
      <TooltipWrapper
        {...{ title, onlyShowTooltipTextOverflow, children, disableArrow }}
        extraTooltipProps={{ arrow: !disableArrow, placement }}
      />
    );
  }
  return children;
};
