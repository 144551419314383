import { Avatar, AvatarProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import { css } from '@mui/system';

const randomColorList = [
  '#ff89ee',
  '#a1baff',
  '#FFC107',
  '#EDAC2E',
  '#E58F0D',
  '#E5680D',
  '#4CAF7F',
  '#1AC7B7',
  '#009688',
  '#1F96BC',
  '#09ADE0',
  '#5CB9FC',
  '#0093FE',
  '#0084E4',
  '#5B74FF',
  '#4353E3',
  '#8845FF',
  '#5D12E1',
  '#4F4CF9',
  '#9C27B0',
  '#9306AB',
  '#C934E2',
  '#F260B3',
  '#C81E8E',
  '#DD43A8',
  '#ffd553',
  '#ffae53',
  '#46dcce',
  '#65dcff',
  '#46d9ff',
  '#8999ff',
  '#ce8bff',
  '#a358ff',
  '#e26df6',
  '#d94cf1',
  '#ff7aff',
  '#ff64d4',
  '#066939',
  '#008171',
  '#005042',
  '#005076',
  '#00679a',
  '#1673b6',
  '#004db8',
  '#003e9e',
  '#152eb9',
  '#000d9d',
  '#4200b9',
  '#17009b',
  '#0906b3',
  '#56006a',
  '#4d0065',
  '#83009c',
  '#ac1a6d',
  '#820048',
  '#970062',
];

type Variant = 'fill' | 'outlined';

interface AvatarInitialsProps extends Partial<Omit<AvatarProps, 'variant'>> {
  name?: string;
  id?: string;
  variant?: Variant;
}
const avatarInitials = css`
  font-size: 1rem;
  font-weight: 600;

  span:first-of-type {
    padding-right: 1px;
  }
`;

const avatarCss = (color: string, variant: Variant) => (theme: Theme) =>
  css`
    background-color: ${variant === 'fill' ? color : color + '80'};
    color: ${theme.palette.common.white};
    ${variant === 'outlined' ? 'border: solid 1.5px ' + color + ';' : ''}
  `;

const stringToColor = (str: string) => {
  const hash =
    Array.from(str).reduce((hash, char) => (hash * 31 + char.charCodeAt(0)) & 0xffffffff, 0) / 0xffffffff + 0.5;
  const index = Math.floor(hash * randomColorList.length);
  return randomColorList[index];
};

const DEFAULT_NAME = 'Laguna Member';
const memberAvatar = (name = DEFAULT_NAME, variant: Variant = 'fill') => {
  const _name = name ? name : DEFAULT_NAME;
  const words = _name.toUpperCase().split(' ');
  const firstName = words[0][0];
  const lastName = words.at(-1)?.[0] ?? 'M';
  return {
    css: avatarCss(stringToColor(name), variant),
    children: (
      <span css={avatarInitials}>
        <span>{firstName}</span>
        <span>{lastName}</span>
      </span>
    ),
  };
};

export const AvatarInitials: React.FC<AvatarInitialsProps> = ({ name, variant, ...rest }) => {
  const avatarProps = useMemo(() => memberAvatar(name, variant), [name, variant]);
  return <Avatar {...avatarProps} {...rest} />;
};
