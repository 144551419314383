import { FieldType, FieldsType } from '@laguna/common/form/formTypes';
import { usePromptForm } from '@laguna/common/modals';
import i18next from 'i18next';
import { zipObject } from 'lodash';
import { useMemo } from 'react';
import { useGets3Types } from '../../../hooks/useGets3Types';
import { TagSentimentFormState } from './types';
import { OutputTagTarget } from '../../../hooks/types';

const getFormFields = (values?: string[]): FieldsType[] =>
  values
    ? [
        {
          name: 'sentiment',
          label: i18next.t('tagging:tagSentiment'),
          type: FieldType.select,
          values: zipObject(values, values),
          required: true,
          nullable: false,
        },
      ]
    : [];

export const useTagSentiment = () => {
  const { data } = useGets3Types();
  const fields = useMemo<FieldsType[]>(() => getFormFields(data?.sentiments), [data]);
  const { prompt } = usePromptForm<TagSentimentFormState>(fields, { formId: 'tagResource' });

  return {
    tagSentiment: async (): Promise<OutputTagTarget | undefined> => {
      const result = await prompt(i18next.t('tagging:tagSentimentTitle'), {});
      if (result) {
        const { sentiment } = result;
        return { type: 'sentiment', id: sentiment || '' };
      }
    },
  };
};
