import { Divider as DividerElement, Typography, TypographyProps } from '@mui/material';
import i18next from 'i18next';
import { get, omit } from 'lodash';
import { FC, useState } from 'react';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useAsyncEffect } from '../utils/hooks';
import AsyncAutocompleteInput from './Autocomplete/AsyncAutocompleteInput';
import { Checkbox } from './Checkbox';
import { ChoiceInput } from './ChoiceInput';
import DateInput from './DateInput';
import DateTimeInput from './DateTimeInput';
import { ExternalAppointmentInput } from './ExternalAppointment/ExternalAppointmentInput';
import { FilePickerInput } from './FileSelect/FilePickerInput';
import { FormPasswordStrength } from './FormPasswordStrength';
import { FrequencySelectorInput } from './FrequencySelect/FrequencySelectorInput';
import { MedicationInput } from './Medication/MedicationInput';
import { OrderedList } from './OrderedList/OrderedList';
import { PasswordInput } from './PasswordInput';
import { PhoneInput } from './PhoneInput';
import { RadioButtons } from './RadioButtons';
import { RangeInput } from './RangeInput';
import AsyncSelectInput from './Select/AsyncSelectInput';
import DynamicSelectInput from './Select/DynamicSelectInput';
import SelectInput from './Select/SelectInput';
import { SubForm } from './SubForm';
import { Switch } from './Switch';
import TextInput from './TextInput';
import { gridArea } from './formStyles';
import { FieldType, FieldsType, SharedFieldProps, isWithInputVariant } from './formTypes';
import { emailPattern } from './utils';
import { AutocompleteInput } from './Autocomplete/AutocompleteInput';

const isDisableAutofill = (field: any) => !!field.disableAutofill;
export interface FormFieldProps {
  field: FieldsType;
  initialData: Record<string, any>;
  control: Control<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<FieldValues>;
  disabled: boolean;
  formId: string;
}

export const FormField: FC<FormFieldProps> = (props) => {
  const { field: originalField, initialData, control, disabled, watch, formId, setValue } = props;
  const [field, setField] = useState(originalField);
  const { name, label, autoFocus, required, type, isHidden, getDerivedState, validate } = field;

  const updateDerivedState = async (derivedData: Partial<any> | Promise<Partial<any>>) => {
    const data = derivedData instanceof Promise ? await derivedData : derivedData;
    if (data.value !== undefined) {
      setValue(name, data.value);
    }
    setField((old) => ({
      ...old,
      ...data,
    }));
  };

  useAsyncEffect(async () => {
    if (getDerivedState) {
      const derivedData = getDerivedState(control._formValues, undefined, undefined, initialData);

      updateDerivedState(derivedData);

      const subscription = watch((data: any, info) => {
        const derivedData = getDerivedState(data, info.name, setValue, initialData);
        updateDerivedState(derivedData);
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, getDerivedState]);

  if (isHidden) {
    return null;
  }

  const defaultValue = get(initialData, name, field.defaultValue) ?? '';
  const sharedProps: SharedFieldProps = {
    name,
    label: i18next.t(label),
    control,
    defaultValue,
    autoFocus: autoFocus ?? false,
    required: required ?? false,
    formId,
    disabled: disabled || field.disabled,
    validate,
  };
  if (isWithInputVariant(field)) {
    sharedProps.variant = field.variant;
  }
  const testIdString = `generated-form-${type}-${name}`;
  const testId = { ['data-testid']: testIdString };

  // weird hack from MUI docs https://mui.com/material-ui/react-autocomplete/#autocomplete-autofill
  const autoComplete = isDisableAutofill(field) ? 'new-password' : undefined;
  switch (type) {
    case FieldType.text:
      return (
        <TextInput
          key={name}
          inputProps={{
            maxLength: field.maxLength,
            autoComplete,
            ...field.inputProps,
            ...testId,
          }}
          {...sharedProps}
          required={!!sharedProps.required}
          InputLabelProps={field.InputLabelProps}
          placeholder={field.placeholder}
          rows={field.rows ?? 1}
          style={{ width: field.width }}
          type={field.inputType ?? 'text'}
        />
      );
    case FieldType.email:
      return (
        <TextInput
          key={name}
          type='email'
          {...sharedProps}
          required={!!sharedProps.required}
          inputProps={{ maxLength: field.maxLength, autoComplete, ...testId }}
          rows={1}
          pattern={emailPattern}
        />
      );
    case FieldType.password:
      return (
        <PasswordInput
          key={name}
          type='password'
          InputLabelProps={field.InputLabelProps}
          {...sharedProps}
          required={!!sharedProps.required}
          inputProps={{ maxLength: field.maxLength, autoComplete, ...testId }}
          rows={1}
        />
      );
    case FieldType.passwordStrength:
      return <FormPasswordStrength key={name} {...sharedProps} passwordFieldName={field.passwordFieldName} />;
    case FieldType.phone: {
      return (
        <PhoneInput
          {...field}
          {...sharedProps}
          required={!!sharedProps.required}
          autoComplete={autoComplete}
          testId={testId}
          name={name}
        />
      );
    }
    case FieldType.select:
      return (
        <SelectInput
          key={name}
          {...omit(field, ['rowId', 'shouldRender', 'defaultValue', 'getDerivedState', 'isHidden'])}
          {...sharedProps}
          values={field.values || {}}
          nullable={field.nullable ?? true}
          inputProps={testId}
        />
      );
    case FieldType.asyncAutocomplete:
      return (
        <AsyncAutocompleteInput
          key={name}
          {...sharedProps}
          fetchItems={field.fetchItems}
          fetchOnMount={field.fetchOnMount}
          groupBy={field.groupBy}
          getOptionDisabled={field.getOptionDisabled}
          renderOption={field.renderOption}
        />
      );
    case FieldType.autocomplete:
      return <AutocompleteInput key={name} {...sharedProps} {...field} />;
    case FieldType.frequency:
      return <FrequencySelectorInput key={name} {...sharedProps} allowPast={field.allowPast} />;
    case FieldType.medication:
      return <MedicationInput key={name} {...sharedProps} />;
    case FieldType.externalAppointment:
      return <ExternalAppointmentInput key={name} {...sharedProps} />;
    case FieldType.asyncSelect:
      return (
        <AsyncSelectInput
          key={name}
          {...sharedProps}
          query={field.query}
          queryKey={field.queryKey}
          valuesKey={field.valuesKey}
          multiple={field.multiple}
          inputProps={testId}
        />
      );
    case FieldType.dynamicSelect:
      return <DynamicSelectInput key={name} fetchItems={field.fetchItems} inputProps={testId} {...sharedProps} />;
    case FieldType.date:
      return (
        <DateInput
          key={name}
          {...sharedProps}
          defaultValue={defaultValue || null}
          disableFuture={field.disableFuture}
          textInputProps={{ inputProps: testId }}
          maxDate={field.maxDate}
        />
      );
    case FieldType.dateTime:
      return <DateTimeInput key={name} {...sharedProps} />;
    case FieldType.title: {
      const variant: { [key: string]: TypographyProps['variant'] } = {
        title: 'h4',
        subtitle: 'h5',
        label: 'body1',
        caption: 'caption',
      };
      return (
        <Typography
          key={name}
          css={gridArea(name)}
          fontWeight={field.fontWeight ?? 600}
          variant={field.variant ? variant[field.variant] : undefined}
          {...field.typographyProps}>
          {field.label}
        </Typography>
      );
    }
    case FieldType.divider:
      return <DividerElement key={name} css={gridArea(name)} />;
    case FieldType.radio:
      return (
        <RadioButtons
          key={name}
          transformValues={field.transformValues}
          {...sharedProps}
          values={field.values || {}}
          direction={field.direction}
          getTooltipForValue={field.getTooltipForValue}
        />
      );
    case FieldType.checkbox:
      return (
        <Checkbox
          key={name}
          {...sharedProps}
          options={field.options}
          transformValues={field.transformValues}
          readOnly={field.readOnly}
        />
      );
    case FieldType.choice:
      return (
        <ChoiceInput
          key={name}
          {...sharedProps}
          options={field.options}
          readOnly={field.readOnly}
          isColored={field.isColored}
        />
      );
    case FieldType.range:
      return <RangeInput key={name} {...sharedProps} {...omit(field, ['isHidden'])} />;
    case FieldType.subForm:
      return <SubForm props={props} field={omit(field, ['isHidden'])} />;
    case FieldType.switch:
      return (
        <Switch
          label={label}
          name={name}
          description={field.description}
          defaultValue={defaultValue}
          validateOnChange={field.validateOnChange}
          {...testId}
          {...props}
        />
      );
    case FieldType.file:
      return (
        <FilePickerInput
          accept={field.accept}
          label={label}
          name={name}
          defaultValue={defaultValue}
          {...props}
          testId={testIdString}
        />
      );
    case FieldType.orderedList:
      return <OrderedList props={props} field={omit(field, ['isHidden'])} />;
    default:
      return null;
  }
};
