import { Close } from '@mui/icons-material';
import { Box, Chip, IconButton, Theme } from '@mui/material';
import { css } from '@mui/system';
import { noop } from 'lodash';
import React, { useMemo } from 'react';

interface FileRendererProps {
  file: File;
  onClick?: VoidFunction;
}
interface FileUrlRendererProps {
  url: string;
  mimeType: string;
  onClick?: VoidFunction;
}

const fileWrapper = (theme: any) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .closeButton {
    position: absolute;
    background-color: white;
    right: ${theme.spacing(1)};
    top: ${theme.spacing(1)};
    opacity: 0;
    box-shadow: ${theme.shadows[10]};
    transition: opacity 0.2s;
    z-index: 1;
  }
  .fileName {
    position: absolute;
    top: ${theme.spacing(1)};
    left: ${theme.spacing(1)};
    background-color: ${theme.palette.background.neutral};
    max-width: calc(100% - 70px);
    transition: opacity 0.2s;
    opacity: 0;
  }

  &:hover {
    .closeButton,
    .fileName {
      opacity: 1;
    }
  }

  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  embed {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const InnerFileRenderer: React.FC<FileUrlRendererProps> = ({ url, mimeType }) => {
  const type = mimeType.split('/')[0];

  switch (type) {
    case 'image':
      return <img src={url} alt={url} />;
    case 'audio':
      return (
        <audio controls>
          <source src={url} />
          Your browser does not support the audio element.
        </audio>
      );
    case 'video':
      return (
        <video controls>
          <source src={url} />
          Your browser does not support the video element.
        </video>
      );
    default:
      if (mimeType === 'application/pdf') {
        return <embed src={url} type='application/pdf' />;
      } else {
        return <p>Unsupported file type.</p>;
      }
  }
};

export const FileUrlRenderer: React.FC<FileUrlRendererProps> = ({ url, mimeType, onClick = noop }) => (
  <Box css={fileWrapper}>
    <IconButton className='closeButton' onClick={onClick}>
      <Close />
    </IconButton>
    <Chip variant='filled' color='default' className='fileName' label={url.split('/').at(-1)} />
    <InnerFileRenderer url={url} mimeType={mimeType} />
  </Box>
);
