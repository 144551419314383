import { passwordStrength } from 'check-password-strength';

export const emailPattern = /\S+@\S+\.\S+/;

export const getPasswordStrength = (password: string) => {
  const result = passwordStrength(password);

  const minimumLength = result.length >= 8;
  const mandatoryNumber = result.contains.includes('number');
  const diverseCharacters = result.contains.length >= 3;
  return {
    minimumLength,
    mandatoryNumber,
    diverseCharacters,
    valid: minimumLength && mandatoryNumber && diverseCharacters,
  };
};

export const isValidPassword = (password: string) => getPasswordStrength(password).valid;
