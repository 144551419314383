import { ButtonProps, DialogProps } from '@mui/material';
import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';

export type HookModalDataProps = Pick<
  ModalDataProps,
  | 'id'
  | 'content'
  | 'title'
  | 'okButtonProps'
  | 'skipButtonProps'
  | 'cancelButtonProps'
  | 'disableCloseOnClickOutSide'
  | 'isDraggable'
  | 'hideButtons'
  | 'hideBackdrop'
  | 'disableBackdropClick'
  | 'dialogProps'
  | 'showCloseIcon'
>;

type ModalButtonProps = keyof Pick<ModalDataProps, 'cancelButtonProps' | 'skipButtonProps' | 'okButtonProps'>;

type ActionButtonProps = {
  props?: Omit<ButtonProps, 'onClick'>;
  label?: string;
  onClick?: (onClose: () => void) => void;
  hidden?: boolean;
};
export type ModalDataProps = {
  content?: ReactNode;
  id: string;
  title?: ReactNode;
  isOpen: boolean;
  isDraggable?: boolean;
  okButtonProps?: ActionButtonProps;
  skipButtonProps?: ActionButtonProps;
  cancelButtonProps?: ActionButtonProps;
  disableCloseOnClickOutSide?: boolean;
  hideButtons?: boolean;
  hideBackdrop?: boolean;
  disableBackdropClick?: boolean;
  dialogProps?: Partial<DialogProps>;
  showCloseIcon?: boolean;
};
class ModalStore {
  isOpen = false;
  _openModalsData: { [id: string]: ModalDataProps } = {};

  constructor() {
    this._openModalsData = {};
    makeAutoObservable(this);
  }

  get openModalsData() {
    return Object.values(this._openModalsData);
  }

  addModalData = (data: ModalDataProps) => {
    this._openModalsData[data.id] = data;
  };

  getIsModalVisible = (id: string) => {
    return this._openModalsData[id]?.isOpen;
  };

  setModalIsOpen = (id: string, show: boolean) => {
    if (this._openModalsData[id]) {
      this._openModalsData[id].isOpen = show;
    }
  };

  closeModal = (id: string) => {
    this.setModalIsOpen(id, false);
  };

  updateModal = (data: HookModalDataProps) => {
    this._openModalsData[data.id] = { ...this._openModalsData[data.id], ...data };
  };

  removeModalData = (modalId: string) => {
    delete this._openModalsData[modalId];
  };

  resolveAndClose = (modalId: string, clickType: ModalButtonProps) => {
    const buttonProps = (this._openModalsData[modalId] || {})[clickType];
    const { onClick } = buttonProps ?? {};
    if (onClick) {
      onClick(() => this.setModalIsOpen(modalId, false));
    } else {
      this.setModalIsOpen(modalId, false);
    }
  };

  onConfirm = (modalId: string) => {
    this.resolveAndClose(modalId, 'okButtonProps');
  };

  onSkip = (modalId: string) => {
    this.resolveAndClose(modalId, 'skipButtonProps');
  };

  onCancel = (modalId: string, reason: string) => {
    const { disableCloseOnClickOutSide } = this._openModalsData[modalId] ?? {};
    if (reason === 'backdropClick' && disableCloseOnClickOutSide) return;
    this.resolveAndClose(modalId, 'cancelButtonProps');
  };
}

export const modalStore = new ModalStore();
