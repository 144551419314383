import { default as i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import { EN_TRANSLATION } from '@laguna/translations';

export const initTranslation = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: { ...en, common: { ...en.common, ...EN_TRANSLATION.common } },
      },
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
};
