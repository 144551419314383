import { FCC } from '@laguna/types';
import { Cancel } from '@mui/icons-material';
import { IconButton, Theme, css } from '@mui/material';
import { SnackbarProvider as Provider, SnackbarKey, useSnackbar } from 'notistack';
import { CommonSnackbar } from './CommonSnackbars';
import { CustomSuccess } from './CustomSnackbars';

const snackBarAction = (theme: Theme) => css`
  color: ${(theme as Theme).palette.common.white};
`;

const customComponentsLagunaLite = {
  success: CustomSuccess,
};

const commonComponents = {
  success: CommonSnackbar,
  error: CommonSnackbar,
  warning: CommonSnackbar,
  info: CommonSnackbar,
};

export const SnackbarProvider: FCC<{ useCustomComponent?: boolean }> = ({ children, useCustomComponent }) => {
  return (
    <Provider
      preventDuplicate
      maxSnack={3}
      Components={useCustomComponent ? customComponentsLagunaLite : commonComponents}
      iconVariant={{
        error: null,
      }}
      
      action={(key: SnackbarKey) => <SnackbarActions messageKey={key} />}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      {children}
    </Provider>
  );
};

export const SnackbarActions: React.FC<{ messageKey?: SnackbarKey }> = ({ messageKey = '' }) => {
  const { closeSnackbar } = useSnackbar();

  const onClick = (e: any) => {
    e.stopPropagation();
    closeSnackbar(messageKey);
  };

  return (
    <IconButton onClick={onClick} size='large' css={snackBarAction}>
      <Cancel />
    </IconButton>
  );
};
