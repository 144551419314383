/**
* THIS FILE WAS GENERATED USING "yarn updateResources"
* DON'T CHANGE IT MANUALLY,
* to update Images, remove/ add svg files to libs/resources/images then re-run "yarn updateResources"
* */

import addCircleOutline from '@laguna/resources/addCircleOutline.svg';
import ai from '@laguna/resources/ai.svg';
import ai_sparkle from '@laguna/resources/ai_sparkle.svg';
import allianceInsight from '@laguna/resources/allianceInsight.svg';
import appointmentsEmpty from '@laguna/resources/appointmentsEmpty.svg';
import assess from '@laguna/resources/assess.svg';
import avatarBarrierIdentification from '@laguna/resources/avatarBarrierIdentification.svg';
import avatarBurnoutRisk from '@laguna/resources/avatarBurnoutRisk.svg';
import avatarEmpathyLevel from '@laguna/resources/avatarEmpathyLevel.svg';
import barrierBlack from '@laguna/resources/barrierBlack.svg';
import barriers from '@laguna/resources/barriers.svg';
import caregiverEmpty from '@laguna/resources/caregiverEmpty.svg';
import collaborate from '@laguna/resources/collaborate.svg';
import constructiveSuggestion from '@laguna/resources/constructiveSuggestion.svg';
import conversation from '@laguna/resources/conversation.svg';
import dnaEmpty from '@laguna/resources/dnaEmpty.svg';
import engage from '@laguna/resources/engage.svg';
import error from '@laguna/resources/error.svg';
import feedback from '@laguna/resources/feedback.svg';
import feedbackSection from '@laguna/resources/feedbackSection.svg';
import gradientBulb from '@laguna/resources/gradientBulb.svg';
import gradientKey from '@laguna/resources/gradientKey.svg';
import gradientTrophy from '@laguna/resources/gradientTrophy.svg';
import influence from '@laguna/resources/influence.svg';
import info from '@laguna/resources/info.svg';
import insights_clock from '@laguna/resources/insights_clock.svg';
import insights_folder from '@laguna/resources/insights_folder.svg';
import insights_logo from '@laguna/resources/insights_logo.svg';
import insights_phone from '@laguna/resources/insights_phone.svg';
import laguna from '@laguna/resources/laguna.svg';
import laguna_live_activation from '@laguna/resources/laguna_live_activation.svg';
import laguna_live_ai from '@laguna/resources/laguna_live_ai.svg';
import laguna_live_ai_empty from '@laguna/resources/laguna_live_ai_empty.svg';
import laguna_live_background from '@laguna/resources/laguna_live_background.png';
import laguna_live_call_empty from '@laguna/resources/laguna_live_call_empty.svg';
import laguna_live_home from '@laguna/resources/laguna_live_home.svg';
import laguna_live_logo from '@laguna/resources/laguna_live_logo.svg';
import laguna_live_task from '@laguna/resources/laguna_live_task.svg';
import lightning from '@laguna/resources/lightning.svg';
import lisa from '@laguna/resources/lisa.svg';
import loading_bg from '@laguna/resources/loading_bg.svg';
import logEmptyState from '@laguna/resources/logEmptyState.svg';
import logo_laguna_l from '@laguna/resources/logo_laguna_l.svg';
import medical from '@laguna/resources/medical.svg';
import meterArrow from '@laguna/resources/meterArrow.svg';
import mobile from '@laguna/resources/mobile.svg';
import msg_icon from '@laguna/resources/msg_icon.svg';
import pathways_icon from '@laguna/resources/pathways_icon.svg';
import pdf_divider from '@laguna/resources/pdf_divider.svg';
import positiveSuggestion from '@laguna/resources/positiveSuggestion.svg';
import profile_icon from '@laguna/resources/profile_icon.svg';
import questionnaireIcon from '@laguna/resources/questionnaireIcon.svg';
import smartPad from '@laguna/resources/smartPad.svg';
import sparkyLagunaIcon from '@laguna/resources/sparkyLagunaIcon.svg';
import success from '@laguna/resources/success.png';
import summary from '@laguna/resources/summary.svg';
import support from '@laguna/resources/support.svg';
import supportiveActions from '@laguna/resources/supportiveActions.svg';
import tasksEmpty from '@laguna/resources/tasksEmpty.svg';
import transcript from '@laguna/resources/transcript.svg';
import empty_attributes_empty from '@laguna/resources/emptyStates/attributes_empty.svg';
import empty_barrier_empty from '@laguna/resources/emptyStates/barrier_empty.svg';
import empty_barriers_empty from '@laguna/resources/emptyStates/barriers_empty.svg';
import empty_calls_empty from '@laguna/resources/emptyStates/calls_empty.svg';
import empty_chat from '@laguna/resources/emptyStates/chat.svg';
import empty_doughnut_empty from '@laguna/resources/emptyStates/doughnut_empty.svg';
import empty_feedback_empty_new from '@laguna/resources/emptyStates/feedback_empty_new.svg';
import empty_insights from '@laguna/resources/emptyStates/insights.svg';
import empty_insights_table_empty from '@laguna/resources/emptyStates/insights_table_empty.svg';
import empty_meeting from '@laguna/resources/emptyStates/meeting.svg';
import empty_memberLog_empty from '@laguna/resources/emptyStates/memberLog_empty.svg';
import empty_members_now_empty from '@laguna/resources/emptyStates/members_now_empty.svg';
import empty_noMessages from '@laguna/resources/emptyStates/noMessages.svg';
import empty_no_consent from '@laguna/resources/emptyStates/no_consent.svg';
import empty_noresults from '@laguna/resources/emptyStates/noresults.svg';
import empty_pathway_empty from '@laguna/resources/emptyStates/pathway_empty.svg';
import empty_pathway_empty_state from '@laguna/resources/emptyStates/pathway_empty_state.svg';
import empty_pie_empty from '@laguna/resources/emptyStates/pie_empty.svg';
import empty_questionnaire_empty from '@laguna/resources/emptyStates/questionnaire_empty.svg';
import empty_sessions_empty from '@laguna/resources/emptyStates/sessions_empty.svg';
import empty_summary_empty from '@laguna/resources/emptyStates/summary_empty.svg';
import empty_tasks_empty from '@laguna/resources/emptyStates/tasks_empty.svg';
import empty_todo_empty from '@laguna/resources/emptyStates/todo_empty.svg';
import empty_transcript_empty from '@laguna/resources/emptyStates/transcript_empty.svg';
import empty_transcript_in_progress_empty from '@laguna/resources/emptyStates/transcript_in_progress_empty.svg';
import barriers_loading from '@laguna/resources/animations/barriers_loading.gif';
import calls_loading from '@laguna/resources/animations/calls_loading.gif';
import care_insights_processing from '@laguna/resources/animations/care_insights_processing.gif';
import conversation_tips_processing from '@laguna/resources/animations/conversation_tips_processing.gif';
import end_call from '@laguna/resources/animations/end_call.gif';
import item_processing from '@laguna/resources/animations/item_processing.gif';
import sparks from '@laguna/resources/animations/sparks.gif';
import summary_processing from '@laguna/resources/animations/summary_processing.gif';
import transcript_loading from '@laguna/resources/animations/transcript_loading.gif';

export const ImagesMap = {
    addCircleOutline,
    ai,
    ai_sparkle,
    allianceInsight,
    appointmentsEmpty,
    assess,
    avatarBarrierIdentification,
    avatarBurnoutRisk,
    avatarEmpathyLevel,
    barrierBlack,
    barriers,
    caregiverEmpty,
    collaborate,
    constructiveSuggestion,
    conversation,
    dnaEmpty,
    engage,
    error,
    feedback,
    feedbackSection,
    gradientBulb,
    gradientKey,
    gradientTrophy,
    influence,
    info,
    insights_clock,
    insights_folder,
    insights_logo,
    insights_phone,
    laguna,
    laguna_live_activation,
    laguna_live_ai,
    laguna_live_ai_empty,
    laguna_live_background,
    laguna_live_call_empty,
    laguna_live_home,
    laguna_live_logo,
    laguna_live_task,
    lightning,
    lisa,
    loading_bg,
    logEmptyState,
    logo_laguna_l,
    medical,
    meterArrow,
    mobile,
    msg_icon,
    pathways_icon,
    pdf_divider,
    positiveSuggestion,
    profile_icon,
    questionnaireIcon,
    smartPad,
    sparkyLagunaIcon,
    success,
    summary,
    support,
    supportiveActions,
    tasksEmpty,
    transcript,
    empty_attributes_empty,
    empty_barrier_empty,
    empty_barriers_empty,
    empty_calls_empty,
    empty_chat,
    empty_doughnut_empty,
    empty_feedback_empty_new,
    empty_insights,
    empty_insights_table_empty,
    empty_meeting,
    empty_memberLog_empty,
    empty_members_now_empty,
    empty_noMessages,
    empty_no_consent,
    empty_noresults,
    empty_pathway_empty,
    empty_pathway_empty_state,
    empty_pie_empty,
    empty_questionnaire_empty,
    empty_sessions_empty,
    empty_summary_empty,
    empty_tasks_empty,
    empty_todo_empty,
    empty_transcript_empty,
    empty_transcript_in_progress_empty,
    barriers_loading,
    calls_loading,
    care_insights_processing,
    conversation_tips_processing,
    end_call,
    item_processing,
    sparks,
    summary_processing,
    transcript_loading
} as const;