import { initializeQueryMessage } from '@laguna/api/utils';
import { useSignOutOnIdle } from '@laguna/webAuth';
import { CircularProgress, Stack } from '@mui/material';
import { css } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Header } from './Header';
import { PATHS } from './Routing';
import { Sidebar } from './Sidebar';
import { ArticlesRoot } from './routes/Articles/ArticlesRoot';
import { EditArticle } from './routes/Articles/EditArticle/EditArticle';
import { EditAttribute } from './routes/Attributes/EditAttribute';
import { ListAttributes } from './routes/Attributes/ListAttributes';
import { EditArticlesOrder } from './routes/OrgOrder/EditArticlesOrder';
import { MyTaggingJobs } from './routes/Tagging/MyTaggingJobs';
import { TaggingJob } from './routes/Tagging/TaggingJob/TaggingJob';
import { backOfficeUserStore } from './stores/backOfficeUserStore';
import { ConfigPrompts } from './routes/Config/ConfigPrompts/ConfigPrompts';

const wrapper = (theme: any) => css`
  min-height: 100vh;
  background-color: ${theme.palette.background.neutral};
  width: 100vw;

  .app-container {
    max-width: 1280px;
    padding: ${theme.spacing(2)};
    margin: 0 auto;
  }
`;

const SuspendedComponent = ({ Comp }: { Comp: React.FC }) => (
  <Suspense fallback={<CircularProgress />}>
    <Comp />
  </Suspense>
);

export function App() {
  const { enqueueSnackbar } = useSnackbar();

  useSignOutOnIdle();
  useEffect(() => {
    initializeQueryMessage(enqueueSnackbar);
  }, []);

  return (
    <Stack css={wrapper} flex={1}>
      <Header />
      <Stack flex={1} direction='row'>
        <Sidebar />
        <Stack flex={1} className='app-container'>
          <Routes>
            <Route index element={<Navigate to={PATHS.TAGGING} />} />
            {backOfficeUserStore.isAdmin && (
              <>
                <Route path={PATHS.ARTICLES}>
                  <Route index element={<SuspendedComponent Comp={ArticlesRoot} />} />
                  <Route path=':articleId' element={<SuspendedComponent Comp={EditArticle} />} />
                </Route>
                <Route path={PATHS.ATTRIBUTES}>
                  <Route index element={<SuspendedComponent Comp={ListAttributes} />} />
                  <Route path=':attributeId' element={<SuspendedComponent Comp={EditAttribute} />} />
                  <Route path=':attributeId/:mode' element={<SuspendedComponent Comp={EditAttribute} />} />
                </Route>
                <Route path={PATHS.ORG_ORDER}>
                  <Route index element={<Navigate to={PATHS.ARTICLES} />} />
                  <Route path=':env/:orgId' element={<SuspendedComponent Comp={EditArticlesOrder} />} />
                </Route>
                <Route path={PATHS.CONFIG_PROMPTS}>
                  <Route index element={<SuspendedComponent Comp={ConfigPrompts} />} />
                </Route>
              </>
            )}
            <Route path={PATHS.TAGGING}>
              <Route index element={<SuspendedComponent Comp={MyTaggingJobs} />} />
              <Route path=':userId/:jobType/:jobId' element={<SuspendedComponent Comp={TaggingJob} />} />
            </Route>
          </Routes>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default App;
