import { Stack, Typography } from '@mui/material';
import React from 'react';

interface SectionTitleProps {
  title: string;
  button?: React.ReactNode;
  titleAddition?: React.ReactNode;
  additionalContent?: React.ReactNode;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ title, button, additionalContent, titleAddition }) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' alignItems='center' gap={2}>
        <Typography variant='h6'>{title}</Typography>
        {titleAddition}
      </Stack>
      {button}
      {additionalContent}
    </Stack>
  );
};
