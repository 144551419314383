import { FormControl, FormHelperText } from '@mui/material';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { gridArea } from '../formStyles';
import { AutocompleteField, SharedFieldProps } from '../formTypes';
import { Autocomplete } from './Autocomplete';

interface AutocompleteInputProps extends SharedFieldProps, Omit<AutocompleteField, 'name' | 'type' | 'validate'> {}

type RenderType = {
  field: ControllerRenderProps<FieldValues, string>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

const FormAutocomplete = (props: AutocompleteInputProps & RenderType) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = props;
  const { options, disabled, label, required, name, ...rest } = props;

  return (
    <FormControl
      fullWidth
      variant='outlined'
      disabled={disabled}
      required={!!required}
      css={gridArea(name)}
      className={'generate-form-item-' + name}>
      <Autocomplete
        onChange={(event, data) => onChange(data)}
        label={required ? label + ' *' : label}
        disabled={disabled}
        name={name}
        options={options}
        {...rest}
        value={value}
      />
      <FormHelperText>{error ? error.message : null}</FormHelperText>
    </FormControl>
  );
};

export const AutocompleteInput: React.FC<AutocompleteInputProps> = (props) => {
  const { name, control, defaultValue, required, options } = props;

  if (options.length === 0) {
    return <Autocomplete {...props} />;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      rules={{ required }}
      render={(render) => <FormAutocomplete {...props} {...render} />}
    />
  );
};
