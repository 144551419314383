import { AppBar, Stack, Theme, Toolbar, Typography } from '@mui/material';
import { css } from '@mui/system';
import { Link } from 'react-router-dom';

const appBar = (theme: Theme) =>
  css`
    .link {
      color: white !important;
      text-decoration: auto;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .logo {
      width: 28px;
      padding-right: 0.5em;
    }
    .flex-grow-1 {
      flex-grow: 1;
    }
    background: ${(theme as Theme).palette.primary.main};
  `;

interface AppHeaderLayoutProps {
  headerCss?: any;
  title: { name: string; logo?: string; link: string; additionalTitleItems?: React.ReactNode };
  additionalItems?: React.ReactNode;
  profile: React.ReactNode;
}

export const AppHeaderLayout: React.FC<AppHeaderLayoutProps> = ({ headerCss, title, additionalItems, profile }) => {
  return (
    <AppBar css={[appBar, headerCss]} position='relative'>
      <Toolbar>
        <Stack direction='row' alignItems='center'>
          <Typography variant='h6' noWrap fontFamily=''>
            <Link className='link' to={title.link}>
              {title.logo && <img alt='Laguna logo' src={title.logo} className='logo' />}
              <Typography variant='h5' fontWeight={400}>
                {title.name}
              </Typography>
            </Link>
          </Typography>
          {title.additionalTitleItems}
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2} marginLeft='auto'>
          {additionalItems}
          {profile}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
