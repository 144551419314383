import { logger } from '@laguna/logger';
import { signOut } from '@laguna/webAuth';

export const getUserEnvs = async () => {
  try {
    return [];
  } catch (error) {
    logger.error('Failed refreshing token');
    signOut();
    return [];
  }
};
