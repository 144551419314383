import { UseQueryOptions } from '@tanstack/react-query';

export type queryAdditionalMethods = {
  getKey: (...params: any) => any | (() => any);
  fetcher: (...params: any) => (...params: any) => Promise<any> | (() => (...params: any) => Promise<any>);
};

type extractGeneric<Type> = Type extends UseQueryOptions<infer X, infer Y, infer Z> ? X : never;

export type extractQueryType<Q extends VoidFunction> = extractGeneric<Parameters<Q>[1]>;

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type MakeFieldsRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export const cast = <T>(a: any) => a as unknown as T;

export type paginationParams = {
  limit?: number;
  sort?: { key: string; order: string }[];
  filter?: any;
  cursor?: any;
};
