import { AvatarInitials } from '@laguna/components/AvatarInitials';
import { ProfileColumn } from '@laguna/types';
import { Avatar, Stack, Typography } from '@mui/material';
import { LinkCell } from './LinkCell';

type ProfileCellProps = ReturnType<ProfileColumn['getInfo']>;

export const ProfileCell = ({ primary, secondary, avatarUrl, cellLink }: ProfileCellProps) => {
  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      {avatarUrl ? <Avatar src={avatarUrl} /> : <AvatarInitials name={primary} />}
      <Stack>
        {cellLink ? <LinkCell {...cellLink} /> : <Typography>{primary}</Typography>}
        <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
          {secondary}
        </Typography>
      </Stack>
    </Stack>
  );
};
