import { useEffect, useMemo } from 'react';
import { HookModalDataProps, modalStore } from './modalStore';

export const useModal = (modalProps: HookModalDataProps) => {
  useEffect(() => {
    modalStore.addModalData({ ...modalProps, isOpen: false });
    return () => modalStore.removeModalData(modalProps.id);
  }, []);

  useEffect(() => {
    modalStore.updateModal(modalProps);
  }, [modalProps]);

  const result = useMemo(
    () => ({ setShow: (show: boolean) => modalStore.setModalIsOpen(modalProps.id, show) }),
    [modalProps.id]
  );

  return result;
};

export const useModalWithDynamicContent = (id: string, initialData: Partial<HookModalDataProps>) => {
  useEffect(() => {
    if (!modalStore.openModalsData.find((modal) => modal.id === id)) {
      modalStore.addModalData({ ...initialData, id, isOpen: false });
    }
  }, []);

  const prompt = (modalProps: Partial<HookModalDataProps>) => {
    modalStore.updateModal({ ...initialData, ...modalProps, id });
    modalStore.setModalIsOpen(id, true);
  };
  const close = () => {
    modalStore.setModalIsOpen(id, false);
  };
  return { prompt, close };
};
