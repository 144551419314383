import { Theme } from '@mui/material/styles';
import { CloseIcon, InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <InputSelectIcon sx={{ right: 1 }} />,
        clearIcon: <CloseIcon />,
      },
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        popupIndicator: {
          width: 18,
          height: 18,
        },
        endAdornment: {
          top: 'auto',
        },
        listbox: {
          padding: theme.spacing(0, 1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: theme.shape.borderRadius,
          },
        },
      },
    },
  };
}
