import * as Types from '../types';

import { AppointmentFragment, MedicationFragment, ExternalAppointmentFragment } from '../common/fragment.generated';
import { AdmissionCoachFragment, MedicationFragmentWithBarriersFragment, ConnectedBarrierFragment, ExternalAppointmentFragmentWithBarriersFragment, AdmissionOverviewFragment, MemberAttributeFragment, PromptFragment } from './fragment.generated';
import { AppointmentFragmentDoc, MedicationFragmentDoc, ExternalAppointmentFragmentDoc } from '../common/fragment.generated';
import { AdmissionCoachFragmentDoc, MedicationFragmentWithBarriersFragmentDoc, ConnectedBarrierFragmentDoc, ExternalAppointmentFragmentWithBarriersFragmentDoc, AdmissionOverviewFragmentDoc, MemberAttributeFragmentDoc, PromptFragmentDoc } from './fragment.generated';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetchData } from './fetchDataProxy';
export type DeleteAvailabilityMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteAvailabilityMutation = Pick<Types.Mutation, 'deleteAvailability'>;

export type DeleteAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteAppointmentMutation = Pick<Types.Mutation, 'deleteAppointment'>;

export type CreateAvailabilitiesMutationVariables = Types.Exact<{
  availabilities: Array<Types.AvailabilityInput> | Types.AvailabilityInput;
}>;


export type CreateAvailabilitiesMutation = { createAvailabilities: Pick<Types.Identifiers, 'ids'> };

export type UpdateMemberMutationVariables = Types.Exact<{
  updateMemberParams: Types.UpdateMemberParams;
}>;


export type UpdateMemberMutation = { updateMember: Pick<Types.MemberWithOrg, 'id'> };

export type SetGeneralNotesMutationVariables = Types.Exact<{
  setGeneralNotesParams: Types.SetGeneralNotesParams;
}>;


export type SetGeneralNotesMutation = Pick<Types.Mutation, 'setGeneralNotes'>;

export type EndAppointmentMutationVariables = Types.Exact<{
  endAppointmentParams: Types.EndAppointmentParams;
}>;


export type EndAppointmentMutation = { endAppointment: AppointmentFragment };

export type SetAppointmentSummaryMutationVariables = Types.Exact<{
  setAppointmentSummaryParams: Types.SetAppointmentSummaryParams;
}>;


export type SetAppointmentSummaryMutation = { setAppointmentSummary: AppointmentFragment };

export type CreateOrSetActionItemMutationVariables = Types.Exact<{
  createOrSetActionItemParams: Types.CreateOrSetActionItemParams;
}>;


export type CreateOrSetActionItemMutation = { createOrSetActionItem: (
    Pick<Types.ActionItem, 'id' | 'memberId' | 'title' | 'status' | 'deadline' | 'description' | 'rejectNote' | 'category' | 'priority' | 'createdAt' | 'createdBy' | 'updatedBy'>
    & { link?: Pick<Types.ActionItemLink, 'type' | 'value'> }
  ) };

export type ScheduleAppointmentMutationVariables = Types.Exact<{
  scheduleAppointmentParams: Types.ScheduleAppointmentParams;
}>;


export type ScheduleAppointmentMutation = { scheduleAppointment: AppointmentFragment };

export type RequestAppointmentMutationVariables = Types.Exact<{
  requestAppointmentParams: Types.RequestAppointmentParams;
}>;


export type RequestAppointmentMutation = { requestAppointment: AppointmentFragment };

export type NotifyMutationVariables = Types.Exact<{
  notifyParams: Types.NotifyParams;
}>;


export type NotifyMutation = Pick<Types.Mutation, 'notify'>;

export type CancelNotifyMutationVariables = Types.Exact<{
  cancelNotifyParams: Types.CancelNotifyParams;
}>;


export type CancelNotifyMutation = Pick<Types.Mutation, 'cancelNotify'>;

export type UpdateRecordingReviewMutationVariables = Types.Exact<{
  updateRecordingReviewParams: Types.UpdateRecordingReviewParams;
}>;


export type UpdateRecordingReviewMutation = Pick<Types.Mutation, 'updateRecordingReview'>;

export type NotifyContentMutationVariables = Types.Exact<{
  notifyContentParams: Types.NotifyContentParams;
}>;


export type NotifyContentMutation = Pick<Types.Mutation, 'notifyContent'>;

export type SetLastQueryAlertMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SetLastQueryAlertMutation = Pick<Types.Mutation, 'setLastQueryAlert'>;

export type DismissAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['String'];
}>;


export type DismissAlertMutation = Pick<Types.Mutation, 'dismissAlert'>;

export type DeleteAdmissionFileMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteAdmissionFileMutation = Pick<Types.Mutation, 'deleteAdmissionFile'>;

export type DeleteMemberMutationVariables = Types.Exact<{
  deleteMemberParams: Types.DeleteMemberParams;
}>;


export type DeleteMemberMutation = Pick<Types.Mutation, 'deleteMember'>;

export type ReplaceUserForMemberMutationVariables = Types.Exact<{
  replaceUserForMemberParams: Types.ReplaceUserForMemberParams;
}>;


export type ReplaceUserForMemberMutation = Pick<Types.Mutation, 'replaceUserForMember'>;

export type ReplaceMemberOrgMutationVariables = Types.Exact<{
  replaceMemberOrgParams: Types.ReplaceMemberOrgParams;
}>;


export type ReplaceMemberOrgMutation = Pick<Types.Mutation, 'replaceMemberOrg'>;

export type UpdateBarrierMutationVariables = Types.Exact<{
  updateBarrierParams: Types.UpdateBarrierParams;
}>;


export type UpdateBarrierMutation = { updateBarrier: Pick<Types.Barrier, 'id'> };

export type CreateMemberMutationVariables = Types.Exact<{
  createMemberParams: Types.CreateMemberParams;
}>;


export type CreateMemberMutation = { createMember: Pick<Types.Identifier, 'id'> };

export type CreateUserMutationVariables = Types.Exact<{
  createUserParams: Types.CreateUserParams;
}>;


export type CreateUserMutation = { createUser: Pick<Types.User, 'id'> };

export type UpdateUserMutationVariables = Types.Exact<{
  updateUserParams: Types.UpdateUserParams;
}>;


export type UpdateUserMutation = { updateUser: Pick<Types.User, 'id'> };

export type DisableUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DisableUserMutation = Pick<Types.Mutation, 'disableUser'>;

export type EnableUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type EnableUserMutation = Pick<Types.Mutation, 'enableUser'>;

export type UpdateJourneyStatusMutationVariables = Types.Exact<{
  updateJourneyStatusParams: Types.UpdateJourneyStatusParams;
}>;


export type UpdateJourneyStatusMutation = Pick<Types.Mutation, 'updateJourneyStatus'>;

export type ChangeMemberDnaMutationVariables = Types.Exact<{
  changeMemberDnaParams: Types.ChangeMemberDnaParams;
}>;


export type ChangeMemberDnaMutation = { changeMemberDna: AdmissionCoachFragment };

export type SetTranscriptSpeakerMutationVariables = Types.Exact<{
  setTranscriptSpeakerParams: Types.SetTranscriptSpeakerParams;
}>;


export type SetTranscriptSpeakerMutation = { setTranscriptSpeaker?: (
    Pick<Types.Transcript, 'id' | 'status' | 'transcriptLink'>
    & { speakers?: Array<Pick<Types.Speaker, 'id' | 'percentage' | 'name'>> }
  ) };

export type UpdateJourneyMutationVariables = Types.Exact<{
  updateJourneyParams: Types.UpdateJourneyParams;
}>;


export type UpdateJourneyMutation = { updateJourney: Pick<Types.Journey, 'id'> };

export type CreateBarrierMutationVariables = Types.Exact<{
  createBarrierParams: Types.CreateBarrierParams;
}>;


export type CreateBarrierMutation = { createBarrier: Pick<Types.Barrier, 'id'> };

export type DeleteBarrierMutationVariables = Types.Exact<{
  deleteBarrierParams: Types.DeleteBarrierParams;
}>;


export type DeleteBarrierMutation = Pick<Types.Mutation, 'deleteBarrier'>;

export type DeleteActionItemMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteActionItemMutation = Pick<Types.Mutation, 'deleteActionItem'>;

export type DeleteMemberDnaMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteMemberDnaMutation = Pick<Types.Mutation, 'deleteMemberDna'>;

export type SetAccessModeToAdmissionFileMutationVariables = Types.Exact<{
  setAccessModeToAdmissionFileParams: Types.SetAccessModeToAdmissionFileParams;
}>;


export type SetAccessModeToAdmissionFileMutation = Pick<Types.Mutation, 'setAccessModeToAdmissionFile'>;

export type AddParticipantMutationVariables = Types.Exact<{
  addParticipantParams: Types.AddParticipantParams;
}>;


export type AddParticipantMutation = Pick<Types.Mutation, 'addParticipant'>;

export type ParticipantLeftMutationVariables = Types.Exact<{
  participantLeftParams: Types.ParticipantLeftParams;
}>;


export type ParticipantLeftMutation = Pick<Types.Mutation, 'participantLeft'>;

export type UpdateParticipantMutationVariables = Types.Exact<{
  updateParticipantParams: Types.UpdateParticipantParams;
}>;


export type UpdateParticipantMutation = Pick<Types.Mutation, 'updateParticipant'>;

export type EndRecordingMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type EndRecordingMutation = Pick<Types.Mutation, 'endRecording'>;

export type CreateRecordingMutationVariables = Types.Exact<{
  createRecordingParams: Types.CreateRecordingParams;
}>;


export type CreateRecordingMutation = { createRecording: Pick<Types.Identifier, 'id'> };

export type CreateGeneralRecordingMutationVariables = Types.Exact<{
  createGeneralRecordingParams: Types.CreateGeneralRecordingParams;
}>;


export type CreateGeneralRecordingMutation = { createGeneralRecording: Pick<Types.Identifier, 'id'> };

export type SetMedicationMutationVariables = Types.Exact<{
  setMedicationParams: Types.SetMedicationParams;
}>;


export type SetMedicationMutation = { setMedication: MedicationFragment };

export type SetExternalAppointmentMutationVariables = Types.Exact<{
  setExternalAppointmentParams: Types.SetExternalAppointmentParams;
}>;


export type SetExternalAppointmentMutation = { setExternalAppointment: ExternalAppointmentFragment };

export type UpdateCandidateMutationVariables = Types.Exact<{
  updateCandidateParams: Types.UpdateCandidateParams;
}>;


export type UpdateCandidateMutation = { updateCandidate: Pick<Types.Candidate, 'id'> };

export type ResolveCandidateMutationVariables = Types.Exact<{
  resolveCandidateParams: Types.ResolveCandidateParams;
}>;


export type ResolveCandidateMutation = { resolveCandidate: Pick<Types.Candidate, 'id'> };

export type SetAdmissionOverviewMutationVariables = Types.Exact<{
  setAdmissionOverviewParams: Types.SetAdmissionOverviewParams;
}>;


export type SetAdmissionOverviewMutation = { setAdmissionOverview?: AdmissionOverviewFragment };

export type ForceMemberEngagedMutationVariables = Types.Exact<{
  memberId: Types.Scalars['String'];
}>;


export type ForceMemberEngagedMutation = Pick<Types.Mutation, 'forceMemberEngaged'>;

export type SendAmeWebSurveyMutationVariables = Types.Exact<{
  sendAmeWebSurveyParams: Types.SendAmeWebSurveyParams;
}>;


export type SendAmeWebSurveyMutation = Pick<Types.Mutation, 'sendAmeWebSurvey'>;

export type SetMemberAttributeMutationVariables = Types.Exact<{
  setMemberAttributeParams: Types.SetMemberAttributeParams;
}>;


export type SetMemberAttributeMutation = { setMemberAttribute?: MemberAttributeFragment };

export type AddUserToChatChannelMutationVariables = Types.Exact<{
  addUserToChatChannelParams: Types.AddUserToChatChannelParams;
}>;


export type AddUserToChatChannelMutation = { addUserToChatChannel?: (
    Pick<Types.MemberCommunications, 'userToken'>
    & { chats: Array<Pick<Types.Chat, 'channelType' | 'clientId' | 'sendBirdChannelUrl'>> }
  ) };

export type DeleteMemberAttributeMutationVariables = Types.Exact<{
  deleteMemberAttributeParams: Types.DeleteMemberAttributeParams;
}>;


export type DeleteMemberAttributeMutation = Pick<Types.Mutation, 'deleteMemberAttribute'>;

export type CreateOrgMutationVariables = Types.Exact<{
  createOrgParams: Types.CreateOrgParams;
}>;


export type CreateOrgMutation = { createOrg: Pick<Types.Identifier, 'id'> };

export type CreateSubOrgMutationVariables = Types.Exact<{
  createSubOrgParams: Types.CreateSubOrgParams;
}>;


export type CreateSubOrgMutation = { createSubOrg: Pick<Types.Identifier, 'id'> };

export type ResolveMemberEngineActionMutationVariables = Types.Exact<{
  resolveMemberEngineActionParams: Types.ResolveMemberEngineActionParams;
}>;


export type ResolveMemberEngineActionMutation = { resolveMemberEngineAction: Pick<Types.EngineAction, 'id'> };

export type DeleteMemberRecentContentMutationVariables = Types.Exact<{
  deleteMemberRecentContentParams: Types.DeleteMemberRecentContentParams;
}>;


export type DeleteMemberRecentContentMutation = Pick<Types.Mutation, 'deleteMemberRecentContent'>;

export type CreateBarrierSuggestionMutationVariables = Types.Exact<{
  createBarrierParams: Types.CreateBarrierParams;
}>;


export type CreateBarrierSuggestionMutation = Pick<Types.Mutation, 'createBarrierSuggestion'>;

export type AssignUserActorMutationVariables = Types.Exact<{
  assignUserActorParams: Types.AssignUserActorParams;
}>;


export type AssignUserActorMutation = Pick<Types.Mutation, 'assignUserActor'>;

export type UnassignUserActorMutationVariables = Types.Exact<{
  unassignUserActorParams: Types.UnassignUserActorParams;
}>;


export type UnassignUserActorMutation = Pick<Types.Mutation, 'unassignUserActor'>;

export type CreateSupportChatChannelMutationVariables = Types.Exact<{
  createSupportChatChannelParams: Types.CreateSupportChatChannelParams;
}>;


export type CreateSupportChatChannelMutation = { createSupportChatChannel?: Pick<Types.Communication, 'channelType' | 'clientId' | 'sendBirdChannelUrl'> };

export type SetMemberPrimaryExternalIdMutationVariables = Types.Exact<{
  setMemberPrimaryExternalIdParams: Types.SetMemberPrimaryExternalIdParams;
}>;


export type SetMemberPrimaryExternalIdMutation = Pick<Types.Mutation, 'setMemberPrimaryExternalId'>;

export type ModerateMemberEngineActionMutationVariables = Types.Exact<{
  moderateMemberEngineActionParams: Types.ModerateMemberEngineActionParams;
}>;


export type ModerateMemberEngineActionMutation = { moderateMemberEngineAction: Pick<Types.EngineAction, 'id'> };

export type UpdateOrgConfigurationMutationVariables = Types.Exact<{
  updateOrgConfigurationParams: Types.UpdateOrgConfigurationParams;
}>;


export type UpdateOrgConfigurationMutation = { updateOrgConfiguration: Pick<Types.OrgConfiguration, 'autoAcceptLisaChatResponse'> };

export type SimulatePromptMutationVariables = Types.Exact<{
  simulatePromptParams: Types.SimulatePromptParams;
}>;


export type SimulatePromptMutation = { simulatePrompt: Pick<Types.PromptSimulationJob, 'id'> };

export type AddPromptRevisionMutationVariables = Types.Exact<{
  addPromptRevisionParams: Types.AddPromptRevisionParams;
}>;


export type AddPromptRevisionMutation = { addPromptRevision: PromptFragment };

export type SetTraitMutationVariables = Types.Exact<{
  setTraitParams: Types.SetTraitParams;
}>;


export type SetTraitMutation = { setTrait: Pick<Types.Trait, 'id'> };

export type DeleteTraitMutationVariables = Types.Exact<{
  deleteTraitParams: Types.DeleteTraitParams;
}>;


export type DeleteTraitMutation = Pick<Types.Mutation, 'deleteTrait'>;

export type AddCallPreparationMutationVariables = Types.Exact<{
  addCallPreparationParams: Types.AddCallPreparationParams;
}>;


export type AddCallPreparationMutation = { addCallPreparation: Pick<Types.CallPreparation, 'id'> };

export type UpdateCallPreparationMutationVariables = Types.Exact<{
  updateCallPreparationParams: Types.UpdateCallPreparationParams;
}>;


export type UpdateCallPreparationMutation = { updateCallPreparation: Pick<Types.CallPreparation, 'id'> };

export type DeleteCallPreparationMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteCallPreparationMutation = Pick<Types.Mutation, 'deleteCallPreparation'>;

export type CreateConversationNoteMutationVariables = Types.Exact<{
  createConversationNoteParams: Types.CreateConversationNoteParams;
}>;


export type CreateConversationNoteMutation = { createConversationNote: Pick<Types.ConversationNote, 'id'> };

export type DeleteConversationNoteMutationVariables = Types.Exact<{
  deleteConversationNoteParams: Types.DeleteConversationNoteParams;
}>;


export type DeleteConversationNoteMutation = { deleteConversationNote: Pick<Types.ConversationNote, 'id'> };

export type UpdateConversationNoteMutationVariables = Types.Exact<{
  updateConversationNoteParams: Types.UpdateConversationNoteParams;
}>;


export type UpdateConversationNoteMutation = { updateConversationNote: Pick<Types.ConversationNote, 'id'> };

export type CreateCallCodeMutationVariables = Types.Exact<{
  memberId: Types.Scalars['String'];
}>;


export type CreateCallCodeMutation = { createCallCode: Pick<Types.CallCode, 'userId' | 'memberId' | 'code'> };

export type RecordingTranscriptSummaryCopiedMutationVariables = Types.Exact<{
  recordingId: Types.Scalars['String'];
}>;


export type RecordingTranscriptSummaryCopiedMutation = Pick<Types.Mutation, 'recordingTranscriptSummaryCopied'>;

export type SignoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SignoutMutation = Pick<Types.Mutation, 'signout'>;

export type PairRecordingMutationVariables = Types.Exact<{
  pairRecordingParams: Types.PairRecordingParams;
}>;


export type PairRecordingMutation = Pick<Types.Mutation, 'pairRecording'>;

export type UpdateRecordingTranscriptSummaryMutationVariables = Types.Exact<{
  updateRecordingTranscriptSummaryParams: Types.UpdateRecordingTranscriptSummaryParams;
}>;


export type UpdateRecordingTranscriptSummaryMutation = Pick<Types.Mutation, 'updateRecordingTranscriptSummary'>;


export const DeleteAvailabilityDocument = `
    mutation deleteAvailability($id: String!) {
  deleteAvailability(id: $id)
}
    `;
export const useDeleteAvailabilityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAvailabilityMutation, TError, DeleteAvailabilityMutationVariables, TContext>) =>
    useMutation<DeleteAvailabilityMutation, TError, DeleteAvailabilityMutationVariables, TContext>(
      ['deleteAvailability'],
      (variables?: DeleteAvailabilityMutationVariables) => fetchData<DeleteAvailabilityMutation, DeleteAvailabilityMutationVariables>(DeleteAvailabilityDocument, variables, undefined, ['deleteAvailability'])(),
      options
    );
useDeleteAvailabilityMutation.getKey = () => ['deleteAvailability'];

useDeleteAvailabilityMutation.fetcher = (variables: DeleteAvailabilityMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteAvailabilityMutation, DeleteAvailabilityMutationVariables>(DeleteAvailabilityDocument, variables, options, ['deleteAvailability', variables]);
export const DeleteAppointmentDocument = `
    mutation deleteAppointment($id: String!) {
  deleteAppointment(id: $id)
}
    `;
export const useDeleteAppointmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAppointmentMutation, TError, DeleteAppointmentMutationVariables, TContext>) =>
    useMutation<DeleteAppointmentMutation, TError, DeleteAppointmentMutationVariables, TContext>(
      ['deleteAppointment'],
      (variables?: DeleteAppointmentMutationVariables) => fetchData<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(DeleteAppointmentDocument, variables, undefined, ['deleteAppointment'])(),
      options
    );
useDeleteAppointmentMutation.getKey = () => ['deleteAppointment'];

useDeleteAppointmentMutation.fetcher = (variables: DeleteAppointmentMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(DeleteAppointmentDocument, variables, options, ['deleteAppointment', variables]);
export const CreateAvailabilitiesDocument = `
    mutation createAvailabilities($availabilities: [AvailabilityInput!]!) {
  createAvailabilities(availabilities: $availabilities) {
    ids
  }
}
    `;
export const useCreateAvailabilitiesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAvailabilitiesMutation, TError, CreateAvailabilitiesMutationVariables, TContext>) =>
    useMutation<CreateAvailabilitiesMutation, TError, CreateAvailabilitiesMutationVariables, TContext>(
      ['createAvailabilities'],
      (variables?: CreateAvailabilitiesMutationVariables) => fetchData<CreateAvailabilitiesMutation, CreateAvailabilitiesMutationVariables>(CreateAvailabilitiesDocument, variables, undefined, ['createAvailabilities'])(),
      options
    );
useCreateAvailabilitiesMutation.getKey = () => ['createAvailabilities'];

useCreateAvailabilitiesMutation.fetcher = (variables: CreateAvailabilitiesMutationVariables, options?: RequestInit['headers']) => fetchData<CreateAvailabilitiesMutation, CreateAvailabilitiesMutationVariables>(CreateAvailabilitiesDocument, variables, options, ['createAvailabilities', variables]);
export const UpdateMemberDocument = `
    mutation UpdateMember($updateMemberParams: UpdateMemberParams!) {
  updateMember(updateMemberParams: $updateMemberParams) {
    id
  }
}
    `;
export const useUpdateMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMemberMutation, TError, UpdateMemberMutationVariables, TContext>) =>
    useMutation<UpdateMemberMutation, TError, UpdateMemberMutationVariables, TContext>(
      ['UpdateMember'],
      (variables?: UpdateMemberMutationVariables) => fetchData<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, variables, undefined, ['UpdateMember'])(),
      options
    );
useUpdateMemberMutation.getKey = () => ['UpdateMember'];

useUpdateMemberMutation.fetcher = (variables: UpdateMemberMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, variables, options, ['UpdateMember', variables]);
export const SetGeneralNotesDocument = `
    mutation SetGeneralNotes($setGeneralNotesParams: SetGeneralNotesParams!) {
  setGeneralNotes(setGeneralNotesParams: $setGeneralNotesParams)
}
    `;
export const useSetGeneralNotesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetGeneralNotesMutation, TError, SetGeneralNotesMutationVariables, TContext>) =>
    useMutation<SetGeneralNotesMutation, TError, SetGeneralNotesMutationVariables, TContext>(
      ['SetGeneralNotes'],
      (variables?: SetGeneralNotesMutationVariables) => fetchData<SetGeneralNotesMutation, SetGeneralNotesMutationVariables>(SetGeneralNotesDocument, variables, undefined, ['SetGeneralNotes'])(),
      options
    );
useSetGeneralNotesMutation.getKey = () => ['SetGeneralNotes'];

useSetGeneralNotesMutation.fetcher = (variables: SetGeneralNotesMutationVariables, options?: RequestInit['headers']) => fetchData<SetGeneralNotesMutation, SetGeneralNotesMutationVariables>(SetGeneralNotesDocument, variables, options, ['SetGeneralNotes', variables]);
export const EndAppointmentDocument = `
    mutation EndAppointment($endAppointmentParams: EndAppointmentParams!) {
  endAppointment(endAppointmentParams: $endAppointmentParams) {
    ...appointmentFragment
  }
}
    ${AppointmentFragmentDoc}`;
export const useEndAppointmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EndAppointmentMutation, TError, EndAppointmentMutationVariables, TContext>) =>
    useMutation<EndAppointmentMutation, TError, EndAppointmentMutationVariables, TContext>(
      ['EndAppointment'],
      (variables?: EndAppointmentMutationVariables) => fetchData<EndAppointmentMutation, EndAppointmentMutationVariables>(EndAppointmentDocument, variables, undefined, ['EndAppointment'])(),
      options
    );
useEndAppointmentMutation.getKey = () => ['EndAppointment'];

useEndAppointmentMutation.fetcher = (variables: EndAppointmentMutationVariables, options?: RequestInit['headers']) => fetchData<EndAppointmentMutation, EndAppointmentMutationVariables>(EndAppointmentDocument, variables, options, ['EndAppointment', variables]);
export const SetAppointmentSummaryDocument = `
    mutation SetAppointmentSummary($setAppointmentSummaryParams: SetAppointmentSummaryParams!) {
  setAppointmentSummary(setAppointmentSummaryParams: $setAppointmentSummaryParams) {
    ...appointmentFragment
  }
}
    ${AppointmentFragmentDoc}`;
export const useSetAppointmentSummaryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetAppointmentSummaryMutation, TError, SetAppointmentSummaryMutationVariables, TContext>) =>
    useMutation<SetAppointmentSummaryMutation, TError, SetAppointmentSummaryMutationVariables, TContext>(
      ['SetAppointmentSummary'],
      (variables?: SetAppointmentSummaryMutationVariables) => fetchData<SetAppointmentSummaryMutation, SetAppointmentSummaryMutationVariables>(SetAppointmentSummaryDocument, variables, undefined, ['SetAppointmentSummary'])(),
      options
    );
useSetAppointmentSummaryMutation.getKey = () => ['SetAppointmentSummary'];

useSetAppointmentSummaryMutation.fetcher = (variables: SetAppointmentSummaryMutationVariables, options?: RequestInit['headers']) => fetchData<SetAppointmentSummaryMutation, SetAppointmentSummaryMutationVariables>(SetAppointmentSummaryDocument, variables, options, ['SetAppointmentSummary', variables]);
export const CreateOrSetActionItemDocument = `
    mutation CreateOrSetActionItem($createOrSetActionItemParams: CreateOrSetActionItemParams!) {
  createOrSetActionItem(createOrSetActionItemParams: $createOrSetActionItemParams) {
    id
    memberId
    title
    status
    link {
      type
      value
    }
    deadline
    description
    rejectNote
    category
    priority
    createdAt
    createdBy
    updatedBy
  }
}
    `;
export const useCreateOrSetActionItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrSetActionItemMutation, TError, CreateOrSetActionItemMutationVariables, TContext>) =>
    useMutation<CreateOrSetActionItemMutation, TError, CreateOrSetActionItemMutationVariables, TContext>(
      ['CreateOrSetActionItem'],
      (variables?: CreateOrSetActionItemMutationVariables) => fetchData<CreateOrSetActionItemMutation, CreateOrSetActionItemMutationVariables>(CreateOrSetActionItemDocument, variables, undefined, ['CreateOrSetActionItem'])(),
      options
    );
useCreateOrSetActionItemMutation.getKey = () => ['CreateOrSetActionItem'];

useCreateOrSetActionItemMutation.fetcher = (variables: CreateOrSetActionItemMutationVariables, options?: RequestInit['headers']) => fetchData<CreateOrSetActionItemMutation, CreateOrSetActionItemMutationVariables>(CreateOrSetActionItemDocument, variables, options, ['CreateOrSetActionItem', variables]);
export const ScheduleAppointmentDocument = `
    mutation ScheduleAppointment($scheduleAppointmentParams: ScheduleAppointmentParams!) {
  scheduleAppointment(scheduleAppointmentParams: $scheduleAppointmentParams) {
    ...appointmentFragment
  }
}
    ${AppointmentFragmentDoc}`;
export const useScheduleAppointmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ScheduleAppointmentMutation, TError, ScheduleAppointmentMutationVariables, TContext>) =>
    useMutation<ScheduleAppointmentMutation, TError, ScheduleAppointmentMutationVariables, TContext>(
      ['ScheduleAppointment'],
      (variables?: ScheduleAppointmentMutationVariables) => fetchData<ScheduleAppointmentMutation, ScheduleAppointmentMutationVariables>(ScheduleAppointmentDocument, variables, undefined, ['ScheduleAppointment'])(),
      options
    );
useScheduleAppointmentMutation.getKey = () => ['ScheduleAppointment'];

useScheduleAppointmentMutation.fetcher = (variables: ScheduleAppointmentMutationVariables, options?: RequestInit['headers']) => fetchData<ScheduleAppointmentMutation, ScheduleAppointmentMutationVariables>(ScheduleAppointmentDocument, variables, options, ['ScheduleAppointment', variables]);
export const RequestAppointmentDocument = `
    mutation RequestAppointment($requestAppointmentParams: RequestAppointmentParams!) {
  requestAppointment(requestAppointmentParams: $requestAppointmentParams) {
    ...appointmentFragment
  }
}
    ${AppointmentFragmentDoc}`;
export const useRequestAppointmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestAppointmentMutation, TError, RequestAppointmentMutationVariables, TContext>) =>
    useMutation<RequestAppointmentMutation, TError, RequestAppointmentMutationVariables, TContext>(
      ['RequestAppointment'],
      (variables?: RequestAppointmentMutationVariables) => fetchData<RequestAppointmentMutation, RequestAppointmentMutationVariables>(RequestAppointmentDocument, variables, undefined, ['RequestAppointment'])(),
      options
    );
useRequestAppointmentMutation.getKey = () => ['RequestAppointment'];

useRequestAppointmentMutation.fetcher = (variables: RequestAppointmentMutationVariables, options?: RequestInit['headers']) => fetchData<RequestAppointmentMutation, RequestAppointmentMutationVariables>(RequestAppointmentDocument, variables, options, ['RequestAppointment', variables]);
export const NotifyDocument = `
    mutation notify($notifyParams: NotifyParams!) {
  notify(notifyParams: $notifyParams)
}
    `;
export const useNotifyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<NotifyMutation, TError, NotifyMutationVariables, TContext>) =>
    useMutation<NotifyMutation, TError, NotifyMutationVariables, TContext>(
      ['notify'],
      (variables?: NotifyMutationVariables) => fetchData<NotifyMutation, NotifyMutationVariables>(NotifyDocument, variables, undefined, ['notify'])(),
      options
    );
useNotifyMutation.getKey = () => ['notify'];

useNotifyMutation.fetcher = (variables: NotifyMutationVariables, options?: RequestInit['headers']) => fetchData<NotifyMutation, NotifyMutationVariables>(NotifyDocument, variables, options, ['notify', variables]);
export const CancelNotifyDocument = `
    mutation cancelNotify($cancelNotifyParams: CancelNotifyParams!) {
  cancelNotify(cancelNotifyParams: $cancelNotifyParams)
}
    `;
export const useCancelNotifyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CancelNotifyMutation, TError, CancelNotifyMutationVariables, TContext>) =>
    useMutation<CancelNotifyMutation, TError, CancelNotifyMutationVariables, TContext>(
      ['cancelNotify'],
      (variables?: CancelNotifyMutationVariables) => fetchData<CancelNotifyMutation, CancelNotifyMutationVariables>(CancelNotifyDocument, variables, undefined, ['cancelNotify'])(),
      options
    );
useCancelNotifyMutation.getKey = () => ['cancelNotify'];

useCancelNotifyMutation.fetcher = (variables: CancelNotifyMutationVariables, options?: RequestInit['headers']) => fetchData<CancelNotifyMutation, CancelNotifyMutationVariables>(CancelNotifyDocument, variables, options, ['cancelNotify', variables]);
export const UpdateRecordingReviewDocument = `
    mutation updateRecordingReview($updateRecordingReviewParams: UpdateRecordingReviewParams!) {
  updateRecordingReview(updateRecordingReviewParams: $updateRecordingReviewParams)
}
    `;
export const useUpdateRecordingReviewMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateRecordingReviewMutation, TError, UpdateRecordingReviewMutationVariables, TContext>) =>
    useMutation<UpdateRecordingReviewMutation, TError, UpdateRecordingReviewMutationVariables, TContext>(
      ['updateRecordingReview'],
      (variables?: UpdateRecordingReviewMutationVariables) => fetchData<UpdateRecordingReviewMutation, UpdateRecordingReviewMutationVariables>(UpdateRecordingReviewDocument, variables, undefined, ['updateRecordingReview'])(),
      options
    );
useUpdateRecordingReviewMutation.getKey = () => ['updateRecordingReview'];

useUpdateRecordingReviewMutation.fetcher = (variables: UpdateRecordingReviewMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateRecordingReviewMutation, UpdateRecordingReviewMutationVariables>(UpdateRecordingReviewDocument, variables, options, ['updateRecordingReview', variables]);
export const NotifyContentDocument = `
    mutation notifyContent($notifyContentParams: NotifyContentParams!) {
  notifyContent(notifyContentParams: $notifyContentParams)
}
    `;
export const useNotifyContentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<NotifyContentMutation, TError, NotifyContentMutationVariables, TContext>) =>
    useMutation<NotifyContentMutation, TError, NotifyContentMutationVariables, TContext>(
      ['notifyContent'],
      (variables?: NotifyContentMutationVariables) => fetchData<NotifyContentMutation, NotifyContentMutationVariables>(NotifyContentDocument, variables, undefined, ['notifyContent'])(),
      options
    );
useNotifyContentMutation.getKey = () => ['notifyContent'];

useNotifyContentMutation.fetcher = (variables: NotifyContentMutationVariables, options?: RequestInit['headers']) => fetchData<NotifyContentMutation, NotifyContentMutationVariables>(NotifyContentDocument, variables, options, ['notifyContent', variables]);
export const SetLastQueryAlertDocument = `
    mutation setLastQueryAlert {
  setLastQueryAlert
}
    `;
export const useSetLastQueryAlert = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetLastQueryAlertMutation, TError, SetLastQueryAlertMutationVariables, TContext>) =>
    useMutation<SetLastQueryAlertMutation, TError, SetLastQueryAlertMutationVariables, TContext>(
      ['setLastQueryAlert'],
      (variables?: SetLastQueryAlertMutationVariables) => fetchData<SetLastQueryAlertMutation, SetLastQueryAlertMutationVariables>(SetLastQueryAlertDocument, variables, undefined, ['setLastQueryAlert'])(),
      options
    );
useSetLastQueryAlert.getKey = () => ['setLastQueryAlert'];

useSetLastQueryAlert.fetcher = (variables?: SetLastQueryAlertMutationVariables, options?: RequestInit['headers']) => fetchData<SetLastQueryAlertMutation, SetLastQueryAlertMutationVariables>(SetLastQueryAlertDocument, variables, options, variables === undefined ? ['setLastQueryAlert'] : ['setLastQueryAlert', variables]);
export const DismissAlertDocument = `
    mutation dismissAlert($alertId: String!) {
  dismissAlert(alertId: $alertId)
}
    `;
export const useDismissAlertMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DismissAlertMutation, TError, DismissAlertMutationVariables, TContext>) =>
    useMutation<DismissAlertMutation, TError, DismissAlertMutationVariables, TContext>(
      ['dismissAlert'],
      (variables?: DismissAlertMutationVariables) => fetchData<DismissAlertMutation, DismissAlertMutationVariables>(DismissAlertDocument, variables, undefined, ['dismissAlert'])(),
      options
    );
useDismissAlertMutation.getKey = () => ['dismissAlert'];

useDismissAlertMutation.fetcher = (variables: DismissAlertMutationVariables, options?: RequestInit['headers']) => fetchData<DismissAlertMutation, DismissAlertMutationVariables>(DismissAlertDocument, variables, options, ['dismissAlert', variables]);
export const DeleteAdmissionFileDocument = `
    mutation deleteAdmissionFile($id: String!) {
  deleteAdmissionFile(id: $id)
}
    `;
export const useDeleteAdmissionFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAdmissionFileMutation, TError, DeleteAdmissionFileMutationVariables, TContext>) =>
    useMutation<DeleteAdmissionFileMutation, TError, DeleteAdmissionFileMutationVariables, TContext>(
      ['deleteAdmissionFile'],
      (variables?: DeleteAdmissionFileMutationVariables) => fetchData<DeleteAdmissionFileMutation, DeleteAdmissionFileMutationVariables>(DeleteAdmissionFileDocument, variables, undefined, ['deleteAdmissionFile'])(),
      options
    );
useDeleteAdmissionFileMutation.getKey = () => ['deleteAdmissionFile'];

useDeleteAdmissionFileMutation.fetcher = (variables: DeleteAdmissionFileMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteAdmissionFileMutation, DeleteAdmissionFileMutationVariables>(DeleteAdmissionFileDocument, variables, options, ['deleteAdmissionFile', variables]);
export const DeleteMemberDocument = `
    mutation deleteMember($deleteMemberParams: DeleteMemberParams!) {
  deleteMember(deleteMemberParams: $deleteMemberParams)
}
    `;
export const useDeleteMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMemberMutation, TError, DeleteMemberMutationVariables, TContext>) =>
    useMutation<DeleteMemberMutation, TError, DeleteMemberMutationVariables, TContext>(
      ['deleteMember'],
      (variables?: DeleteMemberMutationVariables) => fetchData<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, variables, undefined, ['deleteMember'])(),
      options
    );
useDeleteMemberMutation.getKey = () => ['deleteMember'];

useDeleteMemberMutation.fetcher = (variables: DeleteMemberMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, variables, options, ['deleteMember', variables]);
export const ReplaceUserForMemberDocument = `
    mutation replaceUserForMember($replaceUserForMemberParams: ReplaceUserForMemberParams!) {
  replaceUserForMember(replaceUserForMemberParams: $replaceUserForMemberParams)
}
    `;
export const useReplaceUserForMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ReplaceUserForMemberMutation, TError, ReplaceUserForMemberMutationVariables, TContext>) =>
    useMutation<ReplaceUserForMemberMutation, TError, ReplaceUserForMemberMutationVariables, TContext>(
      ['replaceUserForMember'],
      (variables?: ReplaceUserForMemberMutationVariables) => fetchData<ReplaceUserForMemberMutation, ReplaceUserForMemberMutationVariables>(ReplaceUserForMemberDocument, variables, undefined, ['replaceUserForMember'])(),
      options
    );
useReplaceUserForMemberMutation.getKey = () => ['replaceUserForMember'];

useReplaceUserForMemberMutation.fetcher = (variables: ReplaceUserForMemberMutationVariables, options?: RequestInit['headers']) => fetchData<ReplaceUserForMemberMutation, ReplaceUserForMemberMutationVariables>(ReplaceUserForMemberDocument, variables, options, ['replaceUserForMember', variables]);
export const ReplaceMemberOrgDocument = `
    mutation replaceMemberOrg($replaceMemberOrgParams: ReplaceMemberOrgParams!) {
  replaceMemberOrg(replaceMemberOrgParams: $replaceMemberOrgParams)
}
    `;
export const useReplaceMemberOrgMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ReplaceMemberOrgMutation, TError, ReplaceMemberOrgMutationVariables, TContext>) =>
    useMutation<ReplaceMemberOrgMutation, TError, ReplaceMemberOrgMutationVariables, TContext>(
      ['replaceMemberOrg'],
      (variables?: ReplaceMemberOrgMutationVariables) => fetchData<ReplaceMemberOrgMutation, ReplaceMemberOrgMutationVariables>(ReplaceMemberOrgDocument, variables, undefined, ['replaceMemberOrg'])(),
      options
    );
useReplaceMemberOrgMutation.getKey = () => ['replaceMemberOrg'];

useReplaceMemberOrgMutation.fetcher = (variables: ReplaceMemberOrgMutationVariables, options?: RequestInit['headers']) => fetchData<ReplaceMemberOrgMutation, ReplaceMemberOrgMutationVariables>(ReplaceMemberOrgDocument, variables, options, ['replaceMemberOrg', variables]);
export const UpdateBarrierDocument = `
    mutation updateBarrier($updateBarrierParams: UpdateBarrierParams!) {
  updateBarrier(updateBarrierParams: $updateBarrierParams) {
    id
  }
}
    `;
export const useUpdateBarrierMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBarrierMutation, TError, UpdateBarrierMutationVariables, TContext>) =>
    useMutation<UpdateBarrierMutation, TError, UpdateBarrierMutationVariables, TContext>(
      ['updateBarrier'],
      (variables?: UpdateBarrierMutationVariables) => fetchData<UpdateBarrierMutation, UpdateBarrierMutationVariables>(UpdateBarrierDocument, variables, undefined, ['updateBarrier'])(),
      options
    );
useUpdateBarrierMutation.getKey = () => ['updateBarrier'];

useUpdateBarrierMutation.fetcher = (variables: UpdateBarrierMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateBarrierMutation, UpdateBarrierMutationVariables>(UpdateBarrierDocument, variables, options, ['updateBarrier', variables]);
export const CreateMemberDocument = `
    mutation CreateMember($createMemberParams: CreateMemberParams!) {
  createMember(createMemberParams: $createMemberParams) {
    id
  }
}
    `;
export const useCreateMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateMemberMutation, TError, CreateMemberMutationVariables, TContext>) =>
    useMutation<CreateMemberMutation, TError, CreateMemberMutationVariables, TContext>(
      ['CreateMember'],
      (variables?: CreateMemberMutationVariables) => fetchData<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, variables, undefined, ['CreateMember'])(),
      options
    );
useCreateMemberMutation.getKey = () => ['CreateMember'];

useCreateMemberMutation.fetcher = (variables: CreateMemberMutationVariables, options?: RequestInit['headers']) => fetchData<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, variables, options, ['CreateMember', variables]);
export const CreateUserDocument = `
    mutation CreateUser($createUserParams: CreateUserParams!) {
  createUser(createUserParams: $createUserParams) {
    id
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['CreateUser'],
      (variables?: CreateUserMutationVariables) => fetchData<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables, undefined, ['CreateUser'])(),
      options
    );
useCreateUserMutation.getKey = () => ['CreateUser'];

useCreateUserMutation.fetcher = (variables: CreateUserMutationVariables, options?: RequestInit['headers']) => fetchData<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables, options, ['CreateUser', variables]);
export const UpdateUserDocument = `
    mutation UpdateUser($updateUserParams: UpdateUserParams!) {
  updateUser(updateUserParams: $updateUserParams) {
    id
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => fetchData<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables, undefined, ['UpdateUser'])(),
      options
    );
useUpdateUserMutation.getKey = () => ['UpdateUser'];

useUpdateUserMutation.fetcher = (variables: UpdateUserMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables, options, ['UpdateUser', variables]);
export const DisableUserDocument = `
    mutation DisableUser($id: String!) {
  disableUser(id: $id)
}
    `;
export const useDisableUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DisableUserMutation, TError, DisableUserMutationVariables, TContext>) =>
    useMutation<DisableUserMutation, TError, DisableUserMutationVariables, TContext>(
      ['DisableUser'],
      (variables?: DisableUserMutationVariables) => fetchData<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, variables, undefined, ['DisableUser'])(),
      options
    );
useDisableUserMutation.getKey = () => ['DisableUser'];

useDisableUserMutation.fetcher = (variables: DisableUserMutationVariables, options?: RequestInit['headers']) => fetchData<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, variables, options, ['DisableUser', variables]);
export const EnableUserDocument = `
    mutation EnableUser($id: String!) {
  enableUser(id: $id)
}
    `;
export const useEnableUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EnableUserMutation, TError, EnableUserMutationVariables, TContext>) =>
    useMutation<EnableUserMutation, TError, EnableUserMutationVariables, TContext>(
      ['EnableUser'],
      (variables?: EnableUserMutationVariables) => fetchData<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument, variables, undefined, ['EnableUser'])(),
      options
    );
useEnableUserMutation.getKey = () => ['EnableUser'];

useEnableUserMutation.fetcher = (variables: EnableUserMutationVariables, options?: RequestInit['headers']) => fetchData<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument, variables, options, ['EnableUser', variables]);
export const UpdateJourneyStatusDocument = `
    mutation updateJourneyStatus($updateJourneyStatusParams: UpdateJourneyStatusParams!) {
  updateJourneyStatus(updateJourneyStatusParams: $updateJourneyStatusParams)
}
    `;
export const useUpdateJourneyStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateJourneyStatusMutation, TError, UpdateJourneyStatusMutationVariables, TContext>) =>
    useMutation<UpdateJourneyStatusMutation, TError, UpdateJourneyStatusMutationVariables, TContext>(
      ['updateJourneyStatus'],
      (variables?: UpdateJourneyStatusMutationVariables) => fetchData<UpdateJourneyStatusMutation, UpdateJourneyStatusMutationVariables>(UpdateJourneyStatusDocument, variables, undefined, ['updateJourneyStatus'])(),
      options
    );
useUpdateJourneyStatusMutation.getKey = () => ['updateJourneyStatus'];

useUpdateJourneyStatusMutation.fetcher = (variables: UpdateJourneyStatusMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateJourneyStatusMutation, UpdateJourneyStatusMutationVariables>(UpdateJourneyStatusDocument, variables, options, ['updateJourneyStatus', variables]);
export const ChangeMemberDnaDocument = `
    mutation changeMemberDna($changeMemberDnaParams: ChangeMemberDnaParams!) {
  changeMemberDna(changeMemberDnaParams: $changeMemberDnaParams) {
    ...admissionCoachFragment
  }
}
    ${AdmissionCoachFragmentDoc}
${MedicationFragmentWithBarriersFragmentDoc}
${ConnectedBarrierFragmentDoc}
${ExternalAppointmentFragmentWithBarriersFragmentDoc}`;
export const useChangeMemberDnaMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeMemberDnaMutation, TError, ChangeMemberDnaMutationVariables, TContext>) =>
    useMutation<ChangeMemberDnaMutation, TError, ChangeMemberDnaMutationVariables, TContext>(
      ['changeMemberDna'],
      (variables?: ChangeMemberDnaMutationVariables) => fetchData<ChangeMemberDnaMutation, ChangeMemberDnaMutationVariables>(ChangeMemberDnaDocument, variables, undefined, ['changeMemberDna'])(),
      options
    );
useChangeMemberDnaMutation.getKey = () => ['changeMemberDna'];

useChangeMemberDnaMutation.fetcher = (variables: ChangeMemberDnaMutationVariables, options?: RequestInit['headers']) => fetchData<ChangeMemberDnaMutation, ChangeMemberDnaMutationVariables>(ChangeMemberDnaDocument, variables, options, ['changeMemberDna', variables]);
export const SetTranscriptSpeakerDocument = `
    mutation setTranscriptSpeaker($setTranscriptSpeakerParams: SetTranscriptSpeakerParams!) {
  setTranscriptSpeaker(setTranscriptSpeakerParams: $setTranscriptSpeakerParams) {
    id
    status
    speakers {
      id
      percentage
      name
    }
    transcriptLink
  }
}
    `;
export const useSetTranscriptSpeakerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetTranscriptSpeakerMutation, TError, SetTranscriptSpeakerMutationVariables, TContext>) =>
    useMutation<SetTranscriptSpeakerMutation, TError, SetTranscriptSpeakerMutationVariables, TContext>(
      ['setTranscriptSpeaker'],
      (variables?: SetTranscriptSpeakerMutationVariables) => fetchData<SetTranscriptSpeakerMutation, SetTranscriptSpeakerMutationVariables>(SetTranscriptSpeakerDocument, variables, undefined, ['setTranscriptSpeaker'])(),
      options
    );
useSetTranscriptSpeakerMutation.getKey = () => ['setTranscriptSpeaker'];

useSetTranscriptSpeakerMutation.fetcher = (variables: SetTranscriptSpeakerMutationVariables, options?: RequestInit['headers']) => fetchData<SetTranscriptSpeakerMutation, SetTranscriptSpeakerMutationVariables>(SetTranscriptSpeakerDocument, variables, options, ['setTranscriptSpeaker', variables]);
export const UpdateJourneyDocument = `
    mutation updateJourney($updateJourneyParams: UpdateJourneyParams!) {
  updateJourney(updateJourneyParams: $updateJourneyParams) {
    id
  }
}
    `;
export const useUpdateJourneyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateJourneyMutation, TError, UpdateJourneyMutationVariables, TContext>) =>
    useMutation<UpdateJourneyMutation, TError, UpdateJourneyMutationVariables, TContext>(
      ['updateJourney'],
      (variables?: UpdateJourneyMutationVariables) => fetchData<UpdateJourneyMutation, UpdateJourneyMutationVariables>(UpdateJourneyDocument, variables, undefined, ['updateJourney'])(),
      options
    );
useUpdateJourneyMutation.getKey = () => ['updateJourney'];

useUpdateJourneyMutation.fetcher = (variables: UpdateJourneyMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateJourneyMutation, UpdateJourneyMutationVariables>(UpdateJourneyDocument, variables, options, ['updateJourney', variables]);
export const CreateBarrierDocument = `
    mutation createBarrier($createBarrierParams: CreateBarrierParams!) {
  createBarrier(createBarrierParams: $createBarrierParams) {
    id
  }
}
    `;
export const useCreateBarrierMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBarrierMutation, TError, CreateBarrierMutationVariables, TContext>) =>
    useMutation<CreateBarrierMutation, TError, CreateBarrierMutationVariables, TContext>(
      ['createBarrier'],
      (variables?: CreateBarrierMutationVariables) => fetchData<CreateBarrierMutation, CreateBarrierMutationVariables>(CreateBarrierDocument, variables, undefined, ['createBarrier'])(),
      options
    );
useCreateBarrierMutation.getKey = () => ['createBarrier'];

useCreateBarrierMutation.fetcher = (variables: CreateBarrierMutationVariables, options?: RequestInit['headers']) => fetchData<CreateBarrierMutation, CreateBarrierMutationVariables>(CreateBarrierDocument, variables, options, ['createBarrier', variables]);
export const DeleteBarrierDocument = `
    mutation deleteBarrier($deleteBarrierParams: DeleteBarrierParams!) {
  deleteBarrier(deleteBarrierParams: $deleteBarrierParams)
}
    `;
export const useDeleteBarrierMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBarrierMutation, TError, DeleteBarrierMutationVariables, TContext>) =>
    useMutation<DeleteBarrierMutation, TError, DeleteBarrierMutationVariables, TContext>(
      ['deleteBarrier'],
      (variables?: DeleteBarrierMutationVariables) => fetchData<DeleteBarrierMutation, DeleteBarrierMutationVariables>(DeleteBarrierDocument, variables, undefined, ['deleteBarrier'])(),
      options
    );
useDeleteBarrierMutation.getKey = () => ['deleteBarrier'];

useDeleteBarrierMutation.fetcher = (variables: DeleteBarrierMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteBarrierMutation, DeleteBarrierMutationVariables>(DeleteBarrierDocument, variables, options, ['deleteBarrier', variables]);
export const DeleteActionItemDocument = `
    mutation deleteActionItem($id: String!) {
  deleteActionItem(id: $id)
}
    `;
export const useDeleteActionItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteActionItemMutation, TError, DeleteActionItemMutationVariables, TContext>) =>
    useMutation<DeleteActionItemMutation, TError, DeleteActionItemMutationVariables, TContext>(
      ['deleteActionItem'],
      (variables?: DeleteActionItemMutationVariables) => fetchData<DeleteActionItemMutation, DeleteActionItemMutationVariables>(DeleteActionItemDocument, variables, undefined, ['deleteActionItem'])(),
      options
    );
useDeleteActionItemMutation.getKey = () => ['deleteActionItem'];

useDeleteActionItemMutation.fetcher = (variables: DeleteActionItemMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteActionItemMutation, DeleteActionItemMutationVariables>(DeleteActionItemDocument, variables, options, ['deleteActionItem', variables]);
export const DeleteMemberDnaDocument = `
    mutation deleteMemberDna($id: String!) {
  deleteMemberDna(id: $id)
}
    `;
export const useDeleteMemberDnaMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMemberDnaMutation, TError, DeleteMemberDnaMutationVariables, TContext>) =>
    useMutation<DeleteMemberDnaMutation, TError, DeleteMemberDnaMutationVariables, TContext>(
      ['deleteMemberDna'],
      (variables?: DeleteMemberDnaMutationVariables) => fetchData<DeleteMemberDnaMutation, DeleteMemberDnaMutationVariables>(DeleteMemberDnaDocument, variables, undefined, ['deleteMemberDna'])(),
      options
    );
useDeleteMemberDnaMutation.getKey = () => ['deleteMemberDna'];

useDeleteMemberDnaMutation.fetcher = (variables: DeleteMemberDnaMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteMemberDnaMutation, DeleteMemberDnaMutationVariables>(DeleteMemberDnaDocument, variables, options, ['deleteMemberDna', variables]);
export const SetAccessModeToAdmissionFileDocument = `
    mutation setAccessModeToAdmissionFile($setAccessModeToAdmissionFileParams: SetAccessModeToAdmissionFileParams!) {
  setAccessModeToAdmissionFile(
    setAccessModeToAdmissionFileParams: $setAccessModeToAdmissionFileParams
  )
}
    `;
export const useSetAccessModeToAdmissionFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetAccessModeToAdmissionFileMutation, TError, SetAccessModeToAdmissionFileMutationVariables, TContext>) =>
    useMutation<SetAccessModeToAdmissionFileMutation, TError, SetAccessModeToAdmissionFileMutationVariables, TContext>(
      ['setAccessModeToAdmissionFile'],
      (variables?: SetAccessModeToAdmissionFileMutationVariables) => fetchData<SetAccessModeToAdmissionFileMutation, SetAccessModeToAdmissionFileMutationVariables>(SetAccessModeToAdmissionFileDocument, variables, undefined, ['setAccessModeToAdmissionFile'])(),
      options
    );
useSetAccessModeToAdmissionFileMutation.getKey = () => ['setAccessModeToAdmissionFile'];

useSetAccessModeToAdmissionFileMutation.fetcher = (variables: SetAccessModeToAdmissionFileMutationVariables, options?: RequestInit['headers']) => fetchData<SetAccessModeToAdmissionFileMutation, SetAccessModeToAdmissionFileMutationVariables>(SetAccessModeToAdmissionFileDocument, variables, options, ['setAccessModeToAdmissionFile', variables]);
export const AddParticipantDocument = `
    mutation addParticipant($addParticipantParams: AddParticipantParams!) {
  addParticipant(addParticipantParams: $addParticipantParams)
}
    `;
export const useAddParticipantMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddParticipantMutation, TError, AddParticipantMutationVariables, TContext>) =>
    useMutation<AddParticipantMutation, TError, AddParticipantMutationVariables, TContext>(
      ['addParticipant'],
      (variables?: AddParticipantMutationVariables) => fetchData<AddParticipantMutation, AddParticipantMutationVariables>(AddParticipantDocument, variables, undefined, ['addParticipant'])(),
      options
    );
useAddParticipantMutation.getKey = () => ['addParticipant'];

useAddParticipantMutation.fetcher = (variables: AddParticipantMutationVariables, options?: RequestInit['headers']) => fetchData<AddParticipantMutation, AddParticipantMutationVariables>(AddParticipantDocument, variables, options, ['addParticipant', variables]);
export const ParticipantLeftDocument = `
    mutation participantLeft($participantLeftParams: ParticipantLeftParams!) {
  participantLeft(participantLeftParams: $participantLeftParams)
}
    `;
export const useParticipantLeftMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ParticipantLeftMutation, TError, ParticipantLeftMutationVariables, TContext>) =>
    useMutation<ParticipantLeftMutation, TError, ParticipantLeftMutationVariables, TContext>(
      ['participantLeft'],
      (variables?: ParticipantLeftMutationVariables) => fetchData<ParticipantLeftMutation, ParticipantLeftMutationVariables>(ParticipantLeftDocument, variables, undefined, ['participantLeft'])(),
      options
    );
useParticipantLeftMutation.getKey = () => ['participantLeft'];

useParticipantLeftMutation.fetcher = (variables: ParticipantLeftMutationVariables, options?: RequestInit['headers']) => fetchData<ParticipantLeftMutation, ParticipantLeftMutationVariables>(ParticipantLeftDocument, variables, options, ['participantLeft', variables]);
export const UpdateParticipantDocument = `
    mutation updateParticipant($updateParticipantParams: UpdateParticipantParams!) {
  updateParticipant(updateParticipantParams: $updateParticipantParams)
}
    `;
export const useUpdateParticipantMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateParticipantMutation, TError, UpdateParticipantMutationVariables, TContext>) =>
    useMutation<UpdateParticipantMutation, TError, UpdateParticipantMutationVariables, TContext>(
      ['updateParticipant'],
      (variables?: UpdateParticipantMutationVariables) => fetchData<UpdateParticipantMutation, UpdateParticipantMutationVariables>(UpdateParticipantDocument, variables, undefined, ['updateParticipant'])(),
      options
    );
useUpdateParticipantMutation.getKey = () => ['updateParticipant'];

useUpdateParticipantMutation.fetcher = (variables: UpdateParticipantMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateParticipantMutation, UpdateParticipantMutationVariables>(UpdateParticipantDocument, variables, options, ['updateParticipant', variables]);
export const EndRecordingDocument = `
    mutation endRecording($id: String!) {
  endRecording(id: $id)
}
    `;
export const useEndRecordingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EndRecordingMutation, TError, EndRecordingMutationVariables, TContext>) =>
    useMutation<EndRecordingMutation, TError, EndRecordingMutationVariables, TContext>(
      ['endRecording'],
      (variables?: EndRecordingMutationVariables) => fetchData<EndRecordingMutation, EndRecordingMutationVariables>(EndRecordingDocument, variables, undefined, ['endRecording'])(),
      options
    );
useEndRecordingMutation.getKey = () => ['endRecording'];

useEndRecordingMutation.fetcher = (variables: EndRecordingMutationVariables, options?: RequestInit['headers']) => fetchData<EndRecordingMutation, EndRecordingMutationVariables>(EndRecordingDocument, variables, options, ['endRecording', variables]);
export const CreateRecordingDocument = `
    mutation createRecording($createRecordingParams: CreateRecordingParams!) {
  createRecording(createRecordingParams: $createRecordingParams) {
    id
  }
}
    `;
export const useCreateRecordingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateRecordingMutation, TError, CreateRecordingMutationVariables, TContext>) =>
    useMutation<CreateRecordingMutation, TError, CreateRecordingMutationVariables, TContext>(
      ['createRecording'],
      (variables?: CreateRecordingMutationVariables) => fetchData<CreateRecordingMutation, CreateRecordingMutationVariables>(CreateRecordingDocument, variables, undefined, ['createRecording'])(),
      options
    );
useCreateRecordingMutation.getKey = () => ['createRecording'];

useCreateRecordingMutation.fetcher = (variables: CreateRecordingMutationVariables, options?: RequestInit['headers']) => fetchData<CreateRecordingMutation, CreateRecordingMutationVariables>(CreateRecordingDocument, variables, options, ['createRecording', variables]);
export const CreateGeneralRecordingDocument = `
    mutation createGeneralRecording($createGeneralRecordingParams: CreateGeneralRecordingParams!) {
  createGeneralRecording(
    createGeneralRecordingParams: $createGeneralRecordingParams
  ) {
    id
  }
}
    `;
export const useCreateGeneralRecordingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateGeneralRecordingMutation, TError, CreateGeneralRecordingMutationVariables, TContext>) =>
    useMutation<CreateGeneralRecordingMutation, TError, CreateGeneralRecordingMutationVariables, TContext>(
      ['createGeneralRecording'],
      (variables?: CreateGeneralRecordingMutationVariables) => fetchData<CreateGeneralRecordingMutation, CreateGeneralRecordingMutationVariables>(CreateGeneralRecordingDocument, variables, undefined, ['createGeneralRecording'])(),
      options
    );
useCreateGeneralRecordingMutation.getKey = () => ['createGeneralRecording'];

useCreateGeneralRecordingMutation.fetcher = (variables: CreateGeneralRecordingMutationVariables, options?: RequestInit['headers']) => fetchData<CreateGeneralRecordingMutation, CreateGeneralRecordingMutationVariables>(CreateGeneralRecordingDocument, variables, options, ['createGeneralRecording', variables]);
export const SetMedicationDocument = `
    mutation setMedication($setMedicationParams: SetMedicationParams!) {
  setMedication(setMedicationParams: $setMedicationParams) {
    ...medicationFragment
  }
}
    ${MedicationFragmentDoc}`;
export const useSetMedicationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetMedicationMutation, TError, SetMedicationMutationVariables, TContext>) =>
    useMutation<SetMedicationMutation, TError, SetMedicationMutationVariables, TContext>(
      ['setMedication'],
      (variables?: SetMedicationMutationVariables) => fetchData<SetMedicationMutation, SetMedicationMutationVariables>(SetMedicationDocument, variables, undefined, ['setMedication'])(),
      options
    );
useSetMedicationMutation.getKey = () => ['setMedication'];

useSetMedicationMutation.fetcher = (variables: SetMedicationMutationVariables, options?: RequestInit['headers']) => fetchData<SetMedicationMutation, SetMedicationMutationVariables>(SetMedicationDocument, variables, options, ['setMedication', variables]);
export const SetExternalAppointmentDocument = `
    mutation setExternalAppointment($setExternalAppointmentParams: SetExternalAppointmentParams!) {
  setExternalAppointment(
    setExternalAppointmentParams: $setExternalAppointmentParams
  ) {
    ...externalAppointmentFragment
  }
}
    ${ExternalAppointmentFragmentDoc}`;
export const useSetExternalAppointmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetExternalAppointmentMutation, TError, SetExternalAppointmentMutationVariables, TContext>) =>
    useMutation<SetExternalAppointmentMutation, TError, SetExternalAppointmentMutationVariables, TContext>(
      ['setExternalAppointment'],
      (variables?: SetExternalAppointmentMutationVariables) => fetchData<SetExternalAppointmentMutation, SetExternalAppointmentMutationVariables>(SetExternalAppointmentDocument, variables, undefined, ['setExternalAppointment'])(),
      options
    );
useSetExternalAppointmentMutation.getKey = () => ['setExternalAppointment'];

useSetExternalAppointmentMutation.fetcher = (variables: SetExternalAppointmentMutationVariables, options?: RequestInit['headers']) => fetchData<SetExternalAppointmentMutation, SetExternalAppointmentMutationVariables>(SetExternalAppointmentDocument, variables, options, ['setExternalAppointment', variables]);
export const UpdateCandidateDocument = `
    mutation updateCandidate($updateCandidateParams: UpdateCandidateParams!) {
  updateCandidate(updateCandidateParams: $updateCandidateParams) {
    id
  }
}
    `;
export const useUpdateCandidateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCandidateMutation, TError, UpdateCandidateMutationVariables, TContext>) =>
    useMutation<UpdateCandidateMutation, TError, UpdateCandidateMutationVariables, TContext>(
      ['updateCandidate'],
      (variables?: UpdateCandidateMutationVariables) => fetchData<UpdateCandidateMutation, UpdateCandidateMutationVariables>(UpdateCandidateDocument, variables, undefined, ['updateCandidate'])(),
      options
    );
useUpdateCandidateMutation.getKey = () => ['updateCandidate'];

useUpdateCandidateMutation.fetcher = (variables: UpdateCandidateMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateCandidateMutation, UpdateCandidateMutationVariables>(UpdateCandidateDocument, variables, options, ['updateCandidate', variables]);
export const ResolveCandidateDocument = `
    mutation resolveCandidate($resolveCandidateParams: ResolveCandidateParams!) {
  resolveCandidate(resolveCandidateParams: $resolveCandidateParams) {
    id
  }
}
    `;
export const useResolveCandidateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResolveCandidateMutation, TError, ResolveCandidateMutationVariables, TContext>) =>
    useMutation<ResolveCandidateMutation, TError, ResolveCandidateMutationVariables, TContext>(
      ['resolveCandidate'],
      (variables?: ResolveCandidateMutationVariables) => fetchData<ResolveCandidateMutation, ResolveCandidateMutationVariables>(ResolveCandidateDocument, variables, undefined, ['resolveCandidate'])(),
      options
    );
useResolveCandidateMutation.getKey = () => ['resolveCandidate'];

useResolveCandidateMutation.fetcher = (variables: ResolveCandidateMutationVariables, options?: RequestInit['headers']) => fetchData<ResolveCandidateMutation, ResolveCandidateMutationVariables>(ResolveCandidateDocument, variables, options, ['resolveCandidate', variables]);
export const SetAdmissionOverviewDocument = `
    mutation setAdmissionOverview($setAdmissionOverviewParams: SetAdmissionOverviewParams!) {
  setAdmissionOverview(setAdmissionOverviewParams: $setAdmissionOverviewParams) {
    ...admissionOverviewFragment
  }
}
    ${AdmissionOverviewFragmentDoc}`;
export const useSetAdmissionOverviewMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetAdmissionOverviewMutation, TError, SetAdmissionOverviewMutationVariables, TContext>) =>
    useMutation<SetAdmissionOverviewMutation, TError, SetAdmissionOverviewMutationVariables, TContext>(
      ['setAdmissionOverview'],
      (variables?: SetAdmissionOverviewMutationVariables) => fetchData<SetAdmissionOverviewMutation, SetAdmissionOverviewMutationVariables>(SetAdmissionOverviewDocument, variables, undefined, ['setAdmissionOverview'])(),
      options
    );
useSetAdmissionOverviewMutation.getKey = () => ['setAdmissionOverview'];

useSetAdmissionOverviewMutation.fetcher = (variables: SetAdmissionOverviewMutationVariables, options?: RequestInit['headers']) => fetchData<SetAdmissionOverviewMutation, SetAdmissionOverviewMutationVariables>(SetAdmissionOverviewDocument, variables, options, ['setAdmissionOverview', variables]);
export const ForceMemberEngagedDocument = `
    mutation forceMemberEngaged($memberId: String!) {
  forceMemberEngaged(memberId: $memberId)
}
    `;
export const useForceMemberEngagedMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ForceMemberEngagedMutation, TError, ForceMemberEngagedMutationVariables, TContext>) =>
    useMutation<ForceMemberEngagedMutation, TError, ForceMemberEngagedMutationVariables, TContext>(
      ['forceMemberEngaged'],
      (variables?: ForceMemberEngagedMutationVariables) => fetchData<ForceMemberEngagedMutation, ForceMemberEngagedMutationVariables>(ForceMemberEngagedDocument, variables, undefined, ['forceMemberEngaged'])(),
      options
    );
useForceMemberEngagedMutation.getKey = () => ['forceMemberEngaged'];

useForceMemberEngagedMutation.fetcher = (variables: ForceMemberEngagedMutationVariables, options?: RequestInit['headers']) => fetchData<ForceMemberEngagedMutation, ForceMemberEngagedMutationVariables>(ForceMemberEngagedDocument, variables, options, ['forceMemberEngaged', variables]);
export const SendAmeWebSurveyDocument = `
    mutation sendAmeWebSurvey($sendAmeWebSurveyParams: SendAmeWebSurveyParams!) {
  sendAmeWebSurvey(sendAmeWebSurveyParams: $sendAmeWebSurveyParams)
}
    `;
export const useSendAmeWebSurveyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SendAmeWebSurveyMutation, TError, SendAmeWebSurveyMutationVariables, TContext>) =>
    useMutation<SendAmeWebSurveyMutation, TError, SendAmeWebSurveyMutationVariables, TContext>(
      ['sendAmeWebSurvey'],
      (variables?: SendAmeWebSurveyMutationVariables) => fetchData<SendAmeWebSurveyMutation, SendAmeWebSurveyMutationVariables>(SendAmeWebSurveyDocument, variables, undefined, ['sendAmeWebSurvey'])(),
      options
    );
useSendAmeWebSurveyMutation.getKey = () => ['sendAmeWebSurvey'];

useSendAmeWebSurveyMutation.fetcher = (variables: SendAmeWebSurveyMutationVariables, options?: RequestInit['headers']) => fetchData<SendAmeWebSurveyMutation, SendAmeWebSurveyMutationVariables>(SendAmeWebSurveyDocument, variables, options, ['sendAmeWebSurvey', variables]);
export const SetMemberAttributeDocument = `
    mutation setMemberAttribute($setMemberAttributeParams: SetMemberAttributeParams!) {
  setMemberAttribute(setMemberAttributeParams: $setMemberAttributeParams) {
    ...memberAttributeFragment
  }
}
    ${MemberAttributeFragmentDoc}
${ConnectedBarrierFragmentDoc}`;
export const useSetMemberAttributeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetMemberAttributeMutation, TError, SetMemberAttributeMutationVariables, TContext>) =>
    useMutation<SetMemberAttributeMutation, TError, SetMemberAttributeMutationVariables, TContext>(
      ['setMemberAttribute'],
      (variables?: SetMemberAttributeMutationVariables) => fetchData<SetMemberAttributeMutation, SetMemberAttributeMutationVariables>(SetMemberAttributeDocument, variables, undefined, ['setMemberAttribute'])(),
      options
    );
useSetMemberAttributeMutation.getKey = () => ['setMemberAttribute'];

useSetMemberAttributeMutation.fetcher = (variables: SetMemberAttributeMutationVariables, options?: RequestInit['headers']) => fetchData<SetMemberAttributeMutation, SetMemberAttributeMutationVariables>(SetMemberAttributeDocument, variables, options, ['setMemberAttribute', variables]);
export const AddUserToChatChannelDocument = `
    mutation addUserToChatChannel($addUserToChatChannelParams: AddUserToChatChannelParams!) {
  addUserToChatChannel(addUserToChatChannelParams: $addUserToChatChannelParams) {
    userToken
    chats {
      channelType
      clientId
      sendBirdChannelUrl
    }
  }
}
    `;
export const useAddUserToChatChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddUserToChatChannelMutation, TError, AddUserToChatChannelMutationVariables, TContext>) =>
    useMutation<AddUserToChatChannelMutation, TError, AddUserToChatChannelMutationVariables, TContext>(
      ['addUserToChatChannel'],
      (variables?: AddUserToChatChannelMutationVariables) => fetchData<AddUserToChatChannelMutation, AddUserToChatChannelMutationVariables>(AddUserToChatChannelDocument, variables, undefined, ['addUserToChatChannel'])(),
      options
    );
useAddUserToChatChannelMutation.getKey = () => ['addUserToChatChannel'];

useAddUserToChatChannelMutation.fetcher = (variables: AddUserToChatChannelMutationVariables, options?: RequestInit['headers']) => fetchData<AddUserToChatChannelMutation, AddUserToChatChannelMutationVariables>(AddUserToChatChannelDocument, variables, options, ['addUserToChatChannel', variables]);
export const DeleteMemberAttributeDocument = `
    mutation deleteMemberAttribute($deleteMemberAttributeParams: DeleteMemberAttributeParams!) {
  deleteMemberAttribute(deleteMemberAttributeParams: $deleteMemberAttributeParams)
}
    `;
export const useDeleteMemberAttributeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMemberAttributeMutation, TError, DeleteMemberAttributeMutationVariables, TContext>) =>
    useMutation<DeleteMemberAttributeMutation, TError, DeleteMemberAttributeMutationVariables, TContext>(
      ['deleteMemberAttribute'],
      (variables?: DeleteMemberAttributeMutationVariables) => fetchData<DeleteMemberAttributeMutation, DeleteMemberAttributeMutationVariables>(DeleteMemberAttributeDocument, variables, undefined, ['deleteMemberAttribute'])(),
      options
    );
useDeleteMemberAttributeMutation.getKey = () => ['deleteMemberAttribute'];

useDeleteMemberAttributeMutation.fetcher = (variables: DeleteMemberAttributeMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteMemberAttributeMutation, DeleteMemberAttributeMutationVariables>(DeleteMemberAttributeDocument, variables, options, ['deleteMemberAttribute', variables]);
export const CreateOrgDocument = `
    mutation createOrg($createOrgParams: CreateOrgParams!) {
  createOrg(createOrgParams: $createOrgParams) {
    id
  }
}
    `;
export const useCreateOrgMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrgMutation, TError, CreateOrgMutationVariables, TContext>) =>
    useMutation<CreateOrgMutation, TError, CreateOrgMutationVariables, TContext>(
      ['createOrg'],
      (variables?: CreateOrgMutationVariables) => fetchData<CreateOrgMutation, CreateOrgMutationVariables>(CreateOrgDocument, variables, undefined, ['createOrg'])(),
      options
    );
useCreateOrgMutation.getKey = () => ['createOrg'];

useCreateOrgMutation.fetcher = (variables: CreateOrgMutationVariables, options?: RequestInit['headers']) => fetchData<CreateOrgMutation, CreateOrgMutationVariables>(CreateOrgDocument, variables, options, ['createOrg', variables]);
export const CreateSubOrgDocument = `
    mutation createSubOrg($createSubOrgParams: CreateSubOrgParams!) {
  createSubOrg(createSubOrgParams: $createSubOrgParams) {
    id
  }
}
    `;
export const useCreateSubOrgMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSubOrgMutation, TError, CreateSubOrgMutationVariables, TContext>) =>
    useMutation<CreateSubOrgMutation, TError, CreateSubOrgMutationVariables, TContext>(
      ['createSubOrg'],
      (variables?: CreateSubOrgMutationVariables) => fetchData<CreateSubOrgMutation, CreateSubOrgMutationVariables>(CreateSubOrgDocument, variables, undefined, ['createSubOrg'])(),
      options
    );
useCreateSubOrgMutation.getKey = () => ['createSubOrg'];

useCreateSubOrgMutation.fetcher = (variables: CreateSubOrgMutationVariables, options?: RequestInit['headers']) => fetchData<CreateSubOrgMutation, CreateSubOrgMutationVariables>(CreateSubOrgDocument, variables, options, ['createSubOrg', variables]);
export const ResolveMemberEngineActionDocument = `
    mutation resolveMemberEngineAction($resolveMemberEngineActionParams: ResolveMemberEngineActionParams!) {
  resolveMemberEngineAction(
    resolveMemberEngineActionParams: $resolveMemberEngineActionParams
  ) {
    id
  }
}
    `;
export const useResolveMemberEngineActionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResolveMemberEngineActionMutation, TError, ResolveMemberEngineActionMutationVariables, TContext>) =>
    useMutation<ResolveMemberEngineActionMutation, TError, ResolveMemberEngineActionMutationVariables, TContext>(
      ['resolveMemberEngineAction'],
      (variables?: ResolveMemberEngineActionMutationVariables) => fetchData<ResolveMemberEngineActionMutation, ResolveMemberEngineActionMutationVariables>(ResolveMemberEngineActionDocument, variables, undefined, ['resolveMemberEngineAction'])(),
      options
    );
useResolveMemberEngineActionMutation.getKey = () => ['resolveMemberEngineAction'];

useResolveMemberEngineActionMutation.fetcher = (variables: ResolveMemberEngineActionMutationVariables, options?: RequestInit['headers']) => fetchData<ResolveMemberEngineActionMutation, ResolveMemberEngineActionMutationVariables>(ResolveMemberEngineActionDocument, variables, options, ['resolveMemberEngineAction', variables]);
export const DeleteMemberRecentContentDocument = `
    mutation deleteMemberRecentContent($deleteMemberRecentContentParams: DeleteMemberRecentContentParams!) {
  deleteMemberRecentContent(
    deleteMemberRecentContentParams: $deleteMemberRecentContentParams
  )
}
    `;
export const useDeleteMemberRecentContentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMemberRecentContentMutation, TError, DeleteMemberRecentContentMutationVariables, TContext>) =>
    useMutation<DeleteMemberRecentContentMutation, TError, DeleteMemberRecentContentMutationVariables, TContext>(
      ['deleteMemberRecentContent'],
      (variables?: DeleteMemberRecentContentMutationVariables) => fetchData<DeleteMemberRecentContentMutation, DeleteMemberRecentContentMutationVariables>(DeleteMemberRecentContentDocument, variables, undefined, ['deleteMemberRecentContent'])(),
      options
    );
useDeleteMemberRecentContentMutation.getKey = () => ['deleteMemberRecentContent'];

useDeleteMemberRecentContentMutation.fetcher = (variables: DeleteMemberRecentContentMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteMemberRecentContentMutation, DeleteMemberRecentContentMutationVariables>(DeleteMemberRecentContentDocument, variables, options, ['deleteMemberRecentContent', variables]);
export const CreateBarrierSuggestionDocument = `
    mutation createBarrierSuggestion($createBarrierParams: CreateBarrierParams!) {
  createBarrierSuggestion(createBarrierParams: $createBarrierParams)
}
    `;
export const useCreateBarrierSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBarrierSuggestionMutation, TError, CreateBarrierSuggestionMutationVariables, TContext>) =>
    useMutation<CreateBarrierSuggestionMutation, TError, CreateBarrierSuggestionMutationVariables, TContext>(
      ['createBarrierSuggestion'],
      (variables?: CreateBarrierSuggestionMutationVariables) => fetchData<CreateBarrierSuggestionMutation, CreateBarrierSuggestionMutationVariables>(CreateBarrierSuggestionDocument, variables, undefined, ['createBarrierSuggestion'])(),
      options
    );
useCreateBarrierSuggestionMutation.getKey = () => ['createBarrierSuggestion'];

useCreateBarrierSuggestionMutation.fetcher = (variables: CreateBarrierSuggestionMutationVariables, options?: RequestInit['headers']) => fetchData<CreateBarrierSuggestionMutation, CreateBarrierSuggestionMutationVariables>(CreateBarrierSuggestionDocument, variables, options, ['createBarrierSuggestion', variables]);
export const AssignUserActorDocument = `
    mutation assignUserActor($assignUserActorParams: AssignUserActorParams!) {
  assignUserActor(assignUserActorParams: $assignUserActorParams)
}
    `;
export const useAssignUserActorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AssignUserActorMutation, TError, AssignUserActorMutationVariables, TContext>) =>
    useMutation<AssignUserActorMutation, TError, AssignUserActorMutationVariables, TContext>(
      ['assignUserActor'],
      (variables?: AssignUserActorMutationVariables) => fetchData<AssignUserActorMutation, AssignUserActorMutationVariables>(AssignUserActorDocument, variables, undefined, ['assignUserActor'])(),
      options
    );
useAssignUserActorMutation.getKey = () => ['assignUserActor'];

useAssignUserActorMutation.fetcher = (variables: AssignUserActorMutationVariables, options?: RequestInit['headers']) => fetchData<AssignUserActorMutation, AssignUserActorMutationVariables>(AssignUserActorDocument, variables, options, ['assignUserActor', variables]);
export const UnassignUserActorDocument = `
    mutation unassignUserActor($unassignUserActorParams: UnassignUserActorParams!) {
  unassignUserActor(unassignUserActorParams: $unassignUserActorParams)
}
    `;
export const useUnassignUserActorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UnassignUserActorMutation, TError, UnassignUserActorMutationVariables, TContext>) =>
    useMutation<UnassignUserActorMutation, TError, UnassignUserActorMutationVariables, TContext>(
      ['unassignUserActor'],
      (variables?: UnassignUserActorMutationVariables) => fetchData<UnassignUserActorMutation, UnassignUserActorMutationVariables>(UnassignUserActorDocument, variables, undefined, ['unassignUserActor'])(),
      options
    );
useUnassignUserActorMutation.getKey = () => ['unassignUserActor'];

useUnassignUserActorMutation.fetcher = (variables: UnassignUserActorMutationVariables, options?: RequestInit['headers']) => fetchData<UnassignUserActorMutation, UnassignUserActorMutationVariables>(UnassignUserActorDocument, variables, options, ['unassignUserActor', variables]);
export const CreateSupportChatChannelDocument = `
    mutation createSupportChatChannel($createSupportChatChannelParams: CreateSupportChatChannelParams!) {
  createSupportChatChannel(
    createSupportChatChannelParams: $createSupportChatChannelParams
  ) {
    channelType
    clientId
    sendBirdChannelUrl
  }
}
    `;
export const useCreateSupportChatChannelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSupportChatChannelMutation, TError, CreateSupportChatChannelMutationVariables, TContext>) =>
    useMutation<CreateSupportChatChannelMutation, TError, CreateSupportChatChannelMutationVariables, TContext>(
      ['createSupportChatChannel'],
      (variables?: CreateSupportChatChannelMutationVariables) => fetchData<CreateSupportChatChannelMutation, CreateSupportChatChannelMutationVariables>(CreateSupportChatChannelDocument, variables, undefined, ['createSupportChatChannel'])(),
      options
    );
useCreateSupportChatChannelMutation.getKey = () => ['createSupportChatChannel'];

useCreateSupportChatChannelMutation.fetcher = (variables: CreateSupportChatChannelMutationVariables, options?: RequestInit['headers']) => fetchData<CreateSupportChatChannelMutation, CreateSupportChatChannelMutationVariables>(CreateSupportChatChannelDocument, variables, options, ['createSupportChatChannel', variables]);
export const SetMemberPrimaryExternalIdDocument = `
    mutation setMemberPrimaryExternalId($setMemberPrimaryExternalIdParams: SetMemberPrimaryExternalIdParams!) {
  setMemberPrimaryExternalId(
    setMemberPrimaryExternalIdParams: $setMemberPrimaryExternalIdParams
  )
}
    `;
export const useSetMemberPrimaryExternalIdMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetMemberPrimaryExternalIdMutation, TError, SetMemberPrimaryExternalIdMutationVariables, TContext>) =>
    useMutation<SetMemberPrimaryExternalIdMutation, TError, SetMemberPrimaryExternalIdMutationVariables, TContext>(
      ['setMemberPrimaryExternalId'],
      (variables?: SetMemberPrimaryExternalIdMutationVariables) => fetchData<SetMemberPrimaryExternalIdMutation, SetMemberPrimaryExternalIdMutationVariables>(SetMemberPrimaryExternalIdDocument, variables, undefined, ['setMemberPrimaryExternalId'])(),
      options
    );
useSetMemberPrimaryExternalIdMutation.getKey = () => ['setMemberPrimaryExternalId'];

useSetMemberPrimaryExternalIdMutation.fetcher = (variables: SetMemberPrimaryExternalIdMutationVariables, options?: RequestInit['headers']) => fetchData<SetMemberPrimaryExternalIdMutation, SetMemberPrimaryExternalIdMutationVariables>(SetMemberPrimaryExternalIdDocument, variables, options, ['setMemberPrimaryExternalId', variables]);
export const ModerateMemberEngineActionDocument = `
    mutation moderateMemberEngineAction($moderateMemberEngineActionParams: ModerateMemberEngineActionParams!) {
  moderateMemberEngineAction(
    moderateMemberEngineActionParams: $moderateMemberEngineActionParams
  ) {
    id
  }
}
    `;
export const useModerateMemberEngineActionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ModerateMemberEngineActionMutation, TError, ModerateMemberEngineActionMutationVariables, TContext>) =>
    useMutation<ModerateMemberEngineActionMutation, TError, ModerateMemberEngineActionMutationVariables, TContext>(
      ['moderateMemberEngineAction'],
      (variables?: ModerateMemberEngineActionMutationVariables) => fetchData<ModerateMemberEngineActionMutation, ModerateMemberEngineActionMutationVariables>(ModerateMemberEngineActionDocument, variables, undefined, ['moderateMemberEngineAction'])(),
      options
    );
useModerateMemberEngineActionMutation.getKey = () => ['moderateMemberEngineAction'];

useModerateMemberEngineActionMutation.fetcher = (variables: ModerateMemberEngineActionMutationVariables, options?: RequestInit['headers']) => fetchData<ModerateMemberEngineActionMutation, ModerateMemberEngineActionMutationVariables>(ModerateMemberEngineActionDocument, variables, options, ['moderateMemberEngineAction', variables]);
export const UpdateOrgConfigurationDocument = `
    mutation updateOrgConfiguration($updateOrgConfigurationParams: UpdateOrgConfigurationParams!) {
  updateOrgConfiguration(
    updateOrgConfigurationParams: $updateOrgConfigurationParams
  ) {
    autoAcceptLisaChatResponse
  }
}
    `;
export const useUpdateOrgConfigurationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrgConfigurationMutation, TError, UpdateOrgConfigurationMutationVariables, TContext>) =>
    useMutation<UpdateOrgConfigurationMutation, TError, UpdateOrgConfigurationMutationVariables, TContext>(
      ['updateOrgConfiguration'],
      (variables?: UpdateOrgConfigurationMutationVariables) => fetchData<UpdateOrgConfigurationMutation, UpdateOrgConfigurationMutationVariables>(UpdateOrgConfigurationDocument, variables, undefined, ['updateOrgConfiguration'])(),
      options
    );
useUpdateOrgConfigurationMutation.getKey = () => ['updateOrgConfiguration'];

useUpdateOrgConfigurationMutation.fetcher = (variables: UpdateOrgConfigurationMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateOrgConfigurationMutation, UpdateOrgConfigurationMutationVariables>(UpdateOrgConfigurationDocument, variables, options, ['updateOrgConfiguration', variables]);
export const SimulatePromptDocument = `
    mutation simulatePrompt($simulatePromptParams: SimulatePromptParams!) {
  simulatePrompt(simulatePromptParams: $simulatePromptParams) {
    id
  }
}
    `;
export const useSimulatePromptMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SimulatePromptMutation, TError, SimulatePromptMutationVariables, TContext>) =>
    useMutation<SimulatePromptMutation, TError, SimulatePromptMutationVariables, TContext>(
      ['simulatePrompt'],
      (variables?: SimulatePromptMutationVariables) => fetchData<SimulatePromptMutation, SimulatePromptMutationVariables>(SimulatePromptDocument, variables, undefined, ['simulatePrompt'])(),
      options
    );
useSimulatePromptMutation.getKey = () => ['simulatePrompt'];

useSimulatePromptMutation.fetcher = (variables: SimulatePromptMutationVariables, options?: RequestInit['headers']) => fetchData<SimulatePromptMutation, SimulatePromptMutationVariables>(SimulatePromptDocument, variables, options, ['simulatePrompt', variables]);
export const AddPromptRevisionDocument = `
    mutation addPromptRevision($addPromptRevisionParams: AddPromptRevisionParams!) {
  addPromptRevision(addPromptRevisionParams: $addPromptRevisionParams) {
    ...promptFragment
  }
}
    ${PromptFragmentDoc}`;
export const useAddPromptRevisionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddPromptRevisionMutation, TError, AddPromptRevisionMutationVariables, TContext>) =>
    useMutation<AddPromptRevisionMutation, TError, AddPromptRevisionMutationVariables, TContext>(
      ['addPromptRevision'],
      (variables?: AddPromptRevisionMutationVariables) => fetchData<AddPromptRevisionMutation, AddPromptRevisionMutationVariables>(AddPromptRevisionDocument, variables, undefined, ['addPromptRevision'])(),
      options
    );
useAddPromptRevisionMutation.getKey = () => ['addPromptRevision'];

useAddPromptRevisionMutation.fetcher = (variables: AddPromptRevisionMutationVariables, options?: RequestInit['headers']) => fetchData<AddPromptRevisionMutation, AddPromptRevisionMutationVariables>(AddPromptRevisionDocument, variables, options, ['addPromptRevision', variables]);
export const SetTraitDocument = `
    mutation setTrait($setTraitParams: SetTraitParams!) {
  setTrait(setTraitParams: $setTraitParams) {
    id
  }
}
    `;
export const useSetTraitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetTraitMutation, TError, SetTraitMutationVariables, TContext>) =>
    useMutation<SetTraitMutation, TError, SetTraitMutationVariables, TContext>(
      ['setTrait'],
      (variables?: SetTraitMutationVariables) => fetchData<SetTraitMutation, SetTraitMutationVariables>(SetTraitDocument, variables, undefined, ['setTrait'])(),
      options
    );
useSetTraitMutation.getKey = () => ['setTrait'];

useSetTraitMutation.fetcher = (variables: SetTraitMutationVariables, options?: RequestInit['headers']) => fetchData<SetTraitMutation, SetTraitMutationVariables>(SetTraitDocument, variables, options, ['setTrait', variables]);
export const DeleteTraitDocument = `
    mutation deleteTrait($deleteTraitParams: DeleteTraitParams!) {
  deleteTrait(deleteTraitParams: $deleteTraitParams)
}
    `;
export const useDeleteTraitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTraitMutation, TError, DeleteTraitMutationVariables, TContext>) =>
    useMutation<DeleteTraitMutation, TError, DeleteTraitMutationVariables, TContext>(
      ['deleteTrait'],
      (variables?: DeleteTraitMutationVariables) => fetchData<DeleteTraitMutation, DeleteTraitMutationVariables>(DeleteTraitDocument, variables, undefined, ['deleteTrait'])(),
      options
    );
useDeleteTraitMutation.getKey = () => ['deleteTrait'];

useDeleteTraitMutation.fetcher = (variables: DeleteTraitMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteTraitMutation, DeleteTraitMutationVariables>(DeleteTraitDocument, variables, options, ['deleteTrait', variables]);
export const AddCallPreparationDocument = `
    mutation addCallPreparation($addCallPreparationParams: AddCallPreparationParams!) {
  addCallPreparation(addCallPreparationParams: $addCallPreparationParams) {
    id
  }
}
    `;
export const useAddCallPreparationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddCallPreparationMutation, TError, AddCallPreparationMutationVariables, TContext>) =>
    useMutation<AddCallPreparationMutation, TError, AddCallPreparationMutationVariables, TContext>(
      ['addCallPreparation'],
      (variables?: AddCallPreparationMutationVariables) => fetchData<AddCallPreparationMutation, AddCallPreparationMutationVariables>(AddCallPreparationDocument, variables, undefined, ['addCallPreparation'])(),
      options
    );
useAddCallPreparationMutation.getKey = () => ['addCallPreparation'];

useAddCallPreparationMutation.fetcher = (variables: AddCallPreparationMutationVariables, options?: RequestInit['headers']) => fetchData<AddCallPreparationMutation, AddCallPreparationMutationVariables>(AddCallPreparationDocument, variables, options, ['addCallPreparation', variables]);
export const UpdateCallPreparationDocument = `
    mutation updateCallPreparation($updateCallPreparationParams: UpdateCallPreparationParams!) {
  updateCallPreparation(updateCallPreparationParams: $updateCallPreparationParams) {
    id
  }
}
    `;
export const useUpdateCallPreparationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCallPreparationMutation, TError, UpdateCallPreparationMutationVariables, TContext>) =>
    useMutation<UpdateCallPreparationMutation, TError, UpdateCallPreparationMutationVariables, TContext>(
      ['updateCallPreparation'],
      (variables?: UpdateCallPreparationMutationVariables) => fetchData<UpdateCallPreparationMutation, UpdateCallPreparationMutationVariables>(UpdateCallPreparationDocument, variables, undefined, ['updateCallPreparation'])(),
      options
    );
useUpdateCallPreparationMutation.getKey = () => ['updateCallPreparation'];

useUpdateCallPreparationMutation.fetcher = (variables: UpdateCallPreparationMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateCallPreparationMutation, UpdateCallPreparationMutationVariables>(UpdateCallPreparationDocument, variables, options, ['updateCallPreparation', variables]);
export const DeleteCallPreparationDocument = `
    mutation deleteCallPreparation($id: String!) {
  deleteCallPreparation(id: $id)
}
    `;
export const useDeleteCallPreparationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCallPreparationMutation, TError, DeleteCallPreparationMutationVariables, TContext>) =>
    useMutation<DeleteCallPreparationMutation, TError, DeleteCallPreparationMutationVariables, TContext>(
      ['deleteCallPreparation'],
      (variables?: DeleteCallPreparationMutationVariables) => fetchData<DeleteCallPreparationMutation, DeleteCallPreparationMutationVariables>(DeleteCallPreparationDocument, variables, undefined, ['deleteCallPreparation'])(),
      options
    );
useDeleteCallPreparationMutation.getKey = () => ['deleteCallPreparation'];

useDeleteCallPreparationMutation.fetcher = (variables: DeleteCallPreparationMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteCallPreparationMutation, DeleteCallPreparationMutationVariables>(DeleteCallPreparationDocument, variables, options, ['deleteCallPreparation', variables]);
export const CreateConversationNoteDocument = `
    mutation createConversationNote($createConversationNoteParams: CreateConversationNoteParams!) {
  createConversationNote(
    createConversationNoteParams: $createConversationNoteParams
  ) {
    id
  }
}
    `;
export const useCreateConversationNoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateConversationNoteMutation, TError, CreateConversationNoteMutationVariables, TContext>) =>
    useMutation<CreateConversationNoteMutation, TError, CreateConversationNoteMutationVariables, TContext>(
      ['createConversationNote'],
      (variables?: CreateConversationNoteMutationVariables) => fetchData<CreateConversationNoteMutation, CreateConversationNoteMutationVariables>(CreateConversationNoteDocument, variables, undefined, ['createConversationNote'])(),
      options
    );
useCreateConversationNoteMutation.getKey = () => ['createConversationNote'];

useCreateConversationNoteMutation.fetcher = (variables: CreateConversationNoteMutationVariables, options?: RequestInit['headers']) => fetchData<CreateConversationNoteMutation, CreateConversationNoteMutationVariables>(CreateConversationNoteDocument, variables, options, ['createConversationNote', variables]);
export const DeleteConversationNoteDocument = `
    mutation deleteConversationNote($deleteConversationNoteParams: DeleteConversationNoteParams!) {
  deleteConversationNote(
    deleteConversationNoteParams: $deleteConversationNoteParams
  ) {
    id
  }
}
    `;
export const useDeleteConversationNoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteConversationNoteMutation, TError, DeleteConversationNoteMutationVariables, TContext>) =>
    useMutation<DeleteConversationNoteMutation, TError, DeleteConversationNoteMutationVariables, TContext>(
      ['deleteConversationNote'],
      (variables?: DeleteConversationNoteMutationVariables) => fetchData<DeleteConversationNoteMutation, DeleteConversationNoteMutationVariables>(DeleteConversationNoteDocument, variables, undefined, ['deleteConversationNote'])(),
      options
    );
useDeleteConversationNoteMutation.getKey = () => ['deleteConversationNote'];

useDeleteConversationNoteMutation.fetcher = (variables: DeleteConversationNoteMutationVariables, options?: RequestInit['headers']) => fetchData<DeleteConversationNoteMutation, DeleteConversationNoteMutationVariables>(DeleteConversationNoteDocument, variables, options, ['deleteConversationNote', variables]);
export const UpdateConversationNoteDocument = `
    mutation updateConversationNote($updateConversationNoteParams: UpdateConversationNoteParams!) {
  updateConversationNote(
    updateConversationNoteParams: $updateConversationNoteParams
  ) {
    id
  }
}
    `;
export const useUpdateConversationNoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateConversationNoteMutation, TError, UpdateConversationNoteMutationVariables, TContext>) =>
    useMutation<UpdateConversationNoteMutation, TError, UpdateConversationNoteMutationVariables, TContext>(
      ['updateConversationNote'],
      (variables?: UpdateConversationNoteMutationVariables) => fetchData<UpdateConversationNoteMutation, UpdateConversationNoteMutationVariables>(UpdateConversationNoteDocument, variables, undefined, ['updateConversationNote'])(),
      options
    );
useUpdateConversationNoteMutation.getKey = () => ['updateConversationNote'];

useUpdateConversationNoteMutation.fetcher = (variables: UpdateConversationNoteMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateConversationNoteMutation, UpdateConversationNoteMutationVariables>(UpdateConversationNoteDocument, variables, options, ['updateConversationNote', variables]);
export const CreateCallCodeDocument = `
    mutation createCallCode($memberId: String!) {
  createCallCode(memberId: $memberId) {
    userId
    memberId
    code
  }
}
    `;
export const useCreateCallCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCallCodeMutation, TError, CreateCallCodeMutationVariables, TContext>) =>
    useMutation<CreateCallCodeMutation, TError, CreateCallCodeMutationVariables, TContext>(
      ['createCallCode'],
      (variables?: CreateCallCodeMutationVariables) => fetchData<CreateCallCodeMutation, CreateCallCodeMutationVariables>(CreateCallCodeDocument, variables, undefined, ['createCallCode'])(),
      options
    );
useCreateCallCodeMutation.getKey = () => ['createCallCode'];

useCreateCallCodeMutation.fetcher = (variables: CreateCallCodeMutationVariables, options?: RequestInit['headers']) => fetchData<CreateCallCodeMutation, CreateCallCodeMutationVariables>(CreateCallCodeDocument, variables, options, ['createCallCode', variables]);
export const RecordingTranscriptSummaryCopiedDocument = `
    mutation recordingTranscriptSummaryCopied($recordingId: String!) {
  recordingTranscriptSummaryCopied(recordingId: $recordingId)
}
    `;
export const useRecordingTranscriptSummaryCopiedMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RecordingTranscriptSummaryCopiedMutation, TError, RecordingTranscriptSummaryCopiedMutationVariables, TContext>) =>
    useMutation<RecordingTranscriptSummaryCopiedMutation, TError, RecordingTranscriptSummaryCopiedMutationVariables, TContext>(
      ['recordingTranscriptSummaryCopied'],
      (variables?: RecordingTranscriptSummaryCopiedMutationVariables) => fetchData<RecordingTranscriptSummaryCopiedMutation, RecordingTranscriptSummaryCopiedMutationVariables>(RecordingTranscriptSummaryCopiedDocument, variables, undefined, ['recordingTranscriptSummaryCopied'])(),
      options
    );
useRecordingTranscriptSummaryCopiedMutation.getKey = () => ['recordingTranscriptSummaryCopied'];

useRecordingTranscriptSummaryCopiedMutation.fetcher = (variables: RecordingTranscriptSummaryCopiedMutationVariables, options?: RequestInit['headers']) => fetchData<RecordingTranscriptSummaryCopiedMutation, RecordingTranscriptSummaryCopiedMutationVariables>(RecordingTranscriptSummaryCopiedDocument, variables, options, ['recordingTranscriptSummaryCopied', variables]);
export const SignoutDocument = `
    mutation signout {
  signout
}
    `;
export const useSignoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SignoutMutation, TError, SignoutMutationVariables, TContext>) =>
    useMutation<SignoutMutation, TError, SignoutMutationVariables, TContext>(
      ['signout'],
      (variables?: SignoutMutationVariables) => fetchData<SignoutMutation, SignoutMutationVariables>(SignoutDocument, variables, undefined, ['signout'])(),
      options
    );
useSignoutMutation.getKey = () => ['signout'];

useSignoutMutation.fetcher = (variables?: SignoutMutationVariables, options?: RequestInit['headers']) => fetchData<SignoutMutation, SignoutMutationVariables>(SignoutDocument, variables, options, variables === undefined ? ['signout'] : ['signout', variables]);
export const PairRecordingDocument = `
    mutation pairRecording($pairRecordingParams: PairRecordingParams!) {
  pairRecording(pairRecordingParams: $pairRecordingParams)
}
    `;
export const usePairRecordingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PairRecordingMutation, TError, PairRecordingMutationVariables, TContext>) =>
    useMutation<PairRecordingMutation, TError, PairRecordingMutationVariables, TContext>(
      ['pairRecording'],
      (variables?: PairRecordingMutationVariables) => fetchData<PairRecordingMutation, PairRecordingMutationVariables>(PairRecordingDocument, variables, undefined, ['pairRecording'])(),
      options
    );
usePairRecordingMutation.getKey = () => ['pairRecording'];

usePairRecordingMutation.fetcher = (variables: PairRecordingMutationVariables, options?: RequestInit['headers']) => fetchData<PairRecordingMutation, PairRecordingMutationVariables>(PairRecordingDocument, variables, options, ['pairRecording', variables]);
export const UpdateRecordingTranscriptSummaryDocument = `
    mutation updateRecordingTranscriptSummary($updateRecordingTranscriptSummaryParams: UpdateRecordingTranscriptSummaryParams!) {
  updateRecordingTranscriptSummary(
    updateRecordingTranscriptSummaryParams: $updateRecordingTranscriptSummaryParams
  )
}
    `;
export const useUpdateRecordingTranscriptSummaryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateRecordingTranscriptSummaryMutation, TError, UpdateRecordingTranscriptSummaryMutationVariables, TContext>) =>
    useMutation<UpdateRecordingTranscriptSummaryMutation, TError, UpdateRecordingTranscriptSummaryMutationVariables, TContext>(
      ['updateRecordingTranscriptSummary'],
      (variables?: UpdateRecordingTranscriptSummaryMutationVariables) => fetchData<UpdateRecordingTranscriptSummaryMutation, UpdateRecordingTranscriptSummaryMutationVariables>(UpdateRecordingTranscriptSummaryDocument, variables, undefined, ['updateRecordingTranscriptSummary'])(),
      options
    );
useUpdateRecordingTranscriptSummaryMutation.getKey = () => ['updateRecordingTranscriptSummary'];

useUpdateRecordingTranscriptSummaryMutation.fetcher = (variables: UpdateRecordingTranscriptSummaryMutationVariables, options?: RequestInit['headers']) => fetchData<UpdateRecordingTranscriptSummaryMutation, UpdateRecordingTranscriptSummaryMutationVariables>(UpdateRecordingTranscriptSummaryDocument, variables, options, ['updateRecordingTranscriptSummary', variables]);