import { ENV, getGraphqlHeaders } from '@laguna/aws';
import { logger } from '@laguna/logger';
import { makeAutoObservable } from 'mobx';
import Pusher, { Channel } from 'pusher-js/with-encryption';
import { fetchData } from './fetchData';
import { queryClient } from './getQueryResult';
import { getHash } from './queryUtils';
import {
  PusherMessages,
  SocketType,
  getDataDecryptWrapper,
  handleAlertSocketMessage,
  handleEventSocketMessages,
  handleRelaySocketMessages,
  handleSubscriptionSocketMessage,
  handleTranscriptionSocketMessage,
} from './socketUtils';

export const connectToPusher = async (userId: string): Promise<{ pusher: Pusher; userChannel: Channel } | null> => {
  try {
    const headers = await getGraphqlHeaders();
    const endpoint = ENV.NX_GQL_ENDPOINT + '/secured/webhooks/pusher/';
    const appKey = ENV.NX_PUSHER_API_KEY;
    const pusher = new Pusher(appKey, {
      cluster: process.env.NX_REACT_APP_ENV === 'highmark' ? 'ap2' : 'mt1',
      channelAuthorization: {
        transport: 'ajax',
        endpoint: endpoint + 'auth',
        headers,
      },
      userAuthentication: {
        endpoint: endpoint + 'user-auth',
        transport: 'ajax',
        headers,
      },
    });

    pusher.signin();

    await new Promise<void>((resolve, reject) => {
      //if the connected event is not firing within 20 sec setError true;
      setTimeout(reject, 10000);
      pusher.connection.bind('connected', () => resolve());
    });
    const userChannel = pusher.subscribe(`private-encrypted-${userId}`);

    return { pusher, userChannel };
  } catch (error) {
    logger.error('Failed to connect to pusher', { error });
    return null;
  }
};

const UnsubscribeDocument = `
    mutation unsubscribe($unsubscribeParams: UnsubscribeParams!) {
  unsubscribe(unsubscribeParams: $unsubscribeParams)
}
    `;

const unsubscribeMutation = (variables: any) =>
  fetchData(UnsubscribeDocument, variables, undefined, ['unsubscribe', variables]);

class SocketStore {
  private _pusher?: any = undefined;
  socketsError = false;
  constructor() {
    makeAutoObservable(this);
  }

  get socketId() {
    return this._pusher?.connection.socket_id;
  }

  setError = (hasError: boolean) => {
    this.socketsError = hasError;
  };

  initPusher = async (
    userId: string,
    onTranscriptionEvent: (event: PusherMessages) => void,
    getEncryptedPusherMessage?: () => boolean
  ) => {
    const pusherConnection = await connectToPusher(userId);
    if (!pusherConnection) {
      this.setError(true);
      return;
    }
    const { pusher, userChannel } = pusherConnection;

    const subscriptionChannel = pusher.subscribe(`private-encrypted-${userId}-${pusher.connection.socket_id}`);
    this.handleUnsubscribeRegistration();
    const wrapper = getDataDecryptWrapper(userId, onTranscriptionEvent, getEncryptedPusherMessage);

    userChannel.bind(SocketType.alert, wrapper(handleAlertSocketMessage));
    userChannel.bind(SocketType.transcription, wrapper(handleTranscriptionSocketMessage));
    userChannel.bind(SocketType.relay, wrapper(handleRelaySocketMessages));
    userChannel.bind(SocketType.event, wrapper(handleEventSocketMessages));
    subscriptionChannel.bind(SocketType.subscription, handleSubscriptionSocketMessage);

    this._pusher = pusher;
  };

  handleUnsubscribeRegistration = () => {
    const queryCache = queryClient.getQueryCache();
    if (queryCache.hasListeners()) {
      return;
    }
    queryCache.subscribe(async (event) => {
      if (event.type === 'removed') {
        const subscriptionKey = getHash(event.query.queryKey);
        const socketId = this.socketId;
        if (subscriptionKey && socketId) {
          logger.debug('unsubscribe', { subscriptionKey, socketId });
          const fetchPromise = await unsubscribeMutation({
            unsubscribeParams: { subscriptionKey, socketId },
          });
          try {
            await fetchPromise();
          } catch (error) {
            logger.debug('fail to unsubscribe from channel', { subscriptionKey, socketId, error });
          }
        }
      }
    });
  };

  cleanup = () => {
    logger.debug('socket cleanup was called');
    this.setError(false);
    if (this._pusher) {
      logger.debug('socket calling disconnect');
      this._pusher.disconnect();
      logger.debug('socket disconnect called');
      this._pusher = undefined;
    }
  };
}

export const socketStore = new SocketStore();
