import { logger } from '@laguna/logger';
import { useState } from 'react';
import { JobOutput } from './types';
import { writeFile } from './utils';
import { signOutAndReload } from '@laguna/webAuth';

export const useUpdateJobOutput = () => {
  const [isLoading, setIsLoading] = useState(false);
  const update = async (job: JobOutput) => {
    setIsLoading(true);
    try {
      const { userId, id } = job;
      await writeFile({
        path: `${userId}/outputs/${id}.json`,
        content: JSON.stringify(job),
      });
    } catch (error) {
      logger.error('error uploading tag', { error, job });
      signOutAndReload();
    }
    setIsLoading(false);
  };
  return { update, isLoading };
};
