import { FormControl, Typography, InputLabel, TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import { SimpleSelect } from '@laguna/common/SimpleSelect';
import { SelectField, SharedFieldProps } from '../formTypes';
import { gridArea } from '../formStyles';
import { useEffect } from 'react';
import { css } from '@mui/material';

interface SelectInputProps extends SharedFieldProps, Omit<SelectField, 'type' | 'validate'> {
  inputProps?: TextFieldProps['inputProps'];
}

const SelectInput: React.FC<SelectInputProps> = ({ name, control, defaultValue, formId, required, ...selectProps }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { onChange: valueChanged, value }, fieldState: { error } }) => {
        return (
          <RenderSelect
            valueChanged={valueChanged}
            value={value}
            name={name}
            required={!!required}
            defaultValue={defaultValue}
            error={error?.message}
            {...selectProps}
          />
        );
      }}
    />
  );
};

const RenderSelect = ({
  valueChanged,
  value,
  onChange,
  defaultValue,
  label,
  required,
  name,
  disabled,
  error,
  autoFocus = false,
  ...rest
}: any) => {
  const _onChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
    valueChanged(value);
  };

  useEffect(() => {
    if (onChange) {
      onChange(defaultValue);
    }
  }, [onChange]);

  useEffect(() => {
    if (value && !rest.multiple && !rest.values[value]) {
      _onChange('');
    }
  }, [rest.values]);

  return (
    <FormControl
      fullWidth
      variant='outlined'
      disabled={disabled}
      required={required}
      css={gridArea(name)}
      className={'generate-form-item-' + name}>
      <InputLabel id={`${label}-id`}>{label}</InputLabel>
      <SimpleSelect
        labelId={`${label}-id`}
        id={`${label}-id`}
        value={value}
        onChange={(event) => _onChange(event.target.value as string)}
        autoFocus={autoFocus}
        label={label}
        required={required}
        {...rest}
      />
      {!!error && (
        <Typography variant='subtitle2' color='error'>
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default SelectInput;
