import { PaletteMode } from '@mui/material';

export const silenceColor: Record<PaletteMode, string> = {
  light: '#bdbdbd',
  dark: '#888',
};

export const speakersColors: Record<PaletteMode, string[]> = {
  light: ['#212226', '#5b74ff', '#4caf50', silenceColor['light']],
  dark: ['#dddddd', '#5b74ff', '#4caf50', silenceColor['dark']],
};
