import React from 'react';
import { Controller } from 'react-hook-form';
import { FrequencySelectField, SharedFieldProps } from '../formTypes';
import { FrequencySelector } from './FrequencySelector';

interface FrequencySelectorInputProps extends SharedFieldProps, Omit<FrequencySelectField, 'type' | 'validate'> {}

export const FrequencySelectorInput: React.FC<FrequencySelectorInputProps> = ({
  name,
  control,
  defaultValue,
  allowPast,
  required,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FrequencySelector
          showDate={!!value?.start}
          showEndDate={!!value?.end}
          cronExpressions={value?.cronExpressions}
          start={value?.start}
          end={value?.end}
          allowPast={allowPast}
          onChange={onChange}
        />
      )}
    />
  );
};
