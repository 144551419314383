import { Article } from '@laguna/api';
import { ArticleFragment, useGetArticlesQuery, useSetArticleMutation } from '@laguna/api/backoffice';
import { useAsyncMutation } from '@laguna/api/utils';
import { Button, CircularProgress, Stack, Typography, Paper, css } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemsList } from '../../../components/ItemsList';
import { articleDraft } from '../EditArticle/consts';

const listCss = (theme: any) => css`
  margin-top: ${theme.spacing(3)};
`;

const getSecondaryText = (article: ArticleFragment) => {
  if (!article.content) {
    return i18next.t(article.articleMarkdown ? 'articles:status.legacy' : 'articles:status.empty');
  }
  return i18next.t(article.isPublished ? 'articles:status.published' : 'articles:status.draft');
};

export const ListArticles: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetArticlesQuery();

  const { mutateAsync, isLoading: isCreatingDraft } = useAsyncMutation(useSetArticleMutation, {
    messages: {
      successMsg: i18next.t('common:createSuccess', { type: i18next.t('articles:article') }),
      errorMsg: i18next.t('common:createError'),
    },
    invalidationQueryKey: useGetArticlesQuery.getKey(),
  });
  const createArticle = async () => {
    const draft = await mutateAsync({ setArticleParams: articleDraft });
    if (draft.data) {
      navigate(draft.data?.setArticle.id || 'ERROR');
    }
  };

  return (
    <Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h4'>{i18next.t('articles:tabs.list')}</Typography>
        <Button onClick={createArticle} variant='contained' disabled={isCreatingDraft}>
          {isCreatingDraft ? <CircularProgress size='small' /> : i18next.t('articles:createArticle')}
        </Button>
      </Stack>
      <Stack component={Paper} css={listCss} sx={{ boxShadow: 3 }}>
        {isLoading && (
          <Stack height={400} alignItems='center' justifyContent='center'>
            <CircularProgress />
          </Stack>
        )}
        <ItemsList
          items={data?.getArticles || []}
          primaryText='title'
          avatarProperty='smallImageUrl'
          secondaryText={getSecondaryText}
          onClick={(article) => navigate(article.id)}
        />
      </Stack>
    </Stack>
  );
};
