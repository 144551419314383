import { FCC } from '@laguna/types';
import { Settings } from '@mui/icons-material';
import { ReactElement } from 'react';
import { PopupMenuBase, PopupMenuBaseProps } from './PopupMenuBase';

interface IconPopupMenuProps extends Omit<PopupMenuBaseProps, 'buttonType' | 'buttonContent'> {
  icon?: ReactElement;
}

const DEFAULT_BUTTON_COLOR = 'primary';

export const IconPopupMenu: FCC<IconPopupMenuProps> = ({ children, icon, ...rest }) => {
  return (
    <PopupMenuBase
      {...rest}
      buttonContent={icon ? icon : <Settings color={DEFAULT_BUTTON_COLOR} />}
      buttonType='icon'
      buttonProps={{ color: icon?.props.color ?? DEFAULT_BUTTON_COLOR, ...(rest.buttonProps || {}) }}>
      {children}
    </PopupMenuBase>
  );
};
