import { MultiSelect } from '@laguna/common/MultiSelect';
import { IconPopupMenu } from '@laguna/components/PopupMenu';
import { dataTableTransformValue } from '@laguna/types';
import { FilterList } from '@mui/icons-material';
import { Button, css, Grid, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import i18next from 'i18next';
import { groupBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Column, TableInstance } from 'react-table';
import { Scrollbar } from '../../Scrollbar';

interface FilterTableProps extends Pick<TableInstance, 'preFilteredRows'> {
  columns: Column[];
  resetFilters: VoidFunction;
  setColumnFilter: (column: string, value: string[]) => void;
}

type OverflowOption = 'auto' | 'hidden';

const containerCss = css`
  max-height: 50vh;
`;
const resetFiltersCss = (theme: any) => css`
  padding: 0;
  margin-left: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(0.5)};
`;
const filtersCss = (theme: any) => css`
  margin-top: ${theme.spacing(1)};
`;

const filterEmptyValues = (value?: any, transformValue?: dataTableTransformValue) => {
  if (transformValue ? !transformValue(value) : !value) {
    return false;
  }
  if (value === 'null' || value === 'undefined') {
    return false;
  }
  return true;
};

export const FilterTable: React.FC<FilterTableProps> = ({
  columns,
  preFilteredRows,
  resetFilters,
  setColumnFilter,
}) => {
  const filterableColumns = useMemo(() => columns.filter((item) => !item.preventFiltering), [columns]);
  const [overflow, setOverflow] = useState<OverflowOption>('auto');
  return (
    <IconPopupMenu
      icon={
        <Tooltip title='Filter Table'>
          <FilterList color='action' />
        </Tooltip>
      }>
      <Stack padding={2} css={containerCss}>
        <Scrollbar
          autoHeightMin='50vh'
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflow, minHeight: 'calc(50vh - 24px)' }} />
          )}>
          <Stack paddingBottom={2} paddingLeft={2} direction='row' alignItems='flex-end' justifyContent='space-between'>
            <Typography variant='h6'> {i18next.t('dataTable:filters')}</Typography>
            <Button css={resetFiltersCss} onClick={resetFilters}>
              {i18next.t('common:reset')}
            </Button>
          </Stack>
          <Grid container maxWidth={600} alignItems='flex-end'>
            {filterableColumns?.map(({ id, label, transformValue, filterValue }) => {
              if (!id) return null;
              const filterGroups = (item: any) => filterEmptyValues(item, transformValue);
              const groups = Object.keys(groupBy(preFilteredRows, (item) => item.values[id])).filter(filterGroups);
              if (!groups?.length) {
                return null;
              }

              const onChange = (value: string[]) => {
                setColumnFilter(id, value);
              };

              return (
                <Grid item key={id} xs={12}>
                  <MenuItem css={filtersCss}>
                    <MultiSelect
                      fullWidth
                      onChange={onChange as any}
                      options={groups as any}
                      inputParams={{ label, size: 'small' }}
                      customRenderLabel={transformValue as any}
                      customChipLabel={transformValue as any}
                      size='small'
                      onOpen={() => setOverflow('hidden')}
                      onClose={() => setOverflow('auto')}
                      disableCloseOnSelect
                      value={filterValue ?? []}
                    />
                  </MenuItem>
                </Grid>
              );
            })}
          </Grid>
        </Scrollbar>
      </Stack>
    </IconPopupMenu>
  );
};
