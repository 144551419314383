export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type ChatCompletionsOptions = {
  maxTokens?: Scalars['Float'];
  temperature?: Scalars['Float'];
  topP?: Scalars['Float'];
};

export type Identifier = {
  id: Scalars['String'];
};

export type TraitValue = {
  value: Scalars['String'];
  potentialIssue: Scalars['Boolean'];
  associatedBarriers?: Array<Scalars['String']>;
};

export type TraitContext = {
  recordingId?: Scalars['String'];
  transcriptParagraphId?: Scalars['String'];
};

export type TraitSchema = {
  id: Scalars['String'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  domain: TraitDomain;
  definition: Scalars['String'];
  definitionForPrompt: Scalars['String'];
  values: Array<TraitValue>;
  displayType: DisplayType;
  vectorEmbedding: Array<Scalars['Float']>;
  threshold: Scalars['Float'];
};

export enum TraitDomain {
  accessibility = 'accessibility',
  care_engagement = 'care_engagement',
  health_behavior = 'health_behavior',
  sdoh = 'sdoh',
  social_support = 'social_support',
  health_status = 'health_status'
}

export enum DisplayType {
  singleSelect = 'singleSelect',
  multiSelect = 'multiSelect',
  boolean = 'boolean',
  freeText = 'freeText'
}

export type Trait = {
  id: Scalars['String'];
  memberId?: Scalars['String'];
  schema: TraitSchema;
  values: Array<Scalars['String']>;
  identifiedBy: IdentifiedBy;
  reasoning?: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  context?: TraitContext;
};

export enum IdentifiedBy {
  coach = 'coach',
  ai = 'ai',
  audit = 'audit'
}

export type TraitResponse = {
  id: Scalars['String'];
  memberId?: Scalars['String'];
  schema: Scalars['String'];
  values: Array<Scalars['String']>;
  identifiedBy: IdentifiedBy;
  reasoning?: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  context?: TraitContext;
};

export type TraitSchemaForMemberResponse = {
  id: Scalars['String'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  domain: TraitDomain;
  definition: Scalars['String'];
  values: Array<TraitValue>;
  displayType: DisplayType;
  threshold: Scalars['Float'];
  memberTrait?: TraitResponse;
};

export type Caregiver = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  roles: Array<CaregiverRole>;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  dateOfBirth?: Scalars['String'];
  phone: Scalars['String'];
  phoneType: PhoneType;
  email?: Scalars['String'];
  relationship: Relationship;
  appAccess: Scalars['Boolean'];
  authId?: Scalars['String'];
  deviceId?: Scalars['String'];
};

export enum CaregiverRole {
  caregiver = 'caregiver'
}

export enum PhoneType {
  landline = 'landline',
  mobile = 'mobile',
  voip = 'voip',
  unknown = 'unknown'
}

export enum Relationship {
  spouse = 'spouse',
  partner = 'partner',
  parent = 'parent',
  child = 'child',
  sibling = 'sibling',
  friend = 'friend',
  neighbour = 'neighbour',
  professionalCaregiver = 'professionalCaregiver',
  other = 'other'
}

export type BarrierType = {
  id: Scalars['String'];
  description: Scalars['String'];
  promptDescription: Scalars['String'];
  domain: BarrierDomain;
  type: BarrierTypeEnum;
  engage: Scalars['String'];
  support: Scalars['String'];
  influence: Scalars['String'];
  active: Scalars['Boolean'];
  sortingIndex: Scalars['Float'];
  probingQuestions?: Array<Scalars['String']>;
};

export enum BarrierDomain {
  mobility = 'mobility',
  environment = 'environment',
  medical = 'medical',
  medication = 'medication',
  logisticalContextual = 'logisticalContextual',
  emotional = 'emotional',
  unhealthyBehaviors = 'unhealthyBehaviors'
}

export enum BarrierTypeEnum {
  fatigue = 'fatigue',
  recoveryPlan = 'recoveryPlan',
  healthLiteracy = 'healthLiteracy',
  competingResponsibilities = 'competingResponsibilities',
  pain = 'pain',
  financial = 'financial',
  unbalancedEmotion = 'unbalancedEmotion',
  physicalLimitations = 'physicalLimitations',
  unhealthyBehaviors = 'unhealthyBehaviors',
  interpersonalStress = 'interpersonalStress',
  attitudesCultural = 'attitudesCultural',
  logisticalAccessibility = 'logisticalAccessibility',
  physicalEnvironment = 'physicalEnvironment',
  nonCompliance = 'nonCompliance',
  mentalHealth = 'mentalHealth',
  chronicMedical = 'chronicMedical',
  disability = 'disability',
  intubation = 'intubation',
  homePreparation = 'homePreparation',
  unsafeHome = 'unsafeHome',
  lackAssistance = 'lackAssistance',
  lackDme = 'lackDme',
  properNutrition = 'properNutrition',
  properEquipment = 'properEquipment',
  domesticViolence = 'domesticViolence',
  appointmentUnclear = 'appointmentUnclear',
  recoveryProviders = 'recoveryProviders',
  woundKnowledge = 'woundKnowledge',
  woundPsy = 'woundPsy',
  woundPhysical = 'woundPhysical',
  woundSupplies = 'woundSupplies',
  painChronic = 'painChronic',
  painProcedural = 'painProcedural',
  fallPrevention = 'fallPrevention',
  lackInstruction = 'lackInstruction',
  medName = 'medName',
  medAccess = 'medAccess',
  medEffects = 'medEffects',
  medConfusion = 'medConfusion',
  medForgets = 'medForgets',
  medBelief = 'medBelief',
  financialCopays = 'financialCopays',
  financialIncome = 'financialIncome',
  financialCommunity = 'financialCommunity',
  financialHousehold = 'financialHousehold',
  familialBias = 'familialBias',
  transportation = 'transportation',
  language = 'language',
  skills = 'skills',
  griefFunctioning = 'griefFunctioning',
  griefLovedone = 'griefLovedone',
  psychiatricConditions = 'psychiatricConditions',
  naturalWorry = 'naturalWorry',
  naturalEmotion = 'naturalEmotion',
  denial = 'denial',
  readinessChange = 'readinessChange',
  caregiverConflicts = 'caregiverConflicts',
  lackCaregiver = 'lackCaregiver',
  lackMotivation = 'lackMotivation',
  lackTrustProvider = 'lackTrustProvider',
  lackTrustHealthcare = 'lackTrustHealthcare',
  smoking = 'smoking',
  excessiveAlcohol = 'excessiveAlcohol',
  excessiveDrug = 'excessiveDrug',
  sedentaryLifestyle = 'sedentaryLifestyle',
  excessiveExercise = 'excessiveExercise',
  overeating = 'overeating',
  undereating = 'undereating',
  prescribedDiet = 'prescribedDiet',
  riskyBehaviors = 'riskyBehaviors'
}

export type BarrierContext = {
  resourceId: Scalars['String'];
  type: ContextType;
  parentResourceId?: Scalars['String'];
  metadata?: Scalars['JSON'];
};

export enum ContextType {
  medication = 'medication',
  dietary = 'dietary',
  treatment = 'treatment',
  externalAppointment = 'externalAppointment',
  appointment = 'appointment',
  transcript = 'transcript',
  transcriptParagraph = 'transcriptParagraph',
  dailyReport = 'dailyReport',
  questionnaireResponse = 'questionnaireResponse',
  memberAttribute = 'memberAttribute',
  chatMessage = 'chatMessage',
  recording = 'recording'
}

export type Barrier = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  createdBy?: Scalars['String'];
  identifiedBy: IdentifiedBy;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  notes?: Scalars['String'];
  completedAt?: Scalars['DateTime'];
  type: BarrierType;
  status: BarrierStatus;
  deletionNote?: Scalars['String'];
  context?: BarrierContext;
  engineActionId?: Scalars['String'];
};

export enum BarrierStatus {
  active = 'active',
  completed = 'completed'
}

export type QuestionnaireResponseResult = {
  score?: Scalars['Float'];
  severity?: Scalars['String'];
  scoreOverThreshold?: Scalars['Boolean'];
  alerts?: Array<Scalars['String']>;
};

export type QuestionnaireContext = {
  entity: EntityName;
  id: Scalars['String'];
};

export enum EntityName {
  caregiver = 'caregiver',
  insurance = 'insurance',
  member = 'member',
  barrier = 'barrier',
  appointment = 'appointment',
  recording = 'recording',
  transcript = 'transcript',
  externalTranscript = 'externalTranscript',
  externalRecording = 'externalRecording',
  journey = 'journey',
  actionitem = 'actionitem',
  questionnaireResponse = 'questionnaireResponse',
  admission = 'admission',
  admissionFile = 'admissionFile',
  diagnosis = 'diagnosis',
  memberAttribute = 'memberAttribute',
  dailyReport = 'dailyReport',
  todo = 'todo',
  trait = 'trait',
  todoDone = 'todoDone',
  candidate = 'candidate',
  medication = 'medication',
  user = 'user',
  engineAction = 'engineAction',
  chatMessage = 'chatMessage',
  transcriptParagraph = 'transcriptParagraph',
  dietary = 'dietary',
  treatment = 'treatment',
  externalAppointment = 'externalAppointment',
  clientConfig = 'clientConfig',
  communication = 'communication',
  promptSimulationJob = 'promptSimulationJob',
  callPreparation = 'callPreparation',
  conversationNote = 'conversationNote'
}

export type QuestionnaireResponse = {
  id: Scalars['String'];
  questionnaireId: Scalars['String'];
  memberId: Scalars['String'];
  context?: QuestionnaireContext;
  answers: Array<Answer>;
  status: QuestionnaireResponseStatus;
  result?: QuestionnaireResponseResult;
  type: QuestionnaireType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  barriers?: Array<Barrier>;
  submittedByAi?: Scalars['Boolean'];
};

export enum QuestionnaireResponseStatus {
  completed = 'completed',
  pending = 'pending',
  expired = 'expired'
}

export enum QuestionnaireType {
  phq9 = 'phq9',
  phq2 = 'phq2',
  gad7 = 'gad7',
  gad2 = 'gad2',
  who5 = 'who5',
  nps = 'nps',
  lhp = 'lhp',
  csat = 'csat',
  mdl = 'mdl',
  cage = 'cage',
  rcqtv = 'rcqtv',
  sdoh = 'sdoh',
  ame = 'ame',
  sai = 'sai'
}

export type Answer = {
  code: Scalars['String'];
  value: Scalars['String'];
};

export type Source = {
  type: EntityName;
  subType?: Scalars['String'];
  resourceId?: Scalars['String'];
  parentResourceId?: Scalars['String'];
  metadata?: Scalars['JSON'];
};

export type ActionItemLink = {
  type: ActionItemLinkType;
  value?: Scalars['String'];
};

export enum ActionItemLinkType {
  medicalSection = 'medicalSection',
  createTodo = 'createTodo',
  externalLink = 'externalLink',
  createCaregiver = 'createCaregiver',
  sessionsTab = 'sessionsTab',
  sendSMS = 'sendSMS',
  questionnaire = 'questionnaire',
  barriersSection = 'barriersSection'
}

export type AutoActionItem = {
  title: Scalars['String'];
  description?: Scalars['String'];
  category?: ActionItemCategory;
  section?: ActionItemSection;
  link?: ActionItemLink;
  pathwayId?: Scalars['String'];
  deadline?: Scalars['DateTime'];
  priority?: ActionItemPriority;
};

export enum ActionItemCategory {
  learn = 'learn',
  referral = 'referral',
  legWork = 'legWork',
  nextSession = 'nextSession',
  engagement = 'engagement'
}

export enum ActionItemSection {
  assess = 'assess',
  supportiveActions = 'supportiveActions',
  collaborate = 'collaborate'
}

export enum ActionItemPriority {
  urgent = 'urgent',
  normal = 'normal'
}

export type ExternalId = {
  type: ExternalIdType;
  value: Scalars['String'];
};

export enum ExternalIdType {
  personId = 'personId',
  memberKey = 'memberKey',
  studyId = 'studyId',
  hcid = 'hcid'
}

export type Address = {
  street?: Scalars['String'];
  city?: Scalars['String'];
  state?: Scalars['String'];
};

export type Deceased = {
  cause?: Scalars['String'];
  date?: Scalars['String'];
};

export type MemberCaseManagementProgram = {
  name: Scalars['String'];
  programId: Scalars['String'];
  active: Scalars['Boolean'];
  createdAt?: Scalars['DateTime'];
  updatedAt?: Scalars['DateTime'];
  updatedBy?: Scalars['String'];
};

export type ClientLatestReceivedNotification = {
  sentAt: Scalars['DateTime'];
  id?: Scalars['String'];
  customType?: Scalars['String'];
};

export type Failure = {
  message: Scalars['String'];
  stack?: Scalars['String'];
};

export type MemberTimeline = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  journeyId: Scalars['String'];
  date: Scalars['DateTime'];
  text: Scalars['String'];
  tags: Array<TimelineTag>;
  recipient: ClientCategory;
  sender: ClientCategory;
  referenceId?: Scalars['String'];
  payload?: Scalars['String'];
};

export enum TimelineTag {
  created = 'created',
  session = 'session',
  message = 'message',
  barrier = 'barrier',
  appActivity = 'appActivity',
  status = 'status',
  questionnaire = 'questionnaire',
  admission = 'admission',
  log = 'log',
  attribute = 'attribute',
  engine = 'engine'
}

export enum ClientCategory {
  member = 'member',
  caregiver = 'caregiver',
  user = 'user'
}

export type Quality = {
  type: QualityType;
  title?: Scalars['String'];
  text?: Scalars['String'];
  value: Scalars['Float'];
};

export enum QualityType {
  thought_empathy = 'thought_empathy',
  feeling_empathy = 'feeling_empathy',
  validation = 'validation',
  tone = 'tone',
  style = 'style',
  collaborate_goals = 'collaborate_goals',
  collaborate_bond = 'collaborate_bond'
}

export type Burnout = {
  type: BurnoutType;
  value: Scalars['Float'];
};

export enum BurnoutType {
  short_responses = 'short_responses',
  impatient_responses = 'impatient_responses',
  repetitive_responses = 'repetitive_responses',
  lack_of_active_listening = 'lack_of_active_listening',
  reduced_engagement = 'reduced_engagement',
  lack_of_expression_of_empathy = 'lack_of_expression_of_empathy',
  dismissive_responses = 'dismissive_responses',
  lack_of_validation = 'lack_of_validation',
  lack_of_warmth = 'lack_of_warmth',
  treating_client_as_task = 'treating_client_as_task',
  negative_language = 'negative_language',
  rigid_or_mechanical = 'rigid_or_mechanical'
}

export type AllianceInventory = {
  workTogether?: AgreementLevel;
  respectOneAnother?: AgreementLevel;
};

export enum AgreementLevel {
  StronglyAgree = 'StronglyAgree',
  SomewhatAgree = 'SomewhatAgree',
  NeitherAgreeNorDisagree = 'NeitherAgreeNorDisagree',
  SomewhatDisagree = 'SomewhatDisagree',
  StronglyDisagree = 'StronglyDisagree'
}

export type Appointment = {
  id: Scalars['String'];
  userId: Scalars['String'];
  memberId: Scalars['String'];
  caregiverId?: Scalars['String'];
  notBefore?: Scalars['DateTime'];
  status: AppointmentStatus;
  method?: AppointmentMethod;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  noShow?: Scalars['Boolean'];
  noShowReason?: Scalars['String'];
  summary?: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  link: Scalars['String'];
  label?: AppointmentLabel;
  barriers?: Array<Barrier>;
  allianceInventory?: AllianceInventory;
  manualInputParticipants?: Array<ClientCategory>;
};

export enum AppointmentStatus {
  requested = 'requested',
  scheduled = 'scheduled',
  done = 'done'
}

export enum AppointmentMethod {
  chat = 'chat',
  phoneCall = 'phoneCall',
  videoCall = 'videoCall'
}

export enum AppointmentLabel {
  welcome = 'welcome',
  postDischarge = 'postDischarge',
  welcomePostDischarge = 'welcomePostDischarge',
  checkIn = 'checkIn',
  graduation = 'graduation',
  memberExperience = 'memberExperience',
  careCircle = 'careCircle',
  healthcareTeam = 'healthcareTeam',
  insurer = 'insurer',
  community = 'community',
  other = 'other',
  welcomeCaregiver = 'welcomeCaregiver',
  postDischargeCaregiver = 'postDischargeCaregiver',
  welcomePostDischargeCaregiver = 'welcomePostDischargeCaregiver',
  checkInCaregiver = 'checkInCaregiver',
  graduationCaregiver = 'graduationCaregiver',
  adHoc = 'adHoc'
}

export type Alert = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  type: AlertType;
  text: Scalars['String'];
  date: Scalars['DateTime'];
  path: HarmonyPath;
  toast?: Scalars['Boolean'];
  pathParams?: Scalars['String'];
  isNew: Scalars['Boolean'];
  dismissed: Scalars['Boolean'];
};

export enum AlertType {
  appointmentMemberScheduledUser = 'appointmentMemberScheduledUser',
  appointmentCaregiverScheduledUser = 'appointmentCaregiverScheduledUser',
  memberAssigned = 'memberAssigned',
  memberNotFeelingWellMessage = 'memberNotFeelingWellMessage',
  assessmentSubmitScoreOverThreshold = 'assessmentSubmitScoreOverThreshold',
  assessmentSubmitTriggeredAlerts = 'assessmentSubmitTriggeredAlerts',
  customContent = 'customContent',
  journalContent = 'journalContent',
  appointmentReviewed = 'appointmentReviewed',
  appointmentSubmitOverdue = 'appointmentSubmitOverdue',
  newActionItem = 'newActionItem',
  actionItemOverdue = 'actionItemOverdue',
  memberCreateTodo = 'memberCreateTodo',
  memberRejectTodo = 'memberRejectTodo',
  memberUploadAdmissionFile = 'memberUploadAdmissionFile',
  publishedAdmissionToUser = 'publishedAdmissionToUser',
  daysSinceDischarge30 = 'daysSinceDischarge30',
  primaryCaregiverAdded = 'primaryCaregiverAdded',
  newTextMessageFromMember = 'newTextMessageFromMember',
  newTextMessageFromCaregiver = 'newTextMessageFromCaregiver',
  incomingPhoneCallFromMember = 'incomingPhoneCallFromMember',
  incomingPhoneCallFromCaregiver = 'incomingPhoneCallFromCaregiver',
  memberOptOutOfSms = 'memberOptOutOfSms',
  caregiverOptOutOfSms = 'caregiverOptOutOfSms',
  statusJourney = 'statusJourney',
  newAdmission = 'newAdmission',
  engineSuggestion = 'engineSuggestion',
  transcriptBarrierExtractionCompleted = 'transcriptBarrierExtractionCompleted',
  transcriptSummaryCompleted = 'transcriptSummaryCompleted',
  transcriptSummaryInsightsCompleted = 'transcriptSummaryInsightsCompleted',
  coachTaskScheduleAMeeting = 'coachTaskScheduleAMeeting',
  coachTaskEmergency = 'coachTaskEmergency',
  memberMessagedLisa = 'memberMessagedLisa',
  memberSignedUpToApp = 'memberSignedUpToApp'
}

export enum HarmonyPath {
  general = 'general',
  sessions = 'sessions',
  barriers = 'barriers',
  questionnaires = 'questionnaires',
  dailyLog = 'dailyLog',
  medical = 'medical',
  toDos = 'toDos'
}

export type Identifiers = {
  ids: Array<Scalars['String']>;
};

export type Resource = {
  id: Scalars['String'];
  name?: Scalars['String'];
  type?: ResourceType;
};

export enum ResourceType {
  article = 'article',
  video = 'video',
  audio = 'audio',
  medication = 'medication',
  externalAppointment = 'externalAppointment'
}

export type JourneyStatusHistory = {
  status: JourneyStatus;
  statusReason: JourneyStatusReason;
  statusNote?: Scalars['String'];
  date: Scalars['DateTime'];
};

export enum JourneyStatus {
  active = 'active',
  inactive = 'inactive',
  pending = 'pending',
  partialAutonomy = 'partialAutonomy',
  fullAutonomy = 'fullAutonomy',
  excluded = 'excluded'
}

export enum JourneyStatusReason {
  activeReopen = 'activeReopen',
  activeDefaultStatus = 'activeDefaultStatus',
  inactiveDeclinedToParticipateBeforeEngagementPeriodEnds = 'inactiveDeclinedToParticipateBeforeEngagementPeriodEnds',
  inactiveNotEngagedBeforeEngagementPeriodEnds = 'inactiveNotEngagedBeforeEngagementPeriodEnds',
  pendingFindANewPhoneNumber = 'pendingFindANewPhoneNumber',
  pendingNewPhoneNumberToScrubForDoNotCall = 'pendingNewPhoneNumberToScrubForDoNotCall',
  pendingEscalateToClinicalLeadership = 'pendingEscalateToClinicalLeadership',
  partialAutonomyMemberCompletesEngagementPeriod = 'partialAutonomyMemberCompletesEngagementPeriod',
  partialAutonomyMemberCompletesEngagementPeriodAndDoesNotTransferBackToCustomerCM = 'partialAutonomyMemberCompletesEngagementPeriodAndDoesNotTransferBackToCustomerCM',
  partialAutonomyDeclinedToParticipateAndEndOfEngagementPeriod = 'partialAutonomyDeclinedToParticipateAndEndOfEngagementPeriod',
  partialAutonomyNotEngagedAndEndOfEngagementPeriod = 'partialAutonomyNotEngagedAndEndOfEngagementPeriod',
  fullAutonomyLosesEligibility = 'fullAutonomyLosesEligibility',
  fullAutonomyDeceased = 'fullAutonomyDeceased',
  fullAutonomyMemberCompletesEngagementPeriod = 'fullAutonomyMemberCompletesEngagementPeriod',
  fullAutonomyDeclinedToParticipateAndEndOfEngagementPeriod = 'fullAutonomyDeclinedToParticipateAndEndOfEngagementPeriod',
  fullAutonomyNotEngagedAndEndOfEngagementPeriod = 'fullAutonomyNotEngagedAndEndOfEngagementPeriod',
  excludedDoesNotMeetCriteriaLanguage = 'excludedDoesNotMeetCriteriaLanguage',
  excludedDoesNotMeetCriteriaCanceledAdmission = 'excludedDoesNotMeetCriteriaCanceledAdmission',
  excludedDoesNotMeetCriteriaDeniedAdmission = 'excludedDoesNotMeetCriteriaDeniedAdmission',
  excludedDoesNotMeetCriteriaHospice = 'excludedDoesNotMeetCriteriaHospice',
  excludedDoesNotMeetCriteriaTransplants = 'excludedDoesNotMeetCriteriaTransplants',
  excludedDoesNotMeetCriteriaMaternity = 'excludedDoesNotMeetCriteriaMaternity',
  excludedDoesNotMeetCriteriaCatastrophic = 'excludedDoesNotMeetCriteriaCatastrophic',
  excludedDoesNotMeetCriteriaPediatrics = 'excludedDoesNotMeetCriteriaPediatrics',
  excludedDoesNotMeetCriteriaBehavioralHealth = 'excludedDoesNotMeetCriteriaBehavioralHealth',
  excludedRetainedByCustomer = 'excludedRetainedByCustomer',
  excludedNoValidPhoneNumber = 'excludedNoValidPhoneNumber',
  other = 'other'
}

export type Engagement = {
  engaged: Scalars['Boolean'];
};

export type SummarizedMember = {
  id: Scalars['String'];
  phone: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  healthPlan?: Scalars['String'];
  externalIds: Array<ExternalId>;
  groupName?: Scalars['String'];
  isInternal: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  caseManagementPrograms?: Array<MemberCaseManagementProgram>;
};

export type SummarizedClientConfig = {
  platform?: Platform;
};

export enum Platform {
  ios = 'ios',
  android = 'android',
  web = 'web'
}

export type SummarizedUser = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SummarizedOrg = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SummarizedAdmission = {
  id: Scalars['String'];
  admitDate?: Scalars['String'];
  dischargeDate?: Scalars['String'];
};

export type SummarizedJourney = {
  id: Scalars['String'];
  lastLoggedInAt?: Scalars['DateTime'];
  engagement: Engagement;
  readmissionRisk?: ReadmissionRisk;
  statusHistory: Array<JourneyStatusHistory>;
};

export enum ReadmissionRisk {
  high = 'high',
  medium = 'medium',
  low = 'low'
}

export type MemberSummary = {
  id: Scalars['String'];
  member: SummarizedMember;
  clientConfig: SummarizedClientConfig;
  primaryUser: SummarizedUser;
  recentJourney: SummarizedJourney;
  recentAdmission: SummarizedAdmission;
  overviewStatus?: OverviewStatus;
  admissionsCount: Scalars['Float'];
  nextAppointment?: Scalars['DateTime'];
  lastAppointment?: Scalars['DateTime'];
  appointmentsCount: Scalars['Float'];
  hasDischargeInstructions: Scalars['Boolean'];
  org: SummarizedOrg;
  activeBarriersCount: Scalars['Float'];
  completedBarriersCount: Scalars['Float'];
  isOutOfReach?: Scalars['Boolean'];
};

export enum OverviewStatus {
  documentExplainedMyChart = 'documentExplainedMyChart',
  documentExplainedScan = 'documentExplainedScan',
  documentUploadedByMemberViaApp = 'documentUploadedByMemberViaApp',
  documentConsent = 'documentConsent',
  documentToCoordinator = 'documentToCoordinator',
  documentSentToRN = 'documentSentToRN',
  documentSent = 'documentSent',
  faxSent = 'faxSent',
  documentUploaded = 'documentUploaded',
  updated = 'updated',
  workingOnDNA = 'workingOnDNA',
  DNACompleted = 'DNACompleted',
  medsCollectedVerbally = 'medsCollectedVerbally',
  DNACollectedVerbally = 'DNACollectedVerbally'
}

export type CreatedByInfo = {
  id: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  roles?: Array<MemberRole>;
};

export enum MemberRole {
  member = 'member'
}

export type Speaker = {
  id: Scalars['String'];
  percentage: Scalars['Float'];
  name?: Scalars['String'];
};

export type Transcript = {
  id: Scalars['String'];
  status: Scalars['String'];
  speakers?: Array<Speaker>;
  transcriptLink?: Scalars['String'];
  memberId?: Scalars['String'];
  barriers?: Array<Barrier>;
};

export type TranscriptParagraph = {
  id: Scalars['String'];
  memberId?: Scalars['String'];
  recordingId: Scalars['String'];
  text: Scalars['String'];
  speakerType?: Scalars['String'];
  barriers?: Array<Barrier>;
  speakerId?: Scalars['String'];
};

export type ActionItem = {
  title: Scalars['String'];
  description?: Scalars['String'];
  category?: ActionItemCategory;
  section?: ActionItemSection;
  link?: ActionItemLink;
  pathwayId?: Scalars['String'];
  deadline?: Scalars['DateTime'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  journeyId: Scalars['String'];
  appointmentId?: Scalars['String'];
  barrierId?: Scalars['String'];
  status: ActionItemStatus;
  rejectNote?: Scalars['String'];
  priority: ActionItemPriority;
  source?: ActionItemSource;
  createdAt: Scalars['DateTime'];
  createdBy?: Scalars['String'];
  updatedBy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ActionItemStatus {
  active = 'active',
  completed = 'completed',
  refused = 'refused'
}

export enum ActionItemSource {
  jobAid = 'jobAid',
  poc = 'poc',
  event = 'event',
  engine = 'engine'
}

export type ActionItemByPrimaryUser = {
  title: Scalars['String'];
  description?: Scalars['String'];
  category?: ActionItemCategory;
  section?: ActionItemSection;
  link?: ActionItemLink;
  pathwayId?: Scalars['String'];
  deadline?: Scalars['DateTime'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  journeyId: Scalars['String'];
  appointmentId?: Scalars['String'];
  barrierId?: Scalars['String'];
  status: ActionItemStatus;
  rejectNote?: Scalars['String'];
  priority: ActionItemPriority;
  source?: ActionItemSource;
  createdAt: Scalars['DateTime'];
  createdBy?: Scalars['String'];
  updatedBy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  memberName: Scalars['String'];
  readmissionRisk?: ReadmissionRisk;
};

export type Template = {
  id: Scalars['String'];
  templateType: TemplateType;
  templates: Array<TemplateUnion>;
  active: Scalars['Boolean'];
};

export enum TemplateType {
  barrier = 'barrier',
  appointment = 'appointment',
  event = 'event'
}

export type TemplateUnion = AppointmentTemplate | BarrierTemplate | EventTemplate;

export type AppointmentTemplate = {
  actionItems: Array<AutoActionItem>;
  appointmentType: AppointmentLabel;
};

export type BarrierTemplate = {
  actionItems: Array<AutoActionItem>;
  barrierType: BarrierTypeEnum;
};

export type EventTemplate = {
  actionItems: Array<AutoActionItem>;
  eventType: TriggerEventType;
};

export enum TriggerEventType {
  onNewMember = 'onNewMember',
  highPainScore = 'highPainScore',
  dailyLogOverTh = 'dailyLogOverTh',
  appointmentPrepReminder = 'appointmentPrepReminder',
  engagementReminder = 'engagementReminder',
  assessmentScoreOverThSubmitted = 'assessmentScoreOverThSubmitted',
  transitionToAutonomyReminder = 'transitionToAutonomyReminder',
  createBarrierEngineRecommendation = 'createBarrierEngineRecommendation',
  updateAttributeEngineRecommendation = 'updateAttributeEngineRecommendation'
}

export type Diagnosis = {
  barriers?: Array<Barrier>;
  id?: Scalars['String'];
  code?: Scalars['String'];
  description?: Scalars['String'];
  primaryType?: PrimaryDiagnosisType;
  secondaryType?: SecondaryDiagnosisType;
  clinicalStatus?: ClinicalStatus;
  severity?: DiagnosisSeverity;
  onsetStart?: Scalars['String'];
  onsetEnd?: Scalars['String'];
};

export enum PrimaryDiagnosisType {
  principal = 'principal',
  admitting = 'admitting',
  clinical = 'clinical',
  discharge = 'discharge',
  retrospective = 'retrospective',
  self = 'self',
  differential = 'differential'
}

export enum SecondaryDiagnosisType {
  laboratory = 'laboratory',
  nursing = 'nursing',
  prenatal = 'prenatal',
  radiology = 'radiology',
  remote = 'remote'
}

export enum ClinicalStatus {
  active = 'active',
  recurrence = 'recurrence',
  relapse = 'relapse',
  inactive = 'inactive',
  remission = 'remission',
  resolved = 'resolved'
}

export enum DiagnosisSeverity {
  severe = 'severe',
  moderate = 'moderate',
  mild = 'mild'
}

export type TreatmentRendered = {
  barriers?: Array<Barrier>;
  id?: Scalars['String'];
  code?: Scalars['String'];
  startDate?: Scalars['String'];
  endDate?: Scalars['String'];
};

export type Medication = {
  barriers?: Array<Barrier>;
  id?: Scalars['String'];
  status?: MedicationStatus;
  name?: Scalars['String'];
  rxNorm?: Scalars['String'];
  route?: Scalars['String'];
  dosage?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  specialInstructions?: Scalars['String'];
  memberVerballyProvided?: Scalars['Boolean'];
};

export enum MedicationStatus {
  continue = 'continue',
  change = 'change',
  start = 'start',
  stop = 'stop'
}

export type ExternalAppointment = {
  barriers?: Array<Barrier>;
  id?: Scalars['String'];
  status?: ExternalAppointmentStatus;
  placeOfService?: PlaceOfService;
  drName?: Scalars['String'];
  clinic?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  type?: ExternalAppointmentType;
  specialInstructions?: Scalars['String'];
  fullAddress?: Scalars['String'];
  phone?: Scalars['String'];
  fax?: Scalars['String'];
};

export enum ExternalAppointmentStatus {
  scheduled = 'scheduled',
  toBeScheduled = 'toBeScheduled',
  occurred = 'occurred',
  notWanted = 'notWanted',
  missed = 'missed'
}

export enum PlaceOfService {
  office = 'office',
  emergencyRoomHospital = 'emergencyRoomHospital',
  skilledNursingFacility = 'skilledNursingFacility',
  urgentCareFacility = 'urgentCareFacility',
  home = 'home',
  hospice = 'hospice',
  onCampusOutpatientHospital = 'onCampusOutpatientHospital',
  ambulatorySurgicalCenter = 'ambulatorySurgicalCenter',
  offCampusOutpatientHospital = 'offCampusOutpatientHospital',
  inpatientPsychiatricFacility = 'inpatientPsychiatricFacility',
  comprehensiveInpatientRehabilitationFacility = 'comprehensiveInpatientRehabilitationFacility',
  intermediateCareFacilityOrIndividualsWithIntellectualDisabilities = 'intermediateCareFacilityOrIndividualsWithIntellectualDisabilities',
  ambulanceLand = 'ambulanceLand',
  ambulanceAirOrWater = 'ambulanceAirOrWater',
  assistedLivingFacility = 'assistedLivingFacility',
  birthingCenter = 'birthingCenter',
  communityMentalHealthCenter = 'communityMentalHealthCenter',
  comprehensiveOutpatientRehabilitationFacility = 'comprehensiveOutpatientRehabilitationFacility',
  custodialCareFacility = 'custodialCareFacility',
  endStageRenalDiseaseTreatmentFacility = 'endStageRenalDiseaseTreatmentFacility',
  federallyQualifiedHealthCenter = 'federallyQualifiedHealthCenter',
  groupHome = 'groupHome',
  homelessShelter = 'homelessShelter',
  independentClinic = 'independentClinic',
  independentLaboratory = 'independentLaboratory',
  indianHealthServiceFreeStandingFacility = 'indianHealthServiceFreeStandingFacility',
  indianHealthServiceProviderBasedFacility = 'indianHealthServiceProviderBasedFacility',
  inpatientHospital = 'inpatientHospital',
  massImmunizationCenter = 'massImmunizationCenter',
  militaryTreatmentFacility = 'militaryTreatmentFacility',
  mobileUnit = 'mobileUnit',
  nonResidentialOpioidTreatmentFacility = 'nonResidentialOpioidTreatmentFacility',
  nonResidentialSubstanceAbuseTreatmentFacility = 'nonResidentialSubstanceAbuseTreatmentFacility',
  nursingFacility = 'nursingFacility',
  otherPlaceOfService = 'otherPlaceOfService',
  pharmacy = 'pharmacy',
  placeOfEmploymentWorksite = 'placeOfEmploymentWorksite',
  prisonOrCorrectionalFacility = 'prisonOrCorrectionalFacility',
  psychiatricFacilityPartialHospitalization = 'psychiatricFacilityPartialHospitalization',
  psychiatricResidentialTreatmentCenter = 'psychiatricResidentialTreatmentCenter',
  publicHealthClinic = 'publicHealthClinic',
  residentialSubstanceAbuseTreatmentFacility = 'residentialSubstanceAbuseTreatmentFacility',
  ruralHealthClinic = 'ruralHealthClinic',
  school = 'school',
  telehealthProvidedInPatientsHome = 'telehealthProvidedInPatientsHome',
  telehealthProvidedOtherThanInPatientsHome = 'telehealthProvidedOtherThanInPatientsHome',
  temporaryLodging = 'temporaryLodging',
  tribal638FreeStandingFacility = 'tribal638FreeStandingFacility',
  tribal638ProviderBasedFacility = 'tribal638ProviderBasedFacility',
  walkInRetailHealthClinic = 'walkInRetailHealthClinic'
}

export enum ExternalAppointmentType {
  surgical = 'surgical',
  consultation = 'consultation',
  diagnosticXRay = 'diagnosticXRay',
  diagnosticLab = 'diagnosticLab',
  radiationTherapy = 'radiationTherapy',
  blood = 'blood',
  preAdmissionTesting = 'preAdmissionTesting',
  secondSurgicalOpinion = 'secondSurgicalOpinion',
  socialWork = 'socialWork',
  chiropractic = 'chiropractic',
  dentalCare = 'dentalCare',
  oralSurgery = 'oralSurgery',
  homeHealthCare = 'homeHealthCare',
  hospice = 'hospice',
  respiteCare = 'respiteCare',
  MRIScan = 'MRIScan',
  acupuncture = 'acupuncture',
  newbornCare = 'newbornCare',
  smokingCessation = 'smokingCessation',
  maternity = 'maternity',
  transplants = 'transplants',
  audiology = 'audiology',
  inhalationTherapy = 'inhalationTherapy',
  diagnosticMedical = 'diagnosticMedical',
  prosthetics = 'prosthetics',
  dialysis = 'dialysis',
  chemotherapy = 'chemotherapy',
  immunizations = 'immunizations',
  routinePhysical = 'routinePhysical',
  familyPlanning = 'familyPlanning',
  cancerTreatment = 'cancerTreatment',
  podiatry = 'podiatry',
  psychiatric = 'psychiatric',
  psychotherapy = 'psychotherapy',
  psychiatricInpatient = 'psychiatricInpatient',
  rehabilitationInpatient = 'rehabilitationInpatient',
  rehabilitationOutpatient = 'rehabilitationOutpatient',
  occupationalTherapy = 'occupationalTherapy',
  physicalMedicine = 'physicalMedicine',
  speechTherapy = 'speechTherapy',
  skilledNursingCare = 'skilledNursingCare',
  substanceAbuse = 'substanceAbuse',
  drugAddiction = 'drugAddiction',
  optometry = 'optometry',
  routineEyeExam = 'routineEyeExam',
  experimentalDrugTherapy = 'experimentalDrugTherapy',
  burnCare = 'burnCare',
  independentMedicalEvaluation = 'independentMedicalEvaluation',
  dayCare = 'dayCare',
  cognitiveTherapy = 'cognitiveTherapy',
  massageTherapy = 'massageTherapy',
  pulmonaryRehabilitation = 'pulmonaryRehabilitation',
  cardiacRehabilitation = 'cardiacRehabilitation',
  pediatric = 'pediatric',
  orthopedic = 'orthopedic',
  cardiac = 'cardiac',
  gastrointestinal = 'gastrointestinal',
  endocrine = 'endocrine',
  neurology = 'neurology',
  gynecological = 'gynecological',
  obstetrical = 'obstetrical',
  fluVaccination = 'fluVaccination',
  caseManagement = 'caseManagement',
  dermatology = 'dermatology',
  durableMedicalEquipment = 'durableMedicalEquipment',
  diagnosticImaging = 'diagnosticImaging',
  comprehensiveMedicationTherapyManagementReview = 'comprehensiveMedicationTherapyManagementReview',
  dietaryNutritionalServices = 'dietaryNutritionalServices',
  telemedicine = 'telemedicine',
  diabeticEducation = 'diabeticEducation',
  preventiveServices = 'preventiveServices',
  ophthalmology = 'ophthalmology',
  allergy = 'allergy',
  mentalHealth = 'mentalHealth',
  oncology = 'oncology',
  positronEmissionTomographyScan = 'positronEmissionTomographyScan',
  physicalTherapy = 'physicalTherapy',
  pulmonary = 'pulmonary',
  transitionalCare = 'transitionalCare',
  transitionalNurseryCare = 'transitionalNurseryCare',
  catScan = 'catScan'
}

export type Activity = {
  general?: Array<Scalars['String']>;
  lifting?: Array<Scalars['String']>;
  showerOrBathing?: Array<Scalars['String']>;
  stairs?: Array<Scalars['String']>;
  driving?: Array<Scalars['String']>;
  sexualActivity?: Array<Scalars['String']>;
  work?: Array<Scalars['String']>;
};

export type WoundCare = {
  general?: Array<Scalars['String']>;
};

export type Dietary = {
  barriers?: Array<Barrier>;
  id?: Scalars['String'];
  category?: DietaryCategory;
  name?: DietaryName;
  date?: Scalars['String'];
  notes?: Scalars['String'];
};

export enum DietaryCategory {
  adverseReaction = 'adverseReaction',
  cultural = 'cultural',
  diabetic = 'diabetic',
  fiber = 'fiber',
  fluidRestrictions = 'fluidRestrictions',
  fluid = 'fluid',
  heartHealthy = 'heartHealthy',
  house = 'house',
  medication = 'medication',
  mineral = 'mineral',
  other = 'other',
  renal = 'renal',
  sodiumRestricted = 'sodiumRestricted',
  surgery = 'surgery',
  textureModified = 'textureModified',
  therapeutic = 'therapeutic',
  weightReduction = 'weightReduction'
}

export enum DietaryName {
  lowLactose = 'lowLactose',
  glutenFree = 'glutenFree',
  lactoseFree = 'lactoseFree',
  cantonese = 'cantonese',
  hindu = 'hindu',
  kosher = 'kosher',
  halalMeat = 'halalMeat',
  diabetic = 'diabetic',
  maternalDiabetic = 'maternalDiabetic',
  highFiber = 'highFiber',
  lowFiber = 'lowFiber',
  fluidRestriction1500ml = 'fluidRestriction1500ml',
  fluidRestriction = 'fluidRestriction',
  fluidIncreased = 'fluidIncreased',
  clearFluids = 'clearFluids',
  fullFluids = 'fullFluids',
  cardiac = 'cardiac',
  lowCholesterol = 'lowCholesterol',
  dietaryApproachesToStopHypertension = 'dietaryApproachesToStopHypertension',
  lowFat = 'lowFat',
  regularMaternal = 'regularMaternal',
  vegetarian = 'vegetarian',
  vegan = 'vegan',
  decreasedTyramine = 'decreasedTyramine',
  VitaminKRestriction = 'VitaminKRestriction',
  increasedIron = 'increasedIron',
  decreasedCalcium = 'decreasedCalcium',
  increasedCalcium = 'increasedCalcium',
  decreasedIron = 'decreasedIron',
  lowCarbohydrate = 'lowCarbohydrate',
  lowProtein = 'lowProtein',
  fasting = 'fasting',
  lowPotassium = 'lowPotassium',
  chronicKidneyDiseaseHemodialysis = 'chronicKidneyDiseaseHemodialysis',
  acuteRenalFailurePeritonealDialysisRenalTransplant = 'acuteRenalFailurePeritonealDialysisRenalTransplant',
  lowSodium = 'lowSodium',
  bariatricSurgery = 'bariatricSurgery',
  easyToChew = 'easyToChew',
  mechanicallyAltered = 'mechanicallyAltered',
  pureed = 'pureed',
  antiReflux = 'antiReflux',
  lowCalorie = 'lowCalorie',
  dietaryTreatmentForDisorder = 'dietaryTreatmentForDisorder',
  highCalorieAndHighProtein = 'highCalorieAndHighProtein',
  atkins = 'atkins',
  keto = 'keto',
  paleo = 'paleo'
}

export type AdmissionFile = {
  id: Scalars['String'];
  admissionFileType: AdmissionFileType;
  fileName: Scalars['String'];
  isVisibleToMember?: Scalars['Boolean'];
  uploadedBy: ClientCategory;
};

export enum AdmissionFileType {
  dischargeInstructions = 'dischargeInstructions',
  dischargeSummary = 'dischargeSummary',
  general = 'general'
}

export type ExternalNotes = {
  specialInstructions?: Scalars['String'];
  cmDcPlan?: Scalars['String'];
  umDcNote?: Scalars['String'];
  umClinical?: Scalars['String'];
  caseType?: Scalars['String'];
  primaryDx?: Scalars['String'];
  product?: Scalars['String'];
  primaryPolicyHolderPhone?: Scalars['String'];
};

export type Admission = {
  id: Scalars['String'];
  admitDate?: Scalars['String'];
  dischargeDate?: Scalars['String'];
  requestId?: Scalars['String'];
  published: Scalars['Boolean'];
  memberReportedInformation: Scalars['Boolean'];
  admitType?: AdmitType;
  admitSource?: AdmitSource;
  dischargeTo?: DischargeTo;
  facility?: Scalars['String'];
  specialInstructions?: Scalars['String'];
  reasonForAdmission?: Scalars['String'];
  hospitalCourse?: Scalars['String'];
  admissionSummary?: Scalars['String'];
  progressSummary?: Scalars['String'];
  drg?: Scalars['String'];
  drgDesc?: Scalars['String'];
  nurseNotes?: Scalars['String'];
  transitionSummary?: Scalars['String'];
  warningSigns?: Array<WarningSigns>;
  warningSignsNotes?: Scalars['String'];
  activity?: Activity;
  woundCare?: WoundCare;
  woundCareNotes?: Scalars['String'];
  diagnoses?: Array<Diagnosis>;
  treatmentRendereds?: Array<TreatmentRendered>;
  medications?: Array<Medication>;
  externalAppointments?: Array<ExternalAppointment>;
  dietaries?: Array<Dietary>;
  files?: Array<AdmissionFile>;
  externalNotes?: ExternalNotes;
  caseOwnerName?: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum AdmitType {
  emergency = 'emergency',
  urgent = 'urgent',
  elective = 'elective',
  newborn = 'newborn',
  snf = 'snf',
  psych = 'psych',
  rehab = 'rehab'
}

export enum AdmitSource {
  physicianReferral = 'physicianReferral',
  clinicReferral = 'clinicReferral',
  hmoReferral = 'hmoReferral',
  transferFromHospital = 'transferFromHospital',
  transferredFromSkilledNursing = 'transferredFromSkilledNursing',
  transferredFromAnotherFacility = 'transferredFromAnotherFacility',
  emergencyRoom = 'emergencyRoom',
  ecourtLowEnforcement = 'ecourtLowEnforcement',
  infoNotAvailable = 'infoNotAvailable'
}

export enum DischargeTo {
  home = 'home',
  hospital = 'hospital',
  snf = 'snf',
  icf = 'icf',
  anotherTypeOfInstitutionForInpatientCare = 'anotherTypeOfInstitutionForInpatientCare',
  homeHealth = 'homeHealth',
  leftAgainstMedicalAdviceOrDiscontinuedCare = 'leftAgainstMedicalAdviceOrDiscontinuedCare',
  expired = 'expired',
  stillPatient = 'stillPatient',
  hospiceHome = 'hospiceHome',
  hospiceMedicalFacility = 'hospiceMedicalFacility'
}

export enum WarningSigns {
  confusion = 'confusion',
  difficultyBreathingOrShortnessOfBreath = 'difficultyBreathingOrShortnessOfBreath',
  nauseaVomitingAndOrDiarrheaThatWillNotStop = 'nauseaVomitingAndOrDiarrheaThatWillNotStop',
  passingOut = 'passingOut',
  severeDizziness = 'severeDizziness',
  significantIncreaseOrStartOfPain = 'significantIncreaseOrStartOfPain',
  tempOf101FOrHigher = 'tempOf101FOrHigher',
  woundIncisionIsWorsening = 'woundIncisionIsWorsening',
  other = 'other'
}

export type DietaryMapTuple = {
  key: DietaryCategory;
  values: Array<DietaryName>;
};

export type DietaryMatcher = {
  map: Array<DietaryMapTuple>;
};

export type OverviewStatusHistory = {
  overviewStatus?: OverviewStatus;
  date: Scalars['DateTime'];
};

export type AdmissionOverview = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  admissionId: Scalars['String'];
  overviewStatus?: OverviewStatus;
  overviewStatusHistory?: Array<OverviewStatusHistory>;
  text?: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrgAuthProps = {
  clientId?: Scalars['String'];
  idpName?: Scalars['String'];
  idpType?: AuthProvider;
  redirectURI?: Scalars['String'];
  subdomain?: Scalars['String'];
};

export enum AuthProvider {
  laguna = 'laguna',
  microsoft = 'microsoft'
}

export type OrgConfiguration = {
  autoAcceptLisaChatResponse?: Scalars['Boolean'];
};

export type OrgExternalRecordingsDefinition = {
  definitionUrl?: Scalars['String'];
};

export type OrgCaseManagementProgram = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Org = {
  id: Scalars['String'];
  name: Scalars['String'];
  type: OrgType;
  trialDuration: Scalars['Int'];
  countryCode: CountryCode;
  zipCode: Scalars['String'];
  code: Scalars['String'];
  logoUrl?: Scalars['String'];
  parent?: Org;
  primaryExternalId?: ExternalIdType;
  defaultTextSmsProvider: Provider;
  configuration?: OrgConfiguration;
  ssoApplicationId?: Scalars['String'];
  activationCode?: Scalars['String'];
  caseManagementPrograms?: Array<OrgCaseManagementProgram>;
  authProps?: OrgAuthProps;
};

export enum OrgType {
  hospital = 'hospital',
  service = 'service'
}

export enum CountryCode {
  US = 'US',
  ZA = 'ZA'
}

export enum Provider {
  oneSignal = 'oneSignal',
  sendbird = 'sendbird',
  voximplant = 'voximplant',
  twilio = 'twilio',
  slack = 'slack',
  whatsapp = 'whatsapp'
}

export type Config = {
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['JSON'];
  parent?: Config;
};

export type Configuration = {
  data: Scalars['JSON'];
};

export type ReadmissionRiskHistory = {
  readmissionRisk: ReadmissionRisk;
  date: Scalars['DateTime'];
};

export type Journey = {
  id: Scalars['String'];
  lastLoggedInAt?: Scalars['DateTime'];
  engagement: Engagement;
  readmissionRisk?: ReadmissionRisk;
  statusHistory: Array<JourneyStatusHistory>;
  memberId: Scalars['String'];
  org: Org;
  /** @deprecated this field will be deprecated soon, please use statusHistory instead */
  isGraduated: Scalars['Boolean'];
  firstLoggedInAt?: Scalars['DateTime'];
  caregiverFirstLoggedInAt?: Scalars['DateTime'];
  caregiverLastLoggedInAt?: Scalars['DateTime'];
  readmissionRiskHistory?: Array<ReadmissionRiskHistory>;
  /** @deprecated this field will be deprecated soon, please use statusHistory instead */
  status: JourneyStatus;
  statusReason: JourneyStatusReason;
  /** @deprecated this field will be deprecated soon, please use statusHistory instead */
  statusNote?: Scalars['String'];
  /** @deprecated this field will be deprecated soon, please use statusHistory instead */
  graduationDate?: Scalars['DateTime'];
  generalNotes?: Scalars['String'];
};

export type Prompt = {
  id: Scalars['String'];
  key: PromptKey;
  template: Scalars['String'];
  version: Scalars['String'];
  abstractModel: AbstractModel;
  active: Scalars['Boolean'];
  releaseNotes?: Scalars['String'];
  options?: ChatCompletionsOptions;
  isObsolete: Scalars['Boolean'];
};

export enum PromptKey {
  memberSummary = 'memberSummary',
  recordingTranscriptSummary = 'recordingTranscriptSummary',
  liveTranscriptBarrierExtraction = 'liveTranscriptBarrierExtraction',
  chatResponse = 'chatResponse',
  chatSummary = 'chatSummary',
  slimLiveTranscriptBarrierExtraction = 'slimLiveTranscriptBarrierExtraction',
  realTimeCareInsights = 'realTimeCareInsights',
  dynamicCareInsights = 'dynamicCareInsights',
  recordingTranscriptInsights = 'recordingTranscriptInsights',
  recognition = 'recognition',
  teamConversationalSuccessRecommendations = 'teamConversationalSuccessRecommendations',
  caseManagerConversationalSuccessRecommendations = 'caseManagerConversationalSuccessRecommendations',
  teamBurnoutInclinationRecommendations = 'teamBurnoutInclinationRecommendations',
  caseManagerBurnoutInclinationRecommendations = 'caseManagerBurnoutInclinationRecommendations',
  teamConversationalStyleRecommendations = 'teamConversationalStyleRecommendations',
  caseManagerConversationalStyleRecommendations = 'caseManagerConversationalStyleRecommendations',
  memberTextForVectorEmbedding = 'memberTextForVectorEmbedding',
  traitExtraction = 'traitExtraction',
  speakerRecognition = 'speakerRecognition',
  postCallTraitExtraction = 'postCallTraitExtraction'
}

export enum AbstractModel {
  gpt35t = 'gpt35t',
  gpt35t16k = 'gpt35t16k',
  gpt4 = 'gpt4',
  gpt4v1106 = 'gpt4v1106',
  gpt4o = 'gpt4o',
  gpt4oMini = 'gpt4oMini',
  claude3Sonnet = 'claude3Sonnet',
  claude35Sonnet = 'claude35Sonnet',
  claude3Haiku = 'claude3Haiku',
  claude2v1 = 'claude2v1',
  claude2 = 'claude2',
  claudeInstant1 = 'claudeInstant1',
  mistral7B = 'mistral7B',
  mistral8x7B = 'mistral8x7B'
}

export type ChatCompletionContext = {
  memberId?: Scalars['String'];
  userId?: Scalars['String'];
  orgId?: Scalars['String'];
  recordingId?: Scalars['String'];
  transcriptParagraphId?: Scalars['String'];
  sendBirdChannelUrl?: Scalars['String'];
  barrierType?: BarrierTypeEnum;
  jobId?: Scalars['String'];
  traitSchemaId?: Scalars['String'];
  utterancesLength?: Scalars['Float'];
};

export type PromptSimulationJob = {
  promptId: Scalars['String'];
  abstractModel?: AbstractModel;
  chatCompletionsOptions?: ChatCompletionsOptions;
  context: ChatCompletionContext;
  id: Scalars['String'];
  start: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  status: PromptSimulationStatus;
  error?: Scalars['JSON'];
};

export enum PromptSimulationStatus {
  fail = 'fail',
  success = 'success',
  inprogress = 'inprogress'
}

export type ChatCompletionTokens = {
  completion?: Scalars['Float'];
  prompt?: Scalars['Float'];
  total?: Scalars['Float'];
};

export type ChatCompletion = {
  memberId?: Scalars['String'];
  userId?: Scalars['String'];
  orgId?: Scalars['String'];
  recordingId?: Scalars['String'];
  transcriptParagraphId?: Scalars['String'];
  sendBirdChannelUrl?: Scalars['String'];
  barrierType?: BarrierTypeEnum;
  jobId?: Scalars['String'];
  traitSchemaId?: Scalars['String'];
  utterancesLength?: Scalars['Float'];
  id: Scalars['String'];
  prompt: Scalars['String'];
  promptKey?: PromptKey;
  promptVersion?: Scalars['String'];
  model: ModelName;
  modelVersion: Scalars['String'];
  promptText?: Scalars['String'];
  promptData?: Scalars['JSON'];
  failures?: Array<Failure>;
  processTime?: Scalars['Float'];
  isInvalid?: Scalars['Boolean'];
  options?: ChatCompletionsOptions;
  response?: Scalars['String'];
  tokens?: ChatCompletionTokens;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Scalars['String'];
};

export enum ModelName {
  lagunaGpt35t = 'lagunaGpt35t',
  lagunaGpt35t16k = 'lagunaGpt35t16k',
  lagunaGpt4 = 'lagunaGpt4',
  lagunaGpt4v1106 = 'lagunaGpt4v1106',
  lagunaGpt4o = 'lagunaGpt4o',
  lagunaGpt4oMini = 'lagunaGpt4oMini',
  gpt35t = 'gpt35t',
  gpt35t16k = 'gpt35t16k',
  gpt4v0613 = 'gpt4v0613',
  gpt4v1106 = 'gpt4v1106',
  gpt4o = 'gpt4o',
  gpt4oMini = 'gpt4oMini',
  anthropicClaude3Sonnet = 'anthropicClaude3Sonnet',
  anthropicClaude35Sonnet = 'anthropicClaude35Sonnet',
  anthropicClaude3Haiku = 'anthropicClaude3Haiku',
  anthropicClaude2v1 = 'anthropicClaude2v1',
  anthropicClaude2 = 'anthropicClaude2',
  anthropicClaudeInstant1 = 'anthropicClaudeInstant1',
  mistral7B = 'mistral7B',
  mistral8x7B = 'mistral8x7B'
}

export type DomainEntry = {
  type: DomainType;
  data: Scalars['String'];
};

export enum DomainType {
  psychologicalAndEmotional = 'psychologicalAndEmotional',
  medical = 'medical',
  socioEconomic = 'socioEconomic'
}

export type Sentiment = {
  score: Scalars['Float'];
  explanation: Scalars['String'];
};

export type RecordingTranscriptSummaryResponse = {
  status?: GptResponseStatus;
  summary?: Scalars['String'];
  realName?: Scalars['String'];
  outreachSummary?: Scalars['String'];
  memberPrioritizedGoalAndProgress?: Scalars['String'];
  selfManagementPlan?: Scalars['String'];
  followUp?: Scalars['String'];
  domains?: Array<DomainEntry>;
  internalSummary?: Scalars['String'];
  icd10?: Array<Scalars['String']>;
  zcodes?: Array<Scalars['String']>;
  callPreparation?: Array<Scalars['String']>;
};

export enum GptResponseStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  failed = 'failed',
  updatedByUser = 'updatedByUser'
}

export type RecordingTranscriptInsightsResponse = {
  qualities?: Array<Quality>;
  burnout?: Array<Burnout>;
  alliance?: Array<Answer>;
  memberSentiment?: Sentiment;
  summary?: Scalars['String'];
  status?: GptResponseStatus;
};

export type BarrierSuggestionEntry = {
  type: BarrierTypeEnum;
  reason?: Scalars['String'];
  probed?: Scalars['Boolean'];
  probedQuote?: Scalars['String'];
  probeReasoning?: Scalars['String'];
};

export type Category = {
  quality: Scalars['String'];
  text: Scalars['String'];
};

export type RecognitionResponse = {
  description: Scalars['String'];
  aiSuggestion: Scalars['String'];
  categories: Array<Category>;
};

export type RecommendationResponse = {
  description: Scalars['String'];
  recommendation: Scalars['String'];
};

export type ExtractedTraits = {
  name: Scalars['String'];
  concern?: Scalars['Boolean'];
  probed?: Scalars['Boolean'];
  probeReasoning?: Scalars['String'];
  probeQuote?: Scalars['String'];
};

export type Option = {
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type RangeElement = {
  value: Scalars['Float'];
  label: Scalars['String'];
};

export type Range = {
  min: RangeElement;
  max: RangeElement;
};

export type SeverityLevel = {
  min: Scalars['Float'];
  max: Scalars['Float'];
  label: Scalars['String'];
};

export type Questionnaire = {
  id: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  type: QuestionnaireType;
  active: Scalars['Boolean'];
  items: Array<Item>;
  isAssignableToMember: Scalars['Boolean'];
  severityLevels?: Array<SeverityLevel>;
  notificationScoreThreshold?: Scalars['Float'];
  notificationScoreThresholdReverse?: Scalars['Boolean'];
  scoreFactor?: Scalars['Float'];
  createdBy?: Scalars['String'];
  buildResult?: Scalars['Boolean'];
  description?: Scalars['String'];
};

export type Item = {
  code: Scalars['String'];
  label: Scalars['String'];
  type: ItemType;
  variant?: ItemVariant;
  order: Scalars['Float'];
  required: Scalars['Boolean'];
  options?: Array<Option>;
  range?: Range;
  items?: Array<Item>;
  alertCondition?: Array<AlertCondition>;
  excludeForScoring?: Scalars['Boolean'];
};

export enum ItemType {
  choice = 'choice',
  multiChoice = 'multiChoice',
  text = 'text',
  range = 'range',
  group = 'group',
  label = 'label'
}

export enum ItemVariant {
  choice = 'choice',
  radio = 'radio',
  dropdown = 'dropdown',
  choiceNewLine = 'choiceNewLine'
}

export type AlertCondition = {
  type: AlertConditionType;
  value: Scalars['String'];
};

export enum AlertConditionType {
  equal = 'equal',
  gte = 'gte',
  lte = 'lte'
}

export type CallPreparation = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  recordingId: Scalars['String'];
  text: Scalars['String'];
  identifiedBy: IdentifiedBy;
};

export type RecordingReview = {
  userId: Scalars['String'];
  content?: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MultipartUploadInfo = {
  url: Scalars['String'];
  uploadId: Scalars['String'];
};

export type Participant = {
  id: Scalars['String'];
  start: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  phone?: Scalars['String'];
  answered: Scalars['Boolean'];
  consent: Scalars['Boolean'];
  identityVerification: Scalars['Boolean'];
  noConsentReason?: Scalars['String'];
};

export type Recording = {
  id: Scalars['String'];
  recordingTranscriptSummary?: RecordingTranscriptSummaryResponse;
  recordingTranscriptInsights?: RecordingTranscriptInsightsResponse;
  extractedBarriers?: BarrierSuggestionEntry;
  extractedTraits?: ExtractedTraits;
  memberId?: Scalars['String'];
  userId: Scalars['String'];
  appointmentId?: Scalars['String'];
  candidateId?: Scalars['String'];
  recordingType: RecordingType;
  review?: RecordingReview;
  survey?: QuestionnaireResponse;
  callPreparation?: Array<CallPreparation>;
  participants: Array<Participant>;
  status: RecordingStatus;
  start: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  liveTranscriptStart?: Scalars['DateTime'];
};

export enum RecordingType {
  voip = 'voip',
  video = 'video',
  phone = 'phone'
}

export enum RecordingStatus {
  processing = 'processing',
  done = 'done',
  noConsent = 'noConsent'
}

export type RecordingInsight = {
  id: Scalars['String'];
  orgId: Scalars['String'];
  memberId?: Scalars['String'];
  userId: Scalars['String'];
  duration: Scalars['Float'];
  allianceSummary?: Scalars['String'];
  allianceTotalScore?: Scalars['Float'];
  recordingTranscriptSummary?: RecordingTranscriptSummaryResponse;
  recordingTranscriptInsights?: RecordingTranscriptInsightsResponse;
  extractedBarriers?: Array<BarrierSuggestionEntry>;
};

export type DetachedRecording = {
  id: Scalars['String'];
  phone: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  key: Scalars['String'];
};

export type EngineAction = {
  id: Scalars['String'];
  memberId?: Scalars['String'];
  journeyId?: Scalars['String'];
  recordingId?: Scalars['String'];
  userId?: Scalars['String'];
  type: ActionType;
  payload: Scalars['JSON'];
  status: EngineActionStatus;
  title?: Scalars['String'];
  subTitle?: Scalars['String'];
  sources?: Array<Source>;
  identifiedBy?: IdentifiedBy;
  notes?: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export enum ActionType {
  createBarrier = 'createBarrier',
  updateAttribute = 'updateAttribute',
  createActionItem = 'createActionItem',
  generateChatResponse = 'generateChatResponse',
  createCareInsight = 'createCareInsight',
  createRealTimeCareInsight = 'createRealTimeCareInsight'
}

export enum EngineActionStatus {
  active = 'active',
  accepted = 'accepted',
  rejected = 'rejected',
  canceled = 'canceled'
}

export type CallCode = {
  code: Scalars['String'];
  memberId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserSlotSummary = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  roles: Array<UserRole>;
  avatar: Scalars['String'];
  description?: Scalars['String'];
};

export enum UserRole {
  lagunaAdmin = 'lagunaAdmin',
  lagunaCoach = 'lagunaCoach',
  lagunaNurse = 'lagunaNurse',
  coach = 'coach',
  viewer = 'viewer',
  backoffice = 'backoffice',
  supervisor = 'supervisor',
  analyst = 'analyst'
}

export type AppointmentSlotSummary = {
  id: Scalars['String'];
  duration: Scalars['Int'];
  start?: Scalars['DateTime'];
  method?: AppointmentMethod;
  memberId: Scalars['String'];
  userId: Scalars['String'];
};

export type MemberSlotSummary = {
  id: Scalars['String'];
  firstName: Scalars['String'];
};

export type Slots = {
  user: UserSlotSummary;
  appointment?: AppointmentSlotSummary;
  member?: MemberSlotSummary;
  slots: Array<Scalars['DateTime']>;
};

export type Capacity = {
  total: Scalars['Float'];
  perOrganization?: Scalars['JSON'];
};

export type User = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  authId?: Scalars['String'];
  username?: Scalars['String'];
  email: Scalars['String'];
  roles: Array<UserRole>;
  avatar?: Scalars['String'];
  description?: Scalars['String'];
  createdAt: Scalars['DateTime'];
  phone: Scalars['String'];
  title?: Scalars['String'];
  /** @deprecated using a capacity object which encompasses the total max members as well as a per-org capacity */
  maxMembers: Scalars['Float'];
  capacity?: Capacity;
  languages?: Array<Language>;
  lastQueryAlert?: Scalars['DateTime'];
  inEscalationGroup?: Scalars['Boolean'];
  orgs: Array<Scalars['String']>;
  timezone: Timezone;
  specialist?: User;
  supervisor?: User;
  specialistFor?: Array<Scalars['String']>;
  supervisorFor?: Array<Scalars['String']>;
};

export enum Language {
  en = 'en',
  es = 'es'
}

export enum Timezone {
  easternStandardTime = 'easternStandardTime',
  centralStandardTime = 'centralStandardTime',
  mountainStandardTime = 'mountainStandardTime',
  pacificStandardTime = 'pacificStandardTime',
  alaskaStandardTime = 'alaskaStandardTime',
  hawaiiAleutianStandardTime = 'hawaiiAleutianStandardTime',
  southAfricanStandardTime = 'southAfricanStandardTime'
}

export type UserSummary = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  authId?: Scalars['String'];
  username?: Scalars['String'];
  email: Scalars['String'];
  roles: Array<UserRole>;
  avatar?: Scalars['String'];
  description?: Scalars['String'];
  createdAt: Scalars['DateTime'];
  phone: Scalars['String'];
  title?: Scalars['String'];
  /** @deprecated using a capacity object which encompasses the total max members as well as a per-org capacity */
  maxMembers: Scalars['Float'];
  capacity?: Capacity;
  languages?: Array<Language>;
  lastQueryAlert?: Scalars['DateTime'];
  inEscalationGroup?: Scalars['Boolean'];
  orgs: Array<Scalars['String']>;
  timezone: Timezone;
  specialist?: User;
  supervisor?: User;
  specialistFor?: Array<Scalars['String']>;
  supervisorFor?: Array<Scalars['String']>;
  currentMembersCount: Scalars['Float'];
  overdueActionItems: Scalars['Float'];
  averageLengthOfCallsInMinutes?: Scalars['Float'];
  isEnabled: Scalars['Boolean'];
};

export type UserStatistics = {
  totalActiveMembers: Scalars['Float'];
  totalEngagedMembers: Scalars['Float'];
  totalAppUsingMembers: Scalars['Float'];
  averageSessionLength: Scalars['Float'];
};

export type Voximplant = {
  uri: Scalars['String'];
  id: Scalars['String'];
  password: Scalars['String'];
};

export type UserConfig = {
  userId: Scalars['String'];
  accessToken: Scalars['String'];
  voximplant: Voximplant;
  slackUserId?: Scalars['String'];
};

export type Article = {
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  title: Scalars['String'];
  subTitle?: Scalars['String'];
  description?: Scalars['String'];
  bigImageUrl: Scalars['String'];
  smallImageUrl: Scalars['String'];
  videoUrl?: Scalars['String'];
  duration?: Scalars['Float'];
  isRecommended: Scalars['Boolean'];
  type: ArticleType;
  topic: Array<ArticleTopic>;
  /** @deprecated this field will be deprecated soon, please use content instead */
  articleMarkdown: Scalars['String'];
  content: Scalars['String'];
  seriesId?: Scalars['String'];
  seriesIndex?: Scalars['Float'];
};

export enum ArticleType {
  Article = 'Article',
  Audio = 'Audio',
  Video = 'Video'
}

export enum ArticleTopic {
  Guide = 'Guide',
  Exercise = 'Exercise',
  Relaxation = 'Relaxation',
  Pain = 'Pain',
  Mobility = 'Mobility',
  Motivation = 'Motivation',
  Other = 'Other',
  Mood = 'Mood',
  CheckList = 'CheckList',
  CareCircle = 'CareCircle'
}

export type ArticleGroup = {
  id: Scalars['String'];
  orgId: Scalars['String'];
  articles: Array<Article>;
};

export type AttributeOption = {
  category: Scalars['String'];
  key: Scalars['String'];
  description?: Scalars['String'];
  tags?: Array<AttributeTag>;
  value: Scalars['String'];
};

export enum AttributeTag {
  protected = 'protected',
  published = 'published'
}

export type Attribute = {
  category: Scalars['String'];
  key: Scalars['String'];
  description?: Scalars['String'];
  tags?: Array<AttributeTag>;
  values: Array<Scalars['String']>;
};

export type MemberAttribute = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  attribute: AttributeOption;
  barriers?: Array<Barrier>;
  updatedAt: Scalars['DateTime'];
  identifiedBy: IdentifiedBy;
  notes?: Scalars['String'];
};

export type MemberWithOrg = {
  id: Scalars['String'];
  phone: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  healthPlan?: Scalars['String'];
  externalIds: Array<ExternalId>;
  groupName?: Scalars['String'];
  isInternal: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  caseManagementPrograms?: Array<MemberCaseManagementProgram>;
  authId?: Scalars['String'];
  roles: Array<MemberRole>;
  creationSource: CreationSource;
  phoneType: PhoneType;
  deviceId?: Scalars['String'];
  dateOfBirth: Scalars['String'];
  primaryUserId: Scalars['String'];
  users: Array<User>;
  sex: Sex;
  email?: Scalars['String'];
  zipCode?: Scalars['String'];
  phoneSecondary?: Scalars['String'];
  phoneSecondaryType?: PhoneType;
  utcDelta?: Scalars['Float'];
  address?: Address;
  honorific: Honorific;
  race?: Race;
  accountName?: Scalars['String'];
  preferredGenderPronoun?: Scalars['String'];
  healthPersona?: HealthPersona;
  maritalStatus?: MaritalStatus;
  height?: Scalars['Float'];
  weight?: Scalars['Float'];
  deceased?: Deceased;
  summary?: Scalars['String'];
  summaryUpdatedAt?: Scalars['DateTime'];
  org: Org;
  recentJourney: Journey;
  journeyStatus: JourneyStatus;
  readmissionRisk: JourneyStatus;
};

export enum CreationSource {
  automatic = 'automatic',
  manual = 'manual',
  candidate = 'candidate',
  registration = 'registration'
}

export enum Sex {
  male = 'male',
  female = 'female',
  other = 'other'
}

export enum Honorific {
  mr = 'mr',
  mrs = 'mrs',
  ms = 'ms',
  miss = 'miss',
  mx = 'mx',
  dr = 'dr',
  reverend = 'reverend',
  professor = 'professor',
  captain = 'captain',
  coach = 'coach',
  father = 'father'
}

export enum Race {
  americanIndianOrAlaskaNative = 'americanIndianOrAlaskaNative',
  asian = 'asian',
  blackOrAfricanAmerican = 'blackOrAfricanAmerican',
  hispanicOrLatino = 'hispanicOrLatino',
  nativeHawaiianOrOtherPacificIslander = 'nativeHawaiianOrOtherPacificIslander',
  white = 'white'
}

export enum HealthPersona {
  active = 'active',
  passive = 'passive',
  highEffort = 'highEffort',
  complacent = 'complacent'
}

export enum MaritalStatus {
  married = 'married',
  single = 'single',
  divorced = 'divorced',
  widowed = 'widowed',
  lifePartnership = 'lifePartnership'
}

export type AppointmentCompose = {
  memberId: Scalars['String'];
  memberName: Scalars['String'];
  userId: Scalars['String'];
  userName: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  status: AppointmentStatus;
};

export type DischargeDocumentsLinks = {
  dischargeNotesLink?: Scalars['String'];
  dischargeInstructionsLink?: Scalars['String'];
};

export type ClientConfig = {
  platform?: Platform;
  clientId: Scalars['String'];
  externalUserId: Scalars['String'];
  accessToken?: Scalars['String'];
  phoneVerified: Scalars['Boolean'];
  isApprovedTermsAndConditions: Scalars['Boolean'];
  isPushNotificationsEnabled: Scalars['Boolean'];
  isSmsEnabled: Scalars['Boolean'];
  isSmsSupportedOnDevice: Scalars['Boolean'];
  isWhatsappNonTemplateTextEnabled: Scalars['Boolean'];
  whatsAppInstallationStatus: WhatsAppInstallationStatus;
  isDNC: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  isPhoneCallEnabled: Scalars['Boolean'];
  isTodoNotificationsEnabled: Scalars['Boolean'];
  isCoachCommunicationEnabled: Scalars['Boolean'];
  isOperationalCommunicationEnabled: Scalars['Boolean'];
  language: Language;
  firstLoggedInAt?: Scalars['DateTime'];
  lastLoggedInAt?: Scalars['DateTime'];
  timezone?: Scalars['String'];
  systemVersion?: Scalars['String'];
  brand?: Scalars['String'];
  codePushVersion?: Scalars['String'];
  appVersion?: Scalars['String'];
  buildVersion?: Scalars['String'];
  voxImplantPassword?: Scalars['String'];
};

export enum WhatsAppInstallationStatus {
  installed = 'installed',
  notInstalled = 'notInstalled',
  pendingCheck = 'pendingCheck'
}

export type MemberConfig = {
  platform?: Platform;
  externalUserId: Scalars['String'];
  accessToken?: Scalars['String'];
  phoneVerified: Scalars['Boolean'];
  isApprovedTermsAndConditions: Scalars['Boolean'];
  isPushNotificationsEnabled: Scalars['Boolean'];
  isSmsEnabled: Scalars['Boolean'];
  isSmsSupportedOnDevice: Scalars['Boolean'];
  isWhatsappNonTemplateTextEnabled: Scalars['Boolean'];
  whatsAppInstallationStatus: WhatsAppInstallationStatus;
  isDNC: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  isPhoneCallEnabled: Scalars['Boolean'];
  isTodoNotificationsEnabled: Scalars['Boolean'];
  isCoachCommunicationEnabled: Scalars['Boolean'];
  isOperationalCommunicationEnabled: Scalars['Boolean'];
  language: Language;
  firstLoggedInAt?: Scalars['DateTime'];
  lastLoggedInAt?: Scalars['DateTime'];
  timezone?: Scalars['String'];
  systemVersion?: Scalars['String'];
  brand?: Scalars['String'];
  codePushVersion?: Scalars['String'];
  appVersion?: Scalars['String'];
  buildVersion?: Scalars['String'];
  voxImplantPassword?: Scalars['String'];
  memberId: Scalars['String'];
  /** @deprecated the field is no longer available, please use org articles instead */
  articlesPath: Scalars['String'];
};

export type ChatMessage = {
  message?: Scalars['String'];
  timestamp?: Scalars['Float'];
};

export type Chat = {
  clientId: Scalars['String'];
  sendBirdChannelUrl: Scalars['String'];
  channelType: ChannelType;
  memberId?: Scalars['String'];
  timezone?: Scalars['String'];
  channelLastMessage?: ChatMessage;
  relationship?: Scalars['String'];
  isPushNotificationsEnabled?: Scalars['Boolean'];
  memberName?: Scalars['String'];
  coverUrl?: Scalars['String'];
  channelName?: Scalars['String'];
  primaryUserId?: Scalars['String'];
  unreadMessageCount?: Scalars['Float'];
};

export enum ChannelType {
  member = 'member',
  caregiver = 'caregiver',
  support = 'support',
  specialistBot = 'specialistBot'
}

export type MemberCommunications = {
  userToken: Scalars['String'];
  chats: Array<Chat>;
};

export type UserInfo = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  avatar?: Scalars['String'];
  roles: Array<UserRole>;
};

export type ClientUnreadMessagesCount = {
  clientId: Scalars['String'];
  userId: Scalars['String'];
  count: Scalars['Float'];
};

export type ClientCommunication = {
  clientId: Scalars['String'];
  clientToken: Scalars['String'];
  sendBirdChannelUrl: Scalars['String'];
  user: UserInfo;
  type?: ChannelType;
};

export type ClientCommunications = {
  communications: Array<ClientCommunication>;
};

export type CommunicationSummary = {
  content: Scalars['String'];
  updatedAt: Scalars['Float'];
};

export type Communication = {
  clientId: Scalars['String'];
  memberId: Scalars['String'];
  userId: Scalars['String'];
  sendBirdChannelUrl: Scalars['String'];
  latestTimestampBulkDelete: Scalars['Float'];
  channelType: ChannelType;
  summary?: CommunicationSummary;
};

export type UnreadMessagesCount = {
  memberId: Scalars['String'];
  userId: Scalars['String'];
  count: Scalars['Float'];
};

export type MemberCommunicationInfo = {
  memberLink: Scalars['String'];
  user: UserInfo;
};

export type Todo = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  text?: Scalars['String'];
  label?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  status: TodoStatus;
  resource?: Resource;
  relatedTo?: Scalars['String'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
};

export enum TodoStatus {
  active = 'active',
  updated = 'updated',
  ended = 'ended',
  requested = 'requested',
  rejected = 'rejected'
}

export type TodoDone = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  todoId: Scalars['String'];
  done: Scalars['DateTime'];
};

export type AvailabilitySlot = {
  id: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  userId: Scalars['String'];
  userName: Scalars['String'];
};

export type ConversationNote = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  note: Scalars['String'];
  recordingId: Scalars['String'];
  memberId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DailyReport = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  journeyId: Scalars['String'];
  date: Scalars['String'];
  categories: Array<DailyReportCategory>;
  statsOverThreshold?: Array<DailyReportCategoryTypes>;
  barriers?: Array<Barrier>;
};

export enum DailyReportCategoryTypes {
  Pain = 'Pain',
  Mood = 'Mood',
  Sleep = 'Sleep',
  Mobility = 'Mobility',
  Appetite = 'Appetite',
  Energy = 'Energy'
}

export type DailyReportCategory = {
  category: DailyReportCategoryTypes;
  rank: Scalars['Int'];
};

export type Metadata = {
  minDate?: Scalars['String'];
};

export type DailyReportResults = {
  data: Array<DailyReport>;
  metadata?: Metadata;
};

export type EligibilityRelationship = {
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  phone: Scalars['String'];
  relationshipToEmployee: Scalars['String'];
};

export type Candidate = {
  id: Scalars['String'];
  key: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  census: Scalars['JSON'];
  eligibility: Scalars['JSON'];
  primaryPolicyHolderPhone?: Scalars['String'];
  status: CandidateStatus;
  orgName: Scalars['String'];
  memberId?: Scalars['String'];
  phone?: Scalars['String'];
  note?: Scalars['String'];
  userId?: Scalars['String'];
};

export enum CandidateStatus {
  new = 'new',
  pendingAssignment = 'pendingAssignment',
  outreach = 'outreach',
  customerGuidance = 'customerGuidance',
  transitionedBack = 'transitionedBack',
  completed = 'completed',
  pendingDnc = 'pendingDnc',
  blocked = 'blocked'
}

export type ScoreRankingOrgInsights = {
  orgId: Scalars['String'];
  high: Scalars['Float'];
  medium: Scalars['Float'];
  low: Scalars['Float'];
};

export type BarrierIdentificationOrgInsights = {
  orgId: Scalars['String'];
  barrierType: BarrierTypeEnum;
  missed: Scalars['Float'];
  identified: Scalars['Float'];
};

export type OperationalStatisticsOrgInsights = {
  orgId: Scalars['String'];
  averageCallTime: Scalars['String'];
  callsPerCareManagerPerDay: Scalars['Float'];
  caseLoadPerCareManager: Scalars['Float'];
};

export type JournalUploadImageLink = {
  normalImageLink?: Scalars['String'];
};

export type JournalUploadAudioLink = {
  audioLink?: Scalars['String'];
};

export type JournalDownloadLinks = {
  normalImageLink?: Scalars['String'];
  smallImageLink?: Scalars['String'];
  audioLink?: Scalars['String'];
};

export type Journal = {
  id: Scalars['String'];
  memberId: Scalars['String'];
  text?: Scalars['String'];
  published: Scalars['Boolean'];
  imageFormat?: ImageFormat;
  audioFormat?: AudioFormat;
  journalDownloadLinks?: JournalDownloadLinks;
  createdAt: Scalars['DateTime'];
};

export enum ImageFormat {
  jpeg = 'jpeg',
  jpg = 'jpg',
  gif = 'gif',
  png = 'png'
}

export enum AudioFormat {
  m4a = 'm4a',
  mp3 = 'mp3'
}

export type PathwaySchema = {
  id: Scalars['String'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  version: Scalars['String'];
  whatAbout: Scalars['String'];
  whyImportant: Scalars['String'];
  whenPriority: Scalars['String'];
  discussionPrinciples: Scalars['String'];
  discussionText: Scalars['String'];
  relatedBarrierTypes: Array<BarrierTypeEnum>;
  vectorEmbedding: Array<Scalars['Float']>;
  actionItems: Array<AutoActionItem>;
  defaultPriority: Scalars['Float'];
  embeddingTextTemplate: Scalars['String'];
  embeddingModel: Scalars['String'];
  similarityThreshold: Scalars['Float'];
};

export type ChatCompletionsOptionsInput = {
  maxTokens?: Scalars['Float'];
  temperature?: Scalars['Float'];
  topP?: Scalars['Float'];
};

export type TraitValueInput = {
  value: Scalars['String'];
  potentialIssue: Scalars['Boolean'];
  associatedBarriers?: Array<Scalars['String']>;
};

export type TraitContextInput = {
  recordingId?: Scalars['String'];
  transcriptParagraphId?: Scalars['String'];
};

export type BarrierContextInput = {
  resourceId: Scalars['String'];
  type: ContextType;
  parentResourceId?: Scalars['String'];
  metadata?: Scalars['JSON'];
};

export type QuestionnaireContextInput = {
  entity: EntityName;
  id: Scalars['String'];
};

export type AnswerInput = {
  code: Scalars['String'];
  value: Scalars['String'];
};

export type ActionItemLinkInput = {
  type: ActionItemLinkType;
  value?: Scalars['String'];
};

export type AutoActionItemInput = {
  title: Scalars['String'];
  description?: Scalars['String'];
  category?: ActionItemCategory;
  section?: ActionItemSection;
  link?: ActionItemLinkInput;
  pathwayId?: Scalars['String'];
  deadline?: Scalars['DateTime'];
  priority?: ActionItemPriority;
};

export type ExternalIdInput = {
  type: ExternalIdType;
  value: Scalars['String'];
};

export type AddressInput = {
  street?: Scalars['String'];
  city?: Scalars['String'];
  state?: Scalars['String'];
};

export type DeceasedInput = {
  cause?: Scalars['String'];
  date?: Scalars['String'];
};

export type FailureInput = {
  message: Scalars['String'];
  stack?: Scalars['String'];
};

export type AllianceInventoryInput = {
  workTogether?: AgreementLevel;
  respectOneAnother?: AgreementLevel;
};

export type ResourceInput = {
  id: Scalars['String'];
  name?: Scalars['String'];
  type?: ResourceType;
};

export type DiagnosisInput = {
  id?: Scalars['String'];
  code?: Scalars['String'];
  description?: Scalars['String'];
  primaryType?: PrimaryDiagnosisType;
  secondaryType?: SecondaryDiagnosisType;
  clinicalStatus?: ClinicalStatus;
  severity?: DiagnosisSeverity;
  onsetStart?: Scalars['String'];
  onsetEnd?: Scalars['String'];
};

export type TreatmentRenderedInput = {
  id?: Scalars['String'];
  code?: Scalars['String'];
  startDate?: Scalars['String'];
  endDate?: Scalars['String'];
};

export type MedicationInput = {
  id?: Scalars['String'];
  status?: MedicationStatus;
  name?: Scalars['String'];
  rxNorm?: Scalars['String'];
  route?: Scalars['String'];
  dosage?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  specialInstructions?: Scalars['String'];
  memberVerballyProvided?: Scalars['Boolean'];
};

export type ExternalAppointmentInput = {
  id?: Scalars['String'];
  status?: ExternalAppointmentStatus;
  placeOfService?: PlaceOfService;
  drName?: Scalars['String'];
  clinic?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  type?: ExternalAppointmentType;
  specialInstructions?: Scalars['String'];
  fullAddress?: Scalars['String'];
  phone?: Scalars['String'];
  fax?: Scalars['String'];
};

export type ActivityInput = {
  general?: Array<Scalars['String']>;
  lifting?: Array<Scalars['String']>;
  showerOrBathing?: Array<Scalars['String']>;
  stairs?: Array<Scalars['String']>;
  driving?: Array<Scalars['String']>;
  sexualActivity?: Array<Scalars['String']>;
  work?: Array<Scalars['String']>;
};

export type WoundCareInput = {
  general?: Array<Scalars['String']>;
};

export type DietaryInput = {
  id?: Scalars['String'];
  category?: DietaryCategory;
  name?: DietaryName;
  date?: Scalars['String'];
  notes?: Scalars['String'];
};

export type AdmissionFileInput = {
  admissionFileType: AdmissionFileType;
  fileName: Scalars['String'];
  isVisibleToMember?: Scalars['Boolean'];
  uploadedBy: ClientCategory;
};

export type OrgConfigurationInput = {
  autoAcceptLisaChatResponse?: Scalars['Boolean'];
};

export type OrgCaseManagementProgramInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ChatCompletionContextInput = {
  memberId?: Scalars['String'];
  userId?: Scalars['String'];
  orgId?: Scalars['String'];
  recordingId?: Scalars['String'];
  transcriptParagraphId?: Scalars['String'];
  sendBirdChannelUrl?: Scalars['String'];
  barrierType?: BarrierTypeEnum;
  jobId?: Scalars['String'];
  traitSchemaId?: Scalars['String'];
  utterancesLength?: Scalars['Float'];
};

export type ChatCompletionTokensInput = {
  completion?: Scalars['Float'];
  prompt?: Scalars['Float'];
  total?: Scalars['Float'];
};

export type ChatCompletionInput = {
  memberId?: Scalars['String'];
  userId?: Scalars['String'];
  orgId?: Scalars['String'];
  recordingId?: Scalars['String'];
  transcriptParagraphId?: Scalars['String'];
  sendBirdChannelUrl?: Scalars['String'];
  barrierType?: BarrierTypeEnum;
  jobId?: Scalars['String'];
  traitSchemaId?: Scalars['String'];
  utterancesLength?: Scalars['Float'];
  id: Scalars['String'];
  prompt: Scalars['String'];
  promptKey?: PromptKey;
  promptVersion?: Scalars['String'];
  model: ModelName;
  modelVersion: Scalars['String'];
  promptText?: Scalars['String'];
  promptData?: Scalars['JSON'];
  failures?: Array<FailureInput>;
  processTime?: Scalars['Float'];
  isInvalid?: Scalars['Boolean'];
  options?: ChatCompletionsOptionsInput;
  response?: Scalars['String'];
  tokens?: ChatCompletionTokensInput;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Scalars['String'];
};

export type OptionInput = {
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type RangeElementInput = {
  value: Scalars['Float'];
  label: Scalars['String'];
};

export type RangeInput = {
  min: RangeElementInput;
  max: RangeElementInput;
};

export type SeverityLevelInput = {
  min: Scalars['Float'];
  max: Scalars['Float'];
  label: Scalars['String'];
};

export type ItemInput = {
  code: Scalars['String'];
  label: Scalars['String'];
  type: ItemType;
  variant?: ItemVariant;
  order: Scalars['Float'];
  required: Scalars['Boolean'];
  options?: Array<OptionInput>;
  range?: RangeInput;
  items?: Array<ItemInput>;
  alertCondition?: Array<AlertConditionInput>;
  excludeForScoring?: Scalars['Boolean'];
};

export type AlertConditionInput = {
  type: AlertConditionType;
  value: Scalars['String'];
};

export type AttributeInput = {
  category: Scalars['String'];
  key: Scalars['String'];
  description?: Scalars['String'];
  tags?: Array<AttributeTag>;
  values: Array<Scalars['String']>;
};

export type Query = {
  getJourneys: Array<Journey>;
  getJourney: Journey;
  getRecentJourney?: Journey;
  getMedication: Medication;
  getExternalAppointment: ExternalAppointment;
  getMemberAdmissions: Array<Admission>;
  getAdmissionsDietaryMatcher: DietaryMatcher;
  getAdmissionFileUploadLink: Scalars['String'];
  getAdmissionFileDownloadLink: Scalars['String'];
  getAdmissionOverview?: AdmissionOverview;
  getOrg?: Org;
  getOrgs: Array<Org>;
  getOrgConfiguration: OrgConfiguration;
  getOrgExternalRecordingsDefinition: OrgExternalRecordingsDefinition;
  getActivationCode: Scalars['String'];
  getMobileConfiguration: Configuration;
  getWebConfiguration: Configuration;
  getConfigValue: Scalars['JSON'];
  getConfigs: Array<Config>;
  getPrimaryCaregiver?: Caregiver;
  getCaregivers: Array<Caregiver>;
  getCaregiverAccessConsentContent: Scalars['String'];
  getCaregiverConsentFormDownloadUrl: Scalars['String'];
  getMember?: MemberWithOrg;
  getMembers: Array<MemberSummary>;
  getMembersAppointments: Array<AppointmentCompose>;
  /** @deprecated This query will be removed soon, use getAdmissionDocumentUploadLink instead */
  getMemberUploadDischargeDocumentsLinks: DischargeDocumentsLinks;
  /** @deprecated This query will be removed soon, use getAdmissionDocumentDownloadLink instead */
  getMemberDownloadDischargeDocumentsLinks: DischargeDocumentsLinks;
  getMemberCaseSummaryDownloadLink?: Scalars['String'];
  getAlerts: Array<Alert>;
  /** @deprecated use getClientConfig instead */
  getMemberConfig: MemberConfig;
  getCreatedByInfo: CreatedByInfo;
  getClientLatestReceivedNotifications: Array<ClientLatestReceivedNotification>;
  getClientConfig: ClientConfig;
  getClientCommunicationsInfo: ClientCommunications;
  getClientUnreadMessagesCount: ClientUnreadMessagesCount;
  getUserChatList: Array<Chat>;
  getMemberChatListForUser: Array<Chat>;
  /** @deprecated use getClientUnreadMessagesCount instead */
  getMemberUnreadMessagesCount: UnreadMessagesCount;
  /** @deprecated use getClientCommunicationInfo instead */
  getMemberCommunicationInfo: MemberCommunicationInfo;
  getChatMessages: Scalars['String'];
  getEncryptedChatResource: Scalars['String'];
  getCallToken: Scalars['String'];
  /** @deprecated use getClientCommunicationsInfo instead */
  getClientCommunicationInfo: ClientCommunication;
  getUser?: User;
  getUsers: Array<UserSummary>;
  getUserSlots: Slots;
  getUserSlotsByAppointmentId: Slots;
  getUserConfig: UserConfig;
  getHelpCenterToken: Scalars['String'];
  getUserStatistics: UserStatistics;
  getActiveQuestionnaires: Array<Questionnaire>;
  getQuestionnaire: Questionnaire;
  getMemberQuestionnaireResponses: Array<QuestionnaireResponse>;
  getQuestionnaireResponse?: QuestionnaireResponse;
  getTodos: Array<Todo>;
  getTodoDones: Array<TodoDone>;
  getTodoDonesByTodoIds: Array<TodoDone>;
  getArticles: Array<Article>;
  getArticleGroups: Array<Article>;
  getOrgsArticleGroups: Array<ArticleGroup>;
  getActionItems: Array<ActionItem>;
  getTemplate: Template;
  getActionItemsOfPrimaryUser: Array<ActionItemByPrimaryUser>;
  getMemberAppointments: Array<Appointment>;
  getRecordings: Array<Recording>;
  getUserUnidentifiedRecordings: Array<Recording>;
  getRecordingForCallPreparation?: Recording;
  getDetachedRecordings: Array<DetachedRecording>;
  getMemberUploadRecordingLink: Scalars['String'];
  getMemberMultipartUploadRecordingLink: MultipartUploadInfo;
  getMemberDownloadRecordingLink: Scalars['String'];
  getTranscript?: Transcript;
  getLiveTranscriptParagraphs: Array<TranscriptParagraph>;
  getRecordingInsights: Array<RecordingInsight>;
  getRecordingInsight?: RecordingInsight;
  getBarrierTypes: Array<BarrierType>;
  getMemberBarriers: Array<Barrier>;
  getMemberEngineActions: Array<EngineAction>;
  getRecordingEngineActions: Array<EngineAction>;
  getPrompts: Array<Prompt>;
  getPromptSimulationJobs: Array<PromptSimulationJob>;
  getPromptResponses: Array<ChatCompletion>;
  getCandidates: Array<Candidate>;
  getMemberEligibilityRelationship?: EligibilityRelationship;
  getAvailabilities: Array<AvailabilitySlot>;
  getDailyReports: DailyReportResults;
  getJournal: Journal;
  getJournals: Array<Journal>;
  getMemberUploadJournalImageLink: JournalUploadImageLink;
  getMemberUploadJournalAudioLink: JournalUploadAudioLink;
  getTraitSchemas: Array<TraitSchema>;
  getMemberTraits: Array<TraitSchemaForMemberResponse>;
  getRecordingTraits: Array<TraitSchemaForMemberResponse>;
  getAttributes: Array<Attribute>;
  getMemberAttributes: Array<MemberAttribute>;
  getMemberTimeline: Array<MemberTimeline>;
  getScoreRankingOrgInsights: Array<ScoreRankingOrgInsights>;
  getBarrierIdentificationOrgInsights: Array<BarrierIdentificationOrgInsights>;
  getOperationalStatisticsOrgInsights: Array<OperationalStatisticsOrgInsights>;
  getConversationNotes: Array<ConversationNote>;
};


export type QueryGetJourneysArgs = {
  memberId: Scalars['String'];
};


export type QueryGetJourneyArgs = {
  id: Scalars['String'];
};


export type QueryGetRecentJourneyArgs = {
  memberId: Scalars['String'];
};


export type QueryGetMedicationArgs = {
  id: Scalars['String'];
};


export type QueryGetExternalAppointmentArgs = {
  id: Scalars['String'];
};


export type QueryGetMemberAdmissionsArgs = {
  memberId?: Scalars['String'];
};


export type QueryGetAdmissionFileUploadLinkArgs = {
  admissionFileLinkParams: AdmissionFileLinkParams;
};


export type QueryGetAdmissionFileDownloadLinkArgs = {
  admissionFileLinkParams: AdmissionFileLinkParams;
};


export type QueryGetAdmissionOverviewArgs = {
  admissionId: Scalars['String'];
};


export type QueryGetOrgArgs = {
  id: Scalars['String'];
};


export type QueryGetOrgConfigurationArgs = {
  orgId: Scalars['String'];
};


export type QueryGetOrgExternalRecordingsDefinitionArgs = {
  id: Scalars['String'];
};


export type QueryGetActivationCodeArgs = {
  orgId: Scalars['String'];
};


export type QueryGetConfigValueArgs = {
  getConfigValueParams: GetConfigValueParams;
};


export type QueryGetPrimaryCaregiverArgs = {
  getPrimaryCaregiverParams: GetPrimaryCaregiverParams;
};


export type QueryGetCaregiversArgs = {
  memberId?: Scalars['String'];
};


export type QueryGetCaregiverAccessConsentContentArgs = {
  getCaregiverAccessConsentContentParams: GetCaregiverAccessConsentContentParams;
};


export type QueryGetCaregiverConsentFormDownloadUrlArgs = {
  getCaregiverConsentFormDownloadUrlParams: GetCaregiverConsentFormDownloadUrlParams;
};


export type QueryGetMemberArgs = {
  id?: Scalars['String'];
};


export type QueryGetMemberUploadDischargeDocumentsLinksArgs = {
  id: Scalars['String'];
};


export type QueryGetMemberDownloadDischargeDocumentsLinksArgs = {
  id?: Scalars['String'];
};


export type QueryGetMemberCaseSummaryDownloadLinkArgs = {
  memberId?: Scalars['String'];
};


export type QueryGetMemberConfigArgs = {
  id?: Scalars['String'];
};


export type QueryGetCreatedByInfoArgs = {
  id: Scalars['String'];
};


export type QueryGetClientLatestReceivedNotificationsArgs = {
  getClientLatestReceivedNotificationsParams: GetClientLatestReceivedNotificationsParams;
};


export type QueryGetClientConfigArgs = {
  getClientConfigParams: GetClientConfigParams;
};


export type QueryGetMemberChatListForUserArgs = {
  memberId?: Scalars['String'];
};


export type QueryGetChatMessagesArgs = {
  getChatMessagesParams: GetChatMessagesParams;
};


export type QueryGetEncryptedChatResourceArgs = {
  getEncryptedChatResourceParams: GetEncryptedChatResourceParams;
};


export type QueryGetUsersArgs = {
  orgIds?: Array<Scalars['String']>;
};


export type QueryGetUserSlotsArgs = {
  getSlotsParams: GetSlotsParams;
};


export type QueryGetUserSlotsByAppointmentIdArgs = {
  appointmentId: Scalars['String'];
};


export type QueryGetQuestionnaireArgs = {
  id: Scalars['String'];
};


export type QueryGetMemberQuestionnaireResponsesArgs = {
  memberId: Scalars['String'];
};


export type QueryGetQuestionnaireResponseArgs = {
  id: Scalars['String'];
};


export type QueryGetTodosArgs = {
  memberId?: Scalars['String'];
};


export type QueryGetTodoDonesArgs = {
  getTodoDonesParams: GetTodoDonesParams;
};


export type QueryGetTodoDonesByTodoIdsArgs = {
  getTodoDonesByTodoIdsParams: GetTodoDonesByTodoIdsParams;
};


export type QueryGetArticleGroupsArgs = {
  memberId?: Scalars['String'];
};


export type QueryGetActionItemsArgs = {
  memberId: Scalars['String'];
};


export type QueryGetTemplateArgs = {
  templateType: TemplateType;
};


export type QueryGetActionItemsOfPrimaryUserArgs = {
  getActionItemsParams: GetActionItemsParams;
};


export type QueryGetMemberAppointmentsArgs = {
  memberId?: Scalars['String'];
};


export type QueryGetRecordingsArgs = {
  memberId: Scalars['String'];
};


export type QueryGetRecordingForCallPreparationArgs = {
  memberId: Scalars['String'];
};


export type QueryGetMemberUploadRecordingLinkArgs = {
  id: Scalars['String'];
};


export type QueryGetMemberMultipartUploadRecordingLinkArgs = {
  multipartUploadRecordingLinkParams: MultipartUploadRecordingLinkParams;
};


export type QueryGetMemberDownloadRecordingLinkArgs = {
  id: Scalars['String'];
};


export type QueryGetTranscriptArgs = {
  recordingId: Scalars['String'];
};


export type QueryGetLiveTranscriptParagraphsArgs = {
  getLiveTranscriptParagraphsParams: GetLiveTranscriptParagraphsParams;
};


export type QueryGetRecordingInsightsArgs = {
  getRecordingInsightsParams: GetRecordingInsightsParams;
};


export type QueryGetRecordingInsightArgs = {
  recordingId: Scalars['String'];
};


export type QueryGetBarrierTypesArgs = {
  getBarrierTypeParams?: GetBarrierTypeParams;
};


export type QueryGetMemberBarriersArgs = {
  memberId: Scalars['String'];
};


export type QueryGetMemberEngineActionsArgs = {
  getMemberEngineActionParams: GetMemberEngineActionParams;
};


export type QueryGetRecordingEngineActionsArgs = {
  getRecordingEngineActionParams: GetRecordingEngineActionParams;
};


export type QueryGetPromptResponsesArgs = {
  getPromptResponsesParams: GetPromptResponsesParams;
};


export type QueryGetMemberEligibilityRelationshipArgs = {
  memberId: Scalars['String'];
};


export type QueryGetAvailabilitiesArgs = {
  orgIds?: Array<Scalars['String']>;
};


export type QueryGetDailyReportsArgs = {
  dailyReportQueryInput: DailyReportQueryInput;
};


export type QueryGetJournalArgs = {
  id: Scalars['String'];
};


export type QueryGetMemberUploadJournalImageLinkArgs = {
  getMemberUploadJournalImageLinkParams: GetMemberUploadJournalImageLinkParams;
};


export type QueryGetMemberUploadJournalAudioLinkArgs = {
  getMemberUploadJournalAudioLinkParams: GetMemberUploadJournalAudioLinkParams;
};


export type QueryGetMemberTraitsArgs = {
  getMemberTraitsParams: GetMemberTraitsParams;
};


export type QueryGetRecordingTraitsArgs = {
  getRecordingTraitsParams: GetRecordingTraitsParams;
};


export type QueryGetMemberAttributesArgs = {
  getMemberAttributesParams: GetMemberAttributesParams;
};


export type QueryGetMemberTimelineArgs = {
  getMemberTimelineParams: GetMemberTimelineParams;
};


export type QueryGetScoreRankingOrgInsightsArgs = {
  getScoreRankingOrgInsightsParams: GetScoreRankingOrgInsightsParams;
};


export type QueryGetBarrierIdentificationOrgInsightsArgs = {
  getBarrierIdentificationOrgInsightsParams: GetBarrierIdentificationOrgInsightsParams;
};


export type QueryGetOperationalStatisticsOrgInsightsArgs = {
  getOperationalStatisticsOrgInsightsParams: GetOperationalStatisticsOrgInsightsParams;
};


export type QueryGetConversationNotesArgs = {
  recordingId: Scalars['String'];
};

export type AdmissionFileLinkParams = {
  admissionId: Scalars['String'];
  memberId?: Scalars['String'];
  fileName: Scalars['String'];
  admissionFileType: AdmissionFileType;
};

export type GetConfigValueParams = {
  name: Scalars['String'];
};

export type GetPrimaryCaregiverParams = {
  memberId?: Scalars['String'];
};

export type GetCaregiverAccessConsentContentParams = {
  caregiverId: Scalars['String'];
};

export type GetCaregiverConsentFormDownloadUrlParams = {
  caregiverId: Scalars['String'];
};

export type GetClientLatestReceivedNotificationsParams = {
  clientId: Scalars['String'];
  notificationType: NotificationType;
};

export enum NotificationType {
  video = 'video',
  call = 'call',
  text = 'text',
  textSms = 'textSms',
  chat = 'chat',
  slack = 'slack',
  whatsapp = 'whatsapp',
  chatSpecialistAsBot = 'chatSpecialistAsBot'
}

export type GetClientConfigParams = {
  id?: Scalars['String'];
};

export type GetChatMessagesParams = {
  clientId: Scalars['String'];
};

export type GetEncryptedChatResourceParams = {
  url: Scalars['String'];
};

export type GetSlotsParams = {
  userId?: Scalars['String'];
  appointmentId?: Scalars['String'];
  notBefore?: Scalars['DateTime'];
  notAfter?: Scalars['DateTime'];
  allowEmptySlotsResponse?: Scalars['Boolean'];
  defaultSlotsCount?: Scalars['Float'];
  maxSlots?: Scalars['Float'];
};

export type GetTodoDonesParams = {
  memberId?: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type GetTodoDonesByTodoIdsParams = {
  memberId: Scalars['String'];
  todoIds: Array<Scalars['String']>;
};

export type GetActionItemsParams = {
  userId: Scalars['String'];
  pagination?: CursorBasedPagination;
  filter?: Filter;
};

export type CursorBasedPagination = {
  limit?: Scalars['Float'];
  sort?: Array<Scalars['JSON']>;
  cursor?: Scalars['JSON'];
  filter?: Scalars['JSON'];
};

export type Filter = {
  source?: Array<ActionItemSource>;
  priority?: Array<ActionItemPriority>;
  category?: Array<ActionItemCategory>;
  status?: Array<ActionItemStatus>;
  appointmentRelated?: Scalars['Boolean'];
  barrierRelated?: Scalars['Boolean'];
};

export type MultipartUploadRecordingLinkParams = {
  id: Scalars['String'];
  partNumber: Scalars['Float'];
  uploadId?: Scalars['String'];
};

export type GetLiveTranscriptParagraphsParams = {
  recordingId: Scalars['String'];
};

export type GetRecordingInsightsParams = {
  memberId?: Scalars['String'];
  userId?: Scalars['String'];
  orgIds?: Array<Scalars['String']>;
  internals?: Scalars['Boolean'];
};

export type GetBarrierTypeParams = {
  activeOnly?: Scalars['Boolean'];
};

export type GetMemberEngineActionParams = {
  memberId: Scalars['String'];
  status?: Array<EngineActionStatus>;
};

export type GetRecordingEngineActionParams = {
  recordingId: Scalars['String'];
  status?: Array<EngineActionStatus>;
};

export type GetPromptResponsesParams = {
  pagination?: CursorBasedPagination;
  filter?: PromptFilters;
};

export type PromptFilters = {
  id?: Scalars['String'];
  isInvalid?: Scalars['String'];
  memberId?: Scalars['String'];
  model?: Array<ModelName>;
  prompt?: Scalars['String'];
  promptKey?: Array<PromptKey>;
  promptVersion?: Array<Scalars['String']>;
  recordingId?: Scalars['String'];
};

export type DailyReportQueryInput = {
  memberId?: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  improved?: Scalars['Boolean'];
};

export type GetMemberUploadJournalImageLinkParams = {
  id: Scalars['String'];
  imageFormat: ImageFormat;
};

export type GetMemberUploadJournalAudioLinkParams = {
  id: Scalars['String'];
  audioFormat: AudioFormat;
};

export type GetMemberTraitsParams = {
  memberId: Scalars['String'];
};

export type GetRecordingTraitsParams = {
  recordingId: Scalars['String'];
};

export type GetMemberAttributesParams = {
  memberId: Scalars['String'];
};

export type GetMemberTimelineParams = {
  memberId: Scalars['String'];
  pagination: CursorBasedPagination;
};

export type GetScoreRankingOrgInsightsParams = {
  orgIds: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  observationType: ScoreRankingBasedObservationType;
};

export enum ScoreRankingBasedObservationType {
  burnout = 'burnout',
  empathy = 'empathy',
  toneAndStyle = 'toneAndStyle',
  collaboration = 'collaboration',
  contextualAwareness = 'contextualAwareness'
}

export type GetBarrierIdentificationOrgInsightsParams = {
  orgIds: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
};

export type GetOperationalStatisticsOrgInsightsParams = {
  orgIds: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
};

export type Mutation = {
  signout: Scalars['Boolean'];
  updateJourney: Journey;
  forceMemberEngaged?: Scalars['Boolean'];
  replaceMemberOrg?: Scalars['Boolean'];
  setGeneralNotes?: Scalars['Boolean'];
  changeMemberDna: Admission;
  setMedication: Medication;
  setExternalAppointment: ExternalAppointment;
  deleteMemberDna: Scalars['Boolean'];
  deleteAdmissionFile?: Scalars['Boolean'];
  setAccessModeToAdmissionFile?: Scalars['Boolean'];
  setAdmissionOverview?: AdmissionOverview;
  createOrg: Identifier;
  createSubOrg: Identifier;
  updateOrgConfiguration: OrgConfiguration;
  createMobileVersion?: Scalars['Boolean'];
  updateMinMobileVersion?: Scalars['Boolean'];
  updateFaultyMobileVersions?: Scalars['Boolean'];
  createConfig: Config;
  setConfigAttribute: Config;
  unsetConfigAttribute: Config;
  addCaregiver: Caregiver;
  deleteCaregiver: Scalars['Boolean'];
  updateCaregiver: Caregiver;
  grantCaregiverAppAccess: Caregiver;
  revokeCaregiverAppAccess: Caregiver;
  createMember: Identifier;
  updateMember: MemberWithOrg;
  setMemberCaseManagementProgram: Scalars['Boolean'];
  deleteMember: Scalars['Boolean'];
  replaceUserForMember?: Scalars['Boolean'];
  dismissAlert: Scalars['Boolean'];
  registerClientForNotifications?: Scalars['Boolean'];
  /** @deprecated use registerClientForNotifications instead */
  registerMemberForNotifications?: Scalars['Boolean'];
  notify?: Scalars['Boolean'];
  notifyContent?: Scalars['String'];
  cancelNotify?: Scalars['String'];
  updateJourneyStatus?: Scalars['Boolean'];
  addUserToChatChannel?: MemberCommunications;
  setMemberPrimaryExternalId: Scalars['Boolean'];
  createSupportChatChannel?: Communication;
  /** @deprecated use updateClientConfig instead */
  updateMemberConfig: Scalars['Boolean'];
  deleteMemberRecentContent?: Scalars['Boolean'];
  invokeChatSuggestedResponseParams: Scalars['Boolean'];
  updateClientConfig: Scalars['Boolean'];
  unsubscribe: Scalars['Boolean'];
  createUser: User;
  updateUser: User;
  setLastQueryAlert: Scalars['Boolean'];
  disableUser: Scalars['Boolean'];
  enableUser: Scalars['Boolean'];
  assignUserActor: Scalars['Boolean'];
  unassignUserActor: Scalars['Boolean'];
  createQuestionnaire: Questionnaire;
  submitQuestionnaireResponse: QuestionnaireResponse;
  sendAmeWebSurvey?: Scalars['Boolean'];
  createTodo: Identifier;
  updateTodo: Todo;
  endTodo: Scalars['Boolean'];
  approveTodo: Scalars['Boolean'];
  rejectTodo: Scalars['Boolean'];
  createTodoDone: Identifier;
  deleteTodoDone: Scalars['Boolean'];
  deleteArticle?: Scalars['Boolean'];
  setArticleGroups: Array<Article>;
  addArticleGroup?: Scalars['Boolean'];
  removeArticleGroup?: Scalars['Boolean'];
  setArticle: Article;
  createOrSetActionItem: ActionItem;
  deleteActionItem: Scalars['Boolean'];
  createTemplates: Identifiers;
  requestAppointment: Appointment;
  scheduleAppointment: Appointment;
  deleteAppointment: Scalars['Boolean'];
  endAppointment: Appointment;
  setAppointmentSummary: Appointment;
  createRecording: Identifier;
  createGeneralRecording: Identifier;
  addParticipant: Scalars['Boolean'];
  participantLeft: Scalars['Boolean'];
  updateParticipant: Scalars['Boolean'];
  endRecording: Scalars['Boolean'];
  updateRecordingReview?: Scalars['Boolean'];
  attachRecording: Recording;
  pairRecording: Scalars['Boolean'];
  completeMultipartUpload: Scalars['Boolean'];
  setTranscriptSpeaker?: Transcript;
  editRecordingTranscriptSummary: Scalars['Boolean'];
  updateRecordingTranscriptSummary: Scalars['Boolean'];
  recordingTranscriptSummaryCopied: Scalars['Boolean'];
  createCallCode: CallCode;
  createBarrierType: BarrierType;
  createMultipleBarrierTypes: Identifiers;
  createBarrier: Barrier;
  updateBarrier: Barrier;
  deleteBarrier: Scalars['Boolean'];
  createBarrierSuggestion: Scalars['Boolean'];
  resolveMemberEngineAction: EngineAction;
  moderateMemberEngineAction: EngineAction;
  addPromptRevision: Prompt;
  activatePromptRevision?: Scalars['Boolean'];
  simulatePrompt: PromptSimulationJob;
  resolveCandidate: Candidate;
  updateCandidate: Candidate;
  createAvailabilities: Identifiers;
  deleteAvailability: Scalars['Boolean'];
  setDailyReportCategories: DailyReport;
  createJournal: Identifier;
  updateJournalText: Journal;
  deleteJournal: Scalars['Boolean'];
  deleteJournalImage: Scalars['Boolean'];
  deleteJournalAudio: Scalars['Boolean'];
  publishJournal?: Scalars['Boolean'];
  createTraitSchema: TraitSchema;
  setTrait: Trait;
  deleteTrait?: Scalars['Boolean'];
  setAttributeOption: Attribute;
  publishAttributeOption?: Scalars['Boolean'];
  setMemberAttribute?: MemberAttribute;
  deleteMemberAttribute?: Scalars['Boolean'];
  createPathwaySchema: PathwaySchema;
  addCallPreparation: CallPreparation;
  updateCallPreparation: CallPreparation;
  deleteCallPreparation: Scalars['Boolean'];
  createConversationNote: ConversationNote;
  updateConversationNote: ConversationNote;
  deleteConversationNote: ConversationNote;
};


export type MutationUpdateJourneyArgs = {
  updateJourneyParams: UpdateJourneyParams;
};


export type MutationForceMemberEngagedArgs = {
  memberId: Scalars['String'];
};


export type MutationReplaceMemberOrgArgs = {
  replaceMemberOrgParams: ReplaceMemberOrgParams;
};


export type MutationSetGeneralNotesArgs = {
  setGeneralNotesParams: SetGeneralNotesParams;
};


export type MutationChangeMemberDnaArgs = {
  changeMemberDnaParams: ChangeMemberDnaParams;
};


export type MutationSetMedicationArgs = {
  setMedicationParams: SetMedicationParams;
};


export type MutationSetExternalAppointmentArgs = {
  setExternalAppointmentParams: SetExternalAppointmentParams;
};


export type MutationDeleteMemberDnaArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAdmissionFileArgs = {
  id: Scalars['String'];
};


export type MutationSetAccessModeToAdmissionFileArgs = {
  setAccessModeToAdmissionFileParams: SetAccessModeToAdmissionFileParams;
};


export type MutationSetAdmissionOverviewArgs = {
  setAdmissionOverviewParams: SetAdmissionOverviewParams;
};


export type MutationCreateOrgArgs = {
  createOrgParams: CreateOrgParams;
};


export type MutationCreateSubOrgArgs = {
  createSubOrgParams: CreateSubOrgParams;
};


export type MutationUpdateOrgConfigurationArgs = {
  updateOrgConfigurationParams: UpdateOrgConfigurationParams;
};


export type MutationCreateMobileVersionArgs = {
  createMobileVersionParams: CreateMobileVersionParams;
};


export type MutationUpdateMinMobileVersionArgs = {
  updateMinMobileVersionParams: UpdateMinMobileVersionParams;
};


export type MutationUpdateFaultyMobileVersionsArgs = {
  updateFaultyMobileVersionsParams: UpdateFaultyMobileVersionsParams;
};


export type MutationCreateConfigArgs = {
  createConfigParams: CreateConfigParams;
};


export type MutationSetConfigAttributeArgs = {
  setConfigAttributeParams: SetConfigAttributeParams;
};


export type MutationUnsetConfigAttributeArgs = {
  unsetConfigAttributeParams: UnsetConfigAttributeParams;
};


export type MutationAddCaregiverArgs = {
  addCaregiverParams: AddCaregiverParams;
};


export type MutationDeleteCaregiverArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCaregiverArgs = {
  updateCaregiverParams: UpdateCaregiverParams;
};


export type MutationGrantCaregiverAppAccessArgs = {
  grantCaregiverAppAccessParams: GrantCaregiverAppAccessParams;
};


export type MutationRevokeCaregiverAppAccessArgs = {
  revokeCaregiverAppAccessParams: RevokeCaregiverAppAccessParams;
};


export type MutationCreateMemberArgs = {
  createMemberParams: CreateMemberParams;
};


export type MutationUpdateMemberArgs = {
  updateMemberParams: UpdateMemberParams;
};


export type MutationSetMemberCaseManagementProgramArgs = {
  setMemberCaseManagementProgramParams: SetMemberCaseManagementProgramParams;
};


export type MutationDeleteMemberArgs = {
  deleteMemberParams: DeleteMemberParams;
};


export type MutationReplaceUserForMemberArgs = {
  replaceUserForMemberParams: ReplaceUserForMemberParams;
};


export type MutationDismissAlertArgs = {
  alertId: Scalars['String'];
};


export type MutationRegisterClientForNotificationsArgs = {
  registerForNotificationParams: RegisterForNotificationParams;
};


export type MutationRegisterMemberForNotificationsArgs = {
  registerForNotificationParams: RegisterForNotificationParams;
};


export type MutationNotifyArgs = {
  notifyParams: NotifyParams;
};


export type MutationNotifyContentArgs = {
  notifyContentParams: NotifyContentParams;
};


export type MutationCancelNotifyArgs = {
  cancelNotifyParams: CancelNotifyParams;
};


export type MutationUpdateJourneyStatusArgs = {
  updateJourneyStatusParams: UpdateJourneyStatusParams;
};


export type MutationAddUserToChatChannelArgs = {
  addUserToChatChannelParams: AddUserToChatChannelParams;
};


export type MutationSetMemberPrimaryExternalIdArgs = {
  setMemberPrimaryExternalIdParams: SetMemberPrimaryExternalIdParams;
};


export type MutationCreateSupportChatChannelArgs = {
  createSupportChatChannelParams: CreateSupportChatChannelParams;
};


export type MutationUpdateMemberConfigArgs = {
  updateMemberConfigParams: UpdateMemberConfigParams;
};


export type MutationDeleteMemberRecentContentArgs = {
  deleteMemberRecentContentParams: DeleteMemberRecentContentParams;
};


export type MutationInvokeChatSuggestedResponseParamsArgs = {
  invokeChatSuggestedResponseParams: InvokeChatSuggestedResponseParams;
};


export type MutationUpdateClientConfigArgs = {
  updateClientConfigParams: UpdateClientConfigParams;
};


export type MutationUnsubscribeArgs = {
  unsubscribeParams: UnsubscribeParams;
};


export type MutationCreateUserArgs = {
  createUserParams: CreateUserParams;
};


export type MutationUpdateUserArgs = {
  updateUserParams: UpdateUserParams;
};


export type MutationDisableUserArgs = {
  id: Scalars['String'];
};


export type MutationEnableUserArgs = {
  id: Scalars['String'];
};


export type MutationAssignUserActorArgs = {
  assignUserActorParams: AssignUserActorParams;
};


export type MutationUnassignUserActorArgs = {
  unassignUserActorParams: UnassignUserActorParams;
};


export type MutationCreateQuestionnaireArgs = {
  createQuestionnaireParams: CreateQuestionnaireParams;
};


export type MutationSubmitQuestionnaireResponseArgs = {
  submitQuestionnaireResponseParams: SubmitQuestionnaireResponseParams;
};


export type MutationSendAmeWebSurveyArgs = {
  sendAmeWebSurveyParams: SendAmeWebSurveyParams;
};


export type MutationCreateTodoArgs = {
  createTodoParams: CreateTodoParams;
};


export type MutationUpdateTodoArgs = {
  updateTodoParams: UpdateTodoParams;
};


export type MutationEndTodoArgs = {
  id: Scalars['String'];
};


export type MutationApproveTodoArgs = {
  id: Scalars['String'];
};


export type MutationRejectTodoArgs = {
  id: Scalars['String'];
};


export type MutationCreateTodoDoneArgs = {
  createTodoDoneParams: CreateTodoDoneParams;
};


export type MutationDeleteTodoDoneArgs = {
  id: Scalars['String'];
  memberId?: Scalars['String'];
};


export type MutationDeleteArticleArgs = {
  deleteArticleParams: DeleteArticleParams;
};


export type MutationSetArticleGroupsArgs = {
  setArticleGroupsParams: SetArticleGroupsParams;
};


export type MutationAddArticleGroupArgs = {
  articleGroupParams: ArticleGroupParams;
};


export type MutationRemoveArticleGroupArgs = {
  articleGroupParams: ArticleGroupParams;
};


export type MutationSetArticleArgs = {
  setArticleParams: SetArticleParams;
};


export type MutationCreateOrSetActionItemArgs = {
  createOrSetActionItemParams: CreateOrSetActionItemParams;
};


export type MutationDeleteActionItemArgs = {
  id: Scalars['String'];
};


export type MutationCreateTemplatesArgs = {
  createTemplatesParams: CreateTemplatesParams;
};


export type MutationRequestAppointmentArgs = {
  requestAppointmentParams: RequestAppointmentParams;
};


export type MutationScheduleAppointmentArgs = {
  scheduleAppointmentParams: ScheduleAppointmentParams;
};


export type MutationDeleteAppointmentArgs = {
  id: Scalars['String'];
};


export type MutationEndAppointmentArgs = {
  endAppointmentParams: EndAppointmentParams;
};


export type MutationSetAppointmentSummaryArgs = {
  setAppointmentSummaryParams: SetAppointmentSummaryParams;
};


export type MutationCreateRecordingArgs = {
  createRecordingParams: CreateRecordingParams;
};


export type MutationCreateGeneralRecordingArgs = {
  createGeneralRecordingParams: CreateGeneralRecordingParams;
};


export type MutationAddParticipantArgs = {
  addParticipantParams: AddParticipantParams;
};


export type MutationParticipantLeftArgs = {
  participantLeftParams: ParticipantLeftParams;
};


export type MutationUpdateParticipantArgs = {
  updateParticipantParams: UpdateParticipantParams;
};


export type MutationEndRecordingArgs = {
  id: Scalars['String'];
};


export type MutationUpdateRecordingReviewArgs = {
  updateRecordingReviewParams: UpdateRecordingReviewParams;
};


export type MutationAttachRecordingArgs = {
  attachRecordingParams: AttachRecordingParams;
};


export type MutationPairRecordingArgs = {
  pairRecordingParams: PairRecordingParams;
};


export type MutationCompleteMultipartUploadArgs = {
  completeMultipartUploadParams: CompleteMultipartUploadParams;
};


export type MutationSetTranscriptSpeakerArgs = {
  setTranscriptSpeakerParams: SetTranscriptSpeakerParams;
};


export type MutationEditRecordingTranscriptSummaryArgs = {
  editRecordingTranscriptSummaryParams: EditRecordingTranscriptSummaryParams;
};


export type MutationUpdateRecordingTranscriptSummaryArgs = {
  updateRecordingTranscriptSummaryParams: UpdateRecordingTranscriptSummaryParams;
};


export type MutationRecordingTranscriptSummaryCopiedArgs = {
  recordingId: Scalars['String'];
};


export type MutationCreateCallCodeArgs = {
  memberId: Scalars['String'];
};


export type MutationCreateBarrierTypeArgs = {
  createBarrierTypeParams: CreateBarrierTypeParams;
};


export type MutationCreateMultipleBarrierTypesArgs = {
  createMultipleBarrierTypesParams: CreateMultipleBarrierTypesParams;
};


export type MutationCreateBarrierArgs = {
  createBarrierParams: CreateBarrierParams;
};


export type MutationUpdateBarrierArgs = {
  updateBarrierParams: UpdateBarrierParams;
};


export type MutationDeleteBarrierArgs = {
  deleteBarrierParams: DeleteBarrierParams;
};


export type MutationCreateBarrierSuggestionArgs = {
  createBarrierParams: CreateBarrierParams;
};


export type MutationResolveMemberEngineActionArgs = {
  resolveMemberEngineActionParams: ResolveMemberEngineActionParams;
};


export type MutationModerateMemberEngineActionArgs = {
  moderateMemberEngineActionParams: ModerateMemberEngineActionParams;
};


export type MutationAddPromptRevisionArgs = {
  addPromptRevisionParams: AddPromptRevisionParams;
};


export type MutationActivatePromptRevisionArgs = {
  activatePromptRevisionParams: ActivatePromptRevisionParams;
};


export type MutationSimulatePromptArgs = {
  simulatePromptParams: SimulatePromptParams;
};


export type MutationResolveCandidateArgs = {
  resolveCandidateParams: ResolveCandidateParams;
};


export type MutationUpdateCandidateArgs = {
  updateCandidateParams: UpdateCandidateParams;
};


export type MutationCreateAvailabilitiesArgs = {
  availabilities: Array<AvailabilityInput>;
};


export type MutationDeleteAvailabilityArgs = {
  id: Scalars['String'];
};


export type MutationSetDailyReportCategoriesArgs = {
  dailyReportCategoriesInput: DailyReportCategoriesInput;
};


export type MutationUpdateJournalTextArgs = {
  updateJournalTextParams: UpdateJournalTextParams;
};


export type MutationDeleteJournalArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJournalImageArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJournalAudioArgs = {
  id: Scalars['String'];
};


export type MutationPublishJournalArgs = {
  id: Scalars['String'];
};


export type MutationCreateTraitSchemaArgs = {
  createTraitSchemaParams: CreateTraitSchemaParams;
};


export type MutationSetTraitArgs = {
  setTraitParams: SetTraitParams;
};


export type MutationDeleteTraitArgs = {
  deleteTraitParams: DeleteTraitParams;
};


export type MutationSetAttributeOptionArgs = {
  setAttributeOptionParams: SetAttributeOptionParams;
};


export type MutationPublishAttributeOptionArgs = {
  publishAttributeOptionParams: PublishAttributeOptionParams;
};


export type MutationSetMemberAttributeArgs = {
  setMemberAttributeParams: SetMemberAttributeParams;
};


export type MutationDeleteMemberAttributeArgs = {
  deleteMemberAttributeParams: DeleteMemberAttributeParams;
};


export type MutationCreatePathwaySchemaArgs = {
  createPathwaySchemaParams: CreatePathwaySchemaParams;
};


export type MutationAddCallPreparationArgs = {
  addCallPreparationParams: AddCallPreparationParams;
};


export type MutationUpdateCallPreparationArgs = {
  updateCallPreparationParams: UpdateCallPreparationParams;
};


export type MutationDeleteCallPreparationArgs = {
  id: Scalars['String'];
};


export type MutationCreateConversationNoteArgs = {
  createConversationNoteParams: CreateConversationNoteParams;
};


export type MutationUpdateConversationNoteArgs = {
  updateConversationNoteParams: UpdateConversationNoteParams;
};


export type MutationDeleteConversationNoteArgs = {
  deleteConversationNoteParams: DeleteConversationNoteParams;
};

export type UpdateJourneyParams = {
  memberId: Scalars['String'];
  readmissionRisk?: ReadmissionRisk;
};

export type ReplaceMemberOrgParams = {
  memberId: Scalars['String'];
  orgId: Scalars['String'];
};

export type SetGeneralNotesParams = {
  memberId: Scalars['String'];
  note: Scalars['String'];
};

export type ChangeMemberDnaParams = {
  memberId: Scalars['String'];
  id?: Scalars['String'];
  published?: Scalars['Boolean'];
  memberReportedInformation?: Scalars['Boolean'];
  admitDate?: Scalars['String'];
  admitType?: AdmitType;
  admitSource?: AdmitSource;
  dischargeDate?: Scalars['String'];
  dischargeTo?: DischargeTo;
  facility?: Scalars['String'];
  specialInstructions?: Scalars['String'];
  reasonForAdmission?: Scalars['String'];
  hospitalCourse?: Scalars['String'];
  admissionSummary?: Scalars['String'];
  progressSummary?: Scalars['String'];
  drg?: Scalars['String'];
  drgDesc?: Scalars['String'];
  nurseNotes?: Scalars['String'];
  transitionSummary?: Scalars['String'];
  warningSigns?: Array<WarningSigns>;
  warningSignsNotes?: Scalars['String'];
  activity?: ActivityInput;
  woundCare?: WoundCareInput;
  woundCareNotes?: Scalars['String'];
  diagnosis?: ChangeAdmissionDiagnosisParams;
  treatmentRendered?: ChangeAdmissionTreatmentRenderedParams;
  medication?: ChangeAdmissionMedicationParams;
  externalAppointment?: ChangeAdmissionExternalAppointmentParams;
  dietary?: ChangeAdmissionDietaryParams;
};

export type ChangeAdmissionDiagnosisParams = {
  id?: Scalars['String'];
  code?: Scalars['String'];
  description?: Scalars['String'];
  primaryType?: PrimaryDiagnosisType;
  secondaryType?: SecondaryDiagnosisType;
  clinicalStatus?: ClinicalStatus;
  severity?: DiagnosisSeverity;
  onsetStart?: Scalars['String'];
  onsetEnd?: Scalars['String'];
  changeType: ChangeType;
};

export enum ChangeType {
  create = 'create',
  update = 'update',
  delete = 'delete'
}

export type ChangeAdmissionTreatmentRenderedParams = {
  id?: Scalars['String'];
  code?: Scalars['String'];
  startDate?: Scalars['String'];
  endDate?: Scalars['String'];
  changeType: ChangeType;
};

export type ChangeAdmissionMedicationParams = {
  id?: Scalars['String'];
  status?: MedicationStatus;
  name?: Scalars['String'];
  rxNorm?: Scalars['String'];
  route?: Scalars['String'];
  dosage?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  specialInstructions?: Scalars['String'];
  memberVerballyProvided?: Scalars['Boolean'];
  changeType: ChangeType;
};

export type ChangeAdmissionExternalAppointmentParams = {
  id?: Scalars['String'];
  status?: ExternalAppointmentStatus;
  placeOfService?: PlaceOfService;
  drName?: Scalars['String'];
  clinic?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  type?: ExternalAppointmentType;
  specialInstructions?: Scalars['String'];
  fullAddress?: Scalars['String'];
  phone?: Scalars['String'];
  fax?: Scalars['String'];
  changeType: ChangeType;
};

export type ChangeAdmissionDietaryParams = {
  id?: Scalars['String'];
  category?: DietaryCategory;
  name?: DietaryName;
  date?: Scalars['String'];
  notes?: Scalars['String'];
  changeType: ChangeType;
};

export type SetMedicationParams = {
  id?: Scalars['String'];
  status?: MedicationStatus;
  name?: Scalars['String'];
  rxNorm?: Scalars['String'];
  route?: Scalars['String'];
  dosage?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  specialInstructions?: Scalars['String'];
  memberVerballyProvided?: Scalars['Boolean'];
  memberId: Scalars['String'];
};

export type SetExternalAppointmentParams = {
  id?: Scalars['String'];
  status?: ExternalAppointmentStatus;
  placeOfService?: PlaceOfService;
  drName?: Scalars['String'];
  clinic?: Scalars['String'];
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  type?: ExternalAppointmentType;
  specialInstructions?: Scalars['String'];
  fullAddress?: Scalars['String'];
  phone?: Scalars['String'];
  fax?: Scalars['String'];
  memberId: Scalars['String'];
};

export type SetAccessModeToAdmissionFileParams = {
  id: Scalars['String'];
  isVisibleToMember: Scalars['Boolean'];
};

export type SetAdmissionOverviewParams = {
  admissionId: Scalars['String'];
  overviewStatus?: OverviewStatus;
  text?: Scalars['String'];
};

export type CreateOrgParams = {
  name: Scalars['String'];
  code?: Scalars['String'];
  defaultTextSmsProvider?: Provider;
  activationCode?: Scalars['String'];
  type?: OrgType;
  trialDuration: Scalars['Int'];
  countryCode?: CountryCode;
  zipCode: Scalars['String'];
  logoUrl?: Scalars['String'];
  primaryExternalId?: ExternalIdType;
};

export type CreateSubOrgParams = {
  name: Scalars['String'];
  code?: Scalars['String'];
  defaultTextSmsProvider?: Provider;
  activationCode?: Scalars['String'];
  parent: Scalars['String'];
};

export type UpdateOrgConfigurationParams = {
  orgId: Scalars['String'];
  configuration: OrgConfigurationInput;
};

export type CreateMobileVersionParams = {
  version: Scalars['String'];
  platform: Platform;
  minVersion?: Scalars['Boolean'];
};

export type UpdateMinMobileVersionParams = {
  version: Scalars['String'];
  platform: Platform;
};

export type UpdateFaultyMobileVersionsParams = {
  versions: Array<Scalars['String']>;
  platform: Platform;
};

export type CreateConfigParams = {
  name: Scalars['String'];
  value: Scalars['JSON'];
  parent?: Scalars['String'];
};

export type SetConfigAttributeParams = {
  name: Scalars['String'];
  attribute: Scalars['String'];
  value: Scalars['JSON'];
};

export type UnsetConfigAttributeParams = {
  name: Scalars['String'];
  attribute: Scalars['String'];
};

export type AddCaregiverParams = {
  relationship: Relationship;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  dateOfBirth?: Scalars['String'];
  phone: Scalars['String'];
  email?: Scalars['String'];
  deviceId?: Scalars['String'];
  memberId?: Scalars['String'];
};

export type UpdateCaregiverParams = {
  relationship: Relationship;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  dateOfBirth?: Scalars['String'];
  phone: Scalars['String'];
  email?: Scalars['String'];
  deviceId?: Scalars['String'];
  id: Scalars['String'];
};

export type GrantCaregiverAppAccessParams = {
  caregiverId: Scalars['String'];
  signature?: Scalars['String'];
};

export type RevokeCaregiverAppAccessParams = {
  caregiverId: Scalars['String'];
};

export type CreateMemberParams = {
  sex?: Sex;
  email?: Scalars['String'];
  zipCode?: Scalars['String'];
  honorific?: Honorific;
  race?: Race;
  healthPlan?: Scalars['String'];
  preferredGenderPronoun?: Scalars['String'];
  maritalStatus?: MaritalStatus;
  height?: Scalars['Float'];
  weight?: Scalars['Float'];
  isInternal?: Scalars['Boolean'];
  phone: Scalars['String'];
  userId?: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  orgId: Scalars['String'];
  language?: Language;
};

export type UpdateMemberParams = {
  sex?: Sex;
  email?: Scalars['String'];
  zipCode?: Scalars['String'];
  honorific?: Honorific;
  race?: Race;
  healthPlan?: Scalars['String'];
  preferredGenderPronoun?: Scalars['String'];
  maritalStatus?: MaritalStatus;
  height?: Scalars['Float'];
  weight?: Scalars['Float'];
  isInternal?: Scalars['Boolean'];
  id: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  phoneSecondary?: Scalars['String'];
  phone?: Scalars['String'];
  address?: AddressInput;
  dateOfBirth?: Scalars['String'];
  deviceId?: Scalars['String'];
  deceased?: DeceasedInput;
};

export type SetMemberCaseManagementProgramParams = {
  memberId: Scalars['String'];
  programId: Scalars['String'];
  active: Scalars['Boolean'];
};

export type DeleteMemberParams = {
  id: Scalars['String'];
  hard?: Scalars['Boolean'];
};

export type ReplaceUserForMemberParams = {
  memberId: Scalars['String'];
  userId: Scalars['String'];
};

export type RegisterForNotificationParams = {
  memberId?: Scalars['String'];
  platform: Platform;
  token?: Scalars['String'];
  isPushNotificationsEnabled?: Scalars['Boolean'];
};

export type NotifyParams = {
  clientId: Scalars['String'];
  type: NotificationType;
  metadata: NotificationMetadata;
  userChatMessageParams?: UserChatMessageParams;
};

export type NotificationMetadata = {
  peerId?: Scalars['String'];
  content?: Scalars['String'];
  contentKey?: GeneralKey;
  when?: Scalars['DateTime'];
  appointmentId?: Scalars['String'];
};

export enum GeneralKey {
  disableMemberSms = 'disableMemberSms',
  addCaregiver = 'addCaregiver',
  addDailyLog = 'addDailyLog',
  ameSurveyRequest = 'ameSurveyRequest',
  attemptToApproachMember = 'attemptToApproachMember',
  newRegisteredMemberSpecialistChatMessage = 'newRegisteredMemberSpecialistChatMessage'
}

export type UserChatMessageParams = {
  id: Scalars['String'];
  message: Scalars['String'];
  type: SendbirdMessageType;
  data: Scalars['String'];
  customType?: SendbirdCustomType;
  fileName?: Scalars['String'];
  fileUrl?: Scalars['String'];
  fileType?: Scalars['String'];
  fileSize?: Scalars['Float'];
};

export enum SendbirdMessageType {
  FILE = 'FILE',
  MESG = 'MESG'
}

export enum SendbirdCustomType {
  article = 'article',
  questionnaire = 'questionnaire'
}

export type NotifyContentParams = {
  memberId: Scalars['String'];
  userId: Scalars['String'];
  contentKey: ExternalKey;
  metadata?: NotifyContentMetadata;
};

export enum ExternalKey {
  addCaregiverDetails = 'addCaregiverDetails',
  setCallPermissions = 'setCallPermissions',
  scheduleAppointment = 'scheduleAppointment',
  answerQuestionnaire = 'answerQuestionnaire'
}

export type NotifyContentMetadata = {
  questionnaireId?: Scalars['String'];
};

export type CancelNotifyParams = {
  memberId: Scalars['String'];
  type: CancelNotificationType;
  metadata: CancelNotificationMetadata;
};

export enum CancelNotificationType {
  cancelVideo = 'cancelVideo',
  cancelCall = 'cancelCall',
  cancelText = 'cancelText'
}

export type CancelNotificationMetadata = {
  peerId?: Scalars['String'];
};

export type UpdateJourneyStatusParams = {
  memberId: Scalars['String'];
  journeyStatus: JourneyStatus;
  journeyStatusReason: JourneyStatusReason;
  statusNote?: Scalars['String'];
};

export type AddUserToChatChannelParams = {
  memberId: Scalars['String'];
  channelHiddenStatus?: Scalars['String'];
};

export type SetMemberPrimaryExternalIdParams = {
  memberId: Scalars['String'];
  primaryExternalId: Scalars['String'];
};

export type CreateSupportChatChannelParams = {
  memberId: Scalars['String'];
  participants?: Array<Scalars['String']>;
};

export type UpdateMemberConfigParams = {
  platform?: Platform;
  isPushNotificationsEnabled?: Scalars['Boolean'];
  isTodoNotificationsEnabled?: Scalars['Boolean'];
  isApprovedTermsAndConditions?: Scalars['Boolean'];
  isPhoneCallEnabled?: Scalars['Boolean'];
  isCoachCommunicationEnabled?: Scalars['Boolean'];
  isOperationalCommunicationEnabled?: Scalars['Boolean'];
  isSmsEnabled?: Scalars['Boolean'];
  isDNC?: Scalars['Boolean'];
  isDisabled?: Scalars['Boolean'];
  language?: Language;
  timezone?: Scalars['String'];
  systemVersion?: Scalars['String'];
  brand?: Scalars['String'];
  codePushVersion?: Scalars['String'];
  appVersion?: Scalars['String'];
  buildVersion?: Scalars['String'];
  memberId?: Scalars['String'];
};

export type DeleteMemberRecentContentParams = {
  memberId: Scalars['String'];
};

export type InvokeChatSuggestedResponseParams = {
  memberId: Scalars['String'];
  messageId: Scalars['String'];
  sendBirdChannelUrl: Scalars['String'];
};

export type UpdateClientConfigParams = {
  clientId?: Scalars['String'];
  platform?: Platform;
  isPushNotificationsEnabled?: Scalars['Boolean'];
  isTodoNotificationsEnabled?: Scalars['Boolean'];
  isApprovedTermsAndConditions?: Scalars['Boolean'];
  isPhoneCallEnabled?: Scalars['Boolean'];
  isCoachCommunicationEnabled?: Scalars['Boolean'];
  isOperationalCommunicationEnabled?: Scalars['Boolean'];
  isSmsEnabled?: Scalars['Boolean'];
  isDNC?: Scalars['Boolean'];
  isDisabled?: Scalars['Boolean'];
  language?: Language;
  timezone?: Scalars['String'];
  systemVersion?: Scalars['String'];
  brand?: Scalars['String'];
  codePushVersion?: Scalars['String'];
  appVersion?: Scalars['String'];
  buildVersion?: Scalars['String'];
};

export type UnsubscribeParams = {
  subscriptionKey: Scalars['String'];
  socketId: Scalars['String'];
};

export type CreateUserParams = {
  roles?: Array<UserRole>;
  avatar?: Scalars['String'];
  description?: Scalars['String'];
  title?: Scalars['String'];
  maxMembers?: Scalars['Float'];
  languages?: Array<Language>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  orgs: Array<Scalars['String']>;
  timezone: Timezone;
  authId?: Scalars['String'];
};

export type UpdateUserParams = {
  roles?: Array<UserRole>;
  avatar?: Scalars['String'];
  description?: Scalars['String'];
  title?: Scalars['String'];
  maxMembers?: Scalars['Float'];
  languages?: Array<Language>;
  id: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  orgs?: Array<Scalars['String']>;
  timezone?: Timezone;
  email?: Scalars['String'];
  phone?: Scalars['String'];
};

export type AssignUserActorParams = {
  userId: Scalars['String'];
  assignedUserId: Scalars['String'];
  actorType: ActorType;
};

export enum ActorType {
  specialist = 'specialist',
  supervisor = 'supervisor'
}

export type UnassignUserActorParams = {
  userId: Scalars['String'];
  actorType: ActorType;
};

export type CreateQuestionnaireParams = {
  name: Scalars['String'];
  shortName: Scalars['String'];
  type: QuestionnaireType;
  items: Array<ItemInput>;
  isAssignableToMember: Scalars['Boolean'];
  severityLevels?: Array<SeverityLevelInput>;
  notificationScoreThreshold?: Scalars['Float'];
  notificationScoreThresholdReverse?: Scalars['Boolean'];
  scoreFactor?: Scalars['Float'];
  description: Scalars['String'];
  buildResult?: Scalars['Boolean'];
};

export type SubmitQuestionnaireResponseParams = {
  questionnaireId: Scalars['String'];
  memberId?: Scalars['String'];
  answers: Array<AnswerInput>;
  context?: QuestionnaireContextInput;
};

export type SendAmeWebSurveyParams = {
  memberId: Scalars['String'];
};

export type CreateTodoParams = {
  memberId?: Scalars['String'];
  text?: Scalars['String'];
  label?: Label;
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  resource?: ResourceInput;
};

export enum Label {
  Meds = 'Meds',
  Appointment = 'Appointment',
  Questionnaire = 'Questionnaire',
  Explore = 'Explore',
  Scanner = 'Scanner',
  Journal = 'Journal'
}

export type UpdateTodoParams = {
  memberId?: Scalars['String'];
  text?: Scalars['String'];
  label?: Label;
  cronExpressions?: Array<Scalars['String']>;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  resource?: ResourceInput;
  id: Scalars['String'];
  updatedTodoEnd?: Scalars['DateTime'];
};

export type CreateTodoDoneParams = {
  memberId?: Scalars['String'];
  todoId: Scalars['String'];
  done: Scalars['DateTime'];
};

export type DeleteArticleParams = {
  id: Scalars['String'];
};

export type SetArticleGroupsParams = {
  orgId: Scalars['String'];
  articleIds: Array<Scalars['String']>;
};

export type ArticleGroupParams = {
  articleId: Scalars['String'];
  orgIds: Array<Scalars['String']>;
};

export type SetArticleParams = {
  id?: Scalars['String'];
  isDefault?: Scalars['Boolean'];
  isPublished?: Scalars['Boolean'];
  title?: Scalars['String'];
  subTitle?: Scalars['String'];
  description?: Scalars['String'];
  bigImageUrl?: Scalars['String'];
  smallImageUrl?: Scalars['String'];
  videoUrl?: Scalars['String'];
  duration?: Scalars['Float'];
  isRecommended?: Scalars['Boolean'];
  type?: ArticleType;
  topic?: Array<ArticleTopic>;
  articleMarkdown?: Scalars['String'];
  content?: Scalars['String'];
  seriesId?: Scalars['String'];
  seriesIndex?: Scalars['Float'];
};

export type CreateOrSetActionItemParams = {
  id?: Scalars['String'];
  memberId: Scalars['String'];
  title?: Scalars['String'];
  status?: ActionItemStatus;
  deadline?: Scalars['DateTime'];
  description?: Scalars['String'];
  category?: ActionItemCategory;
  section?: ActionItemSection;
  source?: ActionItemSource;
  priority?: ActionItemPriority;
  rejectNote?: Scalars['String'];
  link?: ActionItemLinkInput;
  appointmentId?: Scalars['String'];
  pathwayId?: Scalars['String'];
};

export type CreateTemplatesParams = {
  barrierTemplates?: Array<BarrierTemplateInput>;
  appointmentTemplates?: Array<AppointmentTemplateInput>;
  eventTemplates?: Array<EventTemplateInput>;
};

export type BarrierTemplateInput = {
  actionItems: Array<AutoActionItemInput>;
  barrierType: BarrierTypeEnum;
};

export type AppointmentTemplateInput = {
  actionItems: Array<AutoActionItemInput>;
  appointmentType: AppointmentLabel;
};

export type EventTemplateInput = {
  actionItems: Array<AutoActionItemInput>;
  eventType: TriggerEventType;
};

export type RequestAppointmentParams = {
  memberId: Scalars['String'];
  caregiverId?: Scalars['String'];
  userId: Scalars['String'];
  notBefore: Scalars['DateTime'];
};

export type ScheduleAppointmentParams = {
  memberId?: Scalars['String'];
  caregiverId?: Scalars['String'];
  userId: Scalars['String'];
  method: AppointmentMethod;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  id?: Scalars['String'];
  label?: AppointmentLabel;
};

export type EndAppointmentParams = {
  id: Scalars['String'];
  noShow?: Scalars['Boolean'];
  noShowReason?: Scalars['String'];
  allianceInventory?: AllianceInventoryInput;
  manualInputParticipants?: Array<ClientCategory>;
};

export type SetAppointmentSummaryParams = {
  id: Scalars['String'];
  summary?: Scalars['String'];
};

export type CreateRecordingParams = {
  id: Scalars['String'];
  participantId: Scalars['String'];
  memberId?: Scalars['String'];
  appointmentId?: Scalars['String'];
  phone?: Scalars['String'];
  recordingType: RecordingType;
};

export type CreateGeneralRecordingParams = {
  id: Scalars['String'];
  participantId: Scalars['String'];
  candidateId?: Scalars['String'];
  phone: Scalars['String'];
};

export type AddParticipantParams = {
  recordingId: Scalars['String'];
  participantId: Scalars['String'];
  phone: Scalars['String'];
};

export type ParticipantLeftParams = {
  recordingId: Scalars['String'];
  participantId: Scalars['String'];
};

export type UpdateParticipantParams = {
  recordingId: Scalars['String'];
  participantId: Scalars['String'];
  answered?: Scalars['Boolean'];
  consent?: Scalars['Boolean'];
  noConsentReason?: Scalars['String'];
  identityVerification?: Scalars['Boolean'];
};

export type UpdateRecordingReviewParams = {
  recordingId: Scalars['String'];
  content?: Scalars['String'];
};

export type AttachRecordingParams = {
  recordingId: Scalars['String'];
  memberId: Scalars['String'];
  userId: Scalars['String'];
};

export type PairRecordingParams = {
  recordingId: Scalars['String'];
  memberId: Scalars['String'];
};

export type CompleteMultipartUploadParams = {
  id: Scalars['String'];
  uploadId: Scalars['String'];
};

export type SetTranscriptSpeakerParams = {
  recordingId: Scalars['String'];
  speakerId: Scalars['String'];
  name: Scalars['String'];
};

export type EditRecordingTranscriptSummaryParams = {
  recordingId: Scalars['String'];
  domainType?: DomainType;
  summary?: Scalars['String'];
  outreachSummary?: Scalars['String'];
  memberPrioritizedGoalAndProgress?: Scalars['String'];
  selfManagementPlan?: Scalars['String'];
  followUp?: Scalars['String'];
};

export type UpdateRecordingTranscriptSummaryParams = {
  recordingId: Scalars['String'];
  summary?: Scalars['String'];
  psychologicalAndEmotional?: Scalars['String'];
  medical?: Scalars['String'];
  socioEconomic?: Scalars['String'];
  selfManagementPlan?: Scalars['String'];
  memberPrioritizedGoalAndProgress?: Scalars['String'];
  followUp?: Scalars['String'];
  outreachSummary?: Scalars['String'];
};

export type CreateBarrierTypeParams = {
  type: BarrierTypeEnum;
  description: Scalars['String'];
  promptDescription: Scalars['String'];
  domain: BarrierDomain;
  engage: Scalars['String'];
  support: Scalars['String'];
  influence: Scalars['String'];
  sortingIndex: Scalars['Float'];
  probingQuestions: Array<Scalars['String']>;
};

export type CreateMultipleBarrierTypesParams = {
  barrierTypes: Array<CreateBarrierTypeParams>;
};

export type CreateBarrierParams = {
  memberId: Scalars['String'];
  type: Scalars['String'];
  notes?: Scalars['String'];
  context?: BarrierContextInput;
  identifiedBy?: IdentifiedBy;
  engineActionId?: Scalars['String'];
};

export type UpdateBarrierParams = {
  id: Scalars['String'];
  notes?: Scalars['String'];
  status?: BarrierStatus;
  identifiedBy?: IdentifiedBy;
};

export type DeleteBarrierParams = {
  id: Scalars['String'];
  deletionNote?: Scalars['String'];
};

export type ResolveMemberEngineActionParams = {
  engineActionId: Scalars['String'];
  command: EngineActionResolveCommand;
};

export enum EngineActionResolveCommand {
  accept = 'accept',
  reject = 'reject'
}

export type ModerateMemberEngineActionParams = {
  engineActionId: Scalars['String'];
  notes: Scalars['String'];
};

export type AddPromptRevisionParams = {
  key: PromptKey;
  template: Scalars['String'];
  version: Scalars['String'];
  abstractModel?: AbstractModel;
  releaseNotes?: Scalars['String'];
  options?: ChatCompletionsOptionsInput;
};

export type ActivatePromptRevisionParams = {
  id: Scalars['String'];
  configName?: Scalars['String'];
};

export type SimulatePromptParams = {
  promptId: Scalars['String'];
  abstractModel?: AbstractModel;
  chatCompletionsOptions?: ChatCompletionsOptionsInput;
  context: ChatCompletionContextInput;
};

export type ResolveCandidateParams = {
  id: Scalars['String'];
};

export type UpdateCandidateParams = {
  id: Scalars['String'];
  status?: CandidateStatus;
  phone?: Scalars['String'];
  note?: Scalars['String'];
  userId?: Scalars['String'];
};

export type AvailabilityInput = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type DailyReportCategoriesInput = {
  date: Scalars['String'];
  categories: Array<DailyReportCategories>;
  improved?: Scalars['Boolean'];
};

export type DailyReportCategories = {
  category: DailyReportCategoryTypes;
  rank: Scalars['Int'];
};

export type UpdateJournalTextParams = {
  id: Scalars['String'];
  text: Scalars['String'];
};

export type CreateTraitSchemaParams = {
  name: Scalars['String'];
  domain: TraitDomain;
  definition: Scalars['String'];
  definitionForPrompt: Scalars['String'];
  values: Array<TraitValueInput>;
  displayType: DisplayType;
  threshold: Scalars['Float'];
};

export type SetTraitParams = {
  memberId: Scalars['String'];
  schema: Scalars['String'];
  values: Array<Scalars['String']>;
  reasoning?: Scalars['String'];
  identifiedBy?: IdentifiedBy;
  context?: TraitContextInput;
};

export type DeleteTraitParams = {
  id: Scalars['String'];
};

export type SetAttributeOptionParams = {
  category: Scalars['String'];
  key: Scalars['String'];
  description?: Scalars['String'];
  values: Array<Scalars['String']>;
  renameKey?: Scalars['String'];
};

export type PublishAttributeOptionParams = {
  key: Scalars['String'];
};

export type SetMemberAttributeParams = {
  memberId: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  isSuggested?: Scalars['Boolean'];
  identifiedBy?: IdentifiedBy;
  notes?: Scalars['String'];
};

export type DeleteMemberAttributeParams = {
  memberId: Scalars['String'];
  key: Scalars['String'];
};

export type CreatePathwaySchemaParams = {
  name: Scalars['String'];
  version: Scalars['String'];
  whatAbout: Scalars['String'];
  whyImportant: Scalars['String'];
  whenPriority: Scalars['String'];
  discussionPrinciples: Scalars['String'];
  discussionText: Scalars['String'];
  relatedBarrierTypes: Array<BarrierTypeEnum>;
  actionItems: Array<AutoActionItemInput>;
  defaultPriority: Scalars['Float'];
  embeddingTextTemplate: Scalars['String'];
  similarityThreshold: Scalars['Float'];
};

export type AddCallPreparationParams = {
  memberId: Scalars['String'];
  recordingId: Scalars['String'];
  text: Scalars['String'];
};

export type UpdateCallPreparationParams = {
  id: Scalars['String'];
  text: Scalars['String'];
};

export type CreateConversationNoteParams = {
  note: Scalars['String'];
  recordingId: Scalars['String'];
  memberId: Scalars['String'];
};

export type UpdateConversationNoteParams = {
  id: Scalars['String'];
  note: Scalars['String'];
};

export type DeleteConversationNoteParams = {
  id: Scalars['String'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "TemplateUnion": [
      "AppointmentTemplate",
      "BarrierTemplate",
      "EventTemplate"
    ]
  }
};
      export default result;
    