import { FieldsType } from '@laguna/common/form/formTypes';
import { useMemoForm } from '@laguna/common/form/useMemoForm';
import { DialogProps } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { formsStore as formsStore } from '../form/formsStore';
import { getPromptForm } from './getPromptForm';
import { HookModalDataProps } from './modalStore';
import { useModal } from './useModal';
import { ValidationMode } from 'react-hook-form';

interface PromptOption<T> {
  formId?: string;
  showSkipButton?: boolean;
  validateForm?: (data: Partial<T>) => boolean;
  css?: any;
  dialogProps?: Partial<DialogProps>;
  useFormAsDependency?: boolean;
  omitFalsyResponseFields?: boolean;
  forceRequired?: boolean;
  useGrid?: boolean;
  validationMode?: keyof ValidationMode;
}

export const usePromptForm = <T,>(fields: FieldsType[], options: PromptOption<T> = {}) => {
  const { formId, css, dialogProps, forceRequired } = options;
  const [id] = useState(formId || `prompt-${v4()}`);
  const initialData: HookModalDataProps = useMemo(() => ({ id, title: '', dialogProps }), [dialogProps]);
  useMemoForm<T>({ formId: id, fields });
  useModal(initialData);

  const formProps = {
    isLoading: false,
    className: 'prompt-form',
    css,
    onChange: forceRequired ? () => formsStore.getFormActions(id).clearErrors() : undefined,
  };

  const prompt = (title: ReactNode, defaultValues: Partial<T> = {}) =>
    getPromptForm<T>(fields, title, { ...formProps, ...defaultValues }, options);

  return { prompt };
};
