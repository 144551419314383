import {
  Avatar,
  Checkbox,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Button,
  ButtonProps,
  Stack,
  ListItem,
  ListItemSecondaryAction,
} from '@mui/material';
import { noop } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { groupBy as groupByLodash } from 'lodash';
import { capitalizeCamelCase } from '@laguna/common/utils/general';
import { css } from '@mui/system';
import i18next from 'i18next';

const dividerCss = (theme: any) => css`
  margin-top: ${theme.spacing(2)};
`;
const groupTitleCss = (theme: any) => css`
  padding-left: ${theme.spacing(2)};
  border-radius: ${theme.spacing(1)};
  background-color: ${theme.palette.background.secondary};
  width: 100%;
`;

const buttonCss = (theme: any) => css`
  :hover {
    background-color: ${theme.palette.background.secondary};
  }
`;
interface ItemsListProps<T> {
  items: T[];
  primaryText: keyof T | ((item: T) => ReactNode);
  secondaryText?: keyof T | ((item: T) => ReactNode);
  avatarProperty?: keyof T | ((item: T) => string);
  checkedProperty?: keyof T | ((item: T) => boolean);
  buttonProperty?: (item: T) => ButtonProps | undefined;
  onClick?: (item: T) => void;
  isDisabled?: (item: T) => boolean;
  itemTestId?: string;
  additionalTopElements?: React.ReactNode;
  groupBy?: string;
  emptyListNode?: JSX.Element;
  secondaryAction?: (item: T) => ReactNode;
}

const getFalse = () => false;

const DefaultEmpty = () => (
  <ListItem>
    <ListItemText>{i18next.t('common:emptyList')}</ListItemText>
  </ListItem>
);

export const ItemsList = <T extends { id: string }>({
  items,
  primaryText,
  secondaryText,
  avatarProperty,
  checkedProperty,
  itemTestId = 'generic-list-item',
  additionalTopElements,
  groupBy,
  buttonProperty,
  onClick = noop,
  emptyListNode,
  isDisabled = getFalse,
  secondaryAction,
}: ItemsListProps<T>) => {
  const getVal = (item: T, propGetter: keyof T | ((item: T) => any)) =>
    typeof propGetter === 'function' ? propGetter(item) : (item[propGetter] as string);

  const { data, isEmpty } = useMemo(() => {
    const getData = () => {
      if (!groupBy) return [{ key: '', values: items }];
      const groupsObject = groupByLodash(items, groupBy);
      return Object.keys(groupsObject).map((group) => ({ key: group, values: groupsObject[group] }));
    };
    return { data: getData(), isEmpty: items.length === 0 };
  }, [groupBy, items]);

  if (isEmpty) {
    return emptyListNode || <DefaultEmpty />;
  }

  return (
    <List data-testid='generic-list'>
      {additionalTopElements}
      {data.map((group, index) => (
        <List key={group.key}>
          {group.key && (
            <div css={groupTitleCss}>
              <Typography variant='overline'>{capitalizeCamelCase(group.key)}</Typography>
            </div>
          )}
          {group.values.map((item) => {
            const buttonProps = buttonProperty?.(item);

            return (
              <Stack direction='row' key={item.id}>
                <ListItemButton
                  disabled={isDisabled(item)}
                  data-testid={itemTestId}
                  onClick={() => onClick(item)}
                  css={buttonCss}>
                  {checkedProperty && (
                    <ListItemIcon>
                      <Checkbox edge='start' checked={!!getVal(item, checkedProperty)} disableRipple />
                    </ListItemIcon>
                  )}
                  {avatarProperty && (
                    <ListItemAvatar>
                      <Avatar alt={getVal(item, primaryText)} src={getVal(item, avatarProperty)} />
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={getVal(item, primaryText)}
                    secondary={secondaryText && getVal(item, secondaryText)}
                  />
                  {secondaryAction && <ListItemSecondaryAction>{secondaryAction(item)}</ListItemSecondaryAction>}
                  {buttonProps?.title && <Button {...buttonProps}>{buttonProps.title}</Button>}
                </ListItemButton>
              </Stack>
            );
          })}
          {index !== data.length - 1 && <Divider css={dividerCss} />}
        </List>
      ))}
    </List>
  );
};
