import { putFileInStorage } from '@laguna/aws';
import { Stack } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { noop } from 'lodash';
import React, { useRef } from 'react';
import { css, CSSObject } from '@mui/system';

const BACK_OFFICE_BUCKET_URL = 'https://laguna-backoffice.s3.amazonaws.com/public/';

interface RichTextEditorProps {
  editorRef?: (ref: Editor) => void;
  initialValue?: string;
  uploadFolder?: string;
  pageWidth?: number;
  css?: CSSObject;
}

const plugins = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'media',
  'table',
  'code',
  'help',
  'wordcount',
];

const toolbar = `undo redo | formatselect | image |
blocks bold italic forecolor backcolor | alignleft aligncenter
alignright alignjustify | bullist numlist outdent indent |
removeformat | help`;

const createFilePickerCallback = (_editorRef: React.MutableRefObject<Editor | null>) => (cb: any) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');

  input.onchange = function () {
    const file = (this as any).files[0];
    const reader = new FileReader();
    reader.onload = function () {
      const id = 'blobid' + new Date().getTime();

      if (!_editorRef.current?.editor || !reader.result) return;

      const blobCache = _editorRef.current?.editor?.editorUpload.blobCache;

      const base64 = (reader.result as string).split(',')[1];
      const blobInfo = blobCache.create(id, file, base64);
      blobCache.add(blobInfo);

      cb(blobInfo.blobUri(), { title: file.name });
    };
    reader.readAsDataURL(file);
  };

  input.click();
};

const createUploadHandler =
  (uploadFolder: string) => async (blobInfo: { blob: () => Blob }, progress: (progress: number) => void) => {
    const blob = blobInfo.blob();
    const result = await putFileInStorage(uploadFolder, blob, { onProgress: progress });

    return BACK_OFFICE_BUCKET_URL + (result as any).key;
  };

const pageWidthStyle = (width = 400) => css`
  .tox-edit-area {
    justify-content: center;
    background-color: white;
    iframe {
      width: ${width}px !important;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  editorRef = noop,
  initialValue,
  uploadFolder = 'images',
  pageWidth,
  css,
}) => {
  const _editorRef = useRef<Editor | null>(null);
  const setEditor = (editor: Editor) => {
    _editorRef.current = editor;
    editorRef(editor);
  };

  return (
    <Stack css={pageWidthStyle(pageWidth)}>
      <Editor
        ref={setEditor}
        initialValue={initialValue}
        apiKey={process.env.NX_TINY_API_KEY}
        init={{
          plugins,
          toolbar,
          image_title: true,
          file_picker_types: 'image',
          file_picker_callback: createFilePickerCallback(_editorRef),
          automatic_uploads: false,
          images_upload_handler: createUploadHandler(uploadFolder),
        }}
      />
    </Stack>
  );
};
