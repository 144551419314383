export const toDoLabelGradientColors: { [key: string]: string[] } = {
  Meds: ['#0093FE', '#8D47FF'],
  Appointment: ['#1D73F1', '#1AC9FC'],
  Questionnaire: ['#573CFF', '#E941CE'],
  Explore: ['#6A34CB', '#4353E3'],
  General: ['#43C6AC', '#0074B6'],
  Journal: ['#FF8AB1', '#FFA98D'],
};

export const MAX_TIMES_A_DAY = 8;
export const CRON_WEEK_DAYS_ARRAY = [0, 1, 2, 3, 4, 5, 6];
