import { ArticleFragment, useDeleteArticleMutation, useGetArticlesQuery } from '@laguna/api/backoffice';
import { useAsyncMutation } from '@laguna/api/utils';
import { ButtonsProps } from '@laguna/common/form/formTypes';
import { useConfirm } from '@laguna/common/modals';
import { OpenInNew } from '@mui/icons-material';
import { Button, IconButton, Link } from '@mui/material';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../Routing';
import { PageTitle } from '../../../components/PageTitle';
import { useMultipleOrgArticleGroups } from '../../../hooks/useMultipleOrgArticleGroups';
import { ArticlePreview } from './ArticlePreview';
import { getArticleSiteUrl } from './consts';

interface ArticleContentHeaderProps {
  isLoading: boolean;
  Buttons: ({ isLoading, onCancel }: ButtonsProps) => JSX.Element;
  onPublish: VoidFunction;
  onUnPublish: VoidFunction;
  article: ArticleFragment;
}

export const ArticleContentHeader: React.FC<ArticleContentHeaderProps> = ({
  isLoading,
  Buttons,
  onPublish,
  onUnPublish,
  article,
}) => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();

  const { data } = useMultipleOrgArticleGroups();

  const { mutateAsync } = useAsyncMutation(useDeleteArticleMutation, {
    messages: { successMsg: i18next.t('articles:deleteSuccess'), errorMsg: i18next.t('articles:deleteError') },
    invalidationQueryKey: useGetArticlesQuery.getKey(),
  });

  const goToArticles = () => navigate(`/${PATHS.ARTICLES}`);

  const onDelete = async () => {
    const confirmed = await confirm(i18next.t('articles:deleteConfirm'));
    if (confirmed) {
      const result = await mutateAsync({ deleteArticleParams: { id: article.id } });

      if (result.data) {
        goToArticles();
      }
    }
  };

  const isLinkedToOrg = useMemo(() => {
    return data.some((env) =>
      env.articleGroups.some((org) => org.articles.some((orgArticle) => orgArticle.id === article.id))
    );
  }, [data, article]);

  return (
    <PageTitle
      title={i18next.t('articles:edit', { name: article.title })}
      onBack={goToArticles}
      actionButtons={
        <>
          <Buttons isLoading={isLoading} submitLabel={i18next.t('common:save')} />
          <ArticlePreview disabled={isLoading} />
          {article.isPublished ? (
            <Button variant='contained' color='error' onClick={onUnPublish} disabled={isLoading}>
              {i18next.t('articles:unPublish')}
            </Button>
          ) : (
            <Button variant='contained' onClick={onPublish} disabled={isLoading}>
              {i18next.t('common:publish')}
            </Button>
          )}
          {!article.isPublished && (
            <Button variant='contained' color='error' onClick={onDelete} disabled={isLinkedToOrg}>
              {i18next.t('common:delete')}
            </Button>
          )}
          {article.isPublished && (
            <Link data-testid='openExternalArticleSite' target='_blank' href={getArticleSiteUrl(article.id)}>
              <IconButton disabled={isLinkedToOrg}>
                <OpenInNew />
              </IconButton>
            </Link>
          )}
        </>
      }
    />
  );
};
