import { ArrowBack } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { css } from '@mui/system';

const titleWrapper = (theme: any) => css`
  flex-direction: row;
  justify-content: space-between;
  padding: ${theme.spacing(2)} 0;
  border-bottom: 2px solid ${theme.palette.divider};
`;
interface SectionTitleProps {
  title: React.ReactNode;
  onBack?: VoidFunction;
  actionButtons?: React.ReactNode;
}

export const PageTitle: React.FC<SectionTitleProps> = ({ title, onBack, actionButtons }) => {
  return (
    <Stack css={titleWrapper}>
      <Typography variant='h5' component={Stack} direction='row' gap={1} alignItems='center'>
        {onBack && (
          <IconButton onClick={onBack}>
            <ArrowBack />
          </IconButton>
        )}
        {title}
      </Typography>
      {actionButtons && (
        <Stack direction='row' gap={2}>
          {actionButtons}
        </Stack>
      )}
    </Stack>
  );
};
