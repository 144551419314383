import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { downloadData, remove, uploadData } from 'aws-amplify/storage';
import { Environments } from '../consts';

export interface Endpoint {
  name: string;
  URL: string;
}

enum VARS {
  'NX_GQL_ENDPOINT' = 'NX_GQL_ENDPOINT',
  'NX_REACT_APP_ENV' = 'NX_REACT_APP_ENV',
  'NX_LOCAL_TOKEN' = 'NX_LOCAL_TOKEN',
  'NX_PUSHER_API_KEY' = 'NX_PUSHER_API_KEY',
  'NX_REACT_APP_CHAT_APP_ID' = 'NX_REACT_APP_CHAT_APP_ID',
  'NX_REACT_APP_SPLIT_KEY' = 'NX_REACT_APP_SPLIT_KEY',
  'SUPPORT_NUMBER' = 'SUPPORT_NUMBER',
  'NX_REACT_APP_PDF_ENDPOINT' = 'NX_REACT_APP_PDF_ENDPOINT',
  'NX_VOX_APP' = 'NX_VOX_APP',
  'NX_AWS_USER_POOLS_ID' = 'NX_AWS_USER_POOLS_ID',
  'NX_AWS_USER_POOLS_WEB_CLIENT_ID' = 'NX_AWS_USER_POOLS_WEB_CLIENT_ID',
  'NX_AWS_USER_FILES_S3_BUCKET' = 'NX_AWS_USER_FILES_S3_BUCKET',
  'NX_AWS_IDENTITY_POOL_ID' = 'NX_AWS_IDENTITY_POOL_ID',
}

const urlAttribute = 'custom:urls';
class Environment {
  private _env: any = process.env;

  updateEnvWithConfig = (data: any) => {
    const { gqlEndpoint, sendbirdAppId, pusherKey, supportNumber } = data ?? {};

    if (gqlEndpoint !== this._env[VARS.NX_GQL_ENDPOINT]) {
      // eslint-disable-next-line no-console
      console.error('endpoint mismatch', { gqlEndpoint, env: this._env[VARS.NX_GQL_ENDPOINT] });
    }
    if (sendbirdAppId) {
      this._env[VARS.NX_REACT_APP_CHAT_APP_ID] = sendbirdAppId;
    }
    if (pusherKey) {
      this._env[VARS.NX_PUSHER_API_KEY] = pusherKey;
    }
    if (supportNumber) {
      this._env[VARS.SUPPORT_NUMBER] = supportNumber;
    }
  };

  setEnv = (env?: any) => {
    this._env = env;
  };

  get NX_GQL_ENDPOINT() {
    return this._env[VARS.NX_GQL_ENDPOINT];
  }

  get SUPPORT_NUMBER() {
    const phone = this._env[VARS.SUPPORT_NUMBER];
    return phone ? '+' + phone : null;
  }

  get NX_REACT_APP_ENV() {
    return this._env[VARS.NX_REACT_APP_ENV];
  }

  get NX_LOCAL_TOKEN() {
    return this._env[VARS.NX_LOCAL_TOKEN];
  }

  get NX_PUSHER_API_KEY() {
    return this._env[VARS.NX_PUSHER_API_KEY];
  }

  get NX_REACT_APP_CHAT_APP_ID() {
    return this._env[VARS.NX_REACT_APP_CHAT_APP_ID];
  }

  get NX_REACT_APP_SPLIT_KEY() {
    return this._env[VARS.NX_REACT_APP_SPLIT_KEY] as string;
  }

  get NX_REACT_APP_PDF_ENDPOINT() {
    return this._env[VARS.NX_REACT_APP_PDF_ENDPOINT] as string;
  }
  get NX_VOX_APP() {
    return this._env[VARS.NX_VOX_APP] as string;
  }

  get NX_AWS_USER_POOLS_ID() {
    return this._env[VARS.NX_AWS_USER_POOLS_ID] as string;
  }

  get NX_AWS_USER_POOLS_WEB_CLIENT_ID() {
    return this._env[VARS.NX_AWS_USER_POOLS_WEB_CLIENT_ID] as string;
  }

  get NX_AWS_USER_FILES_S3_BUCKET() {
    return this._env[VARS.NX_AWS_USER_FILES_S3_BUCKET] as string;
  }
  get NX_AWS_IDENTITY_POOL_ID() {
    return this._env[VARS.NX_AWS_IDENTITY_POOL_ID] as string;
  }
}

export const ENV = new Environment();

export const getGraphqlHeaders = async () => {
  if (ENV.NX_REACT_APP_ENV === Environments.localhost) {
    return { Authorization: ENV[VARS.NX_LOCAL_TOKEN] ?? '' };
  }
  const currentSession = await fetchAuthSession();
  const Authorization = currentSession.tokens?.accessToken;
  return { Authorization };
};

export const initAWS = (overrideEnv?: object) => {
  if (overrideEnv) {
    ENV.setEnv(overrideEnv);
  }
  Amplify.configure({
    Storage: {
      S3: {
        region: 'us-east-1',
        bucket: ENV.NX_AWS_USER_FILES_S3_BUCKET,
      },
    },
    Auth: {
      Cognito: {
        userPoolId: ENV.NX_AWS_USER_POOLS_ID,
        userPoolClientId: ENV.NX_AWS_USER_POOLS_WEB_CLIENT_ID,
        identityPoolId: ENV.NX_AWS_IDENTITY_POOL_ID,
      },
    },
    API: {
      GraphQL: {
        endpoint: ENV.NX_GQL_ENDPOINT + '/graphql',
        defaultAuthMode: 'userPool',
      },
    },
  });
};

export const updateEnvWithConfig = (data: any) => {
  ENV.updateEnvWithConfig(data);
};

interface PutFileOptions {
  onProgress?: (completedRatio: number) => void;
  explicitFileName?: string;
}

export const putFileInStorage = async (
  uploadFolder: string,
  blob: Blob,
  { onProgress, explicitFileName }: PutFileOptions = {}
) =>
  uploadData({
    path: `${uploadFolder}/${explicitFileName || (blob as File).name}`,
    data: blob,
    options: {
      contentType: blob.type,
      onProgress: ({ loaded, total }: any) => {
        if (onProgress) {
          onProgress(loaded / total);
        }
      },
    },
  });

export const deleteFileFromStorage = async (fileName: string) => remove({ path: fileName });

export const getFileFromStorage = async (fileName: string) => {
  const result = await downloadData({ path: fileName });
  return (await result.result).body.text();
};
