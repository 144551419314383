import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Breadcrumbs(theme: Theme) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {},
      },
    },
  };
}
