import { BasicTooltip } from '@laguna/common/BasicTooltip';
import { capitalizeCamelCase } from '@laguna/common/utils/general';
import { logger } from '@laguna/logger';
import {
  BuildColumnProps,
  BuildColumnsMap,
  ColumnType,
  DEFAULT_COLUMN_WIDTH,
  DataTableColumn,
  FCC,
} from '@laguna/types';
import { Theme, Typography, TypographyProps } from '@mui/material';
import { css } from '@mui/system';
import { CellProps, Column, Row } from 'react-table';
import { BoolCell } from './CustomCell/BoolCell';
import { ChipCell } from './CustomCell/ChipCell';
import { DateTimeCell } from './CustomCell/DateTimeCell';
import { LinkCell } from './CustomCell/LinkCell';
import { ProfileCell } from './CustomCell/ProfileCell';
import { ProgressCell } from './CustomCell/ProgressCell';

const cellWrapper = (theme: Theme) =>
  css`
    display: flex;
    align-items: center;
    background-color: ${theme.palette.background.paper};
    height: ${theme.spacing(6)};
    padding-left: ${theme.spacing(3)};
  `;

const centerCell = css`
  justify-content: center;
  width: fit-content;
  padding-left: 0;
`;

const cellTextWrapper = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const getCellWidth = (cellsLength: number, width: number, tableWidthInVw = 90) => {
  const defaultWidth = Math.round((tableWidthInVw - 5) / cellsLength);
  const oneVwInPx = window.innerWidth / 100;
  const defaultWidthInPx = oneVwInPx * defaultWidth;
  return Math.max(defaultWidthInPx, width);
};

export const buildColumn = (name: string, rawOptions?: BuildColumnProps): DataTableColumn => {
  const {
    label = '',
    display = true,
    preventFiltering,
    transformValue,
    width,
    sticky,
    renderItem,
    ...rest
  } = rawOptions || {};

  return {
    ...rest,
    label,
    name,
    width: width || DEFAULT_COLUMN_WIDTH.md,
    preventFiltering: preventFiltering ?? !display,
    display,
    transformValue,
    renderItem,
    sticky,
  };
};

export const CellTypography: FCC<TypographyProps> = ({ children, ...rest }) => {
  return (
    <Typography css={cellTextWrapper} {...rest}>
      {children}
    </Typography>
  );
};

const CellWrapper: FCC<{ title: string; center: boolean }> = ({ children, title, center = false }) => {
  return (
    <BasicTooltip title={title}>
      <div css={[cellWrapper, center && centerCell]}>{children}</div>
    </BasicTooltip>
  );
};

const TableHeader = ({ label, name, center }: BuildColumnProps) => {
  return <Typography variant='subtitle2'>{label || capitalizeCamelCase(name)}</Typography>;
};

type Shape = { metadata: { id: string } };

export const generateColumnsFromData = (objectShape: Shape, columnsMap: BuildColumnsMap): DataTableColumn[] => {
  const { metadata, ...restOfObject } = objectShape;
  const columns = Object.keys(restOfObject).map((name) => buildColumn(name, columnsMap[name]));
  const keys = Object.keys(columnsMap);
  const orderedColumns: DataTableColumn[] = [...columns.sort((a, b) => keys.indexOf(a.name) - keys.indexOf(b.name))];
  return orderedColumns;
};

export const generateSimpleColumnsFromData = (
  objectShape: Shape,
  columnOverride: Record<string, Partial<BuildColumnProps>> = {}
): DataTableColumn[] => {
  const { metadata, ...restOfObject } = objectShape || {};

  const columns = Object.keys(restOfObject).map((name) =>
    buildColumn(name, {
      name,
      label: capitalizeCamelCase(name),
      ...columnOverride[name],
    })
  );
  return [...columns];
};

interface ProcessDataTableColumnsProps {
  rawColumns: BuildColumnProps[];
  memberFilters: any;
  columnExclusion: any;
  tableWidthInVW: number;
  maxColumns?: number;
}
export const getPredefinedRender = <T extends object = any>(
  predefinedRender: ColumnType<T>,
  fullData: T,
  value: string
) => {
  const { kind } = predefinedRender;
  switch (kind) {
    case 'date':
    case 'dateTime':
      if (!value) {
        return '';
      }
      return <DateTimeCell isDateOnly={kind === 'date'} value={value} />;
    case 'profile':
      return <ProfileCell {...predefinedRender.getInfo(fullData)} />;
    case 'link':
      return <LinkCell {...predefinedRender.getInfo(fullData)} />;
    case 'chip':
      return <ChipCell {...predefinedRender.getInfo(fullData)} />;
    case 'progress':
      return <ProgressCell {...predefinedRender.getInfo(fullData)} />;
    case 'bool':
      return <BoolCell value={value} />;
    default: {
      logger.warn('cell type not supported');
      return null;
    }
  }
};

const getCellChildren = <T extends object>(row: Row<T>, value: string, col: BuildColumnProps) => {
  if (col.type) {
    return getPredefinedRender(col.type, row.original, value);
  }
  //TODO: remove renderItem
  if (col?.renderItem) {
    return col.renderItem(value, (row.original as any).metadata);
  }
  if (col?.renderFullItem) {
    return col.renderFullItem(row.original);
  }
  if (col?.transformValue) {
    return col.transformValue(value);
  }
  return value || '';
};

export const processDataTableColumns = ({ rawColumns, ...rest }: ProcessDataTableColumnsProps) => {
  let selectedColumnCount = 0;
  return rawColumns.map((col, index) => {
    const { shouldIncrement, filteredColumn } = processDataTableColumn({
      col,
      rawColumnsLength: rawColumns.filter((item) => item.display !== false).length,
      columnProps: rest,
      selectedColumnCount,
      index,
    });
    selectedColumnCount += shouldIncrement ? 1 : 0;
    return filteredColumn;
  });
};

const processDataTableColumn = ({
  col,
  rawColumnsLength,
  selectedColumnCount,
  index,
  columnProps,
}: {
  col: BuildColumnProps;
  rawColumnsLength: number;
  selectedColumnCount: number;
  index: number;
  columnProps: Omit<ProcessDataTableColumnsProps, 'rawColumns'>;
}) => {
  const { tableWidthInVW, memberFilters, maxColumns, columnExclusion } = columnProps;

  const column = {
    display: true,
    filter: (rows: any, id: string, filterValue: string[]) => {
      return rows.filter((row: any) => {
        const rowValue = row.values[id];
        if (!filterValue.length) return true;
        return filterValue.includes(rowValue?.toString());
      });
    },
    ...col,
    label: col.label || capitalizeCamelCase(col.name),
    sticky: col.sticky,
    accessor: col.name,
    width: getCellWidth(rawColumnsLength, col.width || 200, tableWidthInVW),
    Header: () => <TableHeader key={col.name} {...col} />,
    Cell: ({ value, row }: CellProps<any, any>) => {
      const cellChildren = getCellChildren(row, value, col);
      const children =
        typeof cellChildren === 'string' ? <CellTypography>{cellChildren}</CellTypography> : cellChildren;
      const title = value?.length > 25 ? value : '';
      return (
        <CellWrapper title={title} center={!!col.center}>
          {children}
        </CellWrapper>
      );
    },
  };

  const filterList = memberFilters?.[col.name || ''];
  const filteredColumn = filterList ? { ...column, filterList } : { ...column };

  // enforce exclusion map
  if (col.name && columnExclusion[col.name]) {
    filteredColumn.display = false;
  }
  // enforce maxColumns
  let shouldIncrement = false;
  if (maxColumns !== undefined) {
    shouldIncrement = filteredColumn.display;
    if (selectedColumnCount >= maxColumns && index !== rawColumnsLength - 1) {
      filteredColumn.display = false;
    }
  }

  return { filteredColumn: filteredColumn as unknown as Column, shouldIncrement };
};
