import { css, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import React from 'react';
import { getDateValue } from './common';

const hidePickerCss = css`
  visibility: hidden;
  position: absolute;
  width: 0;
`;
interface TimePickerWrapperProps
  extends Omit<TimePickerProps<Date>, 'value'>,
    Pick<TextFieldProps, 'required' | 'helperText' | 'InputLabelProps'> {
  inputVariant?: TextFieldProps['variant'];
  inputProps?: TextFieldProps | any;
  hidden?: boolean;
  value?: string | number | Date;
}

export const TimePickerWrapper: React.FC<TimePickerWrapperProps> = ({
  required,
  inputVariant,
  helperText,
  InputLabelProps,
  hidden,
  inputProps,
  value,
  ...timePickerProps
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <TimePicker
      slotProps={{
        textField: {
          label: timePickerProps.label,
          variant: inputVariant,
          helperText,
          required,
          InputLabelProps,
          ...inputProps,
        },
      }}
      value={getDateValue(value)}
      css={hidden ? hidePickerCss : undefined}
      {...timePickerProps}
    />
  </LocalizationProvider>
);
