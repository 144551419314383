import { useQuery } from '@tanstack/react-query';
import { JobBase } from './types';
import { getFileMetadata, listObjectInFolder } from './utils';

interface JobStatus extends JobBase {
  isDone: boolean;
  createdAt: Date;
  metadata: { jobLink: string };
}

const isFile = ({ Key = '' }) => !Key.endsWith('/');
const isIncoming = ({ Key = '' }) => Key.includes('/incoming/');
const isDoneList = ({ Key = '' }) => Key.includes('doneList.json');

const splitKey = (key = '') => {
  // path example:
  // s3://laguna-tagging/david/incoming/sentiment/sentiment_test.json
  // key example:
  // david/incoming/sentiment/sentiment_test.json
  const [userId, groupFolder, type, fileName] = key.split('/');
  const id = fileName?.replace('.json', '') || '';
  return { userId, groupFolder, type, fileName, id };
};

export const useUserJobs = () => {
  return useQuery(['s3', 'useUserJobs'], async () => {
    const files = await listObjectInFolder({ path: '' });

    const relevantFiles = files.filter(isFile).filter((file: any) => isIncoming(file) || isDoneList(file)) || [];

    const incoming = relevantFiles.filter(isIncoming);

    const jobs = await Promise.all(
      incoming.map(async ({ Key = '', LastModified = new Date() }) => {
        const { userId, id, type } = splitKey(Key);
        const metadata = await getFileMetadata({ path: Key });

        return {
          id,
          userId,
          isDone: !!metadata.isDone,
          type,
          createdAt: LastModified,
          metadata: { jobLink: `${userId}/${type}/${id}` },
        } as JobStatus;
      })
    );

    return jobs;
  });
};
