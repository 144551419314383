import { LagunaImage } from '@laguna/Images';
import { Close } from '@mui/icons-material';
import { Card, IconButton, Theme, Typography, css } from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

const lagunaNowSnackBar = (theme: Theme) => css`
  box-shadow: ${theme.shadows[5]};
  display: flex;
  flex-direction: row;
  max-width: 80vw;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.success.lighter};
  border-radius: ${theme.spacing(2)};
  border: solid 2px ${theme.palette.common.white};
  position: relative;
  align-items: center;

  .snackbar-icon {
    align-self: center;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
  }

  > button {
    min-width: 0;
    width: 5px;
    height: 5px;
  }
`;

export const CustomSuccess = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      closeSnackbar(props.id);
    },
    [props.id, closeSnackbar]
  );

  return (
    <SnackbarContent ref={ref}>
      <Card css={lagunaNowSnackBar}>
        <LagunaImage className='snackbar-icon' image='success' />
        <Typography whiteSpace='pre-wrap' variant='h5'>
          {props.message}
        </Typography>
        <IconButton onClick={handleDismiss}>
          <Close />
        </IconButton>
      </Card>
    </SnackbarContent>
  );
});
