import React, { useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

// solution origin - https://codesandbox.io/s/react-window-custom-scrollbars-t4352?file=/index.js:381-394

const CustomScrollbars = ({ forwardedRef, children, style, ...rest }: any) => {
  const refSetter = useCallback(
    (scrollbarsRef: any) => {
      if (scrollbarsRef) {
        forwardedRef(scrollbarsRef.view);
      } else {
        forwardedRef(null);
      }
    },
    [forwardedRef]
  );

  return (
    <Scrollbars {...rest} autoHide ref={refSetter} style={{ ...style, overflow: 'hidden' }}>
      {children}
    </Scrollbars>
  );
};
export const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => {
  return <CustomScrollbars {...props} forwardedRef={ref} />;
});
