import { Upload } from '@mui/icons-material';
import { Button, Theme, Typography } from '@mui/material';
import { Stack, css } from '@mui/system';
import React, { ChangeEvent, useState } from 'react';
import { FileUrlRenderer } from './FileRenderer';

export const DEFAULT_ACCEPT_TYPES = 'image/*,audio/*,video/*,application/pdf';

const labelStyle = (theme: any) => css`
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s;
  align-items: center;
  justify-content: center;
  height: 140px;
  padding: ${theme.spacing(2)};
  border-radius: ${theme.spacing(1)};
  position: relative;
  overflow: hidden;
  border: dashed 1px blue;
  cursor: pointer;

  :hover {
    background-color: ${(theme as Theme).palette.grey[200]};
  }
`;
interface FilePickerProps {
  label?: string;
  accept?: HTMLInputElement['accept'];
  value: string;
  onChange: (value: string) => void;
  testId?: string;
}

interface FilePickerContentProps {
  file?: File;
  url?: string;
  accept: string;
  reset: VoidFunction;
}

const FilePickerContent: React.FC<FilePickerContentProps> = ({ file, url = '', accept, reset }) => {
  const fileUrl = file ? URL.createObjectURL(file) : url;
  const mimeType = file ? file.type : accept;

  return (
    <Stack css={labelStyle}>
      <FileUrlRenderer url={fileUrl} mimeType={mimeType} onClick={reset} />;
    </Stack>
  );
};

export const FilePicker: React.FC<FilePickerProps> = ({
  label,
  accept = DEFAULT_ACCEPT_TYPES,
  value,
  onChange,
  testId,
}) => {
  const [file, setFile] = useState<File>();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];
    if (newFile) {
      setFile(newFile);
      onChange(URL.createObjectURL(newFile));
    }
  };

  const reset = () => {
    setFile(undefined);
    onChange('');
  };

  return (
    <Stack gap={2} data-testid={testId}>
      {label && <Typography>{label}</Typography>}
      {file || value ? (
        <FilePickerContent file={file} url={value} reset={reset} accept={accept} />
      ) : (
        <label css={labelStyle}>
          <Button component='span' variant='contained'>
            <Upload />
            <input style={{ display: 'none' }} type='file' onChange={handleFileChange} accept={accept} />
          </Button>
          <Typography>{'No file selected'}</Typography>
        </label>
      )}
    </Stack>
  );
};
