import { Close, Info } from '@mui/icons-material';
import { Card, css, IconButton, Palette, PaletteColor, Stack, Theme, Typography } from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';
import { CheckCircle } from '@mui/icons-material';

const getColor = (variant: string, theme: Theme) => theme.palette[variant as keyof Palette] as PaletteColor;
const commonSnackbarCss = (variant: string) => (theme: Theme) =>
  css`
    background-color: ${getColor(variant, theme).lighter};
    border: 1px solid ${getColor(variant, theme).dark};
    color: ${getColor(variant, theme).dark};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    align-items: center;
    width: 70vw;
    justify-content: space-between;
    .snackbar-close {
      align-self: center;
      width: ${theme.spacing(2)};
      height: ${theme.spacing(2)};
      color: ${getColor(variant, theme).dark};
    }
    .snackbar-icon {
      color: ${getColor(variant, theme).main};
    }
  `;

const iconByVariant = {
  info: <Info className='snackbar-icon' />,
  success: <CheckCircle className='snackbar-icon' />,
  error: null,
  warning: null,
  default: null
};

export const CommonSnackbar = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      closeSnackbar(props.id);
    },
    [props.id, closeSnackbar]
  );

  return (
    <SnackbarContent ref={ref}>
      <Stack direction='row' component={Card} css={commonSnackbarCss(props.variant)}>
        <Stack direction='row' spacing={1}>
          {iconByVariant[props.variant] || null}
          <Typography whiteSpace='pre-wrap' variant='body2'>
            {props.message}
          </Typography>
        </Stack>
        <IconButton onClick={handleDismiss}>
          <Close className='snackbar-close' />
        </IconButton>
      </Stack>
    </SnackbarContent>
  );
});
