import { Chip, Stack } from '@mui/material';
import React from 'react';
import { Column, TableInstance } from 'react-table';

interface FiltersChipsListProps extends Pick<TableInstance, 'state'> {
  columns: Column[];
  setColumnFilter: (column: string, value: string[]) => void;
}

const getTransformValue = (columns: Column[], id: string) => {
  const matchingColumnArray = columns.filter((col) => col.name === id);
  const matchingColumn = matchingColumnArray.length ? matchingColumnArray[0] : ({} as any);
  return matchingColumn.options?.transformValue || ((v: string) => v);
};

export const FiltersChipsList: React.FC<FiltersChipsListProps> = ({ columns, state: tableState, setColumnFilter }) => {
  return (
    <Stack direction='row' flexWrap='wrap' data-testid='filter-chips' spacing={2} paddingLeft={2} paddingBottom={2}>
      {tableState?.filters.map((column) => {
        const transformValue = getTransformValue(columns, column.id);

        const getRemoveFilter = (value: string) => () => {
          const newFilters = column.value.filter((item: string) => item !== value);
          setColumnFilter(column.id, newFilters);
        };

        return column.value.map((item: string) => {
          const removeFilter = getRemoveFilter(item);
          return (
            <Chip
              onDelete={removeFilter}
              key={`${column.id}-${item}`}
              label={transformValue(item)}
              onClick={removeFilter}
            />
          );
        });
      })}
    </Stack>
  );
};
