import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function FormControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.body1.fontSize,
        },
      },
    },
  };
}
