import { ArticleType } from '@laguna/api';
import { LAGUNA_APP_LINK } from '@laguna/common/consts';
import { getFormattedTime } from '@laguna/common/date-time-utils';
import { ArticlePreviewData } from './types';

const getSiteResourceURL = (resource: string) => 'https://articles.lagunahealth.com/site_wrapper/' + resource;

const getIconUrl = (type: ArticleType) => {
  switch (type) {
    case ArticleType.Article:
      return getSiteResourceURL('icon_explore_article_type.png');
    case ArticleType.Audio:
      return getSiteResourceURL('icon_explore_audio_type.png');
    case ArticleType.Video:
      return getSiteResourceURL('icon_explore_video_type.png');
    default:
      return getSiteResourceURL('icon_explore_article_type.png');
  }
};
export const createIframeContent = (data: ArticlePreviewData) => {
  const getContentRow = () => {
    const duration = data.duration
      ? `
      <img src="${getSiteResourceURL('icon_clock_explore.png')}"/>
      <span>${getFormattedTime(data.duration)}</span>`
      : '';
    return `<div class="contentTypeRow">
        <img src="${getIconUrl(data.type)}"/>
        <span class="contentTopic">${data.topic[0]}</span>
        ${duration}
    </div>`;
  };

  const getMediaContent = () => {
    switch (data.type) {
      case ArticleType.Audio:
        return `
        <img class="main-image" alt="mainImage" src="${data.bigImageUrl}"/>
        <audio controls controlsList="noplaybackrate nodownload">
          <source src="${data.videoUrl}" />
          Your browser does not support the audio element.
        </audio>
      `;
      case ArticleType.Video:
        return `
        <video controls class="main-video" controlsList="noplaybackrate nodownload" poster="${data.bigImageUrl}">
          <source src="${data.videoUrl}" />
          Your browser does not support the video element.
        </video>
        `;
      default:
        return `<img class="main-image" alt="mainImage" src="${data.bigImageUrl}"/>`;
    }
  };

  const pageContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title></title>
    <link rel="stylesheet" href="https://laguna-backoffice.s3.us-east-1.amazonaws.com/public/common/tinymce/skins/content/default/content.min.css">
    <style>
      body {
        padding: 0;
        margin: 0;
      }
      h1 {
        margin-top:0;
        margin-bottom:8px;
      }
      .container {
        padding: 24px;
        max-width: 354px;
        transition: max-width 0.2s;
        margin: auto;
        overflow-x: hidden;
      }
      .contentTypeRow {
        display:flex;
        align-items:center;
        gap: 8px;
        color:#798996;
        margin-bottom: 8px;
      }
      .footer {
        padding: 14px;
        padding-bottom: 10px;
        background: #f7f7f7;
        display: flex;
        height: auto;
        flex-direction: column;
        align-items: self-start;
      }
      .footer a {
        text-decoration: none;
        display: block;
      }
      .footer img {
        margin-bottom: 8px;
      }

      @media screen and (min-width: 600px) {
        .container {
          max-width: 640px;
        }
        .footer {
          height: 24px;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
        }

        .footer a {
          display: inline;
        }
        .footer img {
          margin-bottom: 0;
        }
      }
      .main-image,
      .main-video,
      .main-audio
       {
        max-width:100%;
        width:auto;
        height:auto;
      }
      audio {
        width: 100%;
      }
    </style>
  </head>
  <body>
   <div class="container">
     <h1>${data.title}</h1>
     ${getContentRow()}
     ${getMediaContent()}
     ${data.content}
   </div>
   <div class="footer">
      <img src="${getSiteResourceURL('logo_laguna.png')}" />
    </div>
  </body>
  </html>
`;

  return new Blob([pageContent], { type: 'text/html' });
};
