import { FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ColoredAvatar, gridArea } from './formStyles';
import { SharedFieldProps } from './formTypes';

interface RangeInputProps extends SharedFieldProps {
  name: string;
  min: {
    value: number;
    label: string;
  };
  max: {
    value: number;
    label: string;
  };
  transformValues?: (key: string) => string;
}

export const RangeInput: React.FC<RangeInputProps> = ({
  name,
  label,
  control,
  min,
  max,
  defaultValue,
  required,
  disabled,
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ required }}
    defaultValue={defaultValue}
    render={({ field: { onChange, value: selectedValue }, fieldState: { error } }) => {
      const range = max.value - min.value + 1; // should include last value as well...
      const valuesArray = new Array(range).fill(min.value).map((i, index) => (index + i).toString());
      return (
        <FormControl
          variant='outlined'
          disabled={disabled}
          required={!!required}
          fullWidth
          css={gridArea(name)}
          className={'generate-form-item-' + name}>
          <Typography>{label}</Typography>
          <Stack direction='row' gap={2} className='rangeInputWrapper' justifyContent='end'>
            {valuesArray.map((value) => (
              <ColoredAvatar
                key={value}
                disabled={disabled}
                selected={selectedValue.toString() === value.toString()}
                onClick={() => !disabled && onChange(value)}>
                {value}
              </ColoredAvatar>
            ))}
          </Stack>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      );
    }}
  />
);
