import { generateClient } from 'aws-amplify/api';
import { QueryKey } from '@tanstack/react-query';
import { getHash } from './queryUtils';
import { socketStore } from './socketStore';
import { cleanWhiteSpaces } from '@laguna/common/utils/general';
import { logger } from '@laguna/logger';
const client = generateClient();

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
  key?: QueryKey
) => {
  return async () => {
    const subSocketId = socketStore.socketId;
    const subKey = getHash(key);
    const isMutation = cleanWhiteSpaces(query).startsWith('mutation');
    const headers = isMutation ? options : { 'sub-key': subKey, 'sub-socket-id': subSocketId, ...options };
    const startTime = Date.now();
    try {
      const res = await client.graphql(
        {
          query,
          variables: variables as any,
        },
        headers as any
      );
      const duration = Date.now() - startTime;
      logger.debug('graphqlRequestSend', { queryKey: key, variables, duration });
      return (res as any).data as TData;
    } catch (error: any) {
      const duration = Date.now() - startTime;
      logger.error('graphqlRequestSend', { queryKey: key, variables, error: error.message, duration });
      throw error;
    }
  };
};
