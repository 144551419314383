import { ReactWindowInfiniteList } from '@laguna/components/ReactWindowInfiniteList';
import { DataTableProps } from '@laguna/types';
import { Card, CircularProgress, css, Link, Paper, Stack, Tooltip, Typography } from '@mui/material';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { DataTableRow } from './DataTableRow';
import { NoResultsFound } from './NoResultsFound';
import { TableColumnHeaders } from './TableColumnHeaders';
import { TableToolbar } from './TableToolbar/TableToolbar';
import { useDataTableFiltersStorage } from './useDataTableFiltersStorage';
import { processDataTableColumns } from './utils';

const DEFAULT_ITEM_HEIGHT = 55;

const tableCard = (minimal = false) => css`
  margin: ${minimal ? '' : 'auto'};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: 100%;
  overflow: auto;
`;

const rowsContainer = css`
  overflow-x: auto;
  height: 100%;
  width: 100%;
`;

const listWrapperCss = css`
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
`;

const rowsListWrapperCss = css`
  position: relative;
`;
const titleCss = css`
  cursor: default;
  align-self: flex-start;
`;

const autoSizerStyle: React.CSSProperties = { height: '100%', width: '100%' };

export const DataTable = ({
  data,
  columns: rawColumns,
  title,
  isLoading,
  extraAction,
  tableId,
  getRowState,
  maxColumns,
  showDownload,
  defaultFilters,
  minimal = false,
  tableWidthInVW = 60,
  titleTooltip,
}: DataTableProps) => {
  const { searchTerm, setSearchTerm, filters, setFilters, columnExclusion, setColumnExclusion, sort, setSort } =
    useDataTableFiltersStorage(tableId, defaultFilters);
  const columns = useMemo(
    () => processDataTableColumns({ rawColumns, memberFilters: filters, columnExclusion, maxColumns, tableWidthInVW }),
    [rawColumns, filters, columnExclusion, maxColumns, tableWidthInVW]
  );

  const initialState = useMemo(() => {
    const hiddenColumns = columns.filter(({ display }) => !display).map(({ name }) => name);
    const initialFilters = Object.keys(filters)
      .map((key) => ({ id: key, value: filters[key] }))
      .filter((item) => item.value?.length > 0);

    return {
      hiddenColumns,
      globalFilter: searchTerm || undefined,
      filters: initialFilters?.length > 0 ? initialFilters : [],
      sortBy: sort,
    };
  }, [columns, filters, searchTerm]);

  const tableInstance = useTable({ columns, data, initialState }, useFilters, useGlobalFilter, useSortBy, useSticky);

  const { headerGroups, rows, prepareRow, setGlobalFilter, setAllFilters: setTableFilters } = tableInstance;
  const resetTableSettings = () => {
    setSearchTerm(null);
    setFilters(defaultFilters || {});
    setTableFilters(
      defaultFilters ? Object.keys(defaultFilters).map((item) => ({ id: item, value: defaultFilters[item] })) : []
    );
    setColumnExclusion({});
    setGlobalFilter(undefined);
    setSort([]);
  };

  const RenderRow = React.useCallback(
    (index: number, setRowHeight: any) => {
      const row = rows[index];
      prepareRow(row);
      return <DataTableRow row={row} setRowHeight={setRowHeight} state={getRowState?.(row.original)} />;
    },
    [prepareRow, rows]
  );

  const isTableEmpty = tableInstance?.state?.hiddenColumns?.length === columns.length || !rows.length;
  const isNoData = !rows.length && !Object.keys(filters).length && !searchTerm;

  return (
    <Card css={tableCard(minimal)} data-testid={tableId}>
      {minimal ? (
        <Stack paddingY={2} paddingX={3}>
          <Tooltip title={titleTooltip} placement='top' arrow>
            <Typography variant='h6' id='tableTitle' component='div' css={titleCss}>
              {title}
            </Typography>
          </Tooltip>
        </Stack>
      ) : (
        <TableToolbar
          tableId={tableId}
          title={title}
          extraAction={extraAction}
          resetTableSettings={resetTableSettings}
          showDownload={showDownload}
          defaultFilters={defaultFilters}
          {...tableInstance}
        />
      )}
      <div css={rowsContainer}>
        <div css={listWrapperCss} data-testid='containerDataTableRows'>
          <AutoSizer style={autoSizerStyle}>
            {({ height }: Size) => {
              return (
                <ReactWindowInfiniteList
                  height={height}
                  itemCount={isTableEmpty ? 0 : rows.length}
                  defaultItemHeight={DEFAULT_ITEM_HEIGHT}
                  renderItem={RenderRow}
                  innerElementType={({ children }: any) => (
                    <>
                      <TableColumnHeaders headerGroups={headerGroups} setSort={setSort} />
                      {isTableEmpty && (
                        <Stack paddingY={10} alignItems='center'>
                          {isLoading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <NoResultsFound search={!!searchTerm} noData={isNoData} />
                              {!isNoData && <Link onClick={resetTableSettings}>{i18next.t('dataTable:back')}</Link>}
                            </>
                          )}
                        </Stack>
                      )}
                      <div css={rowsListWrapperCss}>{children}</div>
                    </>
                  )}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
      {!minimal && (
        <Stack padding={1} minHeight={50} component={Paper} textAlign='center'>
          <Typography fontWeight={600} color={(theme) => theme.palette.text.secondary}>
            {rows.length} {title}
          </Typography>
        </Stack>
      )}
    </Card>
  );
};
