import { TextField, StandardTextFieldProps, TextFieldProps, Theme } from '@mui/material';
import { Controller, Validate } from 'react-hook-form';
import { SharedFieldProps } from './formTypes';
import { css } from '@mui/system';
import { gridArea } from './formStyles';

export interface TextInputProps
  extends Omit<StandardTextFieldProps, 'name' | 'variant'>,
    Omit<SharedFieldProps, 'defaultValue' | 'label' | 'required'> {
  pattern?: RegExp;
  validate?: Validate<string>;
  variant?: TextFieldProps['variant'];
  hideErrorMsg?: boolean;
}

const inputCss = (theme: Theme) => css`
  input {
    :autofill {
      -webkit-box-shadow: 0 0 0 100px ${theme.palette.background.tertiary} inset !important;
    }
    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px ${theme.palette.background.tertiary} inset !important;
    }
  }
`;

//formId is not used but removing it throw error to console
const TextInput: React.FC<TextInputProps> = ({
  name,
  control,
  defaultValue,
  required,
  rows = 1,
  pattern,
  validate,
  formId,
  variant = 'outlined',
  hideErrorMsg,
  ...OtherTextFieldProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required, validate, pattern }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          css={[gridArea(name), inputCss]}
          className={'generate-form-item-' + name}
          variant={variant}
          fullWidth
          multiline={(rows as number) > 1}
          rows={rows}
          {...OtherTextFieldProps}
          value={value}
          onChange={onChange}
          error={!!error}
          required={required}
          helperText={!hideErrorMsg && error ? error.message : null}
        />
      )}
    />
  );
};

export default TextInput;
