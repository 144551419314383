import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { MinimalTimePicker } from './MinimalTimePicker';

interface TodoTimesSelectProps {
  value: Date[];
  onChange: (times: Date[]) => void;
}

export const TodoTimesSelect: React.FC<TodoTimesSelectProps> = ({ value, onChange }) => {
  const onDateChange = useCallback(
    (index: number) => (newDate: Date) => {
      const newDates = [...value];
      newDates[index] = newDate;
      onChange(newDates);
    },
    [value, onChange]
  );

  return (
    <Stack gap={1}>
      {value.map((date, index) => (
        <MinimalTimePicker key={index} value={date} onChange={onDateChange(index)} />
      ))}
    </Stack>
  );
};
