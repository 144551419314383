import { Label, Resource, TodoStatus } from '@laguna/api';

export interface ToDoDisplayItem {
  id: string;
  toDoId: string;
  text: string;
  status: TodoStatus;
  label?: Label;
  index?: number;
  total?: number;
  date?: Date;
  isDone?: boolean;
  resource?: Resource;
}

export enum Repeat {
  doesNotRepeat = 'doesNotRepeat',
  specificWeekdays = 'specificWeekdays',
  everyDay = 'everyDay',
}
