import { Article, ArticleTopic, ArticleType, SetArticleParams } from '@laguna/api';
import { FieldType, FieldsType, generateForm } from '@laguna/common/form/generateForm';
import { uploadData } from 'aws-amplify/storage';
import { decodeHTML5, encodeHTML5 } from 'entities';
import i18next from 'i18next';

const SITE_URL = 'https://articles.lagunahealth.com';

export const FORM_ID = 'editArticle';

export const isBlobURL = (url = '') => url.startsWith('blob');

export const getArticleSiteUrl = (articleId: string) => `${SITE_URL}/${articleId}/`;

export const articleDraft: SetArticleParams = {
  title: 'Draft',
  bigImageUrl: `${SITE_URL}/defaults/bigImage.jpg`,
  type: ArticleType.Article,
  smallImageUrl: `${SITE_URL}/defaults/smallImage.jpg`,
  isDefault: false,
  isRecommended: false,
  topic: [],
  articleMarkdown: '',
  content: '<p>Initial article</p>',
};

export interface FormData {
  title?: string;
  type?: string;
  bigImageUrl?: string;
  duration?: number;
  smallImageUrl?: string;
  videoUrl?: string;
  booleans?: string[];
  topic?: string[];
}

const visibleOnMedia = (fields: any) => {
  switch (fields.type) {
    case ArticleType.Article: {
      return { isHidden: true, required: false };
    }
    case ArticleType.Video: {
      return {
        label: i18next.t('articles:formLabels.videoUrl'),
        isHidden: false,
        required: true,
        accept: 'video/*',
      };
    }
    default: {
      return {
        label: i18next.t('articles:formLabels.audioUrl'),
        isHidden: false,
        required: true,
        accept: 'audio/*',
      };
    }
  }
};

export const getForm = () => {
  const fields: FieldsType[] = [
    { name: 'title', label: i18next.t('common:title'), type: FieldType.text, required: true },
    {
      name: 'type',
      label: i18next.t('articles:formLabels.type'),
      type: FieldType.select,
      required: true,
      values: ArticleType,
      nullable: false,
    },
    {
      name: 'topic',
      label: i18next.t('articles:formLabels.topic'),
      type: FieldType.select,
      multiple: true,
      required: true,
      values: ArticleTopic,
      nullable: false,
    },
    {
      name: 'booleans',
      label: i18next.t('articles:formLabels.booleans.label'),
      type: FieldType.checkbox,
      options: [
        { label: i18next.t('articles:formLabels.booleans.isDefault'), value: 'isDefault' },
        { label: i18next.t('articles:formLabels.booleans.isRecommended'), value: 'isRecommended' },
      ],
    },
    {
      name: 'bigImageUrl',
      label: i18next.t('articles:formLabels.bigImageUrl'),
      type: FieldType.file,
      required: true,
      accept: 'image/*',
    },
    {
      name: 'smallImageUrl',
      label: i18next.t('articles:formLabels.smallImageUrl'),
      type: FieldType.file,
      accept: 'image/*',
      required: true,
    },
    {
      name: 'videoUrl',
      label: i18next.t('articles:formLabels.videoUrl'),
      type: FieldType.file,
      required: true,
      accept: 'audio/*,video/*',
      getDerivedState: visibleOnMedia,
    },
    {
      name: 'duration',
      label: i18next.t('articles:formLabels.duration'),
      type: FieldType.text,
      inputType: 'number',
      required: true,
      getDerivedState: (fields) => ({
        isHidden: fields.type === ArticleType.Article,
        required: fields.type !== ArticleType.Article,
      }),
    },
  ];
  return generateForm<FormData>({
    formId: FORM_ID,
    fields,
    allowNoChangesSubmit: true,
    useOnlyDirtyFields: false,
  });
};

export const articleToFormData = (article: Article): { formData: FormData; content: string } => ({
  content: decodeHTML5(article.content),
  formData: {
    title: article.title,
    type: article.type,
    bigImageUrl: article.bigImageUrl,
    smallImageUrl: article.smallImageUrl,
    videoUrl: article.videoUrl,
    topic: article.topic,
    duration: article.duration,
    booleans: [article.isDefault ? 'isDefault' : '', article.isRecommended ? 'isRecommended' : ''].filter((str) => str),
  },
});

export const formDataToSetParams = async (
  formData: Partial<FormData>,
  content: string,
  id: string,
  isPublished: boolean
): Promise<SetArticleParams> => {
  const bigImageUrl: string = await uploadBlobIfNeeded(id, 'bigImageUrl', formData.bigImageUrl);
  const smallImageUrl: string = await uploadBlobIfNeeded(id, 'smallImageUrl', formData.smallImageUrl);
  const videoUrl: string =
    formData.type !== ArticleType.Article ? await uploadBlobIfNeeded(id, 'videoUrl', formData.videoUrl) : '';

  return {
    isPublished,
    title: formData.title,
    bigImageUrl,
    smallImageUrl,
    videoUrl,
    id,
    duration: Number(formData.duration || '0'),
    content: encodeHTML5(content),
    isRecommended: formData.booleans?.includes('isRecommended'),
    isDefault: formData.booleans?.includes('isDefault'),
    type: formData.type as ArticleType | undefined,
    topic: formData.topic as ArticleTopic[],
    articleMarkdown: '',
  };
};
const BUCKET_URL = process.env.NX_BACK_OFFICE_BUCKET_PUBLIC_URL;

const uploadBlobIfNeeded = async (id: string, name: string, url?: string): Promise<string> => {
  if (!url || !isBlobURL(url)) {
    return url || '';
  }

  const blob = await fetch(url).then((r) => r.blob());

  const result = await uploadData({
    path: id + '/images/' + name,
    data: blob,
    options: {
      contentType: blob.type,
    },
  });

  return BUCKET_URL + (result as any).key;
};
