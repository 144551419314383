import { AppSidebarLayout } from '@laguna/components/Layout/AppSidebarLayout';
import { LocalOffer, Newspaper, SellOutlined, SettingsSuggest } from '@mui/icons-material';
import { PATHS } from './Routing';
import { backOfficeUserStore } from './stores/backOfficeUserStore';

const tabs = [
  {
    path: PATHS.TAGGING,
    icon: LocalOffer,
    name: 'Tagging',
  },
];

const adminTabs = [
  {
    path: PATHS.ARTICLES,
    icon: Newspaper,
    name: 'Articles',
  },
  {
    path: PATHS.ATTRIBUTES,
    icon: SellOutlined,
    name: 'Attributes',
  },
  {
    path: PATHS.TAGGING,
    icon: LocalOffer,
    name: 'Tagging',
  },
  {
    path: PATHS.CONFIG_PROMPTS,
    icon: SettingsSuggest,
    name: 'Config Prompts',
  },
];

export const Sidebar: React.FC = () => {
  return <AppSidebarLayout tabs={backOfficeUserStore.isAdmin ? adminTabs : tabs} />;
};
