import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { ProgressColumn } from '@laguna/types';

type ProgressCellProps = ReturnType<ProgressColumn['getInfo']>;

const [MIN, MAX, ERROR] = [33, 66, -1];

const normalize = (value: number, max: number) => {
  if (value === 0 && max === 0) {
    return ERROR;
  }
  if (value >= max) {
    return 100;
  }
  return (value * 100) / max;
};

const getColor = (percent: number) => {
  if (ERROR === percent) {
    return 'inherit';
  }
  if (percent < MIN) {
    return 'success';
  }
  if (percent > MAX) {
    return 'error';
  }
  if (percent >= MIN && percent <= MAX) {
    return 'warning';
  }

  return 'inherit';
};

export const ProgressCell = ({ progress, max }: ProgressCellProps) => {
  const value = normalize(progress, max);
  const color = getColor(value);
  return (
    <Stack alignItems='center' justifyContent='center' spacing={0.5}>
      <Box sx={{ width: '80px' }}>
        <LinearProgress variant='determinate' value={value} color={color} />
      </Box>
      <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
        {progress}/{max}
      </Typography>
    </Stack>
  );
};
