import { Group } from './types';

const separateSelectedItems = (selectedArticles: Set<string>, items: Group['articles']) =>
  items.reduce(
    (acc, current) => {
      if (selectedArticles.has(current.id)) {
        acc.effectedArticles.push(current);
      } else {
        acc.staticArticles.push(current);
      }
      return acc;
    },
    {
      effectedArticles: [] as Group['articles'],
      staticArticles: [] as Group['articles'],
    }
  );

const swapItems = (arr: any[], index1: number, index2: number) => {
  const temp = arr[index1];
  arr[index1] = arr[index2];
  arr[index2] = temp;
  return arr;
};

export const moveItemsToBottom = (selectedArticles: Set<string>, items: Group['articles']) => {
  const { effectedArticles, staticArticles } = separateSelectedItems(selectedArticles, items);
  return [...staticArticles, ...effectedArticles];
};
export const moveItemsToTop = (selectedArticles: Set<string>, items: Group['articles']) => {
  const { effectedArticles, staticArticles } = separateSelectedItems(selectedArticles, items);
  return [...effectedArticles, ...staticArticles];
};
export const moveItemsDown = (selectedArticles: Set<string>, items: Group['articles']) => {
  let newArticles = [...items];
  for (let index = newArticles.length - 2; index >= 0; index--) {
    const articleToMove = newArticles[index];
    const targetArticle = newArticles[index + 1];
    if (selectedArticles.has(articleToMove.id) && !selectedArticles.has(targetArticle.id)) {
      newArticles = swapItems(newArticles, index, index + 1);
    }
  }
  return newArticles;
};

export const moveItemsUp = (selectedArticles: Set<string>, items: Group['articles']) => {
  let newArticles = [...items];
  for (let index = 1; index < newArticles.length; index++) {
    const articleToMove = newArticles[index];
    const targetArticle = newArticles[index - 1];

    if (selectedArticles.has(articleToMove.id) && !selectedArticles.has(targetArticle.id)) {
      newArticles = swapItems(newArticles, index, index - 1);
    }
  }
  return newArticles;
};
