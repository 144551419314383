import { logger } from '@laguna/logger';
import * as jose from 'jose';

const textEncoder = new TextEncoder();

export const decryptPusherMessage = async (encryptedData: string | object, key: string) => {
  if (typeof encryptedData !== 'string') {
    logger.error('Received object instead of jwt token', { objectKeys: Object.keys(encryptedData) });
    return null;
  }
  try {
    const decryptedData = await jose.jwtVerify(encryptedData, textEncoder.encode(key));
    return decryptedData.payload;
  } catch (e) {
    logger.error('Error decrypting message', { error: e, key, encryptedData });
    return null;
  }
};
