import { TextFieldProps, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import { US_DATE_FORMAT } from '@laguna/common/consts';
import { DatePickerWrapper } from '@laguna/common/PickerWrappers';
import { SharedFieldProps } from './formTypes';
import { gridArea } from './formStyles';
/* eslint-disable @typescript-eslint/ban-types */

interface DateInputProps extends SharedFieldProps {
  disableFuture?: boolean;
  textInputProps: TextFieldProps;
  maxDate?: Date;
}

const DateInput: React.FC<DateInputProps> = ({
  name,
  label,
  control,
  defaultValue,
  disabled,
  required,
  textInputProps = {},
  disableFuture = false,
  autoFocus = false,
  variant = 'outlined',
  maxDate,
  validate,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required, validate }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl css={gridArea(name)} style={{ margin: 0 }} className={'generate-form-item-' + name}>
          <DatePickerWrapper
            label={label}
            inputVariant={variant}
            format={US_DATE_FORMAT}
            value={value}
            onChange={onChange}
            required={!!required}
            helperText={error ? error.message : null}
            disabled={disabled}
            autoFocus={autoFocus}
            disableFuture={disableFuture}
            inputProps={textInputProps}
            maxDate={maxDate}
          />
        </FormControl>
      )}
    />
  );
};

export default DateInput;
