import { Attribute, SetAttributeOptionParams } from '@laguna/api';
import { FieldsType, FieldType } from '@laguna/common/form/formTypes';
import { generateForm } from '@laguna/common/form/generateForm';
import i18next from 'i18next';
import { groupBy } from 'lodash';

export const attributeDraft: SetAttributeOptionParams = {
  category: '',
  key: '',
  values: [''],
};

export const NEW_ATTRIBUTE_ID_PATH = 'newAttribute';
export const PUBLISH_MODE = 'publish';
export const VIEW_MODE = 'view';

export const FORM_ID = 'editAttribute';
export interface AttributeFormData {
  key: string;
  category: string;
  description?: string;
  values: { optionKey: string }[];
}

export const getAttributeForm = (attributes: Attribute[]) => {
  const categoryValues: Record<string, string> = {};
  Object.keys(groupBy(attributes, (item) => item.category)).forEach((category) => {
    categoryValues[category] = category;
  });
  const fields: FieldsType[] = [
    { name: 'key', label: i18next.t('common:title'), type: FieldType.text, required: true },
    {
      name: 'category',
      label: i18next.t('attributes:editAttribute.category'),
      type: FieldType.select,
      required: true,
      values: categoryValues,
    },
    {
      name: 'description',
      label: i18next.t('attributes:editAttribute.attributeDesc'),
      type: FieldType.text,
      rows: 2,
    },
    {
      name: 'values',
      label: i18next.t('attributes:editAttribute.sectionOptions'),
      type: FieldType.orderedList,
      required: true,
      fields: [{ name: 'optionKey', label: 'Option', type: FieldType.text, required: true }],
    },
  ];

  return generateForm<AttributeFormData>({
    formId: FORM_ID,
    fields,
    allowNoChangesSubmit: true,
    useOnlyDirtyFields: false,
  });
};
