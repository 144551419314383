import { GetBackofficePromptsQuery, useGetBackofficePromptsQuery, useGetConfigsQuery } from '@laguna/api/backoffice';
import { useQuerySelect } from '@laguna/api/utils';
import { FieldType, FieldsType } from '@laguna/common/form/formTypes';
import { capitalizeCamelCase } from '@laguna/common/utils/general';
import i18next from 'i18next';
import { groupBy, keyBy, mapValues, uniqBy } from 'lodash';
import { getConfig } from '../../../../hooks/useMultipleOrgPromptConfig';
import { getEnvSpecificQueriesResult } from '../../../../utils/getQueryResult';

type FetchPromptsData = ReturnType<typeof fetchPromptsData>;

const getPromptField = (keys?: FetchPromptsData['keys']): FieldsType => ({
  name: 'prompt',
  label: i18next.t('playground:prompt'),
  type: FieldType.autocomplete,
  options: [],
  getDerivedState: async (updatedFields, updatedFieldName, setValue, initialData) => {
    if (updatedFieldName !== undefined) {
      //only update once on load
      return {};
    }
    const options = keys ?? [];
    const defaultPromptKey = initialData?.promptKey
      ? options.find(({ id }) => id === initialData.promptKey)
      : undefined;
    const defaultVersion = defaultPromptKey?.id ? initialData?.config[defaultPromptKey.id].version : undefined;
    setValue?.('version', defaultVersion);

    return {
      options,
      defaultValue: defaultPromptKey,
    };
  },
});

const getVersionField = (versions: FetchPromptsData['versions'], allowObsolete?: boolean): FieldsType => ({
  name: 'version',
  label: i18next.t('playground:version'),
  type: FieldType.autocomplete,
  options: [],
  getOptionDisabled: ({ isObsolete }) => (allowObsolete ? false : isObsolete),
  getDerivedState: async (updatedFields, updatedFieldName, setValue, initialData) => {
    if (updatedFields?.version) return {};
    if (updatedFieldName !== 'prompt' && !updatedFieldName && !initialData?.promptKey) return {};
    const key = updatedFields.prompt?.id ?? initialData?.promptKey;
    const isHidden = !key;
    setValue?.('version', undefined);
    const options =
      versions[key]?.map(({ id, version, isObsolete }) => ({
        id,
        label: isObsolete ? `${version} invalid data handles (Obsolete)` : version,
        isObsolete,
      })) ?? [];

    const defaultValue = initialData?.config[key].version;

    return {
      options,
      isHidden,
      defaultValue,
    };
  },
});

const fetchPromptsData = (data?: GetBackofficePromptsQuery) => {
  const prompts = data?.getPrompts;
  const keys = uniqBy(prompts, 'key').map(({ key }) => ({
    id: key,
    label: capitalizeCamelCase(key),
  }));
  const versions = mapValues(groupBy(prompts, 'key'));

  return { keys, versions };
};

export const useUpdateConfigPromptFields = () => {
  const {
    parsedData: { keys, versions },
  } = useQuerySelect(useGetBackofficePromptsQuery, {}, fetchPromptsData);

  const getPromptFields = (allowObsolete?: boolean): FieldsType[] => [
    {
      name: 'configName',
      label: 'Config Name',
      type: FieldType.title,
    },
    getPromptField(keys),
    getVersionField(versions, allowObsolete),
    {
      name: 'updateNote',
      label: 'To see the change you need to refresh the page',
      type: FieldType.title,
    },
  ];

  return { getPromptFields, versions };
};

export const getConfigByConfigName = async (configName: string, data?: GetBackofficePromptsQuery) => {
  const configs = await getEnvSpecificQueriesResult('develop', useGetConfigsQuery);
  const currentConfig = configs?.getConfigs.find((config) => config.name === configName);
  if (!currentConfig || !data?.getPrompts) return null;
  return getConfig(
    currentConfig,
    keyBy(
      data?.getPrompts.filter((prompt) => prompt.active),
      'key'
    )
  );
};

export const getFieldWidth = (label: string) => Math.max(capitalizeCamelCase(label).length * 10, 200);
