import React from 'react';
import { AppHeaderLayout } from '@laguna/components/Layout/AppHeaderLayout';
import { AppProfile } from '@laguna/components/Layout/AppProfile';

import i18next from 'i18next';
import { signOutAndReload } from '@laguna/webAuth';

export const Header: React.FC = () => {
  return (
    <AppHeaderLayout
      title={{ name: i18next.t('common:backofficeTitle'), logo: '', link: '/' }}
      profile={<AppProfile onSignOut={signOutAndReload} />}
    />
  );
};
