import {
  useGetBackofficePromptsQuery,
  useGetConfigsQuery,
  useSetConfigAttributeMutation,
} from '@laguna/api/backoffice';
import { useInvalidateCache } from '@laguna/api/utils';
import { FieldsType } from '@laguna/common/form/formTypes';
import { usePromptForm } from '@laguna/common/modals';
import { getConfigByConfigName, useUpdateConfigPromptFields } from './utils';
import { getEnvSpecificMutationResult } from '../../../../utils/getQueryResult';

interface updateConfigPromptParams {
  env: string;
  orgName: string;
  configName: string;
  promptKey: string;
}

export const useUpdateConfigPromptModal = () => {
  const { data } = useGetBackofficePromptsQuery();
  const { getPromptFields } = useUpdateConfigPromptFields();
  const fields: FieldsType[] = getPromptFields(true);
  const { prompt } = usePromptForm<any>(fields, { useGrid: true, validationMode: 'onChange' });
  const { invalidate } = useInvalidateCache(useGetConfigsQuery.getKey({}));

  const updateConfigPrompt = async ({ env, orgName, configName, promptKey }: updateConfigPromptParams) => {
    const config = await getConfigByConfigName(configName, data);
    const title = env !== orgName ? `${orgName} (${env})` : env;
    const res = await prompt(`Update Prompt for ${title}`, { configName, config, promptKey });
    if (!res) {
      return;
    }
    await getEnvSpecificMutationResult('develop', useSetConfigAttributeMutation, {
      setConfigAttributeParams: {
        value: res.version.label,
        name: configName,
        attribute: `ai.prompt.${res.prompt.id}.version`,
      },
    });
    invalidate();
  };

  return { updateConfigPrompt };
};
