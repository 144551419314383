import { FieldType, FieldsType } from '@laguna/common/form/formTypes';
import { capitalizeCamelCase } from '@laguna/common/utils/general';
import { Chunk } from '@laguna/components/TranscriptionViewer/types';
import i18next from 'i18next';
import { groupBy, keyBy, sortBy } from 'lodash';
import { OutputTag, S3Types } from '../../../hooks/types';
import { TagGroup, TagResourceFormState, TagResourcesMode } from './types';

export const outputTagsToMetadata = (tags: OutputTag[]): Record<string, Chunk[]> => {
  const chunks = groupBy(
    tags.flatMap((tag) => {
      return tag.origin.map((origin) => ({ ...origin, tooltip: tag.target.id }));
    }),
    'id'
  );
  return chunks;
};

// gets unique id for a tag,
const getOriginHash = (tag: OutputTag) => tag.origin.map(({ id, start, end }) => [id, start, end].join('@')).join('#');

/**
 * job output consists a flat array of tags
 * This function gets this array groups all tags according to origin
 * for example
 * [
 *    taggedBarrier1 ,
 *    taggedAttribute1,
 *    taggedBarrier2
 * ]
 *  =>
 * [
 *    { id:1, items: [taggedBarrier1, taggedAttribute1] }]},
 *    { id:2, items: [taggedBarrier2] }] }
 * ]
 */
export const outputTagsToTaggingGroups = (tags: OutputTag[]): TagGroup[] => {
  const tagsWithOriginHash = tags.map((tag) => ({ ...tag, hash: getOriginHash(tag) }));
  const tagsGroupedByTarget = groupBy(tagsWithOriginHash, 'hash');
  return Object.entries(tagsGroupedByTarget).map(
    ([id, items]) =>
      ({
        id,
        freeText: items[0].freeText || '',
        items: items.map(({ target: { id, type } }) => ({ id, type })),
      } as TagGroup)
  );
};

export const s3ResourcesToTagFields = (data?: S3Types) => {
  if (!data) {
    return { fields: [] as FieldsType[] };
  }

  const barrierTypesMap = keyBy(data.barriers, 'type');
  const itemsSortedByDomain = sortBy(data.barriers, 'domain').map((barrier) => barrier.type);
  const attributesMap = keyBy(data.attributes, 'key');

  return {
    fields: [
      {
        name: 'mode',
        label: i18next.t('tagging:selectTagResource'),
        type: FieldType.radio,
        values: TagResourcesMode,
      },
      {
        name: 'barrier',
        label: i18next.t('tagging:selectBarrier'),
        type: FieldType.asyncAutocomplete,
        required: true,
        fetchItems: async () => itemsSortedByDomain,
        getDerivedState: (state: Partial<TagResourceFormState>) => ({
          isHidden: state.mode !== TagResourcesMode.barrier,
        }),
        groupBy: (barrierType) => i18next.t('common:barriers.domain.' + barrierTypesMap[barrierType]?.domain),
      },
      {
        name: 'attributeKey',
        label: 'Attribute Name',
        type: FieldType.asyncAutocomplete,
        required: true,
        getDerivedState: (state: Partial<TagResourceFormState>) => ({
          isHidden: state.mode === TagResourcesMode.barrier,
        }),
        fetchItems: async () => data.attributes.map((attr) => attr.key),
        groupBy: (key: string) => capitalizeCamelCase(attributesMap[key]?.category || ''),
      },
      {
        name: 'attributeValue',
        label: 'about to change',
        isHidden: true,
        required: true,
        type: FieldType.radio,
        values: {},
        getDerivedState: (state: Partial<TagResourceFormState>) => {
          if (!state.attributeKey || state.mode === TagResourcesMode.barrier) return { isHidden: true };
          const foundAttr = data.attributes.find((attr) => attr.key === state.attributeKey);
          if (!foundAttr) return { isHidden: true };
          const values = keyBy(foundAttr.values);

          return { isHidden: false, label: foundAttr.key, values };
        },
      },
    ] as FieldsType[],
  };
};
