import { Portal } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubFormField } from './formTypes';
import { buildLayoutFromFields } from './buildLayoutFromFields';
import { FormFieldProps, FormField } from './FormField';

export const SubForm = ({ field, props }: { field: SubFormField; props: FormFieldProps }) => {
  const [, setRef] = useState<any>(null);

  useEffect(() => {
    setRef({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.ref?.current]);

  const formLayout = field.useGrid ? buildLayoutFromFields(field.fields) : '';
  const subFormContent = () => (
    <div css={formLayout} className={'subForm' + field.name}>
      {field.fields.map((_field) => {
        return <FormField key={_field.name} {...props} field={_field} />;
      })}
    </div>
  );
  if (field.ref) {
    return <Portal container={field.ref.current}>{subFormContent()}</Portal>;
  }
  return subFormContent();
};
