import { BasicTooltip } from '@laguna/common/BasicTooltip';
import { getTimeFromSeconds } from '@laguna/common/date-time-utils';
import { Link, Theme, Typography } from '@mui/material';
import { css } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { speakersColors } from './consts';
import { Chunk, TranscriptRow } from './types';
import { buildSearchResult } from './utils';

const getColor =
  (speakerIndex = 0) =>
  (theme: Theme) =>
    css`
      color: ${speakersColors[theme.palette.mode][speakerIndex]};
    `;

const getSpeakerCss =
  (speakerIndex = 0) =>
  (theme: Theme) =>
    css`
      ${getColor(speakerIndex)(theme)};
      margin-top: 6px;
      margin-bottom: 0px;
      font-size: 16px;
    `;

const highlightStyle = (theme: Theme) => css`
  background-color: ${theme.palette.error.lighter};
`;

const highlightSearchStyle = css`
  background-color: #0093fe66;
  border-radius: 5px;
`;

const textSpaceCss = (theme: Theme) => css`
  margin-left: ${theme.spacing(1)};
`;

const underline = css`
  text-decoration: underline;
  text-underline-offset: 2px;
`;
const rowContainerCss = css`
  display: flex;
  flex: 0 0 auto;
`;

const infoLine = css`
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const boldText = css`
  font-weight: 600;
`;

interface TranscriptionTextsRowProps {
  item: TranscriptRow;
  setRowHeight: (size: number) => void;
  searchText?: string;
  chunks?: Chunk[];
  onTimeClick: VoidFunction;
  speakersInfo: Record<string, { name?: string; index: number }>;
  hideRowInfo?: boolean;
}

const _TranscriptionTextsRow = ({
  item: { speaker, text, time, id },
  setRowHeight,
  onTimeClick,
  chunks,
  searchText,
  speakersInfo,
  hideRowInfo,
}: TranscriptionTextsRowProps) => {
  const speakerName = speakersInfo[speaker]?.name || speaker;
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div ref={rowRef} css={rowContainerCss} data-testid='transcriptionTextRow'>
      <Typography variant='body1' css={getSpeakerCss(speakersInfo[speaker]?.index)} key={text}>
        {!hideRowInfo && (
          <span css={infoLine}>
            <Link onClick={onTimeClick} css={[getColor(speakersInfo[speaker]?.index), underline]}>
              {getTimeFromSeconds(time)}
            </Link>
            <Typography component='span' css={[textSpaceCss, boldText]}>
              {speakerName + ':'}
            </Typography>
          </span>
        )}
        <Row rowId={id} text={text} searchText={searchText} chunks={chunks} />
      </Typography>
    </div>
  );
};

interface RowProps {
  rowId: string;
  text: string;
  chunks?: Chunk[];
  searchText?: string;
}

const Row: React.FC<RowProps> = ({ rowId, text, chunks, searchText }) => {
  const indexes = buildSearchResult(text, searchText, chunks || []);

  return (
    <span css={textSpaceCss} id={rowId} className='highlighterRow'>
      {indexes?.map(({ start, end, tooltip, highlight, id = '' }, index) => {
        const item = text.slice(start, end);
        const key = index + start + end + id;
        if (highlight) {
          return (
            <BasicTooltip title={tooltip} key={key} placement='top'>
              <Typography component='span' css={id ? highlightStyle : highlightSearchStyle}>
                {item}
              </Typography>
            </BasicTooltip>
          );
        }
        return (
          <Typography key={key} component='span' fontWeight={500}>
            {item}
          </Typography>
        );
      })}
    </span>
  );
};

export const TranscriptionTextsRow = observer(_TranscriptionTextsRow);
