import { FieldsType, FieldType } from '../form/formTypes';
import { ReactNode } from 'react';
import { usePromptForm } from './usePromptForm';

const fields: FieldsType[] = [
  {
    name: 'text',
    label: '',
    type: FieldType.text,
    rows: 3,
  },
];
interface FormData {
  text: string;
}

export const usePromptSimple = () => {
  const promptForm = usePromptForm<FormData>(fields);
  const prompt = (title: ReactNode = '', defaultValue = '') => promptForm.prompt(title, { text: defaultValue } as any);

  return { prompt };
};
