import { Org } from '@laguna/api';
import {
  ConfigFragment,
  GetOrganizationsQuery,
  useGetBackofficePromptsQuery,
  useGetConfigsQuery,
  useGetOrganizationsQuery,
} from '@laguna/api/backoffice';
import { PromptFragment } from '@laguna/api/harmony';
import { useAsyncEffect } from '@laguna/common/utils/hooks';
import { logger } from '@laguna/logger';
import { cloneDeep, keyBy, merge } from 'lodash';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { getEnvSpecificQueriesResult, getMultiQueriesResults } from '../utils/getQueryResult';

type ConfigExtended = ConfigFragment & { orgName: string };

const getSingleConfig = (config: Partial<ConfigFragment>, prompts: Record<string, PromptFragment>) => {
  const updatedConfig: Record<string, any> = {};
  Object.keys(prompts).forEach((key) => {
    const version = config.value?.ai?.prompt?.[key]?.version;
    const requestType = config.value?.ai?.requestType?.[key];
    updatedConfig[key] = { version, requestType, key };
  });
  return updatedConfig;
};

const getCleanConfig = (config: Partial<ConfigFragment>, prompts: Record<string, PromptFragment>) => {
  const currentConfig = getSingleConfig(config, prompts);
  if (!config.parent) return currentConfig;
  const parentConfig: any = getCleanConfig(config.parent, prompts);
  const mergedConfig = merge(cloneDeep(parentConfig), currentConfig) || {};
  return mergedConfig;
};

export const getConfig = (config: Partial<ConfigFragment>, prompts: Record<string, PromptFragment>) => {
  const cleanConfig = getCleanConfig(config, prompts);
  // set to default version if no override
  Object.keys(prompts).forEach((key) => {
    const version = cleanConfig[key].version || prompts[key].version;
    cleanConfig[key] = { ...cleanConfig[key], version };
  });
  return cleanConfig;
};

const getOrgsObject = (orgData: Record<string, GetOrganizationsQuery>) => {
  let orgs: Partial<Org & { env: string }>[] = [];
  Object.keys(orgData).forEach((envName) => {
    const envOrgs = orgData[envName].getOrgs;
    orgs = [...orgs, ...envOrgs.map((org) => ({ ...org, env: envName }))];
  });
  return keyBy(orgs, 'id');
};

export const useMultipleOrgPromptConfig = () => {
  const [data, setData] = useState<ConfigExtended[]>([]);
  const promptKeys = useRef<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const [orgData, configs, promptsData] = await Promise.all([
        getMultiQueriesResults(useGetOrganizationsQuery),
        getEnvSpecificQueriesResult('develop', useGetConfigsQuery),
        getEnvSpecificQueriesResult('develop', useGetBackofficePromptsQuery),
      ]);

      if (!orgData || !configs || !promptsData) {
        throw new Error('Failed to fetch data: ' + JSON.stringify({ orgData, configs, promptsData }));
      }

      const activePrompts = keyBy(
        promptsData?.getPrompts.filter((prompt) => prompt.active),
        'key'
      );

      const orgsById = getOrgsObject(orgData);
      const configsData =
        configs?.getConfigs?.map((group) => {
          const orgName = orgsById[group.name]?.name || group.name;
          const orgEnv = orgsById[group.name]?.env || group.name;
          const config = getConfig(group, activePrompts);
          return { env: orgEnv, ...group, orgName, ...config };
        }) || [];
      setData(configsData);
      promptKeys.current = Array.from(new Set<string>(promptsData?.getPrompts.map((item) => item.key))).sort();
    } catch (error: any) {
      logger.error('Error on useMultipleOrgConfig', { error });
      enqueueSnackbar(error.message, { variant: 'error' });
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, isLoading, error, promptKeys: promptKeys.current };
};
