import { FieldsType } from '@laguna/common/form/formTypes';
import { DialogProps, Stack } from '@mui/material';
import { css } from '@mui/system';
import { identity, pickBy } from 'lodash';
import { ReactNode } from 'react';
import { ValidationMode } from 'react-hook-form';
import { v4 } from 'uuid';
import { formsStore as formsStore } from '../form/formsStore';
import { generateForm } from '../form/generateForm';
import { modalStore } from './modalStore';

const formWrapper = (theme: any) => css`
  form {
    gap: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
  }
`;

interface PromptOption<T> {
  formId?: string;
  showSkipButton?: boolean;
  validateForm?: (data: Partial<T>) => boolean;
  css?: any;
  dialogProps?: Partial<DialogProps>;
  useFormAsDependency?: boolean;
  omitFalsyResponseFields?: boolean;
  forceRequired?: boolean;
  useGrid?: boolean;
  validationMode?: keyof ValidationMode;
}

export const getPromptForm = <T,>(
  fields: FieldsType[],
  title: ReactNode,
  defaultValues: Partial<T> = {},
  options: PromptOption<T> = {}
) => {
  const { formId, showSkipButton, validateForm, css, omitFalsyResponseFields, forceRequired } = options;
  const id = formId || `prompt-${v4()}`;
  const { Form } = generateForm<T>({
    formId: id,
    fields,
    useGrid: options.useGrid,
    validationMode: options.validationMode,
  });

  const formProps = {
    isLoading: false,
    className: 'prompt-form',
    css,
    onChange: forceRequired ? () => formsStore.getFormActions(id).clearErrors() : undefined,
  };

  return new Promise<Partial<T> | null>((res) => {
    modalStore.updateModal({
      title,
      content: (
        <Stack css={css ? undefined : formWrapper}>
          <Form initialData={defaultValues} {...formProps} />
        </Stack>
      ),
      id,
      okButtonProps: {
        onClick: (close) => {
          const data = formsStore.getFormsValues(id);
          if (forceRequired) {
            const requiredFields = fields
              .filter((field) => field.required)
              .filter((reqField) => !data[reqField.name])
              .map((reqField) => reqField.name);

            if (requiredFields.length) {
              const actions = formsStore.getFormActions(id);
              requiredFields.forEach((reqField) => {
                actions.setError(
                  reqField,
                  { type: 'focus', message: '*This field is Required' },
                  { shouldFocus: true }
                );
              });
              return;
            }
          }

          const validationResult = !validateForm || validateForm(data);
          if (validationResult) {
            if (omitFalsyResponseFields) {
              res(pickBy(data, identity));
            } else {
              res(data);
            }
            close();
          }
        },
      },
      cancelButtonProps: {
        onClick: (close) => {
          res(null);
          close();
        },
      },
      skipButtonProps: showSkipButton
        ? {
            onClick: (close) => {
              res({});
              close();
            },
          }
        : undefined,
    });
    modalStore.setModalIsOpen(id, true);
  });
};
