import { Stack, Typography } from '@mui/material';
import React from 'react';

interface SelectListItemWithIconProps {
  itemText: string;
  itemIcon?: React.ReactNode;
  containerStyle?: React.CSSProperties;
}

export const SelectListItemWithIcon: React.FC<SelectListItemWithIconProps> = ({
  itemText,
  itemIcon = null,
  containerStyle,
  ...props
}) => {
  return (
    <Stack direction='row' component='span' style={containerStyle} {...props} justifyContent='space-between'>
      <Typography width='100%'>{itemText}</Typography>
      {itemIcon}
    </Stack>
  );
};
