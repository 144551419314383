// import { Auth } from 'aws-amplify';
import { makeAutoObservable } from 'mobx';
import { readJsonFile } from '../hooks/utils';
import { logger } from '@laguna/logger';

class BackOfficeUserStore {
  isAdmin = false;
  email = '';
  picture = '';

  constructor() {
    makeAutoObservable(this);
  }

  init = async () => {
    try {
      // const session = await Auth.currentSession();
      // const userInfo = session.getIdToken().payload;
      // this.email = userInfo.email;
      // this.picture = userInfo.picture;
      // const admins = await readJsonFile({ path: 'admins.json', defaultValue: '[]' });
      // this.isAdmin = admins.includes(userInfo.email);
    } catch (e: any) {
      logger.error(e);
    }
  };
}

export const backOfficeUserStore = new BackOfficeUserStore();
