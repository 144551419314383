import { HOUR_FORMAT_AM_PM } from '@laguna/common/consts';
import { DAY_DISPLAY_FORMAT_SHORT, getFormattedDate } from '@laguna/common/date-time-utils';
import { Stack, Typography } from '@mui/material';

export const DateTimeCell = ({ value = '', isDateOnly = false }) => {
  const date = getFormattedDate(new Date(value), DAY_DISPLAY_FORMAT_SHORT);
  const time = getFormattedDate(new Date(value), HOUR_FORMAT_AM_PM);

  return (
    <Stack>
      <Typography>{date}</Typography>
      {!isDateOnly && (
        <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
          {time}
        </Typography>
      )}
    </Stack>
  );
};
