import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { css } from '@mui/system';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ModalDataProps, modalStore } from './modalStore';
import { useDraggable } from 'libs/common/draggable';
import { CloseOutlined } from '@mui/icons-material';

const defaultCoordinates = {
  y: 10,
  x: 450,
};

const defaultContentStyle = css`
  min-width: 400px;
  padding-top: 1em !important;
  white-space: pre-line;
`;

const defaultModalStyle = () => css`
  h2 {
    white-space: break-spaces;
  }
`;

const draggableTitleCss = css`
  cursor: move;
`;

interface ModalProps extends ModalDataProps {
  onConfirm: (modalId: string) => void;
  onCancel: (modalId: string, reason: string) => void;
  onSkip: (modalId: string) => void;
  dialogProps?: Partial<DialogProps>;
}
export const Modal = (props: ModalProps) => {
  const {
    id,
    title,
    content,
    okButtonProps,
    skipButtonProps,
    cancelButtonProps,
    isDraggable,
    hideButtons,
    hideBackdrop,
    disableBackdropClick,
    showCloseIcon,
    onConfirm,
    onCancel,
    onSkip,
    isOpen = false,
    dialogProps = {},
  } = props;
  const { containerProps, style, setNodeRef } = useDraggable({ id, defaultCoordinates });

  const onClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason == 'backdropClick') {
      return;
    }

    onCancel(id, reason);
  };

  return (
    <Dialog
      open={isOpen}
      data-testid={id}
      hideBackdrop={hideBackdrop}
      onClose={onClose}
      css={defaultModalStyle}
      aria-labelledby={`form-dialog-title-${id}`}
      maxWidth={'inherits' as any}
      PaperProps={{ style: isDraggable ? style : undefined }}
      {...dialogProps}
      {...(isDraggable ? containerProps : {})}
      ref={isDraggable ? setNodeRef : undefined}>
      <DialogTitle css={isDraggable ? draggableTitleCss : undefined} id={`form-dialog-title-${id}`}>
        {toJS(title || '')}
        {showCloseIcon && (
          <IconButton onClick={() => onCancel(id, 'userClicked')} style={{ float: 'right' }}>
            <CloseOutlined />
          </IconButton>
        )}
      </DialogTitle>
      {content && (
        <>
          {title && <Divider />}
          <DialogContent id={`form-dialog-content-${id}`} css={defaultContentStyle}>
            {toJS(content)}
          </DialogContent>
        </>
      )}
      {!hideButtons && (
        <DialogActions>
          {!cancelButtonProps?.hidden && (
            <Button
              onClick={() => onCancel(id, '')}
              color='error'
              {...cancelButtonProps?.props}
              data-testid={`${id}-cancel-button`}>
              {cancelButtonProps?.label ? cancelButtonProps.label : 'Cancel'}
            </Button>
          )}
          {skipButtonProps && !skipButtonProps.hidden && (
            <Button onClick={() => onSkip(id)} {...skipButtonProps?.props} data-testid={`${id}-skip-button`}>
              {skipButtonProps?.label ? skipButtonProps.label : 'Skip'}
            </Button>
          )}
          {!okButtonProps?.hidden && (
            <Button
              onClick={() => onConfirm(id)}
              color='primary'
              {...okButtonProps?.props}
              data-testid={`${id}-ok-button`}>
              {okButtonProps?.label ? okButtonProps.label : 'Ok'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

const _BaseModal: React.FC = () => {
  const { openModalsData } = modalStore;

  return (
    <>
      {openModalsData.map((data) => (
        <Modal
          key={data.id}
          onCancel={modalStore.onCancel}
          onConfirm={modalStore.onConfirm}
          onSkip={modalStore.onSkip}
          {...data}
        />
      ))}
    </>
  );
};

export const BaseModal = observer(_BaseModal);
