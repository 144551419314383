import { Attribute } from '@laguna/api';

export interface JobBase {
  id: string;
  type: JobType;
  userId: string;
}
export enum JobType {
  transcript = 'transcript',
  sentiment = 'sentiment',
}
export type JobMetadata = {
  isDone?: boolean;
};

export interface Sentence {
  text: string;
  id: string;
}
export interface TranscriptRow extends Sentence {
  speaker: string;
  time: number;
}
export interface TranscriptTagInput {
  transcript: TranscriptRow[];
}
export interface SentimentTagInput {
  sentences: Sentence[];
}

export type TagInput = TranscriptTagInput | SentimentTagInput;

export type JobInput = {
  transcript: TranscriptRow[];
};

type OutputTagOrigin = {
  start: number;
  id?: string;
  end: number;
};
export type OutputTagTarget = {
  type: 'barrier' | 'attribute' | 'sentiment';
  id: string;
};

export type OutputTag = {
  origin: OutputTagOrigin[];
  target: OutputTagTarget;
  freeText?: string;
};

export type JobOutput = {
  userId: string;
  id: string;
  duration?: number;
  tags: OutputTag[];
  generalTags?: OutputTagTarget[];
};

interface S3Barrier {
  id: string;
  description: string;
  domain: string;
  type: string;
  sortingIndex: number;
}

export interface S3Types {
  attributes: Attribute[];
  barriers: S3Barrier[];
  sentiments: string[];
}
