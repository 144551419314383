export const insert = <T>(arr: T[], index: number, newItem: T[]) => [
  ...arr.slice(0, index),
  ...newItem,
  ...arr.slice(index),
];

export const updateItem = <T>(arr: T[], index: number, updateData: Partial<T>) =>
  arr.map((item, i) => (i === index ? { ...item, ...updateData } : item));

export const replaceItem = <T>(arr: T[], index: number, newItem: Partial<T>) =>
  arr.map((item, i) => (i === index ? newItem : item));

export const stringArrayToObject = (arr: string[], transformValues?: (value: string) => string) => {
  return arr.reduce<Record<string, string>>(
    (acc, current) => ({
      ...acc,
      [current]: transformValues ? transformValues(current) : current,
    }),
    {}
  );
};

export const arrayOfObjectsToObject = <T>(
  arr: T[],
  key: keyof T,
  value: keyof T,
  transformValue?: (value: any) => any
) => {
  return arr.reduce<Record<string, string>>(
    (acc, current) => ({
      ...acc,
      [(current as any)[key]]: transformValue ? transformValue((current as any)[value]) : (current as any)[value],
    }),
    {}
  );
};

export const arrayOfObjectsToObjectById = <T>(arr: T[], key: keyof T) => {
  return arr.reduce<Record<string, T>>(
    (acc, current) => ({
      ...acc,
      [(current as any)[key]]: current,
    }),
    {}
  );
};
