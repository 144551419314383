import { Label, Todo, TodoFragment, TodoStatus } from '@laguna/api';
import { SHORT_DATE_FORMAT } from '@laguna/common/consts';
import {
  getCronFields,
  getIsEveryDayInMonth,
  getIsEveryDayInWeek,
  getIsEveryMonth,
  getIsSpecificDate,
  getMinuteHourFromTime,
  getRoundedHour,
} from '@laguna/common/date-time-utils';
import { capitalizeCamelCase } from '@laguna/common/utils/general';
import { logger } from '@laguna/logger';
import parser, { CronFields } from 'cron-parser';
import { addDays, addHours, format, getDate, getHours, getMinutes, getMonth, setMinutes, startOfYear } from 'date-fns';
import i18next from 'i18next';
import { isEqual, uniq } from 'lodash';
import { CRON_WEEK_DAYS_ARRAY } from './consts';
import { Repeat, ToDoDisplayItem } from './types';

const actionTodoLabels = [Label.Explore, Label.Journal, Label.Questionnaire, Label.Scanner];
export const isActionLabel = (label?: Label) => !!(label && actionTodoLabels.includes(label));

export const getTimesAccordingToRepeatValue = (times: Date[], repeat: Repeat, timesADay: number) => {
  const getTimesArray = (count: number): Date[] => {
    const start = times.length ? times[0] : addHours(setMinutes(new Date(), 0), 1);
    const result = [start];
    for (let index = 1; index < count; index++) {
      result.push(times.length > index ? times[index] : addHours(start, index * 2));
    }
    return result;
  };
  return getTimesArray(repeat === Repeat.doesNotRepeat ? 1 : timesADay);
};

interface convertCronsToFormDataRes {
  times: Date[];
  repeat: Repeat;
  showDate: boolean;
  showEndDate: boolean;
  weekDays: number[];
  timesADay: number;
}

interface convertCronsToFormDataParams {
  start: Date;
  end?: Date;
  cronExpressions: string[];
}

export const getRepeatType = (firstCron?: string): Repeat => {
  if (!firstCron) {
    return Repeat.doesNotRepeat;
  }

  const [minutes, hours, days, month, dayOfWeek] = firstCron.split(' ');

  switch (dayOfWeek) {
    case '*': {
      return Repeat.doesNotRepeat;
    }
    case '0-6': {
      return Repeat.everyDay;
    }
    default: {
      return Repeat.specificWeekdays;
    }
  }
};

const getWeekDays = (crons: string[]): number[] => {
  return crons.flatMap((cron) => {
    const expression = parser.parseExpression(cron);
    const { fields } = expression;
    return fields.dayOfWeek;
  });
};

export const convertCronsToFormData = (
  params?: convertCronsToFormDataParams | Pick<Todo, 'start' | 'end' | 'cronExpressions'>
): convertCronsToFormDataRes => {
  const result: convertCronsToFormDataRes = {
    times: [new Date()],
    repeat: Repeat.doesNotRepeat,
    showDate: params ? !!params.start : true,
    showEndDate: !!params?.end,
    weekDays: [new Date().getDay()],
    timesADay: 1,
  };
  if (!params) {
    return result;
  }
  const { cronExpressions } = params;
  if (!cronExpressions || cronExpressions.length === 0) {
    return { ...result, showDate: false };
  }

  const repeat: Repeat = getRepeatType(cronExpressions[0]);
  if (repeat !== Repeat.doesNotRepeat) {
    result.weekDays = uniq(getWeekDays(cronExpressions));
  }
  const times = cronExpressions
    .map((cron: string): Date[] => {
      const expression = parser.parseExpression(cron);
      const { fields } = expression;
      const cronTimes = fields.hour.map((hour) => getRoundedHour(hour, fields.minute[0]));
      return cronTimes;
    })
    .flat();

  return {
    ...result,
    times,
    repeat,
    timesADay: times.length,
  };
};

export const getCronStringsByRepeat = (
  date: Date | undefined,
  times: Date[],
  repeat: Repeat,
  weekDays: number[]
): string[] => {
  if (!date) {
    return [];
  }
  switch (repeat) {
    case Repeat.doesNotRepeat: {
      const time = times[0];
      const fields = getCronFields({
        ...getMinuteHourFromTime(time),
        dayOfMonth: [getDate(date)],
        month: [getMonth(date) + 1],
      });

      return [parser.fieldsToExpression(fields).stringify()];
    }
    case Repeat.everyDay:
    case Repeat.specificWeekdays: {
      const dayOfWeek = repeat === Repeat.everyDay ? CRON_WEEK_DAYS_ARRAY : weekDays;
      if (dayOfWeek.length === 0) {
        return [];
      }
      const timesGroupedByMinutes = times.reduce<{ [key: number]: Date[] }>((acc, current) => {
        const minutes = getMinutes(current);
        if (!acc[minutes]) {
          acc[minutes] = [];
        }
        acc[minutes].push(current);
        return acc;
      }, {});

      return Object.entries(timesGroupedByMinutes).map(([minuteGroup, hourDates]) => {
        const fields = getCronFields({
          minute: [Number.parseInt(minuteGroup)],
          hour: [...new Set(hourDates.map((hourDate) => getHours(hourDate)))],
          dayOfWeek,
        });
        return parser.fieldsToExpression(fields).stringify();
      });
    }
  }
};

// if active/ updated or unscheduled
export const getCanUpdateToDoDone = ({ status, total }: Pick<ToDoDisplayItem, 'status' | 'total'>): boolean =>
  status === TodoStatus.active || status === TodoStatus.updated || !total;

export const canSaveTodo = (todo: Todo, initialTodo?: Todo) => {
  const { times } = convertCronsToFormData(todo);
  if (initialTodo) {
    const { times: initialTimes } = convertCronsToFormData(initialTodo);
    return (
      todo.text && (initialTodo.label !== todo.label || initialTodo.text !== todo.text || !isEqual(times, initialTimes))
    );
  } else {
    return todo.text && (!todo.start || (todo.start && times.length));
  }
};

export const getInitialTodo = (todo?: Todo): Pick<Todo, 'start' | 'end' | 'text' | 'label' | 'cronExpressions'> => ({
  start: todo?.start ? new Date(todo.start) : undefined,
  end: todo?.end ? new Date(todo.end) : undefined,
  text: todo?.text,
  label: todo?.label,
  cronExpressions: todo?.cronExpressions,
});

const getNotificationStartDates = (cron: string, startDate: Date, endDate: Date, count: number) => {
  const interval = parser.parseExpression(cron, {
    currentDate: startDate,
    endDate: endDate,
    iterator: true,
    utc: false,
  });
  try {
    const dates = [...Array(count)].map((_, i) => {
      const { value } = interval.next();
      return value.toDate();
    });
    return dates;
  } catch (error) {
    logger.error('cronToDoCalculationError', { error, cron, endDate, count });
    return [];
  }
};

interface getToDoRepeatTypeResponse extends CronFields {
  repeatType?: string;
  dates: Date[];
}
// we currently support notification every X times a month/week/day or onetime
/**
 *
 * @param cron
 * @returns repeat type, and notifications start dates.
 * start dates calculation:
 * minutes count * hours count * days count
 * Examples:
 * -- 1:
 * [0 30 17 * * 2,4] == on tuesdays & thursdays at 17:30:00
 * minutes count = 1, hours count = 1, days count = 2 => 1*1*2 = 2 -> we will have 2 start dates
 * -- 2:
 * [0 15,30 17-19 * * 2,4] == on tuesdays & thursdays at 17:15, 17:30, 18:15, 18:30, 19:15, 19:30
 * minutes count = 2, hours count = 3, days count = 2 => 2*3*2 = 2 -> we will have 12 start dates
 */
export const getToDoNotificationRepeat = (cron: string): getToDoRepeatTypeResponse => {
  const { fields } = parser.parseExpression(cron, { iterator: true, utc: true });
  const { dayOfMonth, month, dayOfWeek, hour, minute } = fields;
  const isEveryDayInMonth = getIsEveryDayInMonth(cron);
  const isEveryMonth = getIsEveryMonth(month);
  const isEveryDayInWeek = getIsEveryDayInWeek(dayOfWeek);
  const isOneTime = getIsSpecificDate(month, dayOfMonth);
  const res: getToDoRepeatTypeResponse = { ...fields, repeatType: undefined, dates: [] };

  const startDate = new Date();
  const endDate = addDays(new Date(startDate), 365);

  if (isOneTime) {
    res.dates = getNotificationStartDates(cron, startDate, endDate, 1);
    res.repeatType = undefined;
    return res;
  }

  // x times a month
  if (!isEveryDayInMonth && isEveryMonth) {
    res.dates = getNotificationStartDates(cron, startDate, endDate, dayOfMonth.length * hour.length * minute.length);
    res.repeatType = 'month';
    return res;
  }
  // x times a week
  if (!isEveryDayInWeek) {
    res.dates = getNotificationStartDates(cron, startDate, endDate, dayOfWeek.length * hour.length * minute.length);
    res.repeatType = 'week';
    return res;
  }
  // x times a day
  res.dates = getNotificationStartDates(cron, startDate, endDate, hour.length * minute.length);
  res.repeatType = 'day';
  return res;
};

// can be:
//  * one time
//  * x times a day && (y days a week ||  every day)
// for now we ignore times a month
const getCronFrequency = (cron: string) => {
  const { fields } = parser.parseExpression(cron, { iterator: true, utc: true });
  const { dayOfMonth, month, dayOfWeek, hour, minute } = fields;
  const isEveryDayInWeek = getIsEveryDayInWeek(dayOfWeek);
  const isOneTime = getIsSpecificDate(month, dayOfMonth);

  const timesADay = hour.length * minute.length;
  const timesAWeek = isEveryDayInWeek ? undefined : dayOfWeek.length;

  return { isOneTime, isEveryDayInWeek, timesADay, timesAWeek, oneTimeMonth: month, oneTimeDayOfMonth: dayOfMonth };
};

/**
 *  going through each cron expression
 *  assuming they are all in the same days, the only difference between crons is hour & minute
 *  log if there is a mismatch in the days
 *  returning
 *  - the total amount of times a day
 *  - the amount of days in week.
 *  - is one times
 */
export const getToDoFrequency = (cronExpressions: string[]) => {
  let isOneTime = true;
  let isEveryDayInWeek = false;
  let timesADay = 0;
  let timesAWeek = 0;
  let oneTimeMonth: number | undefined = undefined;
  let oneTimeDayOfMonth: number | undefined = undefined;

  for (const cron of cronExpressions) {
    const cronFrequency = getCronFrequency(cron);

    if (cronFrequency.isOneTime) {
      oneTimeMonth = cronFrequency.oneTimeMonth[0];
      oneTimeDayOfMonth = cronFrequency.oneTimeDayOfMonth[0] as number;
      continue;
    }
    if (!cronFrequency.isOneTime) {
      isOneTime = false;
    }

    if (!cronFrequency.isEveryDayInWeek && isEveryDayInWeek) {
      logger.error('mismatching days frequency in the same todo', { cronExpressions });
    }
    if (cronFrequency.isEveryDayInWeek) {
      isEveryDayInWeek = true;
    }

    if (timesAWeek && timesAWeek !== cronFrequency.timesAWeek) {
      logger.error('mismatching days frequency in the same todo', { cronExpressions });
    }
    if (cronFrequency.timesAWeek) {
      timesAWeek = Math.max(timesAWeek, cronFrequency.timesAWeek);
    }
    timesADay = timesADay + cronFrequency.timesADay;
  }
  return { isOneTime, isEveryDayInWeek, timesADay, timesAWeek, oneTimeMonth, oneTimeDayOfMonth };
};

export const getIntervalText = (count: number, repeatType: string) => {
  if (!count) {
    return null;
  }
  if (count === 1) {
    return i18next.t('common:frequency.once', { repeatType });
  }
  if (count === 2) {
    return i18next.t('common:frequency.twice', { repeatType });
  }
  return i18next.t('common:frequency.multiple', { count, repeatType });
};

export const getCronInFrequencyText = (cronExpressions: string[]) => {
  if (!cronExpressions || !cronExpressions.length) return;
  const { timesADay, isEveryDayInWeek, timesAWeek, isOneTime } = getToDoFrequency(cronExpressions);
  if (isOneTime) {
    return;
  }

  const timesADayText = getIntervalText(timesADay, i18next.t('common:frequency.repeatType.day'));
  const timesAWeekText = isEveryDayInWeek
    ? i18next.t('common:frequency.every', { repeatType: i18next.t('common:frequency.repeatType.day') })
    : getIntervalText(timesAWeek, i18next.t('common:frequency.repeatType.week'));

  return `${capitalizeCamelCase(timesADayText || '')}${timesADayText ? ', ' : ''}${timesAWeekText}`;
};

export const getTodoFrequencyInfo = ({ cronExpressions, start, end }: TodoFragment) => {
  if (!cronExpressions?.length || !start) return {};
  const { timesADay, isOneTime, oneTimeMonth, oneTimeDayOfMonth } = getToDoFrequency(cronExpressions);
  if (isOneTime && oneTimeMonth) {
    return {
      isOneTime: true,
      formattedDates: format(new Date(2000, oneTimeMonth - 1, oneTimeDayOfMonth), SHORT_DATE_FORMAT),
    };
  }

  return {
    formattedFrequency: getIntervalText(timesADay, i18next.t('common:frequency.repeatType.day')),
    formattedDates: end
      ? `${format(start, SHORT_DATE_FORMAT)}  - ${format(end, SHORT_DATE_FORMAT)}`
      : `${i18next.t('common:frequency.starts')} ${format(start, SHORT_DATE_FORMAT)}`,
  };
};

export const getFirstInstance = (todo: TodoFragment) => {
  const { cronExpressions, start } = todo;
  if (!cronExpressions?.length || !start) {
    return null;
  }
  const { isOneTime } = getToDoFrequency(cronExpressions);
  if (!isOneTime) {
    return null;
  }
  const cronObject = parser.parseExpression(cronExpressions[0], { currentDate: startOfYear(start) });
  if (cronObject.hasNext()) {
    return cronObject.next().toDate();
  }
  return null;
};

interface getToDoTextProps {
  type: Label;
  sourceType?: string;
  sourceName?: string;
}

export const getToDoTextAction = ({ type, sourceType }: getToDoTextProps) => {
  if (type !== Label.Explore) {
    return i18next.t(`common:toDos.actions.${type}`);
  }
  if (!sourceType) {
    return i18next.t('common:toDos.actions.Explore.article');
  }
  return i18next.t(`common:toDos.actions.${type}.${sourceType.toLowerCase()}`);
};
export const getToDoText = ({ type, sourceType, sourceName }: getToDoTextProps) => {
  if (type !== Label.Explore) {
    return i18next.t(`common:toDos.todosTexts.${type}`);
  }
  if (!sourceType) {
    return i18next.t('common:toDos.todosTexts.Explore.article', { name: sourceName });
  }

  return i18next.t(`common:toDos.todosTexts.${type}.${sourceType.toLowerCase()}`, { name: sourceName });
};
