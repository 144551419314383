import { useQuery } from '@tanstack/react-query';
import { S3Types } from './types';
import { readJsonFile } from './utils';

export const useGets3Types = () => {
  return useQuery<S3Types>(['s3', 'useGets3Types'], async () => {
    const [attributes, barriers, sentiments] = await Promise.all([
      readJsonFile({ path: 'attributes.json', defaultValue: '[]' }),
      readJsonFile({ path: 'barriers.json', defaultValue: '[]' }),
      readJsonFile({ path: 'sentiments.json', defaultValue: '[]' }),
    ]);

    return { attributes, barriers, sentiments } as S3Types;
  });
};
