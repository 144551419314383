import { Stack, Tab, Tabs } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ListArticles } from './ListArticles/ListArticles';
import { OrgList } from './OrgList/OrgList';
import { ARTICLES_TABS, TAB_URL_PARAM } from '../../Routing';

export const ArticlesRoot: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get(TAB_URL_PARAM) || ARTICLES_TABS.ArticlesList;

  return (
    <Stack>
      <Tabs value={currentTab} onChange={(event: React.SyntheticEvent, tab: string) => setSearchParams({ tab })}>
        <Tab label={i18next.t('articles:tabs.list')} value={ARTICLES_TABS.ArticlesList} />
        <Tab label={i18next.t('articles:tabs.orgOrder')} value={ARTICLES_TABS.ArticleOrder} />
      </Tabs>
      {currentTab === ARTICLES_TABS.ArticleOrder ? <OrgList /> : <ListArticles />}
    </Stack>
  );
};
