import { makeAutoObservable } from 'mobx';
import { UseFormReturn } from 'react-hook-form';

export class FormsStore {
  private _activeForms: { [id: string]: UseFormReturn<any> } = {};
  dynamicSelectChanges: { [name: string]: string } = {};
  activeFormsCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  triggerChange = (formId: string, fieldName: string, query: string) => {
    this.dynamicSelectChanges[formId + fieldName] = query;
  };

  removeTrigger = (formId: string, fieldName: string) => {
    delete this.dynamicSelectChanges[formId + fieldName];
  };

  addFormActions = (formId: string, formActions: UseFormReturn<any>) => {
    this._activeForms[formId] = formActions;
    this.activeFormsCount = Object.keys(this._activeForms).length;
  };

  removeFormActions = (formId: string) => {
    delete this._activeForms[formId];
    this.activeFormsCount = Object.keys(this._activeForms).length;
  };

  getFormActions = (formId: string) => {
    return this._activeForms[formId];
  };

  getFormsValues = (formId: string) => {
    return this.getFormActions(formId)?.getValues();
  };
}

export const formsStore = new FormsStore();
