import { Article, ArticleType } from '@laguna/api';
import { useGetArticlesQuery, useSetArticleMutation } from '@laguna/api/backoffice';
import { useAsyncMutation } from '@laguna/api/utils';
import { deleteFileFromStorage, putFileInStorage } from '@laguna/aws';
import { useConfirm } from '@laguna/common/modals';
import { SectionTitle } from '@laguna/components/Layout/SectionTitle';
import { logger } from '@laguna/logger';
import { Button, Card, CardContent } from '@mui/material';
import { Stack, css } from '@mui/system';
import { Editor } from '@tinymce/tinymce-react';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useMemo, useRef, useState } from 'react';
import { RichTextEditor } from '../../../components/RichTextEditor';
import { ArticleContentHeader } from './ArticleContentHeader';
import { ArticleOrgs } from './ArticleOrgs';
import { FormData, articleToFormData, formDataToSetParams, getForm, isBlobURL } from './consts';
import { clearCloudfrontCache, createContentBlob, getFormData } from './utils';
// --------------------------------

const wrapper = (theme: any) => css`
  width: 972px;
  form {
    gap: ${theme.spacing(3)};
    display: flex;
    flex-direction: column;
    position: relative;
    flex-wrap: wrap;
    max-height: 600px;
    & > div {
      width: 500px;
    }
    .generate-form-item-title,
    .generate-form-item-type,
    .generate-form-item-topic,
    .generate-form-item-booleans {
      width: 400px;
    }

    .generate-form-item-booleans {
      /* force break column */
      margin-bottom: 40px;
    }

    .generate-form-item-bigImageUrl {
      width: 300px;
      margin-right: 200px;
    }

    .generate-form-item-smallImageUrl {
      width: 180px;
      position: absolute;
      right: 0;
    }
  }
`;
interface ArticleContentProps {
  article: Article;
}

export const ArticleContent: React.FC<ArticleContentProps> = ({ article }) => {
  const { Form, Buttons } = useMemo(() => getForm(), []);
  const { formData, content } = useMemo(() => articleToFormData(article), [article]);

  const [isUpdating, setIsUpdating] = useState(false);
  const editorRef = useRef<Editor>();
  const { confirm } = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useAsyncMutation(useSetArticleMutation, {
    messages: { successMsg: i18next.t('common:updateSuccess', { type: i18next.t('articles:article') }) },
    invalidationQueryKey: useGetArticlesQuery.getKey(),
  });

  const catchAsyncOperation = async (operation: () => Promise<void>) => {
    setIsUpdating(true);
    try {
      await operation();
    } catch (error: any) {
      logger.error('Error updating article', { error, article });
      enqueueSnackbar(error?.message || 'Error', { variant: 'error' });
    } finally {
      setIsUpdating(false);
    }
  };

  const updateArticle = async (data: Partial<FormData>, isPublished: boolean) => {
    await catchAsyncOperation(async () => {
      const editor = editorRef.current?.editor;
      if (!editor) return;
      await editor.uploadImages();
      const currentContent = editor.getContent() || '';
      const setArticleParams = await formDataToSetParams(data, currentContent, article.id, isPublished);
      if (data.type !== ArticleType.Article && isBlobURL(data.videoUrl)) {
        const confirmed = await confirm('Do you want to extract duration from media?');
        if (confirmed) {
          const mediaElement = document.querySelectorAll(
            `[data-testid="generated-form-file-videoUrl"] ${data.type?.toLowerCase()}`
          )[0] as HTMLMediaElement;
          setArticleParams.duration = Math.ceil(mediaElement.duration);
        }
      }
      await mutateAsync({ setArticleParams });
    });
  };

  const onSubmit = (data: Partial<FormData>) =>
    catchAsyncOperation(async () => {
      if (!editorRef.current?.editor?.getContent()) {
        throw new Error('Cannot save empty content');
      }
      await updateArticle(data, article.isPublished); //keep
    });

  const publish = async () =>
    catchAsyncOperation(async () => {
      await updateArticle(getFormData(), true);
      const articleBlob = createContentBlob(editorRef.current?.editor?.getContent());
      await putFileInStorage(article.id, articleBlob, { explicitFileName: 'index.html' });
      await clearCloudfrontCache(article.id);
    });


  const unPublish = () =>
    catchAsyncOperation(async () => {
      await updateArticle(getFormData(), false);
      await deleteFileFromStorage(article.id + '/index.html');
      await clearCloudfrontCache(article.id);
    });

  return (
    <Stack gap={2} css={wrapper}>
      <ArticleContentHeader
        isLoading={isUpdating}
        Buttons={Buttons}
        onPublish={publish}
        onUnPublish={unPublish}
        article={article}
      />

      <SectionTitle title={i18next.t('articles:generalSection')} />
      <Card>
        <CardContent>
          <Form isLoading={isUpdating} initialData={formData} onSubmit={onSubmit} />
        </CardContent>
      </Card>
      <SectionTitle
        title={i18next.t('articles:textSection')}
      />
      <RichTextEditor
        uploadFolder={article.id}
        initialValue={content}
        editorRef={(editor) => (editorRef.current = editor)}
      />

      <ArticleOrgs article={article} />
    </Stack>
  );
};
