import { useSettings } from '@laguna/common/theme';
import { useLocalStorage } from '@laguna/common/utils/hooks';
import { NightsStay, WbSunny, Computer } from '@mui/icons-material';
import { Divider, ListSubheader, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { css } from '@mui/system';
import i18next from 'i18next';

const menuItemCss = css`
  .MuiToggleButton-root {
    width: 50%;
  }
`;

export const THEME_MODE_STORAGE_KEY = 'themeMode';

export const ToggleThemeMode = () => {
  const { setTheme } = useSettings();
  const [mode, setMode] = useLocalStorage(THEME_MODE_STORAGE_KEY, null, true);

  return (
    <>
      <Stack direction='column' width='100%' marginBottom={2} css={menuItemCss} paddingX={1}>
        <ListSubheader sx={{ lineHeight: 3 }}> {i18next.t('header:toggleTheme.mode')}</ListSubheader>
        <ToggleButtonGroup
          value={mode}
          exclusive
          style={{ width: '100%' }}
          onChange={(event, value) => {
            if (value && value !== 'system') {
              setTheme(value);
              setMode(value);
            }

            if (value && value === 'system') {
              setMode(value);
              setTheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
            }
          }}
          color='primary'
          aria-label='themeMode'>
          <ToggleButton value='light' className='toggleButtonLeft'>
            <WbSunny sx={{ marginRight: 1 }} />
            {i18next.t('header:toggleTheme.light')}
          </ToggleButton>
          <ToggleButton value='system'>
            <Computer sx={{ marginRight: 1 }} />
            {i18next.t('header:toggleTheme.system')}
          </ToggleButton>
          <ToggleButton value='dark' className='toggleButtonRight'>
            <NightsStay sx={{ marginRight: 1 }} />
            {i18next.t('header:toggleTheme.dark')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Divider />
    </>
  );
};
