import { ArticlePreviewData, ArticleViewer } from '@laguna/components/ArticleViewer';
import { Button, ButtonProps, Modal } from '@mui/material';
import { getTinymce } from '@tinymce/tinymce-react/lib/es2015/main/ts/TinyMCE';
import i18next from 'i18next';
import React, { useState } from 'react';
import { createArticleData } from './utils';
import { useSnackbar } from 'notistack';

const validateArticleData = (data: ArticlePreviewData) => {
  const getErrorMessage = () => {
    if (!data.title) {
      return 'missing title';
    }
    if (!data.bigImageUrl) {
      return 'missing image file';
    }
    if (!data.content) {
      return 'missing content';
    }
    if (!data.topic.length) {
      return 'missing topics';
    }
    if (!data.type) {
      return 'missing type';
    }
  };
  const error = getErrorMessage();
  if (error) {
    return `Can't preview - ${error}`;
  }
  return '';
};

export const ArticlePreview: React.FC<ButtonProps> = (buttonProps) => {
  const [article, setArticle] = useState<ArticlePreviewData>();
  const { enqueueSnackbar } = useSnackbar();

  const onPreviewClick = () => {
    const activeEditor = getTinymce(window)?.activeEditor;

    const data = createArticleData(activeEditor?.getContent());
    const error = validateArticleData(data);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      setArticle(data);
    }
  };
  const onClose = () => setArticle(undefined);

  return (
    <>
      <Button variant='outlined' onClick={onPreviewClick}>
        {i18next.t('articles:preview')}
      </Button>
      <Modal onClose={onClose} open={!!article}>
        <div>
          <ArticleViewer article={article} />
        </div>
      </Modal>
    </>
  );
};
