import { ArticleFragment, useAddArticleGroupMutation, useRemoveArticleGroupMutation } from '@laguna/api/backoffice';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { ExtendedGroup, useMultipleOrgArticleGroups } from '../../../hooks/useMultipleOrgArticleGroups';
import { logger } from '@laguna/logger';
import { getEnvSpecificMutationResult } from '../../../utils/getQueryResult';

type OrgData = ExtendedGroup & { exists: boolean };
export type GroupData = {
  name: string;
  articleGroups: OrgData[];
};

type DiffItem = { orgId: string; env: string };
const initValue = { added: [], removed: [] };
type DiffState = { added: DiffItem[]; removed: DiffItem[] };

/**
 * This function updates the diff lists
 * If an org was added, then we need to make sure its not in "removed" list and that its in "added" list
 * Same goes for if an org is removed, we need to make its not in "added" list
 */
const updateDiffStateForOrgId = (updateType: keyof DiffState, env: string, orgId: string) => {
  const noneEffectedListKey: keyof DiffState = updateType === 'added' ? 'removed' : 'added';
  return (oldDiff: DiffState): DiffState => {
    const noneEffectedList = oldDiff[noneEffectedListKey].filter((item) => !(item.env === env && item.orgId === orgId));
    const alreadyExistsInEffectedList = oldDiff[updateType].some((item) => item.env === env && item.orgId === orgId);
    return {
      [noneEffectedListKey]: noneEffectedList,
      [updateType]: alreadyExistsInEffectedList ? oldDiff[updateType] : [...oldDiff[updateType], { env, orgId }],
    } as DiffState;
  };
};

const addToOrgsMutations = async (diffAdded: DiffItem[], articleId: string) => {
  const addedByEnvs = groupBy(diffAdded, 'env');
  return await Promise.all(
    Object.entries(addedByEnvs).map(([env, effectedOrgs]) => {
      getEnvSpecificMutationResult(env, useAddArticleGroupMutation, {
        articleGroupParams: { articleId, orgIds: effectedOrgs.map((org) => org.orgId) },
      });
    })
  );
};
const removeFromOrgsMutations = async (diffRemove: DiffItem[], articleId: string) => {
  const removeByEnvs = groupBy(diffRemove, 'env');

  return await Promise.all(
    Object.entries(removeByEnvs).map(([env, effectedOrgs]) => {
      getEnvSpecificMutationResult(env, useRemoveArticleGroupMutation, {
        articleGroupParams: { articleId, orgIds: effectedOrgs.map((org) => org.orgId) },
      });
    })
  );
};

export const useEditArticleOrgGroup = (article: ArticleFragment) => {
  const [groups, setGroups] = useState<GroupData[]>([]);

  const [diff, setDiff] = useState<DiffState>(initValue);

  const { data, isLoading } = useMultipleOrgArticleGroups();

  const addToOrg = (env: string, orgId: string) => setDiff(updateDiffStateForOrgId('added', env, orgId));
  const removeFromOrg = (env: string, orgId: string) => setDiff(updateDiffStateForOrgId('removed', env, orgId));
  const updateDiff = (env: string, orgId: string, shouldAdd: boolean) => {
    if (shouldAdd) {
      addToOrg(env, orgId);
    } else {
      removeFromOrg(env, orgId);
    }
  };
  const toggleOrg = (env: string, orgId: string) => {
    setGroups((old) =>
      old.map((group) => {
        if (group.name !== env) return group;

        return {
          name: env,
          articleGroups: group.articleGroups.map((group) => {
            if (group.orgId !== orgId) {
              return group;
            }
            const exists = !group.exists;
            updateDiff(env, group.orgId, exists);
            return { ...group, exists };
          }),
        };
      })
    );
  };

  useEffect(() => {
    if (data) {
      setGroups(
        data.map(({ name, articleGroups }) => ({
          name,
          articleGroups: articleGroups.map((group) => ({
            ...group,
            exists: group.articles.some(({ id }) => id === article.id),
          })),
        }))
      );
    }
  }, [data]);

  const toggleEnv = (env: string, currentValue: boolean) => {
    setGroups((old) =>
      old.map((group) => {
        if (group.name !== env) return group;

        return {
          name: env,
          articleGroups: group.articleGroups.map((group) => {
            const exists = !currentValue;
            updateDiff(env, group.orgId, exists);
            return { ...group, exists };
          }),
        };
      })
    );
  };

  const updateOrgs = async () => {
    try {
      if (diff.added.length) {
        await addToOrgsMutations(diff.added, article.id);
      }
      if (diff.removed.length) {
        await removeFromOrgsMutations(diff.removed, article.id);
      }
    } catch (error) {
      logger.error('Update orgs failed', { error });
    }
    setDiff(initValue);
  };

  return { groups, isLoading, toggleOrg, isDirty: !(diff.added.length + diff.removed.length), updateOrgs, toggleEnv };
};
