import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, MenuItem, Select, SelectProps, SelectVariants, Tooltip } from '@mui/material';
import { omit, startCase } from 'lodash';
import { ReactNode } from 'react';
import { SelectListItemWithIcon } from '../form/Select/SelectListItemWithIcon';

type AdditionalProps = {
  nullable?: boolean;
  bold?: boolean;
  values: { [k: string]: string };
  noneLabel?: string;
  descriptions?: { [k: string]: string };
  transformValues?: (key: string) => string;
  renderOption?: (key: string) => string | ReactNode;
  variant?: SelectVariants;
};

type SimpleSelectProps = Omit<SelectProps<string>, 'variant'> & AdditionalProps;

export const SimpleSelect: React.FC<SimpleSelectProps> = (selectProps) => {
  const {
    nullable,
    bold,
    noneLabel = 'None',
    descriptions,
    transformValues = startCase,
    multiple,
    values,
    value,
  } = selectProps;

  return (
    <Select
      fullWidth
      {...omit(selectProps, 'transformValues', 'nullable')}
      multiple={multiple}
      renderValue={(selectedKeys: any) =>
        multiple
          ? (selectedKeys as string[]).map((key) => transformValues(values[key])).join(', ')
          : transformValues(values[selectedKeys])
      }>
      {nullable && (
        <MenuItem value=''>
          <em>{noneLabel}</em>
        </MenuItem>
      )}
      {Object.entries(values).map(([key, name]) => {
        return (
          <MenuItem key={key} value={key} sx={{ fontWeight: bold ? 'bold' : 'inherit' }}>
            {multiple && <Checkbox checked={(value as unknown as string[]).includes(key)} />}
            {descriptions ? (
              <SelectListItemWithIcon
                itemText={transformValues(name)}
                itemIcon={
                  descriptions[key] && (
                    <Tooltip title={descriptions[key]}>
                      <InfoOutlined />
                    </Tooltip>
                  )
                }
                containerStyle={{ width: '100%', padding: 8 }}
              />
            ) : (
              transformValues(name)
            )}
          </MenuItem>
        );
      })}
    </Select>
  );
};
