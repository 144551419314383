import React, { memo } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { ReactWindowList, ReactWindowListProps } from './ReactWindowList';

const DEFAULT_ITEM_HEIGHT = 55;

interface ReactWindowInfiniteListProps extends ReactWindowListProps {
  hasNextPage?: boolean;
  isLoading?: boolean;
  loadMoreItems?: VoidFunction;
  heightUsed?: number;
}

const _ReactWindowInfiniteList: React.FC<ReactWindowInfiniteListProps> = (props) => {
  const {
    isLoading = false,
    loadMoreItems,
    hasNextPage = false,
    renderItem,
    itemCount,
    defaultItemHeight = DEFAULT_ITEM_HEIGHT,
    height,
    ...rest
  } = props;

  const loadMore = () => {
    if (!isLoading) {
      loadMoreItems?.();
    }
  };
  const isItemLoaded = (index: number) => !hasNextPage || index < itemCount;

  return (
    <InfiniteLoader threshold={5} isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMore}>
      {({ onItemsRendered, ref }) => {
        return (
          <ReactWindowList
            height={height}
            defaultItemHeight={defaultItemHeight}
            itemCount={itemCount}
            renderItem={renderItem}
            onItemsRendered={onItemsRendered}
            ref={ref}
            {...rest}
          />
        );
      }}
    </InfiniteLoader>
  );
};
export const ReactWindowInfiniteList = memo(_ReactWindowInfiniteList);
