import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Checkbox as MuiCheckbox,
  Collapse,
  Theme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { gridArea } from './formStyles';
import { CheckboxField, SharedFieldProps, ChoiceOption, ChoiceRefOption } from './formTypes';
import { css } from '@mui/material';

const disabledCheckbox = (theme: Theme) => css`
  .MuiFormControlLabel-root {
    cursor: default;
  }
  .Mui-focused {
    color: ${theme.palette.text.primary};
  }
  .MuiButtonBase-root {
    pointer-events: none;
  }
`;
interface CheckboxProps extends SharedFieldProps, Omit<CheckboxField, 'type' | 'validate'> {}

interface CheckboxOption extends ChoiceOption {
  ref: ChoiceRefOption['ref'];
}

const isRefOptions = (options: CheckboxField['options']): options is ChoiceRefOption[] => {
  return Object.getOwnPropertyNames(options[0]).includes('ref');
};

const isStringOptions = (options: CheckboxField['options']): options is string[] => {
  return typeof options[0] === 'string';
};

const getCheckboxOptions = (options: CheckboxField['options']): CheckboxOption[] => {
  if (isStringOptions(options)) {
    return options.map((str) => ({ label: str, value: str, ref: undefined }));
  }
  if (isRefOptions(options)) {
    return options.map(({ ref, option }) => ({ label: option, value: option, ref }));
  }
  return options.map((option) => ({ ...option, ref: undefined }));
};

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  control,
  options,
  defaultValue,
  required,
  transformValues,
  disabled,
  readOnly,
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={{ required }}
    render={({ field: { onChange, value }, fieldState: { error } }) => {
      const disabledInteractivity = readOnly || disabled;
      const realDisabled = !readOnly && disabled;
      const _onChange = (option: string, checked: boolean) => {
        if (disabledInteractivity) {
          return;
        }
        const newValues = checked ? value.filter((val: string) => val !== option) : [...value, option];
        onChange(newValues);
      };
      const _options = getCheckboxOptions(options);

      return (
        <FormControl
          variant='outlined'
          required={!!required}
          fullWidth
          disabled={realDisabled}
          css={[gridArea(name), disabledInteractivity && disabledCheckbox]}
          data-testid={'generated-form-checkbox-' + name}
          className={'generate-form-item-' + name}>
          <FormLabel id={`checkbox-group-${name}`} component='h6'>
            {label}
          </FormLabel>
          <FormGroup>
            {_options.map(({ ref, value: optionValue, label }) => {
              const checked = (value ?? []).includes(optionValue.toString());
              return (
                <div key={optionValue}>
                  <FormControlLabel
                    disabled={realDisabled}
                    id={optionValue.toString()}
                    onChange={() => _onChange(optionValue.toString(), checked)}
                    control={<MuiCheckbox checked={checked} />}
                    label={transformValues ? transformValues(label) : label}
                  />
                  {ref && (
                    <Collapse in={checked}>
                      <div ref={ref} />
                    </Collapse>
                  )}
                </div>
              );
            })}
          </FormGroup>
          <FormHelperText>{error ? error.message : null}</FormHelperText>
        </FormControl>
      );
    }}
  />
);
