import { ArticleFragment } from '@laguna/api/backoffice';
import { SectionTitle } from '@laguna/components/Layout/SectionTitle';
import { Edit } from '@mui/icons-material';
import { Card, CardContent, Chip, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import i18next from 'i18next';
import React, { useState } from 'react';
import { EditArticleOrgs } from './EditArticleOrgs';
import { useOrgsContainingArticle } from './useOrgsContainingArticle';

interface ArticleOrgsProps {
  article: ArticleFragment;
}

export const ArticleOrgs: React.FC<ArticleOrgsProps> = ({ article }) => {
  const { data } = useOrgsContainingArticle(article);
  const [editOrgs, setEditOrgs] = useState(false);
  return (
    <Stack>
      <SectionTitle
        title={i18next.t('articles:orgsSection')}
        button={
          <IconButton onClick={() => setEditOrgs(true)}>
            <Edit />
          </IconButton>
        }
      />
      <Card>
        <CardContent>
          {data.map((group) => (
            <Stack direction='row' flexWrap='wrap' gap={2} key={group.env} marginY={2}>
              <Typography component='span' fontWeight='bold'>
                {group.env}:
              </Typography>
              {group.orgNames.length ? (
                group.orgNames.map((orgName, index) => <Chip key={orgName + index} label={orgName} />)
              ) : (
                <Typography component='span'>No organization relations</Typography>
              )}
            </Stack>
          ))}
        </CardContent>
        <Dialog onClose={() => setEditOrgs(false)} open={editOrgs}>
          <DialogContent>
            <Stack width={400}>
              <EditArticleOrgs article={article} />
            </Stack>
          </DialogContent>
        </Dialog>
      </Card>
    </Stack>
  );
};
