/// <reference types="chrome"/>
import { ExtensionMessageType, LAGUNA_NOW_EVENT, ExtensionState } from '@laguna/common/chromeExtension';
import { logger } from '@laguna/logger';
import { useEffect } from 'react';

const MAX_RETRIES = 3;

interface LagunaNowCustomEvent extends CustomEvent, Event {
  type: typeof LAGUNA_NOW_EVENT;
  detail: { data: ExtensionMessageType };
}

export const getExtensionId = () => document.getElementById('laguna-now-state')?.dataset.lagunaNowId || '';
export const isExtensionAvailable = () => !!getExtensionId();

export const useExtensionCommunication = (onMessage: (event: ExtensionMessageType) => void) => {
  useEffect(() => {
    const eventListener = (event: LagunaNowCustomEvent) => onMessage?.(event.detail.data);
    window.addEventListener(LAGUNA_NOW_EVENT, eventListener as EventListenerOrEventListenerObject);
    return () => {
      window.removeEventListener(LAGUNA_NOW_EVENT, eventListener as EventListenerOrEventListenerObject);
    };
  }, []);
};

export const sendMessageToExtension = (message: ExtensionMessageType, retriesLeft = MAX_RETRIES) => {
  if (retriesLeft <= 0) {
    logger.error('Extension ID not found - max retries reached', { message });
    return;
  }

  if (!chrome?.runtime) {
    return;
  }

  const lagunaNowId = getExtensionId();

  if (!lagunaNowId) {
    logger.warn('Extension ID not found - retrying', { message, retriesLeft });
    setTimeout(() => sendMessageToExtension(message, retriesLeft - 1), 250);
    return;
  }

  chrome.runtime.sendMessage(lagunaNowId, message);
};

export const getExtensionState = () =>
  new Promise<ExtensionState | undefined>((resolve) => {
    if (chrome?.runtime) {
      const lagunaNowId = getExtensionId();
      chrome.runtime.sendMessage(lagunaNowId, { type: 'getState' }, (response: ExtensionState) => {
        resolve(response);
      });
    } else {
      resolve(undefined);
    }
  });

export const dispatchWindowExtensionMessage = (message: ExtensionMessageType) => {
  window.dispatchEvent(
    new CustomEvent(LAGUNA_NOW_EVENT, {
      detail: message,
    })
  );
};
