import React from 'react';
import { Controller } from 'react-hook-form';
import { FrequencySelectField, SharedFieldProps } from '../formTypes';
import { ExternalAppointmentEditor } from './ExternalAppointmentEditor';

interface ExternalAppointmentInputProps extends SharedFieldProps, Omit<FrequencySelectField, 'type' | 'validate'> {}

export const ExternalAppointmentInput: React.FC<ExternalAppointmentInputProps> = ({
  name,
  control,
  defaultValue,
  required,
}) => {
  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return <ExternalAppointmentEditor {...{ onChange, value }} />;
      }}
    />
  );
};
