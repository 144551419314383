import { SECOND_MS } from '@laguna/common/consts';
import { FieldsType, FieldType } from '@laguna/common/form/formTypes';
import { usePromptForm } from '@laguna/common/modals';
import { Chunk } from '@laguna/components/TranscriptionViewer/types';
import { Button, CircularProgress, Stack } from '@mui/material';
import i18next from 'i18next';
import { groupBy, parseInt } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from '../../../components/PageTitle';
import { JobBase, JobType, OutputTag, OutputTagTarget, TagInput, TranscriptTagInput } from '../../../hooks/types';
import { useGetJobMetadata, useUpdateJobMetadata } from '../../../hooks/useGetJobMetadata';
import { useUpdateJobOutput } from '../../../hooks/useUpdateJobOutput';
import { useUserJobData } from '../../../hooks/useUserJobData';
import { useUserJobOutput } from '../../../hooks/useUserJobOutput';
import { PATHS, TaggingJobParams } from '../../../Routing';
import { SentimentTaggingJob } from './SentimentTaggingJob';
import { TaggingJobStatus } from './TaggingJobStatus';
import { TranscriptTaggingJob } from './TranscriptTaggingJob';
import { TagGroupItem } from './types';
import { outputTagsToMetadata, outputTagsToTaggingGroups } from './utils';
import { logger } from '@laguna/logger';

const fields: FieldsType[] = [
  { type: FieldType.text, name: 'duration', label: 'Tagging duration in minutes', inputType: 'number' },
];

interface DurationState {
  duration: string;
}

const AUTOSAVE_TIMEOUT = SECOND_MS * 2;

export const isTranscriptInput = (input?: TagInput): input is TranscriptTagInput =>
  input === undefined || 'transcript' in input;

export const TaggingJob: React.FC = () => {
  const navigate = useNavigate();
  const { jobId = '', userId = '', jobType = JobType.transcript } = useParams<Partial<TaggingJobParams>>();
  const jobInfo: JobBase = {
    id: jobId,
    userId,
    type: jobType as JobType,
  };

  const { data } = useUserJobData(jobInfo);
  const [generalTags, setGeneralTags] = useState<OutputTagTarget[]>([]);

  const { data: output, isLoading: isOutputLoading, refetch } = useUserJobOutput(userId, jobId);
  const { prompt } = usePromptForm<DurationState>(fields, { formId: 'tagDuration' });

  const [tags, setTags] = useState<OutputTag[]>([]);

  const [isPristine, setIsPristine] = useState(true);

  useEffect(() => {
    setTags(output?.tags || []);
    setGeneralTags(output?.generalTags || []);
    setIsPristine(true);
  }, [output]);

  const tagGroups = useMemo(() => outputTagsToTaggingGroups(tags), [tags]);

  const [selection, setSelection] = useState<Record<string, Chunk[]>>({});
  const { update, isLoading: isUpdating } = useUpdateJobOutput();
  const metaData = useMemo(() => outputTagsToMetadata(tags), [tags]);

  const updateJob = async (duration?: number) => {
    await update({ id: jobId, userId, tags, duration, generalTags });
    refetch();
  };

  const jobMetadata = useGetJobMetadata(jobInfo);
  const { update: updateDoneStatus, isLoading: isUpdatingDoneStatus } = useUpdateJobMetadata();

  // Autosave effect
  useEffect(() => {
    if (!isPristine) {
      const timer = setTimeout(() => {
        updateJob(output?.duration);
      }, AUTOSAVE_TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, [tags, output, generalTags, isPristine]);

  const postSelection = () => {
    setIsPristine(false);
    setSelection({});
  };

  const onSelect = async (
    chunks: Chunk[],
    selectedParagraphs: string,
    getTarget: () => Promise<OutputTagTarget | undefined>
  ) => {
    if (!chunks.length) {
      return;
    }

    const newSelection = groupBy(
      chunks.map((chunk) => ({ ...chunk, highlight: true })),
      'id'
    );
    setSelection(newSelection);

    const target = await getTarget();

    if (target) {
      const newTag: OutputTag = {
        freeText: selectedParagraphs,
        target,
        origin: Object.values(newSelection).flatMap((selectionTags) =>
          selectionTags.map(({ start, end, id = '' }) => ({ start, end, id }))
        ),
      };
      setTags((currentTabs) => [...currentTabs, newTag]);
      postSelection();
    }
  };

  const onAddGeneral = (newTag: OutputTagTarget) => {
    setGeneralTags((currentTags) => [...currentTags, newTag]);
    postSelection();
  };
  const onRemoveGeneral = (tagToRemove: OutputTagTarget) => {
    setGeneralTags((currentTags) => currentTags.filter((tag) => tag.id !== tagToRemove.id));
    postSelection();
  };

  const onTagRemove = (item: TagGroupItem, freeText: string) => {
    setTags((oldTags) => oldTags.filter((oldTag) => !(oldTag.freeText === freeText && oldTag.target.id === item.id)));
    postSelection();
  };

  const toggleDone = async () => {
    const newValue = !jobMetadata.data?.isDone;
    logger.debug('toggleDone started', { newValue, id: jobId });
    const result = await prompt(i18next.t('tagging:getDuration'));
    await updateDoneStatus(jobInfo, newValue);
    logger.debug('toggleDone completed', { newValue, id: jobId });
    jobMetadata.refetch();
    updateJob(result?.duration !== undefined ? parseInt(result?.duration) : undefined);
  };

  return (
    <Stack>
      <PageTitle
        title={
          <>
            {i18next.t('tagging:taggingJob', { jobId })}{' '}
            <TaggingJobStatus isUpdating={isUpdating || isOutputLoading} isPristine={isPristine} />
          </>
        }
        onBack={() => navigate('/' + PATHS.TAGGING)}
        actionButtons={
          <Stack direction='row' alignItems='center'>
            <Button disabled={jobMetadata.isLoading} onClick={toggleDone}>
              {jobMetadata.isLoading || isUpdatingDoneStatus ? (
                <CircularProgress size='1em' />
              ) : (
                i18next.t(jobMetadata.data?.isDone ? 'tagging:markAsWIP' : 'tagging:markAsDone')
              )}
            </Button>
          </Stack>
        }
      />
      {isTranscriptInput(data) ? (
        <TranscriptTaggingJob
          onSelect={onSelect}
          tagGroups={tagGroups}
          onAddGeneral={onAddGeneral}
          onRemoveGeneral={onRemoveGeneral}
          onTagRemove={onTagRemove}
          transcriptMetadata={{ ...selection, ...metaData }}
          transcript={data?.transcript || []}
          generalTags={generalTags}
        />
      ) : (
        <SentimentTaggingJob
          sentences={data?.sentences || []}
          onSelect={onSelect}
          tagGroups={tagGroups}
          onTagRemove={onTagRemove}
          transcriptMetadata={{ ...selection, ...metaData }}
        />
      )}
    </Stack>
  );
};
