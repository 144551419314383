import { TranscriptionViewer } from '@laguna/components/TranscriptionViewer';
import { Chunk } from '@laguna/components/TranscriptionViewer/types';
import { ArrowForward } from '@mui/icons-material';
import { Card, Divider, Stack, Typography } from '@mui/material';
import { css } from '@mui/system';
import i18next from 'i18next';
import React, { Fragment } from 'react';
import { OutputTag, SentimentTagInput, OutputTagTarget } from '../../../hooks/types';
import { TaggingGroup } from './TaggingGroup';
import { TagGroup, TagGroupItem } from './types';
import { useTagSentiment } from './useTagSentiment';
const sentenceWrapper = (theme: any) => css`
  display: grid;
  grid-template-columns: auto ${theme.spacing(4)} 400px;
`;
interface SentimentTaggingJobProps {
  sentences: SentimentTagInput['sentences'];
  onSelect: (
    chunks: Chunk[],
    selectedParagraphs: string,
    getTarget: () => Promise<OutputTagTarget | undefined>
  ) => Promise<void>;
  onTagRemove: (item: TagGroupItem, freeText: string) => void;
  transcriptMetadata: Record<string, Chunk[]>;
  tagGroups: TagGroup[];
}

export const SentimentTaggingJob: React.FC<SentimentTaggingJobProps> = ({
  sentences,
  transcriptMetadata,
  onSelect,
  onTagRemove,
  tagGroups,
}) => {
  const { tagSentiment } = useTagSentiment();
  const internalOnSelect = async (chunks: Chunk[], selectedParagraphs: string) =>
    onSelect(chunks, selectedParagraphs, tagSentiment);

  return (
    <Stack flex={1} gap={1}>
      {sentences.map((sentence, i) => {
        const relevantGroups = tagGroups.filter(({ id }) => id.startsWith(sentence.id));
        return (
          <Fragment key={sentence.id}>
            {!!i && <Divider />}
            <Stack data-testid='taggingSentence' css={sentenceWrapper}>
              <Stack padding={2}>
                <TranscriptionViewer
                  hideRowInfo={true}
                  transcriptMetadata={transcriptMetadata}
                  onSelect={internalOnSelect}
                  transcript={[{ ...sentence, time: 0, speaker: '' }]}
                />
              </Stack>
              <Stack paddingTop={2}>
                <ArrowForward />
              </Stack>
              <Card>
                <Stack padding={2} gap={1}>
                  {relevantGroups?.length ? (
                    relevantGroups.map((group) => (
                      <TaggingGroup key={group.id} group={group} onRemoveClicked={onTagRemove} />
                    ))
                  ) : (
                    <Typography>{i18next.t('tagging:noTags')}</Typography>
                  )}
                </Stack>
              </Card>
            </Stack>
          </Fragment>
        );
      })}
    </Stack>
  );
};
