import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Delete, DragIndicator } from '@mui/icons-material';
import { css, IconButton, Stack } from '@mui/material';
import { memo } from 'react';
import { FormField, FormFieldProps } from '../FormField';
import { OrderedListField } from '../formTypes';
import { getOrderedListRowFieldName } from './utils';
const rowCss = (theme: any) =>
  css`
    gap: ${theme.spacing(3)} ${theme.spacing(3)};
    width: 100%;
  `;

interface OrderListItem {
  row: any;
  field: OrderedListField;
  props: FormFieldProps;
  onDelete: VoidFunction;
}

const OrderedListItem_ = ({ row, field, props, onDelete }: OrderListItem) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Stack
      data-no-dnd='true'
      ref={setNodeRef}
      {...attributes}
      direction='row'
      css={rowCss}
      style={style}
      data-testid='ordered-list-item'>
      <IconButton {...listeners} disabled={props.disabled}>
        <DragIndicator />
      </IconButton>
      {field.fields.map((_field) => {
        return (
          <FormField
            key={_field.name}
            {...props}
            field={{ ..._field, name: getOrderedListRowFieldName(field, row.id, _field.name) }}
            initialData={row}
          />
        );
      })}
      <IconButton onClick={onDelete} disabled={props.disabled}>
        <Delete color={props.disabled ? 'disabled' : 'error'} />
      </IconButton>
    </Stack>
  );
};

export const OrderedListItem = memo(OrderedListItem_);
