import { logger } from '@laguna/logger';
import { MutateOptions, UseMutateAsyncFunction } from '@tanstack/react-query';
import { identity } from 'lodash';
type toastFunction = (message: string, { variant }: { variant: 'success' | 'info' | 'error' | 'warning' }) => void;

let showToast: toastFunction = (message, { variant }) => {
  // eslint-disable-next-line no-console
  console.log('calling initial showToast with', { message, variant });
};

export const initializeQueryMessage = (showToastFunc: toastFunction) => {
  showToast = showToastFunc;
};

export interface UseQueryMessageParams {
  errorMsg?: string;
  successMsg?: string;
  transformErrorMessage?: (message: string) => string;
}

export const extractGQLError = (data: any): string => {
  if (typeof data === 'string') return data;
  return data.message || data.errors?.flatMap((error: any) => error)?.[0].message;
};

export const useQueryMessages = ({
  errorMsg = '',
  successMsg = '',
  transformErrorMessage = identity,
}: UseQueryMessageParams) => {
  const onError = (params: any) => {
    const message = errorMsg || extractGQLError(params);
    showToast(transformErrorMessage(message), { variant: 'error' });
    const extra = params?.errors?.[0].originalError?.response?.data?.errors?.[0].message;
    logger.warn('graphqlRequestError', { message, extra });
  };

  const onSuccess = () => {
    if (successMsg) {
      showToast(successMsg, { variant: 'success' });
    }
  };

  return { onError, onSuccess };
};

type WrapperResult<T> = Promise<{ data: T; error?: undefined } | { error: string; data?: undefined }>;
type WrappedFunction<T, U> = (args: U, options?: MutateOptions<T, unknown, U, unknown>) => WrapperResult<T>;
export const wrapMutateAsync =
  <T, U>(asyncFunction: UseMutateAsyncFunction<T, unknown, U, unknown>): WrappedFunction<T, U> =>
  async (args: U, options?: MutateOptions<T, unknown, U, unknown>) => {
    try {
      const data: T = await asyncFunction(args, options);
      return { data };
    } catch ({ errors }: any) {
      return { error: extractGQLError(errors) };
    }
  };
