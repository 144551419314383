import { logger } from '@laguna/logger';
import { QueryKey } from '@tanstack/react-query';
import { getUserEnvs } from '../../utils';

const getAuthDetails = async (options?: any) => {
  if (options) {
    return {
      endpoint: options.url + '/graphql',
      Authorization: `Bearer ${options.token}`,
    };
  }
  const selectedEnv = '';
  return {
    endpoint: selectedEnv + '/graphql',
    Authorization: `Bearer ${selectedEnv}`,
  };
};

export const fetchData = <TData, TVariables>(query: string, variables?: TVariables, options?: any, key?: QueryKey) => {
  return async () => {
    logger.debug('graphqlRequestSend', { queryKey: key, variables });
    const { Authorization, endpoint } = await getAuthDetails(options);

    const apiCall = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    const res = await apiCall.json();
    return (res as any).data as TData;
  };
};
