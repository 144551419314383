import { AUTO_LOGOUT_DURATION_IN_MINUTES } from '@laguna/common/consts';
import { logger } from '@laguna/logger';
import { signOutAndReload } from './auth';
import { useRef } from 'react';
import { useIdleTimer as useIdleTimerLib } from 'react-idle-timer';

interface InnerRef {
  shouldPostponeSignOut: boolean;
  timeout?: ReturnType<typeof setTimeout>;
}

const IDLE_TIME_DURATION = AUTO_LOGOUT_DURATION_IN_MINUTES;

export const useSignOutOnIdle = () => {
  const closureRef = useRef<InnerRef>({
    shouldPostponeSignOut: false,
  });

  const onIdle = () => {
    if (closureRef.current.timeout) {
      clearTimeout(closureRef.current.timeout);
    }

    if (closureRef.current.shouldPostponeSignOut) {
      logger.info('user is in call');
      closureRef.current.timeout = setTimeout(onIdle, IDLE_TIME_DURATION);
    } else {
      logger.info('session timed out');
      signOutAndReload();
    }
  };

  useIdleTimerLib({
    onIdle,
    timeout: IDLE_TIME_DURATION,
    crossTab: true,
    syncTimers: 20,
    // eslint-disable-next-line no-restricted-globals
    name: location.host, // should separate envs
  });
  return closureRef;
};
