import { TextField } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import React from 'react';

interface DateTimePickerWrapperProps {
  label?: React.ReactNode;
  value?: Date;
  onChange: (date: Date) => void;
  className?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  disabled?: boolean;
}

const FORMAT = "yyyy-MM-dd'T'HH:mm";
export const DateTimePickerWrapper: React.FC<DateTimePickerWrapperProps> = ({
  label,
  value,
  onChange,
  variant,
  className,
  disabled,
}) => {
  const _onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    onChange(parse(event.target.value, FORMAT, new Date()));
  };

  const getValue = () => {
    const formatValue = value && isValid(value) && format(value, FORMAT);
    return formatValue ? formatValue : '';
  };

  return (
    <TextField
      className={className}
      label={label}
      disabled={disabled}
      value={getValue()}
      variant={variant}
      onChange={_onChange}
      type='datetime-local'
    />
  );
};
