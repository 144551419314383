export interface TagGroupItem {
  id: string;
  type: 'barrier' | 'attribute' | 'sentiment';
}
export interface TagGroup {
  id: string;
  freeText: string;
  items: TagGroupItem[];
}

export interface TagResourceFormState {
  mode: string;
  attributeKey: string;
  attributeValue: string;
  barrier: string;
}
export interface TagSentimentFormState {
  sentiment: string;
}
export enum TagResourcesMode {
  barrier = 'barrier',
  attribute = 'attribute',
}
