import { useAsyncEffect } from '@laguna/common/utils/hooks';
import { FCC } from '@laguna/types';
import { initAuthMgmt } from '@laguna/webAuth';
import { Google } from '@mui/icons-material';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { css } from '@mui/system';
import { getCurrentUser } from 'aws-amplify/auth';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import lagunaLogo from '../assets/logo_laguna_l.svg';

const signInWrapper = css`
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
  }
`;

const buttonCss = (theme: any) => css`
  margin-top: ${theme.spacing(2)};
`;

const isAuthenticated = async () => {
  try {
    await getCurrentUser();
    return true;
  } catch (error) {
    return false;
  }
};

export const GoogleSignIn: FCC = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const onSignIn = async () => {
    setIsAuthenticating(true);
    // await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  useAsyncEffect(async () => {
    const authResult = await isAuthenticated();
    if (authResult) {
      setAuthenticated(authResult);
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      initAuthMgmt();
    }
  }, [authenticated]);

  if (authenticated) {
    return <>{children}</>;
  }

  return (
    <Stack css={signInWrapper}>
      <img src={lagunaLogo} alt='laguna-logo' />
      <Typography variant='h4'>{i18next.t('login:title')}</Typography>
      <Button variant='contained' onClick={onSignIn} disabled={isAuthenticating} css={buttonCss}>
        {isAuthenticating ? (
          <Stack gap={2} direction='row'>
            <CircularProgress size={24} />
            <Typography component='span'>{i18next.t('login:authenticating')}</Typography>
          </Stack>
        ) : (
          <Stack gap={2} direction='row'>
            <Google />
            <Typography component='span'>{i18next.t('login:login')}</Typography>
          </Stack>
        )}
      </Button>
    </Stack>
  );
};
