import * as Types from '../types';

export type ArticleFragment = Pick<Types.Article, 'id' | 'title' | 'subTitle' | 'bigImageUrl' | 'smallImageUrl' | 'videoUrl' | 'duration' | 'isRecommended' | 'type' | 'topic' | 'articleMarkdown' | 'content' | 'seriesId' | 'seriesIndex' | 'isDefault' | 'isPublished'>;

export type ConfigFragment = (
  Pick<Types.Config, 'id' | 'name' | 'value'>
  & { parent?: Pick<Types.Config, 'name' | 'value'> }
);

export const ArticleFragmentDoc = `
    fragment articleFragment on Article {
  id
  title
  subTitle
  bigImageUrl
  smallImageUrl
  videoUrl
  duration
  isRecommended
  type
  topic
  articleMarkdown
  content
  seriesId
  seriesIndex
  isDefault
  isPublished
}
    `;
export const ConfigFragmentDoc = `
    fragment configFragment on Config {
  id
  name
  value
  parent {
    name
    value
  }
}
    `;