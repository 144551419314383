import {
  GetOrgsArticleGroupsQuery,
  useGetOrganizationsQuery,
  useGetOrgsArticleGroupsQuery,
} from '@laguna/api/backoffice';
import { useAsyncEffect } from '@laguna/common/utils/hooks';
import { logger } from '@laguna/logger';
import { keyBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { getMultiQueriesResults } from '../utils/getQueryResult';

export type ExtendedGroup = GetOrgsArticleGroupsQuery['getOrgsArticleGroups'][number] & { name: string };
interface EnvData {
  name: string;
  articleGroups: ExtendedGroup[];
}

export const useMultipleOrgArticleGroups = () => {
  const [data, setData] = useState<EnvData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const orgData = await getMultiQueriesResults(useGetOrganizationsQuery);
      if (!orgData) {
        throw new Error('Empty useGetOrgsArticleGroupsQuery');
      }
      const orgArticles = await getMultiQueriesResults(useGetOrgsArticleGroupsQuery);
      if (!orgArticles) {
        throw new Error('Empty useGetOrgsArticleGroupsQuery');
      }
      const envData: EnvData[] = Object.entries(orgArticles).map(([env, value]) => {
        const orgsById = keyBy(orgData[env].getOrgs, 'id');

        return {
          name: env,
          articleGroups: value.getOrgsArticleGroups.map((group) => {
            return { ...group, name: orgsById[group.orgId]?.name };
          }),
        };
      });
      setData(envData);
    } catch (error: any) {
      logger.error('Error on useMultipleOrgArticleGroups', { error });
      enqueueSnackbar(error.message, { variant: 'error' });
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, isLoading, error };
};
