import { DataTable } from '@laguna/components/DataTable';
import { DataTableColumn } from '@laguna/types';
import { Stack } from '@mui/material';
import React from 'react';
import { useUserJobs } from '../../hooks/useUserJobs';
import { useUserName } from '../../hooks/useUserName';

const columns: DataTableColumn[] = [
  {
    name: 'userId',
    label: 'User',
  },
  {
    name: 'type',
    label: 'Type',
  },
  {
    name: 'id',
    label: 'Job',
    width: 450,
    type: {
      kind: 'link',
      getInfo: (row: any) => ({
        path: row.metadata.jobLink,
        label: row.id,
      }),
    },
  },
  {
    name: 'createdAt',
    label: 'Created At',
    type: {
      kind: 'dateTime',
    },
  },
  {
    width: 100,
    name: 'isDone',
    label: 'Done',
    type: {
      kind: 'bool',
    },
  },
];
export const MyTaggingJobs: React.FC = () => {
  const userName = useUserName();
  const defaultFilters = { isDone: ['false'], userId: [userName] };
  const { data, isLoading } = useUserJobs();

  return (
    <Stack height='90vh'>
      <DataTable
        tableWidthInVW={40}
        tableId='taggingJobs'
        title='Tagging Jobs'
        defaultFilters={defaultFilters}
        data={data || []}
        isLoading={isLoading}
        columns={columns}
      />
    </Stack>
  );
};
