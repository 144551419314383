import { SECOND_MS } from '@laguna/common/consts';
import { STORAGE_KEY_PREFIX, sendMessageToExtension } from '@laguna/common/utils/hooks';
import { logger } from '@laguna/logger';
import { logoutTabSync } from './tabsManager';
import { useSignoutMutation } from '@laguna/api/harmony';
import { getMutationResult } from '@laguna/api/utils';
import { getCurrentUser, signOut as amplifySignOut, fetchAuthSession } from 'aws-amplify/auth';

export const LAST_POLL_TIME_KEY = 'harmonyLastPollTime';
export const ORIGIN_JTI_KEY = 'ORIGIN_JTI_KEY';
export const STALE_TAB_POLL_INTERVAL_MS = 1 * SECOND_MS;
export const STALE_TAB_THRESHOLD = 70 * SECOND_MS;

const logModuleError = (errorMessage: string, { message, stack }: any) =>
  logger.error(`auth.ts - ${errorMessage}`, { error: { message, stack } });

// Clear local storage but skip auth mgmt keys
const clearLocalStorage = () => {
  Object.keys(localStorage)
    .filter((key) => !key.startsWith(STORAGE_KEY_PREFIX))
    .forEach((key) => localStorage.removeItem(key));
};

const moduleState: { interval?: ReturnType<typeof setInterval> } = {};

export const setIsFirstTime = () => localStorage.removeItem(LAST_POLL_TIME_KEY);

export const signOut = async () => {
  try {
    try {
      await useSignoutMutation.fetcher({});
    } catch (e) {
      logModuleError('useSignoutMutation', e);
    }
    clearLocalStorage();
    await amplifySignOut();
    await sendMessageToExtension({
      type: 'clearCredentials',
    });
    logoutTabSync();
  } catch (error) {
    logModuleError('signOut', error);
  }
};

const getCurrentTime = () => new Date().getTime();

const getLastPollTime = () => {
  const savedKey = localStorage.getItem(LAST_POLL_TIME_KEY);
  if (savedKey === null) {
    return getCurrentTime();
  }
  const result = Number(savedKey);
  const isResultInValid = isNaN(result) || savedKey === ''; // for some reason Number('') => 0
  return isResultInValid ? null : result;
};

const setLastPollTime = () => localStorage.setItem(LAST_POLL_TIME_KEY, getCurrentTime().toString());

export const signOutAndReload = async (preReloadCallback?: VoidFunction) => {
  if (moduleState.interval) {
    clearInterval(moduleState.interval);
    moduleState.interval = undefined;
  }
  await signOut();
  if (typeof preReloadCallback === 'function') {
    preReloadCallback();
  }
  window.location.reload();
};

const checkStaleTab = () => {
  const lastPollTime = getLastPollTime();
  setLastPollTime(); // we want to get last poll time even if we refresh

  if (!lastPollTime) {
    logModuleError('Malformed lastPollTimeKey', localStorage.getItem(LAST_POLL_TIME_KEY));
    signOutAndReload();
    return;
  }

  const msPassedSinceLastPoll = getCurrentTime() - lastPollTime;

  if (msPassedSinceLastPoll > STALE_TAB_THRESHOLD) {
    logModuleError('Stale tab, reloading page', { timePassedSinceLastPoll: msPassedSinceLastPoll });
    signOutAndReload();
  }
};
const startPollingForStaleTab = () => {
  moduleState.interval = setInterval(() => {
    checkStaleTab();
  }, STALE_TAB_POLL_INTERVAL_MS);
  checkStaleTab();
};

const signOutIfInvalidSession = async () => {
  try {
    await getCurrentUser();
  } catch (error) {
    logModuleError('invalid session, skipping sign out', error);
    await signOutAndReload();
  }
};

export const initAuthMgmt = async () => {
  if (process.env.NX_IS_DEV_SERVER) {
    logger.log('dev server found. skipping auto logout mechanic');
    return;
  }

  await signOutIfInvalidSession();
  startPollingForStaleTab();
};

const isNewSession = async (): Promise<boolean> => {
  const session = await fetchAuthSession();
  const decodedPayload = session.tokens?.idToken?.payload;
  const originJTI = decodedPayload?.origin_jti?.toString() || '';
  const storedOriginJTI = localStorage.getItem(ORIGIN_JTI_KEY);

  localStorage.setItem(ORIGIN_JTI_KEY, originJTI);
  return originJTI !== storedOriginJTI;
};

export const checkForNewSession = async () => {
  if (process.env.NX_IS_DEV_SERVER) {
    return;
  }
  const newSession = await isNewSession();
  return newSession;
};
