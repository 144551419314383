import { Stack, Typography, CircularProgress, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useMultipleOrgArticleGroups } from '../../../hooks/useMultipleOrgArticleGroups';
import { ItemsList } from '../../../components/ItemsList';
import { ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../Routing';

const TransitionProps = { unmountOnExit: true, mountOnEnter: true };

export const OrgList: React.FC = () => {
  const { data, isLoading } = useMultipleOrgArticleGroups();
  const navigate = useNavigate();
  const onOrgClicked = (env: string, orgId: string) => navigate(`/${PATHS.ORG_ORDER}/${env}/${orgId}`);

  return (
    <Stack>
      <Stack direction='row'>
        <Typography variant='h4'>{i18next.t('articles:tabs.list')}</Typography>
      </Stack>
      {isLoading && <CircularProgress />}
      {data.map((envData) => (
        <Accordion key={envData.name} TransitionProps={TransitionProps}>
          <AccordionSummary expandIcon={<ExpandMore />} id={envData.name}>
            <Typography>{envData.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ItemsList
              items={envData.articleGroups}
              primaryText='name'
              onClick={(group) => onOrgClicked(envData.name, group.orgId)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};
