import { Attribute, SetAttributeOptionParams } from '@laguna/api';
import {
  useGetAttributesQuery,
  usePublishAttributeOptionMutation,
  useSetAttributeOptionMutation,
} from '@laguna/api/backoffice';
import { useAsyncMutation } from '@laguna/api/utils';
import { useConfirm } from '@laguna/common/modals';
import { capitalizeCamelCase } from '@laguna/common/utils/general';
import { logger } from '@laguna/logger';
import { ArrowBack } from '@mui/icons-material';
import { Button, Card, CardContent, CircularProgress, css, IconButton, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../Routing';
import { attributeDraft, getAttributeForm, NEW_ATTRIBUTE_ID_PATH, PUBLISH_MODE, VIEW_MODE } from './consts';

const titleCss = (theme: any) => css`
  margin-top: ${theme.spacing(2)};
`;

const attrTitleCss = (theme: any) => css`
  margin-bottom: ${theme.spacing(3)};
`;
const wrapper = (theme: any) => css`
  form {
    gap: ${theme.spacing(3)};
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const EditAttribute: React.FC = () => {
  const { attributeId, mode } = useParams();
  const navigate = useNavigate();
  const { data } = useGetAttributesQuery();
  const [attribute, setAttribute] = useState<Attribute>();
  const { Form, controller } = useMemo(() => getAttributeForm(data?.getAttributes || []), [data?.getAttributes]);
  const { confirm } = useConfirm();
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingEdit } = useAsyncMutation(useSetAttributeOptionMutation, {
    messages: { successMsg: i18next.t('common:updateSuccess', { type: i18next.t('attributes:attribute') }) },
    invalidationQueryKey: useGetAttributesQuery.getKey(),
  });
  const { mutateAsync: mutateAsyncPublish, isLoading: isLoadingPublish } = useAsyncMutation(
    usePublishAttributeOptionMutation,
    {
      messages: { successMsg: i18next.t('common:updateSuccess', { type: i18next.t('attributes:attribute') }) },
      invalidationQueryKey: useGetAttributesQuery.getKey(),
    }
  );
  const initialData = useMemo(() => {
    if (attribute) {
      const values = attribute.values.map((item) => ({ optionKey: item }));
      return { ...attribute, values };
    }
    return {};
  }, [attribute]);

  useEffect(() => {
    if (attribute) return;
    if (attributeId === NEW_ATTRIBUTE_ID_PATH) {
      setAttribute(attributeDraft);
      return;
    }
    if (data) {
      const foundAttribute = data.getAttributes.find((item: Attribute) => item.key === attributeId);
      if (foundAttribute) {
        setAttribute(foundAttribute);
      } else {
        logger.error(`Attribute ${attributeId} not found`);
      }
    }
  }, [data]);

  const submit = async () => {
    const data = await controller.submit();

    if (!data) return;

    const key = attribute?.key || data.key;
    if (!key || !data.category || !data.values?.length) {
      return;
    }
    const setAttributeOptionParams: SetAttributeOptionParams = {
      key,
      category: data.category.trim(),
      description: data.description?.trim(),
      values: data.values?.map((item) => item.optionKey.trim()) || [],
    };
    if (data.key && key !== data.key) {
      setAttributeOptionParams.renameKey = data.key.trim();
    }
    const res = await mutateAsyncUpdate({ setAttributeOptionParams });
    if (res.data?.setAttributeOption) {
      setAttribute(res.data?.setAttributeOption);
    }
  };

  const onPublish = async () => {
    if (attribute?.key) {
      const res = await confirm(
        i18next.t('attributes:editAttribute.confirmBeforePublish', { title: capitalizeCamelCase(attribute.key) }),
        i18next.t('attributes:editAttribute.confirmBeforePublishWarning')
      );
      if (res) {
        mutateAsyncPublish({ publishAttributeOptionParams: { key: attribute.key } });
      }
    }
  };

  const isEditMode = mode !== VIEW_MODE && mode !== PUBLISH_MODE;
  return (
    <Stack gap={2} css={wrapper}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={1}>
        <Typography variant='h5' css={titleCss}>
          <IconButton onClick={() => navigate(`/${PATHS.ATTRIBUTES}`)}>
            <ArrowBack />
          </IconButton>
          {i18next.t('attributes:editAttribute.title', { name: capitalizeCamelCase(attribute?.key || attributeId) })}
        </Typography>
        {mode === PUBLISH_MODE && (
          <Button onClick={onPublish} disabled={isLoadingPublish}>
            {i18next.t('common:publish')}
          </Button>
        )}
        {isEditMode && (
          <Button color='primary' variant='contained' disabled={isLoadingEdit} onClick={submit}>
            {isLoadingEdit ? <CircularProgress size={16} /> : i18next.t('common:save')}
          </Button>
        )}
      </Stack>
      <Card>
        <CardContent>
          <Typography variant='subtitle1' css={attrTitleCss}>
            {i18next.t('attributes:editAttribute.sectionAttribute')}
          </Typography>
          {attribute && (
            <Form disabled={!isEditMode} isLoading={isLoadingEdit || isLoadingPublish} initialData={initialData} />
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};
