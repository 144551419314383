import { useGetOrganizationsQuery, GetOrganizationsQuery } from '@laguna/api/backoffice';
import { useAsyncEffect } from '@laguna/common/utils/hooks';
import { useState } from 'react';
import { getEnvSpecificQueriesResult } from '../utils/getQueryResult';

type OrgInfo = GetOrganizationsQuery['getOrgs'][number];

export const useOrgFromEnv = (env: string, orgId: string) => {
  const [data, setData] = useState<OrgInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const data = await getEnvSpecificQueriesResult(env, useGetOrganizationsQuery);
      if (data) {
        setData(data.getOrgs.find((org) => org.id === orgId));
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [env, orgId]);

  return {
    data,
    isLoading,
    error,
  };
};
