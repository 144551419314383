import { throttle } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { useCallback } from 'react';
import { useStoreObserver } from '.';

type SetterFunction<T> = ((oldVal: T) => T) | ((oldVal: T) => Promise<T>);
type SetValueCallback<T> = (newVal: T | SetterFunction<T>) => void;

export enum StoreStateKeys {
  Todos = 'Todos',
  TodoInstances = 'TodoInstances',
  SelectedAdmission = 'SelectedAdmission',
  SelectedEnv = 'SelectedEnv',
  ChatViewerMode = 'ChatViewerMode',
  ChatMessageMetadata = 'ChatMessageMetadata',
  TypewriterCurrentSentence = 'TypewriterCurrentSentence',
  PreventMediaPreview = 'PreventMediaPreview',
  AvailableChatsInvalidator = 'AvailableChatsInvalidator',
  NewAvailableChat = 'NewAvailableChat',
  RealtimeTranscript = 'RealtimeTranscript',
  CallCopilotDrawerCollapsed = 'CallCopilotDrawerCollapsed',
}

// suffix type implementation from https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html#intrinsic-string-manipulation-types
export type TodoPrefixKey = `${StoreStateKeys.Todos}${Lowercase<string>}`;
export type TodoInstancesPrefixKey = `${StoreStateKeys.TodoInstances}${Lowercase<string>}`;

type StoreKeys = {
  [key in StoreStateKeys]?: any;
} & { [key: TodoPrefixKey | TodoInstancesPrefixKey]: any };

class StateStore {
  values: StoreKeys = {};

  constructor() {
    makeAutoObservable(this);
  }

  private setValueInKey = (key: keyof StoreKeys, value: any) => {
    this.values[key] = value;
  };

  private setValueWithUpdaterFunction = (key: keyof StoreKeys, updater: any | SetterFunction<any>) => {
    const oldVal = this.values[key];
    const promiseOrValue = (updater as SetterFunction<any>)(oldVal);
    Promise.resolve(promiseOrValue).then((value) => {
      this.setValueInKey(key, value);
    });
  };

  private innerSetter = (key: keyof StoreKeys, updater: any | SetterFunction<any>) => {
    if (typeof updater == 'function') {
      this.setValueWithUpdaterFunction(key, updater);
    } else {
      this.setValueInKey(key, updater);
    }
  };

  setKey = throttle(this.innerSetter, 300);
  reset = () => {
    this.values = {};
  };
}
export const stateStore = new StateStore();

export const useStoreState = <T>(key: keyof StoreKeys, initialValue?: T): [T, SetValueCallback<T>] => {
  const value = useStoreObserver(stateStore.values, key, initialValue);

  const setValue = useCallback((newVal: T | SetterFunction<T>) => stateStore.setKey(key, newVal), [key]);

  return [value, setValue];
};
