import { logger } from '@laguna/logger';
import { useState, useEffect } from 'react';
export const STORAGE_KEY_PREFIX = 'session_exclusion';
/**
 * details on the atob/btoa utf-8 encoding issues + solution:
 * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 */
function toBinary(stringToEncode: string) {
  const codeUnits = new Uint16Array(stringToEncode.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = stringToEncode.charCodeAt(i);
  }
  return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

function fromBinary(encoded: string) {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

const EVENT_KEY_PREFIX = 'storageKeyChanged_';

export const getLocalStorageItem = (key: string, decrypt?: boolean) => {
  const result = localStorage.getItem(`${STORAGE_KEY_PREFIX}_${key}`);
  if (!result) return null;
  return JSON.parse(decrypt ? fromBinary(result) : result);
};
export const setLocalStorageItem = (_key: string, newValue: any, currentValue?: any, encrypt?: boolean) => {
  const key = `${STORAGE_KEY_PREFIX}_${_key}`;
  const eventKey = EVENT_KEY_PREFIX + key;
  const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;
  const stringResult = JSON.stringify(result);
  localStorage.setItem(key, encrypt ? toBinary(stringResult) : stringResult);
  window.dispatchEvent(new CustomEvent(eventKey, { detail: { value: result } }));
  return result;
};

export const useLocalStorage = <ValueType>(_key: string, defaultValue: ValueType, encrypt?: boolean) => {
  const key = `${STORAGE_KEY_PREFIX}_${_key}`;
  const eventKey = EVENT_KEY_PREFIX + key;
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue === null ? defaultValue : JSON.parse(encrypt ? fromBinary(storedValue) : storedValue);
    } catch (e) {
      // in case of decode failure, reset key
      localStorage.setItem(key, '');
      return '';
    }
  });

  useEffect(() => {
    // listener fires when local storage changes are made in another document
    const listenerBetweenTabs = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        const { newValue } = e;
        if (newValue) {
          try {
            const decryptedValue = encrypt ? fromBinary(newValue) : newValue;
            // string values arrive wrapped with " so parse removes them
            const parsedValue = JSON.parse(decryptedValue);
            setValue(parsedValue);
          } catch (error) {
            logger.error('Failed parsing newValue', { value: e.newValue, error, key });
          }
        } else {
          setValue(newValue);
        }
      }
    };
    const listenerInTab = (e: any) => {
      setValue(e.detail.value);
    };
    window.addEventListener(eventKey, listenerInTab);
    window.addEventListener('storage', listenerBetweenTabs);

    return () => {
      window.removeEventListener('storage', listenerBetweenTabs);
      window.removeEventListener(eventKey, listenerInTab);
    };
  }, [key, defaultValue, eventKey, encrypt]);

  const setValueInLocalStorage = (newValue: ValueType) => {
    setValue((currentValue: any) => setLocalStorageItem(_key, newValue, currentValue, encrypt));
  };

  return [value, setValueInLocalStorage];
};
