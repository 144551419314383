import { FormControl, FormHelperText, Stack, Theme, Tooltip, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ColoredAvatar, gridArea } from './formStyles';
import { ChoiceOption, SharedFieldProps } from './formTypes';
import { ChoiceStepper } from './ChoiceStepper';
import { css } from '@mui/material';

const choiceColumns = (theme: Theme) => css`
  flex-direction: row;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  margin: 0 -${theme.spacing(2)};
  align-items: center;
  > p {
    flex: 1;
  }
`;

const coloredChoice = (theme: Theme) => css`
  background-color: ${theme.palette.background.secondary};
`;

interface ChoiceInputProps extends SharedFieldProps {
  name: string;
  options: ChoiceOption[];
  readOnly?: boolean;
  isColored?: boolean;
  transformValues?: (key: string) => string;
}

export const ChoiceInput: React.FC<ChoiceInputProps> = ({
  name,
  label,
  control,
  options,
  defaultValue,
  required,
  disabled,
  readOnly,
  isColored,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({
        field: { onChange, value: selectedValue },
        fieldState: { error, invalid },
        formState: { errors },
      }) => {
        return (
          <FormControl
            variant='outlined'
            disabled={disabled}
            required={!!required}
            fullWidth
            css={gridArea(name)}
            className={'generate-form-item-' + name}>
            <Stack
              css={[choiceColumns, isColored && coloredChoice]}
              className='choiceColumns'
              data-testid='choiceColumns'>
              <Typography>{label}</Typography>
              {readOnly ? (
                <ChoiceStepper selectedValue={selectedValue} options={options} />
              ) : (
                <Stack flexDirection='row' display='flex' gap={2}>
                  {options.map(({ value, label }, index) => {
                    const valueString = value.toString();
                    return (
                      <Tooltip key={value} title={label} enterDelay={0} arrow disableInteractive>
                        <ColoredAvatar
                          key={value}
                          disabled={disabled}
                          data-testid={selectedValue === valueString ? 'selectedOption' : ''}
                          selected={selectedValue === valueString}
                          onClick={() => !disabled && onChange(valueString)}>
                          {value}
                        </ColoredAvatar>
                      </Tooltip>
                    );
                  })}
                </Stack>
              )}
            </Stack>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
