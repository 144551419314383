import { createQueryClient } from '@laguna/api/utils';
import { initAWS } from '@laguna/aws';
import { MINUTE } from '@laguna/common/consts';
import { useAsyncEffect } from '@laguna/common/utils/hooks';
import { QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { initTranslation } from '../translation/initTranslation';
import { setupS3BucketCredentials } from './utils';
import { backOfficeUserStore } from '../stores/backOfficeUserStore';

// should be used if using password login
export const beforeAuthenticated = async () => {
  await setupS3BucketCredentials();
  await backOfficeUserStore.init();
};

export const useInitApp = () => {
  const [isInit, setIsInit] = useState(false);
  const [queryClient, setQueryClient] = useState<QueryClient>({} as QueryClient);

  useAsyncEffect(async () => {
    initAWS();
    initTranslation();
    setQueryClient(() => createQueryClient({ staleTime: 10 * MINUTE }));
    await beforeAuthenticated();
    setIsInit(true);
  }, []);

  return { isInit, queryClient };
};
