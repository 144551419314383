import { cast } from '@laguna/api/utils';
import { useOnUnmount } from '@laguna/common/utils/hooks';
import { ReactWindowList } from '@laguna/components/ReactWindowList';
import { VariableSizeListRef } from '@laguna/types';
import { MenuItem, MenuList, Popover, PopoverPosition, Stack, Typography, css } from '@mui/material';
import i18next from 'i18next';
import { noop } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent, useState } from 'react';
import AutoSizerBase, { AutoSizerProps } from 'react-virtualized-auto-sizer';
import { TranscriptionTextsRow } from './TranscriptionTextsRow';
import { Chunk, TranscriptRow } from './types';
import { buildMetadata } from './utils';

const AutoSizer = cast<React.FC<AutoSizerProps>>(AutoSizerBase);
const SELECTED_TEXT_THRESHOLD = 10;
const MINIMUM_HEIGHT = 200;
const autoSizerStyle = { height: '100%', width: '100%' };
const noTranscriptCss = css`
  text-align: center;
  padding: 24px;
`;

interface ActionButtonProps {
  label: string;
  onClick: (selectedParagraphs: string) => void | Promise<void>;
}

interface TranscriptionTextsProps {
  transcript: TranscriptRow[];
  actionButtons?: ActionButtonProps[];
  search?: string;
  onSelect?: (chunks: Chunk[], selectedParagraphs: string) => void | Promise<void>;
  onTimeClicked?: (time: number) => void;
  getRef?: (ref: VariableSizeListRef) => void;
  transcriptMetadata?: Record<string, Chunk[]>;
  speakersInfo?: Record<string, { name?: string; index: number }>;
  height?: number | ((height: number) => number);
  hideRowInfo?: boolean;
  selectionThreshold?: number;
}

const TranscriptionTexts: React.FC<TranscriptionTextsProps> = ({
  transcript,
  actionButtons,
  search,
  onSelect = noop,
  onTimeClicked = noop,
  getRef = noop,
  transcriptMetadata = {},
  speakersInfo = {},
  height,
  hideRowInfo,
  selectionThreshold = SELECTED_TEXT_THRESHOLD,
}) => {
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition>();
  const [selectedParagraphs, setSelectedParagraphs] = useState('');

  const onMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    const selectionObj = window.getSelection && window.getSelection();
    const anchorNode = selectionObj?.anchorNode;
    const focusNode = selectionObj?.focusNode;
    const startId = anchorNode?.parentElement?.closest('.highlighterRow')?.id;
    const endId = focusNode?.parentElement?.closest('.highlighterRow')?.id;
    const selectedText = selectionObj?.toString().trim();
    if (!startId || !selectionObj || !selectedText || selectedText.length < selectionThreshold) {
      return;
    }
    const { metadata, paragraphArray } = buildMetadata(selectedText, transcript, startId, endId);
    setAnchorPosition({ top: event.clientY, left: event.clientX });
    const newSelectedParagraphs = paragraphArray.join('\n');
    setSelectedParagraphs(newSelectedParagraphs);
    onSelect(metadata, newSelectedParagraphs);
  };

  const clear = () => {
    setAnchorPosition(undefined);
    onSelect([]);
  };

  useOnUnmount(clear);

  if (!transcript?.length) {
    return (
      <Typography css={noTranscriptCss} variant='h6'>
        {i18next.t('sessions:recordings.noTranscript')}
      </Typography>
    );
  }

  const wrapActionItemClick = (callBack: (selection: string) => void | Promise<void>) => async () => {
    await callBack(selectedParagraphs);
    clear();
  };

  return (
    <>
      <Stack onMouseUp={onMouseUp} flex={1} minHeight={MINIMUM_HEIGHT}>
        {transcript.length === 1 ? (
          <TranscriptionTextsRow
            item={transcript[0]}
            setRowHeight={noop}
            speakersInfo={speakersInfo}
            onTimeClick={() => onTimeClicked(transcript[0].time)}
            chunks={transcriptMetadata[transcript[0].id]}
            searchText={search}
            hideRowInfo={hideRowInfo}
          />
        ) : (
          <AutoSizer style={autoSizerStyle}>
            {({ height: autoSizerHeight, width }) => {
              const getHeight = () => {
                if (!height) {
                  return autoSizerHeight;
                }
                return typeof height === 'function' ? height(autoSizerHeight) : height;
              };
              return (
                <ReactWindowList
                  height={getHeight()}
                  itemCount={transcript.length}
                  width={width}
                  ref={getRef}
                  defaultItemHeight={82}
                  renderItem={(index, setRowHeight) => {
                    const item = transcript[index];
                    return (
                      <TranscriptionTextsRow
                        item={item}
                        setRowHeight={setRowHeight}
                        speakersInfo={speakersInfo}
                        onTimeClick={() => onTimeClicked(item.time)}
                        chunks={transcriptMetadata[item.id]}
                        searchText={search}
                        hideRowInfo={hideRowInfo}
                      />
                    );
                  }}
                />
              );
            }}
          </AutoSizer>
        )}
      </Stack>
      {actionButtons?.length && (
        <Popover
          id='transcriptContextPopover'
          open={!!anchorPosition}
          anchorReference='anchorPosition'
          anchorPosition={anchorPosition}
          onClose={clear}>
          <MenuList>
            {actionButtons.map((actionButton) => (
              <MenuItem key={actionButton.label} onClick={wrapActionItemClick(actionButton.onClick)}>
                {actionButton.label}
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      )}
    </>
  );
};

export const TranscriptionViewer = observer(TranscriptionTexts);
