import { FormControlLabel, FormHelperText, FormLabel, Switch as SwitchMui } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { SwitchWrapper } from './formStyles';
import { SharedFieldProps, SwitchField } from './formTypes';
import { omit } from 'lodash';

interface SwitchProps extends SharedFieldProps, Omit<SwitchField, 'type' | 'validate'> {}

export const Switch: React.FC<SwitchProps> = ({
  label,
  name,
  control,
  value,
  description,
  defaultValue,
  validateOnChange,
  isHidden,
  required,
  ...rest
}) => {
  const cleanRest = omit(rest, [
    'initialData',
    'setValue',
    'formId',
    'formState',
    'fieldState',
    'getDerivedState',
    'isHidden',
    'watch',
  ]);
  const [checked, setChecked] = useState(!!defaultValue);
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const newVal = event.target.checked;
          if (validateOnChange && !validateOnChange(defaultValue, newVal)) {
            return;
          }

          onChange(newVal);
          setChecked(newVal);
        };
        return (
          <FormControlLabel
            css={SwitchWrapper}
            className={'generate-form-item-' + name}
            control={<SwitchMui checked={checked} onChange={handleChange} required={!!required} {...cleanRest} />}
            label={
              <div>
                <FormLabel>{label}</FormLabel>
                {!!description && <FormHelperText>{description}</FormHelperText>}
              </div>
            }
          />
        );
      }}
    />
  );
};
