import { useQuery } from '@tanstack/react-query';
import { JobBase, TagInput } from './types';
import { getJobPath, readJsonFile } from './utils';

export const useUserJobData = (job: JobBase) => {
  return useQuery<TagInput>(
    ['s3', 'userJobData', job.id],
    async () => {
      return readJsonFile({ path: getJobPath(job) });
    },
    { enabled: !!job }
  );
};
