import { css, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import { HeaderGroup } from 'react-table';

const stickyHeaderCss = css`
  position: sticky;
  z-index: 4;
  top: 0;
`;

const rowCss = (theme: any) => css`
  width: 100%;
  display: flex;
  .MuiTableCell-root {
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    background-color: ${theme.palette.background.secondary};
  }
`;

interface TableColumnHeadersProps {
  headerGroups: HeaderGroup[];
  setSort: (sortArray: any) => void;
}

export const TableColumnHeaders: React.FC<TableColumnHeadersProps> = ({ headerGroups, setSort }) => {
  return (
    <TableHead component='div' css={stickyHeaderCss}>
      {headerGroups.map((headerGroup) => {
        const props = headerGroup.getHeaderGroupProps();
        return (
          <TableRow component='div' key={props.key} css={rowCss} data-testid='tableHeaderRow'>
            {headerGroup.headers.map((column) => {
              const props = column.getHeaderProps();
              const sortProps = column.getSortByToggleProps();
              const direction = column.isSortedDesc ? 'desc' : 'asc';

              const onClick = (e: any) => {
                sortProps?.onClick?.(e);
                if (column.isSortedDesc === false) {
                  setSort([]);
                } else {
                  const desc = !column.isSortedDesc === true; // if current isSortedDesc undefined - desc is going to be true. if current isSortedDesc is true, desc is false
                  setSort([{ id: column.id, desc }]);
                }
              };

              return (
                <TableCell
                  {...props}
                  key={props.key}
                  onClick={onClick}
                  sortDirection={column.isSorted ? direction : false}
                  style={{ ...(props?.style || {}), width: column.totalWidth }}
                  component='div'>
                  <TableSortLabel active={column.isSorted} direction={direction} onClick={onClick}>
                    {column.render('Header')}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableHead>
  );
};
