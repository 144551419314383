import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, css, List, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import { findIndex } from 'lodash';
import { Dispatch, useEffect, useState } from 'react';
import { FormFieldProps } from '../FormField';
import { OrderedListField } from '../formTypes';
import { OrderedListItem } from './OrderedListItem';
import { getOrderedListRowFieldName } from './utils';

const wrapperCss = (theme: any) => css`
  gap: ${theme.spacing(3)} ${theme.spacing(3)};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

interface OrderedListOverride extends FormFieldProps {
  initialData: Record<string, any[]>;
}

interface OrderedListProps {
  field: OrderedListField;
  props: OrderedListOverride;
}
export const OrderedList: React.FC<OrderedListProps> = ({ field, props }) => {
  const initialData: any[] =
    props.initialData[field.name]?.map((item, index) => {
      const id = index.toString(); // dnd kit is not OK with falsy values (0)
      const res: { [key: string]: string } = { id };
      Object.keys(item).map((key) => {
        const resKey = getOrderedListRowFieldName(field, id, key);
        res[resKey] = item[key];
      });
      return res;
    }) || [];
  const [rows, _setRows] = useState<any[]>(initialData);
  const [nextIndex, setNextIndex] = useState(initialData.length);

  const setRows: Dispatch<any> = (rowsFunction) => {
    _setRows((prev) => {
      const newRows = rowsFunction(prev);
      props.setValue(field.name, newRows);
      return newRows;
    });
  };

  const addRow = () => {
    setRows((prev: typeof initialData) => [...prev, { id: nextIndex.toString() }]);
    setNextIndex((prev: number) => prev + 1);
  };

  const removeRow = (id: string) => () => {
    setRows((prev: any[]) => prev.filter((item) => item.id !== id));
    field.fields.map((_field) => {
      props.control.unregister(getOrderedListRowFieldName(field, id, _field.name));
    });
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over?.id && active.id !== over.id) {
      setRows((items: any[]) => {
        const oldIndex = findIndex(items, (item) => item.id === active.id);
        const newIndex = findIndex(items, (item) => item.id === over.id);
        const newRows = arrayMove(items, oldIndex, newIndex);
        props.setValue(field.name, newRows);
        return newRows;
      });
    }
  };

  useEffect(() => {
    props.control.register(field.name, { required: field.required });
    props.setValue(field.name, rows);
  }, []);

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <List css={wrapperCss} data-no-dnd='true'>
        <Stack direction='row' width='100%' justifyContent='space-between'>
          <Typography variant='h6'>{field.label}</Typography>
          <Button variant='outlined' onClick={addRow} disabled={props.disabled}>
            {i18next.t('common:components.orderedList.add')}
          </Button>
        </Stack>
        <SortableContext items={rows.map((item) => item.id)} strategy={verticalListSortingStrategy}>
          {rows.map((row) => (
            <OrderedListItem key={row.id} row={row} field={field} props={props} onDelete={removeRow(row.id)} />
          ))}
        </SortableContext>
      </List>
    </DndContext>
  );
};
