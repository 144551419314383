import { Controller } from 'react-hook-form';
import { SharedFieldProps } from './formTypes';
import { DateTimePickerWrapper } from '@laguna/common/PickerWrappers';
import { gridArea } from './formStyles';
import { FormControl } from '@mui/material';

const DateTimeInput: React.FC<SharedFieldProps> = ({ name, label, control, disabled, defaultValue, required }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <FormControl css={gridArea(name)} style={{ margin: 0 }} disabled={disabled}>
          <DateTimePickerWrapper label={label} value={new Date(value)} onChange={onChange} disabled={disabled} />
        </FormControl>
      )}
    />
  );
};

export default DateTimeInput;
