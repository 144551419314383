import { Box, TextField } from '@mui/material';
import { format, parse } from 'date-fns';
import React from 'react';
import { HOUR_FORMAT } from '@laguna/common/consts';

interface MinimalTimePickerProps {
  value: Date;
  onChange: (newDate: Date) => void;
}

export const MinimalTimePicker: React.FC<MinimalTimePickerProps> = ({ value, onChange }) => {
  const selectTime: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    if (e.target.value) {
      onChange(parse(e.target.value, HOUR_FORMAT, new Date()));
    }
  };

  return (
    <Box textAlign='center'>
      <TextField
        type='time'
        value={format(value, HOUR_FORMAT)}
        onChange={selectTime}
        inputProps={{
          ['data-testid']: 'minimalTimePicker',
        }}
      />
    </Box>
  );
};
