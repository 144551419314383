import { Attribute, AttributeTag } from '@laguna/api';
import { useGetAttributesQuery } from '@laguna/api/backoffice';
import { Button, CircularProgress, css, Paper, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemsList } from '../../components/ItemsList';
import { NEW_ATTRIBUTE_ID_PATH, PUBLISH_MODE, VIEW_MODE } from './consts';

const titleCss = (theme: any) => css`
  margin-bottom: ${theme.spacing(3)};
  margin-top: ${theme.spacing(3)};
`;
const attributeListStyle = css`
  max-height: 85vh;
  overflow: auto;
`;
export const ListAttributes: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetAttributesQuery();
  const createAttribute = async () => {
    navigate(NEW_ATTRIBUTE_ID_PATH);
  };

  const navigateToEdit = (attribute: Attribute) => {
    if (!attribute.tags?.includes(AttributeTag.published)) {
      navigate(attribute.key);
    } else {
      navigate(attribute.key + '/' + VIEW_MODE);
    }
  };

  const isPublished = (attribute: Attribute) => !!attribute.tags?.includes(AttributeTag.published);

  const onPublish =
    ({ key }: Attribute) =>
    (event: any) => {
      event.stopPropagation();
      navigate(key + '/' + PUBLISH_MODE);
    };

  return (
    <Stack>
      <Stack direction='row' justifyContent='space-between' css={titleCss}>
        <Typography variant='h4'>{i18next.t('attributes:listAttributes.title')}</Typography>
        <Button onClick={createAttribute} variant='contained'>
          {i18next.t('attributes:listAttributes.addAttribute')}
        </Button>
      </Stack>
      <Stack component={Paper} padding={2} css={attributeListStyle}>
        {isLoading && (
          <Stack height={400} alignItems='center' justifyContent='center'>
            <CircularProgress />
          </Stack>
        )}
        <ItemsList
          items={data?.getAttributes.map((item) => ({ ...item, id: item.key })) || []}
          primaryText={(attribute) => (
            <Typography variant='subtitle2'>
              {attribute.key}
              <Typography component='span' color={(theme) => theme.palette.text.secondary} variant='caption'>
                {isPublished(attribute)
                  ? i18next.t('attributes:listAttributes.published')
                  : i18next.t('attributes:listAttributes.draft')}
              </Typography>
            </Typography>
          )}
          secondaryText='description'
          onClick={navigateToEdit}
          buttonProperty={(attribute) =>
            isPublished(attribute) ? undefined : { title: i18next.t('common:publish'), onClick: onPublish(attribute) }
          }
          groupBy='category'
        />
      </Stack>
    </Stack>
  );
};
