import { Button, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ItemsList } from '../../components/ItemsList';
import { useArticleOrder } from './useArticleOrder';
import {
  ArrowBack,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material';
import { ARTICLE_ORDER_TAB_ABSOLUTE } from '../../Routing';
import i18next from 'i18next';
import { useOrgFromEnv } from '../../hooks/useOrgFromEnv';

export const EditArticlesOrder: React.FC = () => {
  const { env = '', orgId = '' } = useParams();
  const { data: orgInfo } = useOrgFromEnv(env, orgId);
  const navigate = useNavigate();
  const {
    toggleSelectedArticle,
    selectedGroup,
    moveBottom,
    moveDown,
    moveTop,
    moveUp,
    isDirty,
    isChecked,
    updateOrg,
    isSelected,
  } = useArticleOrder(env, orgId);

  if (!selectedGroup || !orgInfo) {
    return null;
  }

  return (
    <Stack>
      <Stack>
        <Typography variant='h5'>
          <IconButton onClick={() => navigate(ARTICLE_ORDER_TAB_ABSOLUTE)}>
            <ArrowBack />
          </IconButton>
          {i18next.t('order:title', { orgName: orgInfo.name, env: env })}
        </Typography>
      </Stack>
      <Stack direction='row'>
        <Stack justifyContent='center' visibility={isSelected ? 'visible' : 'hidden'}>
          <IconButton data-testid='move-top' onClick={moveTop}>
            <KeyboardDoubleArrowUp />
          </IconButton>
          <IconButton data-testid='move-up' onClick={moveUp}>
            <KeyboardArrowUp />
          </IconButton>
          <IconButton data-testid='move-down' onClick={moveDown}>
            <KeyboardArrowDown />
          </IconButton>
          <IconButton data-testid='move-bottom' onClick={moveBottom}>
            <KeyboardDoubleArrowDown />
          </IconButton>
        </Stack>
        <Stack maxHeight='80vh' overflow='scroll'>
          <ItemsList
            items={selectedGroup.articles}
            primaryText='title'
            avatarProperty='smallImageUrl'
            onClick={toggleSelectedArticle}
            checkedProperty={isChecked}
          />
        </Stack>
      </Stack>
      <Stack direction='row-reverse'>
        <Button data-testid='updateOrder' disabled={!isDirty} onClick={updateOrg}>
          {i18next.t('order:update')}
        </Button>
      </Stack>
    </Stack>
  );
};
