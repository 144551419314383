import { DesktopMacOutlined, PhoneAndroidOutlined } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { Stack, css } from '@mui/system';
import React, { useMemo, useState } from 'react';
import phone from './phonemock.svg';
import { ArticlePreviewData } from './types';
import { createIframeContent } from './utils';

const DESKTOP_WIDTH = '1024px';
const MOBILE_WRAPPER_WIDTH = '448px';
const ALL_ANIMATION = 'all 0.2s';

const container = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background-color: white;
  transition: ${ALL_ANIMATION};
  &.mobile {
    width: 600px;
    left: 160px;
  }

  .mobile-wrapper {
    position: relative;
    width: ${MOBILE_WRAPPER_WIDTH};
    margin: auto;
    transition: ${ALL_ANIMATION};

    img {
      pointer-events: none;
      display: block;
      max-width: 100%;
      width: ${MOBILE_WRAPPER_WIDTH};
      z-index: -1;
      transition: ${ALL_ANIMATION};

      scale: 1;
    }

    iframe {
      transition: ${ALL_ANIMATION};
      position: absolute;
      background: white;
      width: 400px;
      top: 72px;
      left: 24px;
      height: 725px;
    }
  }

  &.desktop {
    width: ${DESKTOP_WIDTH};
    .mobile-wrapper {
      width: ${DESKTOP_WIDTH};
      img {
        scale: 0;
      }
      iframe {
        width: ${DESKTOP_WIDTH};
        top: 0;
        left: 0;
        height: 890px;
      }
    }
  }
`;

interface ArticlePreviewProps {
  article?: ArticlePreviewData;
}

enum VIEW_MODE {
  mobile = 0,
  desktop = 1,
}

export const ArticleViewer: React.FC<ArticlePreviewProps> = ({ article }) => {
  const [viewMode, setViewMode] = useState(VIEW_MODE.mobile);
  const previewUrl = useMemo(() => {
    if (!article) return '';
    const contentBlob = createIframeContent(article);
    return URL.createObjectURL(contentBlob);
  }, [article]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setViewMode(newValue);
  };

  return (
    <Stack css={container} className={viewMode === VIEW_MODE.mobile ? 'mobile' : 'desktop'}>
      <Box paddingX={3}>
        <Tabs value={viewMode} onChange={handleChange}>
          <Tab label={<PhoneAndroidOutlined />} />
          <Tab label={<DesktopMacOutlined />} />
        </Tabs>
      </Box>
      <Box className='mobile-wrapper'>
        <img src={phone} alt='phone' className='phoneFrame' />
        <iframe src={previewUrl} title='articlePreview' frameBorder='0' width='100%' height='100%' />
      </Box>
    </Stack>
  );
};

export const MobileArticleViewer: React.FC<ArticlePreviewProps> = ({ article }) => {
  const previewUrl: string = useMemo(() => {
    if (!article) return '';
    const contentBlob = createIframeContent(article);
    return URL.createObjectURL(contentBlob);
  }, [article]);

  return (
    <Stack css={container} className='mobile'>
      <Box className='mobile-wrapper'>
        <img src={phone} alt='phone' className='phoneFrame' />
        <iframe src={previewUrl} title='articlePreview' frameBorder='0' width='100%' height='100%' />
      </Box>
    </Stack>
  );
};
