import { Controller } from 'react-hook-form';
import { SharedFieldProps } from './formTypes';
import { PasswordInputStrength } from './PasswordInputStrength';

interface FormPasswordStrengthProps extends SharedFieldProps {
  passwordFieldName: string;
}
export const FormPasswordStrength: React.FC<FormPasswordStrengthProps> = ({ control, passwordFieldName, required }) => {
  return (
    <Controller
      name={passwordFieldName}
      control={control}
      rules={{ required }}
      render={({ field: { value } }) => <PasswordInputStrength password={value} />}
    />
  );
};
