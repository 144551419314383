import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getHash } from './queryUtils';

const DEFAULT_OPTIONS = {};
const DEFAULT_DEPS: any[] = [];

export const useQuerySelect = <T, U, K>(
  queryFunc: (variables: T, options: any) => { data: U; isLoading: boolean; isError: boolean; error: any, isFetching: boolean },
  variable: T,
  select: (data: U) => K,
  options: any = DEFAULT_OPTIONS,
  deps: any[] = DEFAULT_DEPS
) => {
  const { data, isLoading, error, isError, isFetching } = queryFunc(variable, { ...options });
  const parsedData = useMemo(() => select(data), [isLoading, data, ...deps]);

  return { data, isError, error, parsedData, isLoading, isFetching };
};

const statusKey = ['__INTERNAL_STATUS__'];

export const useCachedQuerySelect = <T, U, K>(
  queryFunc: (variables: T, options: any) => { data: U; isLoading: boolean; isError: boolean; error: any },
  variable: T,
  select: (data: U) => K,
  options: any = DEFAULT_OPTIONS
) => {
  const queryClient = useQueryClient();
  const key = (queryFunc as any).getKey(variable);
  const stringKey = getHash(key) ?? '';
  const { isLoading, data } = queryFunc(variable, {
    ...options,
    onSuccess: (data: U) => {
      const selectData = select(data);
      queryClient.setQueryData(key, selectData);
      queryClient.setQueryData(statusKey, (old: any) => ({ ...old, [stringKey]: true }));
    },
  });

  const queriesStatus = queryClient.getQueryData(statusKey) as any;
  const isQueryReady = queriesStatus?.[stringKey];
  return { data: isQueryReady ? (data as unknown as K) : null, isLoading };
};
