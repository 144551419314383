import { useGetOrgsArticleGroupsQuery, useSetArticleGroupsMutation } from '@laguna/api/backoffice';
import { useAsyncEffect } from '@laguna/common/utils/hooks';
import { useState } from 'react';
import { Group } from './types';
import { moveItemsDown, moveItemsToBottom, moveItemsToTop, moveItemsUp } from './utils';
import { useSnackbar } from 'notistack';
import i18next from 'i18next';
import {
  getEnvSpecificMutationResult,
  getEnvSpecificQueriesResult,
  invalidateEnvSpecificCache,
} from '../../utils/getQueryResult';

export const useArticleOrder = (env: string, orgId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isDirty, setIsDirty] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedArticles, setSelectedArticles] = useState(new Set<string>());

  useAsyncEffect(async () => {
    const data = await getEnvSpecificQueriesResult(env || '', useGetOrgsArticleGroupsQuery);
    const orgGroup = data?.getOrgsArticleGroups.find((group) => group.orgId === orgId);
    setSelectedGroup(orgGroup);
  }, [env, orgId]);

  const moveUp = () =>
    setSelectedGroup((prev) => {
      if (!prev) return undefined;
      setIsDirty(true);
      const newArticles = moveItemsUp(selectedArticles, prev.articles);
      return { ...prev, articles: newArticles };
    });

  const moveDown = () =>
    setSelectedGroup((prev) => {
      if (!prev) return undefined;
      setIsDirty(true);
      const newArticles = moveItemsDown(selectedArticles, prev.articles);
      return { ...prev, articles: newArticles };
    });
  const moveTop = () =>
    setSelectedGroup((prev) => {
      if (!prev) return undefined;
      setIsDirty(true);
      const newArticles = moveItemsToTop(selectedArticles, prev.articles);
      return { ...prev, articles: newArticles };
    });
  const moveBottom = () =>
    setSelectedGroup((prev) => {
      if (!prev) return undefined;
      setIsDirty(true);
      const newArticles = moveItemsToBottom(selectedArticles, prev.articles);
      return { ...prev, articles: newArticles };
    });

  const isChecked = ({ id }: { id: string }) => selectedArticles.has(id);
  const toggleSelectedArticle = ({ id }: { id: string }) => {
    if (selectedArticles.has(id)) {
      setSelectedArticles((prev) => new Set([...prev].filter((articleId) => articleId !== id)));
    } else {
      setSelectedArticles((prev) => new Set(prev.add(id)));
    }
  };

  const updateOrg = async () => {
    if (!selectedGroup) {
      return;
    }
    try {
      await getEnvSpecificMutationResult(env, useSetArticleGroupsMutation, {
        setArticleGroupsParams: {
          orgId: selectedGroup.orgId,
          articleIds: selectedGroup.articles.map((article) => article.id),
        },
      });

      enqueueSnackbar(i18next.t('order:updateSuccess'), { variant: 'success' });
      setIsDirty(false);
      setSelectedArticles(new Set<string>());
      await invalidateEnvSpecificCache(env, useGetOrgsArticleGroupsQuery.getKey() as string[]);
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return {
    moveUp,
    moveDown,
    moveTop,
    moveBottom,
    isDirty,
    selectedGroup,
    toggleSelectedArticle,
    isChecked,
    updateOrg,
    isSelected: !!selectedArticles.size,
  };
};
