import { getBasePhone, preparePhoneForSubmit } from '@laguna/common/utils/phone';
import { logger } from '@laguna/logger';
import { Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { css } from '@mui/system';
import cc from 'classcat';
import { get, mapKeys, mapValues, set } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { buildLayoutFromFields } from './buildLayoutFromFields';
import { FormField } from './FormField';
import { formsStore } from './formsStore';
import { ButtonsProps, FieldsType, FieldType, FormProps, generateFormArgs, OrderedListField } from './formTypes';
import i18next from 'i18next';
export * from './formTypes';

const hiddenButtonCSS = css`
  display: none;
`;

type PathObj = { [key: string]: PathObj | boolean };
enum FormValidationStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  NoChanges = 'NoChanges',
}

type UseFormResetType = ReturnType<typeof useForm>['reset'];

function getDirtyPaths(obj: PathObj, parent = '', res: Array<string> = []) {
  for (const key in obj) {
    const propName = parent ? parent + '.' + key : key;
    const value = obj[key];

    if (typeof value === 'boolean') {
      if (value) res.push(propName);
    } else {
      getDirtyPaths(value, propName, res);
    }
  }

  return res;
}

const getOrderedArrayResponse = <T,>(res: Partial<T>, field: OrderedListField) => {
  const regex = new RegExp(`${field.name}:(\\d+):`);
  const rowsObject: { [key: string]: any } = {};

  for (const key in res) {
    const match = regex.exec(key);

    if (match) {
      const id = match[1];
      const newValue = { ...(rowsObject[id] || {}) };
      const newKey = key.split(':')[2];
      newValue[newKey] = res[key];
      rowsObject[id] = newValue;
    }
  }
  const rows: any[] = [];
  const orderArray = res[field.name as keyof T] as unknown as { id: string }[];
  orderArray.map(({ id }) => {
    rows.push(rowsObject[id]);
  });
  return rows;
};

// Get changed values
function buildRes<T>(paths: Array<string>, initValues: Partial<T>, newValues: Partial<T>) {
  let res = {};
  paths.forEach((path) => {
    const initValue = get(initValues, path);
    const newValue = get(newValues, path);

    // Add to mutation only if:
    // 1. values are different
    // 2. at least one is truthy (we can't do null -> "")
    if (initValue !== newValue && (!!initValue || !!newValue)) {
      res = set(res, path, newValue);
    }
  });
  return res as Partial<T>;
}

export interface generateFormReturn<T> {
  Form: (formProps: FormProps<T>) => JSX.Element;
  Buttons: ({ isLoading, onCancel }: ButtonsProps) => JSX.Element;
  controller: { submit: () => Promise<Partial<T> | null> };
}

type ConnectorType = {
  updateIsValid: (isValid: boolean) => void;
  onValidChange: (callback: (isValid: boolean) => void | undefined) => void;
  reset: UseFormResetType;
  defaultValues: Record<string, any>;
};

export function generateForm<T>({
  formId,
  fields: baseFields,
  useOnlyDirtyFields = true,
  allowNoChangesSubmit,
  useGrid,
  enableButtonsOnValid,
  validationMode,
}: generateFormArgs): generateFormReturn<T> {
  const formButtonsConnector: ConnectorType = {
    updateIsValid: () => undefined,
    onValidChange: function (callback: (isValid: boolean) => void) {
      this.updateIsValid = callback;
    },
    reset: () => undefined,
    defaultValues: {},
  };
  const fieldNamesMap = baseFields.reduce<{ [key: string]: FieldsType }>((acc, current) => {
    acc[current.name] = current;
    return acc;
  }, {});

  const submitPromise = { resolve: (result: Partial<T> | null) => null, reject: (params: any) => null };

  const innerController = {
    current: null as UseFormReturn<FieldValues, object> | null,
    initialData: undefined as any,
    buttonRef: undefined as HTMLButtonElement | undefined,
  };

  function Form({
    onSubmit,
    initialData,
    isLoading,
    className,
    disabled,
    error,
    onChange,
    useDivAsContainer,
  }: FormProps<T>) {
    const formActions = useForm({ mode: validationMode });
    const fields = useMemo(
      () =>
        baseFields.map((field) =>
          field.getDerivedState ? { ...field, ...field.getDerivedState(initialData) } : field
        ),
      [baseFields, initialData]
    );
    const defaultValues = useMemo(() => mapValues(mapKeys(fields, 'name'), 'defaultValue'), [fields]);
    const { control, handleSubmit, watch, setValue, formState, getValues, reset } = formActions;
    formButtonsConnector.reset = reset;
    formButtonsConnector.defaultValues = defaultValues;
    const { dirtyFields } = formState;
    const fieldsRef = useRef(dirtyFields);
    fieldsRef.current = dirtyFields;

    const validateForm = useCallback(() => {
      const dirtyPaths = getDirtyPaths(fieldsRef.current);
      if (dirtyPaths.length === 0 && !allowNoChangesSubmit) {
        return {
          res: null,
          status: FormValidationStatus.NoChanges,
        };
      }
      const values = getValues() as Partial<T>;
      const res = useOnlyDirtyFields ? buildRes<T>(dirtyPaths, initialData, values) : values;

      Object.keys(res)
        .filter((resKey) => fieldNamesMap[resKey])
        .forEach((key) => {
          const field = fieldNamesMap[key];
          if (field.type === FieldType.orderedList) {
            res[key as keyof T] = getOrderedArrayResponse(res, field) as any;
          }
          // prepare phone fields
          if (field.type === FieldType.phone) {
            const phone = res[key as keyof T] as unknown as string;
            const initialPhone = initialData[key as keyof T] as unknown as string;
            const { countryCode: defaultCountryCode } = getBasePhone(initialPhone || '');
            const countryCode = (res[(field.name + 'CountryCode') as keyof T] || defaultCountryCode || 'US') as string;
            res[field.name as keyof T] = preparePhoneForSubmit(phone, countryCode) as any;
          }
        });
      return {
        res,
        status:
          (Object.keys(res).length || allowNoChangesSubmit) && onSubmit
            ? FormValidationStatus.Valid
            : FormValidationStatus.Invalid,
      };
    }, [getValues, initialData, onSubmit]);

    useEffect(
      function subscribeToFormChangesAndUpdateValidate() {
        if (!innerController.current) {
          innerController.current = formActions;
          innerController.initialData = initialData;
        }
        if (enableButtonsOnValid || onChange) {
          const subscription = watch(async (value) => {
            await Promise.resolve(); // wait for dirty fields to update
            if (enableButtonsOnValid) {
              const { status } = validateForm();
              formButtonsConnector.updateIsValid(status === FormValidationStatus.Valid);
            }
            formActions.trigger();
            onChange?.(value as any);
          });
          return () => subscription.unsubscribe();
        }
      },
      [validateForm, watch, onChange, formActions]
    );

    const [noChangesMade, setNoChangesMade] = useState(false);
    useEffect(() => {
      setNoChangesMade(false);
    }, [dirtyFields]);

    useEffect(() => {
      formsStore.addFormActions(formId, formActions);
      return () => {
        formsStore.removeFormActions(formId);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formActions.formState.dirtyFields]);

    const _onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { res, status } = validateForm();
      if (status === FormValidationStatus.NoChanges) {
        setNoChangesMade(true);
      } else if (status === FormValidationStatus.Valid && res && onSubmit) {
        handleSubmit(
          () => onSubmit(res),
          (error) => logger.warn('invalid form submit', { error, formId, res })
        )();
      }
      submitPromise.resolve(res);
    };
    const onInvalid = (event: React.FormEvent<HTMLFormElement>) => {
      submitPromise.resolve(null);
    };

    const formLayout = useGrid ? buildLayoutFromFields(fields) : '';
    const formContent = useMemo(
      () => (
        <>
          {fields.map((field) => (
            <FormField
              key={field.name}
              field={field}
              initialData={initialData}
              control={control}
              watch={watch}
              setValue={setValue}
              formId={formId}
              disabled={disabled || isLoading}
            />
          ))}
          {noChangesMade && (
            <Typography variant='body2' color='error' data-testid='form-error'>
              No changes were made
            </Typography>
          )}
          {error && (
            <Typography variant='body2' color='error' data-testid='form-error'>
              {error}
            </Typography>
          )}
        </>
      ),
      [control, disabled, error, initialData, isLoading, noChangesMade, watch]
    );

    return useDivAsContainer ? (
      <div id={formId} css={formLayout} className={cc([className, { 'grid-form': useGrid }])}>
        {formContent}
      </div>
    ) : (
      <form
        css={formLayout}
        id={formId}
        onInvalid={onInvalid}
        onSubmit={_onSubmit}
        className={cc([className, { 'grid-form': useGrid }])}>
        <Button
          ref={(instance) => instance && (innerController.buttonRef = instance)}
          type='submit'
          form={formId}
          css={hiddenButtonCSS}
          disabled={isLoading || disabled}
        />
        {formContent}
      </form>
    );
  }

  function Buttons({
    isLoading,
    onCancel,
    onReset,
    additionalButtons = [],
    submitLabel,
    cancelLabel,
    resetLabel,
    submitButtonProps = {},
    cancelButtonProps = {},
    resetButtonProps = {},
    buttonsClassName,
  }: ButtonsProps) {
    const [isFormValid, setIsFormValid] = useState(!enableButtonsOnValid);
    useEffect(() => {
      if (enableButtonsOnValid) {
        formButtonsConnector.onValidChange((isValid: boolean) => {
          setIsFormValid(isValid);
        });
      }
    }, []);

    return (
      <div className={buttonsClassName}>
        {onCancel && (
          <Button
            onClick={onCancel}
            disabled={isLoading}
            color='inherit'
            data-testid='generatedFormCancel'
            {...cancelButtonProps}>
            {cancelLabel || i18next.t('common:Cancel')}
          </Button>
        )}
        {onReset && (
          <Button
            onClick={() => {
              formButtonsConnector.reset(formButtonsConnector.defaultValues, { keepDefaultValues: true });
              onReset();
            }}
            disabled={isLoading}
            color='inherit'
            data-testid='generatedFormReset'
            {...resetButtonProps}>
            {resetLabel || i18next.t('common:Reset')}
          </Button>
        )}
        {additionalButtons.map(({ label, onClick, buttonProps }) => (
          <Button key={label} onClick={onClick} disabled={isLoading} {...buttonProps}>
            {label}
          </Button>
        ))}
        <Button
          type='submit'
          color='primary'
          form={formId}
          data-testid='generatedFormSubmit'
          {...submitButtonProps}
          disabled={isLoading || !isFormValid || submitButtonProps.disabled}>
          {isLoading ? <CircularProgress size={16} /> : submitLabel || 'Submit'}
        </Button>
      </div>
    );
  }

  const controller = {
    submit: (): Promise<T> => {
      return new Promise((resolve, reject) => {
        submitPromise.resolve = resolve as any;
        submitPromise.reject = reject as any;
        if (!innerController.buttonRef) {
          logger.debug('missing button ref in generate form controller');
        }
        innerController.buttonRef?.click();
      });
    },
  };

  return { Form, Buttons, controller };
}
