import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontWeight: 600,
        },
      },
      defaultProps: {
        underline: 'hover',
        fontWeight: 600,

      },
    },
  };
}
