import { Article } from '@laguna/api';
import { useGetArticlesQuery } from '@laguna/api/backoffice';
import { logger } from '@laguna/logger';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArticleContent } from './ArticleContent';

export const EditArticle: React.FC = () => {
  const { articleId } = useParams();
  const { data, isLoading } = useGetArticlesQuery();
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    if (data) {
      const foundArticle = data.getArticles.find((item) => item.id === articleId);
      if (foundArticle) {
        setArticle(foundArticle);
      } else {
        logger.error(`Article ${articleId} not found`);
      }
    }
  }, [data]);

  return isLoading || !article ? <CircularProgress /> : <ArticleContent article={article} />;
};
