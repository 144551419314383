import { ReactNode, useCallback, useMemo, useState } from 'react';
import { modalStore } from './modalStore';
import { useModal } from './useModal';
import { v4 } from 'uuid';

interface ConfirmOptions {
  disableCloseOnClickOutSide: boolean;
  hideCancelButton: boolean;
  okLabel: string;
  cancelLabel: string;
}

const ConfirmOptionsDefaults: ConfirmOptions = {
  disableCloseOnClickOutSide: false,
  hideCancelButton: false,
  okLabel: '',
  cancelLabel: '',
};

export const useConfirm = () => {
  const [id] = useState(`confirm-${v4()}`);
  const initialData = useMemo(() => ({ id, title: '' }), []);
  const { setShow } = useModal(initialData);
  const confirm = useCallback(
    (title: ReactNode = '', content: ReactNode = '', options: Partial<ConfirmOptions> = ConfirmOptionsDefaults) => {
      const confirmOptions = { ...ConfirmOptionsDefaults, ...options };
      const { disableCloseOnClickOutSide, okLabel, hideCancelButton, cancelLabel } = confirmOptions;
      return new Promise<boolean>((res) => {
        modalStore.updateModal({
          title,
          content,
          disableCloseOnClickOutSide,
          id,
          okButtonProps: {
            label: okLabel,
            onClick: (close) => {
              res(true);
              close();
            },
          },
          cancelButtonProps: {
            hidden: hideCancelButton,
            label: cancelLabel,
            onClick: (close) => {
              res(false);
              close();
            },
          },
        });
        setShow(true);
      });
    },
    [id, setShow]
  );

  return { confirm };
};
