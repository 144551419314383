import { CommonBarrierTypeFragment } from '@laguna/api';
import { GetTranscriptQuery } from '@laguna/api/harmony';
import { identity, orderBy } from 'lodash';
import { TranscriptRow, Chunk } from './types';

const regex = /^\d{2}:\d{2}:\d{2}[a-zA-Z]*:/i;

export const chunkWithTooltip = (metadata: Chunk, tooltip: string) => {
  return {
    ...metadata,
    tooltip,
    highlight: true,
  };
};
export const chunkWithBarrier = (metadata: Chunk, barrier?: CommonBarrierTypeFragment) => {
  const tooltip = barrier ? 'Barrier: ' + barrier.description : '';
  return chunkWithTooltip(metadata, tooltip);
};
export const serverToMetadata = (data: GetTranscriptQuery): Chunk[] | undefined => {
  return data.getTranscript?.barriers?.flatMap(
    (barrier) =>
      barrier.context?.metadata?.transcript.map((meta: Chunk) =>
        chunkWithBarrier(meta, barrier.type as CommonBarrierTypeFragment)
      ) || []
  );
};
export const buildMetadata = (
  selectedText: string,
  filteredTranscription: TranscriptRow[],
  startId: string,
  endId?: string
) => {
  const metadata: Chunk[] = [];
  //split the text into paragraphs and remove empty texts (by splitting new lines)
  const splittedText = selectedText.split('\n').filter(identity);
  //remove prefix on some paragraphs for example 00:44:23 member:
  const paragraphArray = splittedText.map((text) => text.replace(regex, ''));
  const firstIndex = filteredTranscription.findIndex(({ id }) => id === startId);

  //single paragraph selection
  if (startId === endId || !endId) {
    const paragraphText = paragraphArray[0];
    const start = filteredTranscription[firstIndex].text.indexOf(paragraphText);
    const end = start + paragraphText.length;
    metadata.push({ id: startId, start, end });
  }
  //multi paragraph selection
  else {
    const secondIndex = filteredTranscription.findIndex(({ id }) => id === endId);
    const [minIndex, maxIndex] = firstIndex < secondIndex ? [firstIndex, secondIndex] : [secondIndex, firstIndex];
    const start = filteredTranscription[minIndex].text.indexOf(paragraphArray[0]);
    const end = paragraphArray.at(-1)?.length ?? 0;

    for (let index = minIndex; index <= maxIndex; index++) {
      const row = filteredTranscription[index];
      const { id, text } = row;

      if (minIndex === index) {
        metadata.push({ start, id, end: text.length });
      } else if (maxIndex === index) {
        metadata.push({ start: 0, id, end: end });
      } else {
        metadata.push({ start: 0, id, end: text.length });
      }
    }
  }

  return { metadata, paragraphArray };
};

const escapeRegExp = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const getIntersectedChunks = (highlights: Chunk[], meta: Chunk) => {
  const intersections: { highlight: Chunk; type: 'start' | 'end' | 'split' | 'replace' }[] = [];
  highlights.forEach((highlight) => {
    if (highlight.start > meta.end || highlight.end < meta.start) {
      return null;
    }
    if (meta.start > highlight.start && meta.end < highlight.end) {
      intersections.push({ highlight, type: 'split' });
    } else if (meta.start < highlight.start && meta.end > highlight.end) {
      intersections.push({ highlight, type: 'replace' });
    } else if (meta.end < highlight.end) {
      intersections.push({ highlight, type: 'start' });
    } else if (meta.start < highlight.end) {
      intersections.push({ highlight, type: 'end' });
    }
  });
  return intersections;
};

const updateHighlights = (highlights: Chunk[]) => (chunk: Chunk) => {
  const intersections = getIntersectedChunks(highlights, chunk);
  const item = { ...chunk, highlight: true };
  intersections?.forEach(({ type, highlight }) => {
    switch (type) {
      case 'replace':
        {
          const index = highlights.findIndex((h) => h === highlight);
          highlights.splice(index, 1);
        }
        break;
      case 'split': {
        const oldEnd = highlight.end;
        highlight.end = item.start;
        highlights.push({ ...highlight, start: item.end, end: oldEnd });
        break;
      }
      case 'start':
        highlight.start = item.end;
        break;
      case 'end':
        highlight.end = item.start;
        break;
    }
  });

  highlights.push(item);
};

const buildIndexArrayIncludeEmpty = (sortedHighlights: Chunk[], indexes: Chunk[], text: string) => {
  sortedHighlights.forEach((match, index) => {
    const matchIndex = match.start;
    const endSearch = match.end;
    if (index === 0) {
      //match on the first index
      if (matchIndex === index) {
        indexes[0] = match;
      } else {
        indexes[0] = { start: 0, end: matchIndex, tooltip: '', highlight: false };
        indexes.push(match);
      }
    } else {
      const lastValue = indexes[indexes.length - 1];
      if (matchIndex > lastValue.end) {
        indexes.push({ start: lastValue.end, end: matchIndex, tooltip: '', highlight: false });
      }
      indexes.push(match);
    }
    if (index === sortedHighlights.length - 1 && endSearch < text.length) {
      indexes.push({ start: endSearch, end: text.length, tooltip: '', highlight: false });
    }
  });
  return indexes;
};

export const buildSearchResult = (text: string, searchText?: string, metadata: Chunk[] = []) => {
  const indexes: Chunk[] = [{ start: 0, end: text.length, tooltip: '', highlight: false }];
  if (!searchText && !metadata) {
    return indexes;
  }
  const highlights: Chunk[] = [];
  metadata.forEach(updateHighlights(highlights));
  if (searchText) {
    const searchTextLength = searchText.length;
    const matches = [...text.matchAll(new RegExp(escapeRegExp(searchText), 'gi'))].map((match) => ({
      start: match.index!,
      end: match.index! + searchTextLength,
      highlight: true,
      tooltip: '',
    }));
    matches.forEach(updateHighlights(highlights));
  }

  const sortedHighlights = orderBy(highlights, 'start');
  return buildIndexArrayIncludeEmpty(sortedHighlights, indexes, text);
};
