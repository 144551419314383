import { FCC } from '@laguna/types';
import { Avatar, Link, MenuItem, Theme } from '@mui/material';
import { css } from '@mui/system';
import { signOut } from 'aws-amplify/auth';
import { useMemo } from 'react';
import { IconPopupMenu } from '../PopupMenu';
import { ToggleThemeMode } from './ToggleThemeMode';

// This CSS is critical for UX. It ensures that the <a> tag fully overlaps the
// parent <li> so that when you click on the item, you're also clicking on the link.
const menuItemCss = (theme: Theme) => css`
  padding: 0;

  > a {
    padding: 6px 16px;
    text-decoration: none;
    width: 100%;
    display: flex;
    gap: ${theme.spacing(2)};
  }
`;

interface AppProfileProps {
  onSignOut?: VoidFunction;
  name?: string;
  avatar?: string;
  showThemeModeToggle?: boolean;
}

export const AppProfile: FCC<AppProfileProps> = ({
  onSignOut,
  name,
  avatar = '',
  showThemeModeToggle = false,
  children,
}) => {
  const onSignoutCallback = useMemo(() => (onSignOut ? onSignOut : () => signOut()), [onSignOut]);

  return (
    <IconPopupMenu icon={<Avatar alt='Profile Picture' src={avatar} data-test='userAvatar' />}>
      {children}
      {showThemeModeToggle && <ToggleThemeMode />}
      <MenuItem css={menuItemCss}>
        <Link id='sign-out' data-testid='menuItemSignOut' onClick={onSignoutCallback}>
          Sign out {name ? `(${name})` : ''}
        </Link>
      </MenuItem>
    </IconPopupMenu>
  );
};
