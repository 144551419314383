import { useAsyncEffect, useIsMounted } from '@laguna/common/utils/hooks';
import { FormControl, FormHelperText } from '@mui/material';
import { debounce } from 'lodash';
import { useState } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { gridArea } from '../formStyles';
import { AsyncAutocompleteField, SharedFieldProps } from '../formTypes';
import { Autocomplete } from './Autocomplete';

interface AsyncAutocompleteInputProps extends SharedFieldProps, Omit<AsyncAutocompleteField, 'type' | 'validate'> {}

type RenderType = {
  field: ControllerRenderProps<FieldValues, string>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

const FormAutocomplete = (props: AsyncAutocompleteInputProps & RenderType) => {
  const {
    field: { onChange },
    fieldState: { error },
  } = props;
  const { fetchItems, fetchOnMount, disabled, defaultValue, label, required, name, ...rest } = props;
  const [values, setValues] = useState<string[]>([]);
  const [_value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  const onInputChange = async (event: any, value: string) => {
    if (!isMounted.current) return;
    setIsLoading(true);
    setValue(value);
    const _values = await fetchItems(value);
    if (!isMounted.current) return;
    setIsLoading(false);
    setValues(_values);
  };

  const onFocus = () => {
    if (!disabled && !_value) {
      onInputChange(undefined, defaultValue ? defaultValue : ' ');
    }
  };

  useAsyncEffect(async () => {
    if (fetchOnMount && defaultValue) {
      await onInputChange(undefined, defaultValue);
      onChange(defaultValue);
    }
  }, []);

  const debouncedOnInputChange = debounce(onInputChange, 500);
  return (
    <FormControl
      fullWidth
      variant='outlined'
      disabled={disabled}
      required={!!required}
      css={gridArea(name)}
      className={'generate-form-item-' + name}>
      <Autocomplete
        onChange={(event, data) => onChange(data)}
        onInputChange={debouncedOnInputChange}
        label={required ? label + ' *' : label}
        disabled={disabled}
        defaultValue={defaultValue}
        name={name}
        onFocus={onFocus}
        options={values}
        loading={isLoading}
        {...rest}
      />
      <FormHelperText>{error ? error.message : null}</FormHelperText>
    </FormControl>
  );
};

const AsyncAutocompleteInput: React.FC<AsyncAutocompleteInputProps> = (props) => {
  const { name, control, defaultValue, required } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={(render) => <FormAutocomplete {...props} {...render} />}
    />
  );
};

export default AsyncAutocompleteInput;
