import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import i18next from 'i18next';
import React from 'react';
import noResultsImage from './noresults.svg';

interface NoResultsFoundProps {
  search?: boolean;
  noData?: boolean;
}
const getCopy = ({ search, noData }: NoResultsFoundProps) => {
  if (noData) return '';
  if (search) return i18next.t('common:emptyStates.tryNewSearch');
  return i18next.t('common:emptyStates.tryChangingFilters');
};
export const NoResultsFound: React.FC<NoResultsFoundProps> = ({ search, noData }) => {
  const text = getCopy({ search, noData });
  return (
    <Stack alignItems='center' justifyContent='center'>
      <img src={noResultsImage} alt='no result' />
      <Typography>{i18next.t('common:emptyStates.noResultsFound')}</Typography>
      <Typography>{text}</Typography>
    </Stack>
  );
};
