import { Check } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React from 'react';

interface TaggingJobStatusProps {
  isUpdating: boolean;
  isPristine: boolean;
}

export const TaggingJobStatus: React.FC<TaggingJobStatusProps> = ({ isUpdating, isPristine }) => {
  if (isUpdating) {
    return <CircularProgress size='1em' />;
  }
  return <Check color={isPristine ? 'success' : 'disabled'} />;
};
