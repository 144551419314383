import { capitalizeCamelCase } from '@laguna/common/utils/general';
import { DataTable } from '@laguna/components/DataTable';
import Loader from '@laguna/components/Loader';
import { DataTableColumn } from '@laguna/types';
import { Chip, css, Stack, Theme, Tooltip, Typography } from '@mui/material';
import { useMultipleOrgPromptConfig } from '../../../hooks/useMultipleOrgPromptConfig';
import { useUpdateConfigPromptModal } from './useUpdateConfigPromptModal/useUpdateConfigPromptModal';
import { getFieldWidth } from './useUpdateConfigPromptModal/utils';

const promptCellCss = (theme: Theme) => css`
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing(1)};
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

const rowCss = css`
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  p {
    text-overflow: ellipsis;
    flex-wrap: wrap;
    overflow: hidden;
  }
`;

const columns: DataTableColumn[] = [
  {
    name: 'env',
    label: 'Env',
    sticky: 'left',
  },
  {
    name: 'orgName',
    label: 'Name',
    sticky: 'left',
    renderItem: (value: any, metadata: any) => {
      const prodEnv = metadata.env !== 'develop';
      if (prodEnv) {
        return (
          <Tooltip title={value}>
            <Chip label={value} color='error' />
          </Tooltip>
        );
      }
      return (
        <Stack css={rowCss}>
          <Typography>{value}</Typography>
        </Stack>
      );
    },
  },
];

export const ConfigPrompts: React.FC = () => {
  const { updateConfigPrompt } = useUpdateConfigPromptModal();
  const { data = [], isLoading, promptKeys } = useMultipleOrgPromptConfig();
  const tableData = data.map((config) => ({
    metadata: config,
    ...config,
  }));
  const promptColumns =
    promptKeys.map((key) => ({
      name: key as string,
      label: capitalizeCamelCase(key as string),
      width: getFieldWidth(key as string),
      renderItem: (value: any, metadata: any) => {
        const isTurnedOn = value?.requestType || (value?.requestType === undefined && value?.version);

        return (
          <Stack
            css={promptCellCss}
            gap={1}
            onClick={() => {
              updateConfigPrompt({
                env: metadata.env,
                orgName: metadata.orgName,
                configName: metadata.name,
                promptKey: value.key,
              });
            }}>
            <Typography>{isTurnedOn ? value?.version : '-'}</Typography>
          </Stack>
        );
      },
    })) || [];
  if (isLoading) return <Loader />;
  return (
    <Stack height='90vh'>
      <DataTable
        tableWidthInVW={40}
        tableId='configPrompts'
        title='Config Prompts'
        data={tableData}
        isLoading={isLoading}
        columns={[...columns, ...promptColumns]}
      />
    </Stack>
  );
};
