import { css, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { identity } from 'lodash';
import { Controller } from 'react-hook-form';
import { BasicTooltip } from '../BasicTooltip/BasicTooltip';
import { gridArea } from './formStyles';
import { RadioField, SharedFieldProps } from './formTypes';

const lineCss = css`
  display: flex;
  justify-content: space-between;
`;

interface RadioButtonsProps extends SharedFieldProps {
  values: RadioField['values'];
  direction: RadioField['direction'];
  transformValues: RadioField['transformValues'];
  getTooltipForValue?: (value: string) => string | undefined;
}
export const RadioButtons: React.FC<RadioButtonsProps> = ({
  name,
  label,
  control,
  values,
  defaultValue,
  required,
  disabled,
  direction = 'column',
  transformValues = identity,
  getTooltipForValue,
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={{ required }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControl
        variant='outlined'
        disabled={disabled}
        required={!!required}
        fullWidth
        css={gridArea(name)}
        className={'generate-form-item-' + name}>
        <div css={direction === 'fullRow' ? lineCss : undefined}>
          <FormLabel id={`radio-buttons-group-${name}`}>{label}</FormLabel>
          <RadioGroup
            aria-labelledby={`radio-buttons-group-${name}`}
            data-testid={`generated-form-radio-${name}`}
            name={name}
            value={value}
            row={direction !== 'column'}
            onChange={onChange}>
            {Object.entries(values).map(([key, value]) => (
              <BasicTooltip key={key} title={getTooltipForValue?.(value)} disableArrow>
                <FormControlLabel value={key} control={<Radio />} label={transformValues(value)} />
              </BasicTooltip>
            ))}
          </RadioGroup>
        </div>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    )}
  />
);
