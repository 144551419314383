import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiAccordion-root': {
            marginBottom: theme.spacing(2),
          },
          '&:before': { display: 'none' },
          '&.MuiAccordion-rounded': {
            borderRadius: Number(theme.shape.borderRadius) * 2,
          },
          '&.Mui-expanded': {
            boxShadow: theme.customShadows.z8,
            borderRadius: Number(theme.shape.borderRadius) * 2,
            marginBottom: `${theme.spacing(2)} !important`,
            marginTop: '0 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          margin: 0,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit',
            },
          },
        },
        content: {
          margin: `${theme.spacing(2)} 0`,
        },
        expandIconWrapper: {
          color: 'inherit',
        },
      },
    },
  };
}
