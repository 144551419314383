import { countryPrefixes, getBasePhone, phoneValidation, phoneValidationWithEmpty } from '@laguna/common/utils/phone';
import { css, Stack, Theme, Typography } from '@mui/material';
import { FC, useRef } from 'react';
import { Controller, useController } from 'react-hook-form';
import { SimpleSelect } from '../SimpleSelect';
import TextInput, { TextInputProps } from './TextInput';

interface PhoneInputProps extends Omit<TextInputProps, 'testId'> {
  testId: { 'data-testid': string };
  hideCountryCode?: boolean;
}

const selectCss = css`
  flex: 1;
  min-width: 110px;
  margin: 0;
`;

const selectErrorCss = (theme: Theme) => css`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.error.main};
  }
`;

const phoneCss = css`
  justify-content: space-between;
  align-items: center;
  margin: 0;
  .MuiFormControl-root {
    margin: 0 !important;
  }
`;

const COUNTRY_PREFIX = '+1';

const countryCodesValues: { [k: string]: string } = {};
Object.keys(countryPrefixes).map((key) => {
  countryCodesValues[key] = key;
});
const transformValues = (key: string) => key + ' (' + countryPrefixes[key]?.value + ')';
const emptyDefault = { number: '', countryCode: '' };

export const PhoneInput: FC<PhoneInputProps> = ({
  defaultValue,
  control,
  required,
  name,
  autoComplete,
  testId,
  inputProps,
  formId,
  hideCountryCode,
  ...rest
}) => {
  const { number: defaultPhone, countryCode: defaultCountryCode } = defaultValue
    ? getBasePhone(defaultValue as string)
    : emptyDefault;
  const { formState } = useController({ name, control });
  const countryCodeRef = useRef(defaultCountryCode || 'US');
  const validatePhone = (number: string) => {
    const countryCode = countryCodeRef.current;
    return required ? phoneValidation(number, countryCode) : phoneValidationWithEmpty(number, countryCode);
  };
  const error = formState.errors?.[name];

  return (
    <Stack className='phoneInputContainer' gap={!!error ? 1 : 0}>
      <Stack key={name} direction='row' gap={1} css={phoneCss}>
        {!hideCountryCode && (
          <Controller
            {...rest}
            control={control}
            name={name + 'CountryCode'}
            defaultValue={defaultCountryCode || 'US'}
            render={({ field: { onChange, value } }) => (
              <SimpleSelect
                data-testid='phoneCountryCodeSelect'
                value={value}
                values={countryCodesValues}
                transformValues={transformValues}
                onChange={(event) => {
                  onChange(event);
                  countryCodeRef.current = event.target.value;
                }}
                css={[selectCss, error?.message ? selectErrorCss : undefined]}
              />
            )}
          />
        )}
        <TextInput
          key={name}
          name={name}
          control={control}
          formId={formId}
          {...rest}
          required={!!required}
          defaultValue={defaultPhone}
          rows={1}
          hideErrorMsg
          type='number'
          validate={validatePhone}
          InputProps={hideCountryCode ? { startAdornment: rest?.prefix || COUNTRY_PREFIX } : undefined}
          inputProps={{
            autoComplete,
            ...inputProps,
            ...testId,
          }}
        />
      </Stack>
      <Typography variant='caption' color={(theme: Theme) => theme.palette.error.main}>
        {error?.message}
      </Typography>
    </Stack>
  );
};
