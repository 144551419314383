import { delay } from '@laguna/common/utils/general';
import parser, { DayOfTheMonthRange, DayOfTheWeekRange, MonthRange } from 'cron-parser';
import { subMilliseconds } from 'date-fns';

export const getDatesByCronExpression = async (
  cronExpression: string,
  startDate: Date,
  endDate: Date
): Promise<Date[]> => {
  const res: Date[] = [];
  const interval = parser.parseExpression(cronExpression, {
    currentDate: subMilliseconds(startDate, 1),
    endDate: endDate,
    iterator: true,
    utc: true,
  });
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const { value } = interval.next();
      res.push(value.toDate());
      await delay(0);
    } catch (e) {
      return res;
    }
  }
};

export const getDatesByCronExpressionSync = (cronExpression: string, startDate: Date, endDate: Date) => {
  const res: Date[] = [];
  const interval = parser.parseExpression(cronExpression, {
    currentDate: subMilliseconds(startDate, 1),
    endDate: endDate,
    iterator: true,
  });
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const { value } = interval.next();
      res.push(value.toDate());
    } catch (e) {
      return res;
    }
  }
};

export const getCronFields = (overrides: any) => {
  const expression = parser.parseExpression('0 * * * *');
  const fields = JSON.parse(JSON.stringify(expression.fields));
  return { ...fields, ...overrides };
};

export const getIsEveryMonth = (monthsArray: readonly MonthRange[]) => monthsArray.length === 12;
export const getIsEveryDayInMonth = (cronExpression: string) => {
  const cronArray = cronExpression.split(' ');
  const daysInMonth = cronArray[cronArray.length - 3];
  return daysInMonth === '*';
};
export const getIsEveryDayInWeek = (daysArray: readonly DayOfTheWeekRange[]) => daysArray.length >= 7;
export const getIsSpecificDate = (monthsArray: readonly MonthRange[], daysArray: readonly DayOfTheMonthRange[]) =>
  monthsArray.length === 1 && daysArray.length === 1;
