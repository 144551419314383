import { EventSocketMessage, SocketHandler } from './types';

export const handleEventSocketMessages: SocketHandler = async (data: EventSocketMessage, onTranscriptionEvent) => {
  const event = {
    name: data.payload.type,
    payload: {
      recordingId: data.payload.content.entityId,
    },
  };
  onTranscriptionEvent(event);
};
